import JoindGuildContainer from './styled'
import { useNavigate, useParams } from 'react-router-dom'
import { links } from 'apps'
import { BUTTON_BG } from 'assets/images'

const PutHorseToGuild = () => {
  const navigate = useNavigate()
  const { guild_id } = useParams()

  const handlePutHorse = () => {
    navigate(guild_id ? links.guild.guildFarmId(guild_id) : links.guild.guildFarm())
  }

  return (
    <JoindGuildContainer>
      <div className='btn-container' onClick={handlePutHorse}>
        <img src={BUTTON_BG} alt='' className='background-btn' />
        <div className='btn-name'>
          <span>Put Horse</span>
          <span>To Guild Farm</span>
        </div>
      </div>
    </JoindGuildContainer>
  )
}

export default PutHorseToGuild
