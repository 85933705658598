import styled from 'styled-components'

interface ProfileCloneStyledProp {
  total?: number
}

const ProfileCloneStyled = styled.div<ProfileCloneStyledProp>`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .header-top {
    width: 800px;
    border: 1px solid;
    border-image-source: linear-gradient(90deg, rgba(18, 21, 32, 0) 50%, #a3b5fb 52.54%, rgba(18, 21, 32, 0) 100%);
    background: linear-gradient(90deg, rgba(18, 21, 32, 0) 0%, #a3b5fb 52.54%, rgba(18, 21, 32, 0) 100%);

    .infor-body {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      padding: 14px;
      width: 100%;
      background: linear-gradient(90deg, rgb(18 21 32 / 97%) 0%, rgb(37 43 67) 53%, rgb(18 21 32) 100%);
    }
  }

  .avatar {
    width: 100px;
    height: 100px;
    display: inline-block;
    vertical-align: middle;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
  }

  .avatar img {
    width: 100%;
  }

  .name-profile {
    display: flex;
    font-size: 22px;
  }

  .address-wallet {
    display: flex;
    font-size: 16px;
    span {
      color: #bebebe;
    }
  }

  .disableH2H {
    display: flex;
    font-size: 14px;
    span {
      color: #bebebe;
      margin-left: 12px;
    }
  }

  .undisableH2H {
    font-size: 20px;
    .setting {
      button {
        background-color: #121520;
        border: none;
      }
    }
    .connect-h2h {
      button {
        background-color: #121520;
        border: none;
      }
    }
    span {
      color: #bebebe;
      margin-left: 7px;
    }
  }

  .attribute-container {
    width: 100%;
    .width-attributeBox {
      // flex: 0 0 auto;
      min-width: 11%;
      margin-right: 1%;
      margin-left: 1%;
    }
  }

  .search-my-horse {
    display: flex;
    margin-top: 16px;
    width: 1000px;
    justify-content: space-between;
    .my-profile{
      .items{
        margin-left: 28px;
      }
    }
    .title {
      font-size: 24px;
      text-transform: uppercase;
      text-decoration: unset;
      &.active, &:hover{
        color: #4EF076;
        position: relative;
        img {
          position: absolute;
          bottom: -7px;
          left: 0;
          width: 109px;
        }
      }
    }
    .search-container {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      & > div {
        height: 34px;
      }
    }
  }

  .my-horse-container {
    background-color: #121520;
    padding: 32px 0px;
    width: 1000px;

    ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
      padding: 32px;
    }

    ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
      padding: 16px 12px;
    }
    ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
      overflow-x: scroll;
    }

    .header-horse {
      font-size: 16px;
      span {
        color: #8d8d8d;
      }

      .horse {
        padding-left: 0;
        text-align: center;
      }

      .name {
        padding-left: 0;
        text-align: start;
      }

      .blood-line {
        padding-left: 0;
        text-align: start;
      }
      .classes {
        width: 12%;
      }

      .status {
        margin-left: 0;
      }
    }

    .my-horse-table {
      width: 1000px;
      border-collapse: separate;
      border-spacing: 0px 16px;

      ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
        min-width: 760px;
      }

      tr {
        background-color: #252b43;
      }
    }

    .margin-left-11 {
      margin-left: 11px;
    }

    .width-5 {
      width: 5%  !important;
    }

    .width-4 {
      width: 4%  !important;
    }

    .width-8 {
      width: 8%  !important;
    }

    .width-12 {
      width: 12%  !important;
    }

    .width-13 {
      width: 13%  !important;
    }

    .width-10 {
      width: 10%  !important;
    }
    .width-15 {
      width: 15% !important;
    }

    .width-16 {
      width: 16% !important;
    }

    .width-17 {
      width: 17%  !important;
    }

    .width-18 {
      width: 18%  !important;
    }

    .width-19 {
      width: 19%  !important;
    }

    .width-20 {
      width: 20%  !important;
    }

    .width-21 {
      width: 21%  !important;
    }

    .width-22 {
      width: 22%  !important;
    }

    .width-27 {
      width: 27%  !important;
    }

    .width-25 {
      width: 25%  !important;
    }

    .paginate {
      display: flex;
      justify-content: center;
      .pagination {
        .page-item {
          .page-link {
            z-index: 3;
            color: #fff;
            background-color: #191d2c;
            border-color: #51c21a;
            &:focus {
              box-shadow: none;
            }
          }
        }

        .page-item.active {
          .page-link {
            z-index: 3;
            color: #fff;
            background-color: #135200;
            border-color: #135200;
            font-weight: 700;
          }
        }

        .page-item.disabled {
          cursor: not-allowed;
          .page-link {
            z-index: 3;
            color: #fff;
            background-color: #191d2c;
            border-color: #191d2c;
          }
        }

        .page-item:hover {
          .page-link {
            z-index: 3;
            color: #fff;
            background-color: #135200;
            border-color: #191d2c;
          }
        }
      }
    }
  }

  .horse__name__cus {
    .row-name {
      /* width: 200px !important; */

      .name__horse {
        /* flex: 1; */
        max-width: 200px;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;

        .name {
          padding-left: 5px;
        }
      }
    }
  }
  .vip__pass {
    width: 40px;
    margin-left: -10px;
  }
  .gender {
    vertical-align: middle;
  }
`

export default ProfileCloneStyled
