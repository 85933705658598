/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { Form, Spin, notification } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { GUILD_MESSAGE } from 'i18n/constants'
import { handleAsyncRequest } from 'utils/helper'
import useAppSelector from 'hooks/useAppSelector'
import guildApi from 'apis/guildApi'
import links from 'apps/links'
import DisbandGuildContainer from './styled';
import Button from 'shared/Button'
import InputTextarea from 'shared/InputTextarea'
import { STRING, NUMBER } from 'apps/constants'
import ModalConfirmEditGuild from 'features/Guild/components/ModalConfirmEditGuild'

const DisbandGuild = () => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [api, contextHolder] = notification.useNotification()
  const [loading, setLoading] = useState<boolean>(true)
  const { profile } = useAppSelector(state => state)
  const [showModalEditIcome, setShowModalEditIncome] = useState<boolean>(false)
  const [dataDisbandGuild, setDataDisbandGuild] = useState<any>(null)
  const [loadingUpdateIncome, setLoadingUpdateIncome] = useState<boolean>(false)
  const [disbandGuildError, setDisbandGuildError] = useState('')

  useEffect(() => {
    getManagerData()
  }, [profile])

  const getManagerData = async () => {
    const [error, result]: any = await handleAsyncRequest(guildApi.getGuildInformation())

    if (error) {
      openNotification('ERROR', error.message || error.errors[NUMBER.ZERO]?.message)
      if (error?.code === "E1000") {
        navigate(links.guild.joinGuild())
      }
    }

    if (result) {
      setDataDisbandGuild(() => result?.data?.id)
    }
    setLoading(() => false)
    
    const description = `${t(`${GUILD_MESSAGE}.note1_band`)}\r\n${t(`${GUILD_MESSAGE}.note2_band`)}\r\n${t(`${GUILD_MESSAGE}.note3_band`)}\r\n${t(`${GUILD_MESSAGE}.note4_band`)}
      \r\n${t(`${GUILD_MESSAGE}.note5_band`)}` 
    form.setFieldsValue({ guildDescription: description })
  }

  const onSubmitForm = () => {
    setShowModalEditIncome(!showModalEditIcome)
  }

  const handleShowModalEditIncome = () => {
    setDisbandGuildError('')    
    setShowModalEditIncome(!showModalEditIcome)
  }

  const handleConfirmEdit = async () => {
    setLoadingUpdateIncome(true)
    setDisbandGuildError('')
    
    const [error, result]: any = await handleAsyncRequest(guildApi.postDisband(dataDisbandGuild))
    setLoadingUpdateIncome(false)

    if (error) {
      setDisbandGuildError(() => error?.message || error.errors[NUMBER.ZERO]?.message)
    }

    if (result) {
      openNotification(STRING.SUCCESS, t(`${GUILD_MESSAGE}.message.edit_disband_guild`))
      navigate(links.guild.joinGuild())
      setDisbandGuildError('')
    }
  }

  const openNotification = (message: string, description: string) => {
    notification.config({
      duration: NUMBER.FOUR
    })

    api.info({
      message,
      description,
      placement: 'bottomRight',
      className: message === STRING.ERROR ? 'ant-custom-error' : 'ant-custom-success'
    })
  }

  return (
    <DisbandGuildContainer>
      {contextHolder}
      {loading ? (
        <div className='loading'>
          <Spin className='mt-2 mr-3' />
        </div>
      ) : (
        <>
          <Form form={form} name='basic' onFinish={onSubmitForm} autoComplete='off'>
            {(values) => {       
              return (
                <div>
                  <div className='form-content'>
                    <div className='row-content'>
                      <div className={`income-row disabled`}>
                        <div>
                          <Form.Item
                            name='guildDescription'
                            rules={[
                              { required: true, message: t(`${GUILD_MESSAGE}.message.validate_req_description`) },
                              { max: 500, message: t(`${GUILD_MESSAGE}.message.validate_max_char`) },
                              {
                                validator: (_, value) => {
                                  if (value && value && value.trim().length < 50) {
                                    return Promise.reject(new Error(t(`${GUILD_MESSAGE}.message.validate_min_char`)))
                                  }
                                  return Promise.resolve()
                                }
                              }
                            ]}
                            className='input-box'
                          >
                            <InputTextarea 
                              type='textarea'
                              minLength={50}
                              maxLength={500}
                              value={values.guildDescription}
                              customClass='guild__textarea'
                              placeholder={t(`${GUILD_MESSAGE}.place_guild_description`)}
                              disabled={true}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Form.Item className='button-edit'>
                    <Button buttonName={STRING.Disband} type="btnDisband" onClickButton={form.submit} />
                  </Form.Item>
                </div>
              )
            }}
          </Form>
        </>
      )}

      {showModalEditIcome && (
        <ModalConfirmEditGuild
          onClose={handleShowModalEditIncome}
          onConfirm={handleConfirmEdit}
          titleModal={t(`${GUILD_MESSAGE}.header_disband`)}
          loading={loadingUpdateIncome}
          content={
            <span>
              {t(`${GUILD_MESSAGE}.content_disband_modal`)}<br />
              {t(`${GUILD_MESSAGE}.content_disband_modal1`)}
            </span>
          }
          error={disbandGuildError}
          margingTop={true}
          widthM={550}
          heightM={285}
        />
      )}
    </DisbandGuildContainer>
  )
}

export default DisbandGuild;