/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GUILD_MESSAGE } from 'i18n/constants'
import { notification } from 'antd'

import { getCurrentUser } from 'utils/metamask'
import { setCurrentUser } from 'features/Profile/profile.slice'
import { useAppDispatch } from 'hooks'
import { FX_1, FX_2 } from 'assets/images'
import guildApi from 'apis/guildApi'
import { handleAsyncRequest } from 'utils/helper'
import { links } from 'apps'
import { NUMBER, STRING } from 'apps/constants'
import Loading from '../Loading'
import InfoBox from '../GuildMemberDetail/member-info/info-box'
import RaceBox from '../GuildMemberDetail/member-info/race-box'
import BtnApprove from './btn-approve'
import BtnReject from './btn-reject'
import BtnCancelUserBlacklist from './btn-cancel-user-blacklist'
import BtnCancelHorseBlacklist from './btn-cancel-horse-blacklist'
import InfoBoxHorse from './info-box-horse'
import { convertShortDay } from 'utils/time'
import ManagementDetailStyled from './styled'

export default function ManagementDetail() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [api, contextHolder] = notification.useNotification()
  const { guild_id, user_id, horse_id, management_id } = useParams()
  const [data, setData] = useState<any>(null)
  const [dataGuildInfo, setDataGuildInfo] = useState<any>(null)
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    (async() => {
      switch (management_id) {
        case STRING.STR_1: {
          await fetchApplication()
          await fetchGuildInfo()
          await fetchInformation()
          setLoading(false)
          break;
        }
        case STRING.STR_2: {
          await fetchUserBlacklist()
          await fetchGuildInfo()
          await fetchInformation()
          setLoading(false)
          break;
        }
        case STRING.STR_3: {
          await fetchHorseBlacklist()
          await fetchGuildInfo()
          await fetchInformation()
          setLoading(false)
          break;
        }
        default:
          setLoading(true)
          break;
      }
    })()    
  }, [guild_id, user_id, horse_id, management_id])

  const fetchApplication = async () => {
    const [error, result]: any = await handleAsyncRequest(
      guild_id ? guildApi.getApplicationMemberDetails(guild_id || '', user_id || '') :
        guildApi.getMyProfile()
    )
    
    if (error) {
      openNotification(STRING.ERROR, error.message || error.errors[NUMBER.ZERO]?.message)
    }    

    if (result) {
      setData(() => result?.data)
    }
  }

  const fetchGuildInfo = async () => {
    const [error, result]: any = await handleAsyncRequest(guildApi.getGuildInfomationById(guild_id || ''))
    
    if (error) {
      openNotification(STRING.ERROR, error.message || error.errors[NUMBER.ZERO]?.message)
    }    

    if (result) {
      setDataGuildInfo(() => result?.data)
    }
  }

  const fetchInformation = async () => {
    const [, currentUser] = await handleAsyncRequest(getCurrentUser())
    if (currentUser) {
      dispatch(setCurrentUser(currentUser))
    }
  }

  const fetchUserBlacklist = async () => {
    const [error, result]: any = await handleAsyncRequest(
      guild_id ? guildApi.getUserBlacklistDetails(guild_id || '', user_id || '') :
        guildApi.getMyProfile()
    )
    
    if (error) {
      openNotification(STRING.ERROR, error.message || error.errors[NUMBER.ZERO]?.message)
    }    

    if (result) {
      setData(() => result?.data)
    }
  }

  const fetchHorseBlacklist = async () => {
    const [error, result]: any = await handleAsyncRequest(guildApi.getHorseBlacklistDetails(guild_id || '', horse_id || ''))
    
    if (error) {
      openNotification(STRING.ERROR, error.message || error.errors[0]?.message)
    }    

    if (result) {
      setData(() => result?.data)
    }
  }

  const openNotification = (message: string, description: string) => {
    notification.config({
      duration: NUMBER.FOUR
    })

    api.info({
      message,
      description,
      placement: 'bottomRight',
      className: message === STRING.ERROR ? 'ant-custom-error' : 'ant-custom-success'
    })
  }

  const _handleCallbackApplication = () => {
    navigate(links.guild.applicationGuild())
  }

  const _handleCallbackUserBlacklist = () => {
    navigate(links.guild.userBlacklistGuild())
  }

  const _handleCallbackHorseBlacklist = () => {
    navigate(links.guild.horseBlacklistGuild())
  }

  const renderBtn = () => {
    switch (management_id) {
      case STRING.STR_1: {
        return <div className='flex-btn'>
          <BtnApprove callBackSuccess={_handleCallbackApplication} />
          <BtnReject callBackSuccess={_handleCallbackApplication} />
        </div>
      }
      case STRING.STR_2: {
        return <div className='flex-btn'>
          <BtnCancelUserBlacklist callBackSuccess={_handleCallbackUserBlacklist} />
        </div>
      }
      case STRING.STR_3: {
        return <div className='flex-btn'>
          <BtnCancelHorseBlacklist callBackSuccess={_handleCallbackHorseBlacklist} />
        </div>
      }
      default:
        break;
    }
  }

  const renderText = () => {
    switch (management_id) {
      case STRING.STR_1: {
        return <>
          <span className='color-text'>{t(`${GUILD_MESSAGE}.apply_since`)}</span>
          {data?.date_submission ? convertShortDay(data?.date_submission * NUMBER.THOUSAND) : '---'}
        </>
      }
      case STRING.STR_2:
      case STRING.STR_3: {
        return <>
          <span className='color-text'>{t(`${GUILD_MESSAGE}.block_since`)}</span>
          {data?.block_since ? convertShortDay(data?.block_since * NUMBER.THOUSAND) : '---'}
        </>
      }
      default:
        break;
    }
  }

  return (
    <ManagementDetailStyled>
      {contextHolder}
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className='head'>
            {(dataGuildInfo?.avatar) && <img src={dataGuildInfo?.avatar} alt='avatar' />}
            <span className='color-yellow'>[{dataGuildInfo?.guild_tag}]</span>
            <span>{dataGuildInfo?.name || dataGuildInfo?.guild_name}</span>
          </div>

          <div className='time-since'>
            { data?.id ? renderText() : '' }
          </div>

          <div className='content-horse'>
            <img src={FX_2} alt='line' className='line-1' />
            <img src={FX_1} alt='line' className='line-2' />
            { management_id === STRING.STR_3 ? <InfoBoxHorse data={data} /> : <InfoBox data={data} />}
            <RaceBox data={data} />
          </div>

          <div className='group-button-action'>
            { data?.id ? renderBtn() : '' }
          </div>
        </>
      )}
    </ManagementDetailStyled>
  )
}
