import styled from 'styled-components'

const SpecialRaceStyle = styled.div`
  padding-top: 45px;

  .container {
    .title-tabs-container {
      margin-bottom: 12px;
      width: 100%;
      display: flex;
      justify-content: center;
      ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
        overflow-x: scroll;
      }
      height: 70px;

      .title-tabs {
        column-gap: 10px;

        ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
          width: fit-content;
        }
        font-size: 32px;
        img {
          top: -32px;
        }

        .tab-link {
          ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
            font-size: 26px;
          }

          ${({ theme }) => theme.media.lessThan(theme.size.md)} {
            font-size: 20px;
            line-height: 20px;
          }

          ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
            min-width: 160px;
            width: auto;
          }
        }
      }
    }
  }
`

export default SpecialRaceStyle
