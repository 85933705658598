import { GUILD_BG_INPUT } from 'assets/images'
import styled from 'styled-components'

const ManagementBalanceStyled = styled.div`
  width: 800px;
  position: relative;

  .loading {
    height: 200px;
    margin: 0 auto;
    align-items: center;
    display: flex;
    justify-content: center;

    .ant-spin {
      .ant-spin-dot-item {
        background-color: #4bf296;
      }
    }
  }

  .container__balance {
    border: 1px solid #4bf296;
    padding: 20px;
    background: #232840;
  }
  
  .title-content {
    background-color: transparent;
    text-align: center;
    color: #4bf296;
    font-size: 24px;
    line-height: 26px; 
    text-transform: uppercase;
    width: 100%;
    height: 30px;
    margin: 30px auto 0;
    position: relative;
  }

  .title__img {
    width: 100%;
    height: 5px;
    margin-bottom: 40px;

    img {
      width: 100%;
    }
  }

  .wallet {
    width: 650px;
    margin: 0 auto;
    margin-top: -20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    .wallet-item {
      font-size: 20px;
      width: 300px;
      height: 70px;
      display: grid;
      grid-template-columns: 0.6fr 1fr 2fr;
      color: white;
      align-items: center;
      padding: 0 20px;
      margin: 12px 0px;
      position: relative;

      .img__border_balance {
        position: absolute;
        top: 0;
        right: 0;
        border: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .icon-coin {
        width: 24px;
        height: 24px;
        margin-bottom: 20px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          margin-top: -5px;
        }
      }
      .value-coin {
        text-align: end;
        grid-column: 3 / span 2;
        margin-bottom: 12px;
      }
      .name-coin {
        text-align: start;
        margin-bottom: 12px;
        margin-left: -15px;
      }
    }
  }

  .input_balance {
    font-size: 18px;
    display: flex;
    align-items: center;
    width: 650px;
    margin: 0 auto 20px;

    .title_input {
      font-size: 24px;
      color: #fff;
      width: 200px;
    }
    
    .input {
      flex: 1;
      width: 100%;
      height: 100%;
      color: white; 
      display: flex;

      .input_input {
        flex: 1;
        position: relative;
        width: 100%;
        height: 46px;
        padding: 1px;        
        /* background-color: #4bf296; */

        /* .cover__rigth {
          position: absolute;
          top: -12px;
          right: -12px;
          display: block;
          width: 21px;
          height: 21px;
          background-color: #232840;
          -webkit-transform: rotate(46deg);
          -ms-transform: rotate(46deg);
          transform: rotate(50deg);
        }

        .orange-line {
          position: absolute;
          z-index: 2;
          left: 0;
          top: 0px;
          width: 250px;
          height: 3px;
        } */

        .search-input {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          /* background-color: #252b43 !important; */
          background: url(${GUILD_BG_INPUT});
          background-repeat: no-repeat;
          background-size: 100% 100%;
          border: none;
          /* resize: none; */
          padding-left: 10px;

          font-size: 16px;
          line-height: 20px;
          color: ${({ theme }) => theme.color.white};

          &:focus {
            outline: none;
            box-shadow: none;
          }

          &::placeholder {
            color: ${({ theme }) => theme.color.grey};
          }
        }

        .input_select {
          position: absolute;
          top: 2px;
          right: -2px;
          bottom: 0px;
          z-index: 2;
          width: 165px;
        }
      }
    }

    .btn-name {
      text-transform: capitalize;
    }
  }

  .replay_select {
    position: absolute;
    top: 0;
    right: 0;
    height: 44px;
    width: 160px;
    z-index: 1;

    .select-name {
      display: flex;
      align-items: center;
      height: 44px;
      width: 160px;
      color: #6d6d6d;
      font-weight: 600;
      font-size: 16px;
      margin-left: 10px;

      .img_select {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-right: 5px;
        margin-top: -5px;
    
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .orange-line {
      right: 0;
      bottom: -2px;
      width: 100%;
      height: 3px;
    }
  }

  .contain__btn_withdraw {
    justify-content: center;
    margin-top: 40px;
  }

  .box__no_master {
    width: 800px;
    height: 600px;
    margin: 0 auto;
    text-align: center;
    border: 1px solid #4bf296;
    padding: 20px;
    background: #232840;
    margin-top: 40px;
    
    .content__no_master {
      width: 100%;
      height: 100%;
      // position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      /* color: #f47351; */
      color: #fff;
      font-size: 24px;
      line-height: 26px;
      padding: 8px 20px;
    }
  }
`

export default ManagementBalanceStyled
