import styled from 'styled-components'

const TwoLineTitleStyled = styled.div`
  background-color: transparent;
  text-align: center;
  color: #4bf296;
  .text {
    text-transform: uppercase;
  }

  .line {
    position: relative;
    top: -6px;
    width: 100%;
  }

  .line__create {
    position: relative;
    top: -30px;
    width: 100%;
  }
`

export default TwoLineTitleStyled
