/* eslint-disable @typescript-eslint/no-explicit-any */
import { ItemContainer } from "./styled";

interface itemProp {
  imgUrl: string
  onClickItem: () => void
  className?: any
  imgDefault?: string
  horseItem?: any
}

const Item = ({ imgUrl, onClickItem, className, imgDefault = '', horseItem = '' }: itemProp) => {
  return (
    <ItemContainer
      color={
        horseItem?.own_status === "OWNER"
          ? "#00f7f6"
          : (horseItem?.own_status === "IN_LENDING" || horseItem?.own_status === "IN_GUILD")
            ? "#cc661d"
            : "#3ca942"}
      onClick={onClickItem}
      className={className}
    >
      <div className="img-box">
        <img src={imgUrl} className={`${imgDefault ? "img-default" : ""}`} alt="icon" />
      </div>
    </ItemContainer>
  )
};

export default Item;