import RateStarStyled from './styled'

interface RateStarProps {
  isActive: boolean
}

function RateStar({ isActive }: RateStarProps) {
  return (
    <RateStarStyled isActive={isActive}>
      <div className={`rate-star w-100 h-100 ${isActive ? 'enable' : 'disable'}`} />
    </RateStarStyled>
  )
}

export default RateStar
