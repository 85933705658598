import { HORSE_AVATAR_BG } from 'assets/images'
import styled from 'styled-components'

const AvatarEnergyItemStyled = styled.div`
  position: relative;
  width: 800px;

  .close-btn {
    top: 10px;
    right: 90px;
    background-color: ${({ theme }) => theme.color.transparent};
    border: none;
    z-index: 1;
  }
  .title {
    width: 200px;
    background-color: ${({ theme }) => theme.color.transparent};
    z-index: 1;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0%);
    .text {
      font-size: 24px;
    }
  }
  .quick-view {
    background-color: ${({ theme }) => theme.color.neutral};
    margin: 0 80px;
    padding: 32px 0;

    .quick-view-box {
      padding: 20px;

      ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
        padding: 20px;
      }

      .quick-view-container {
        ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
          row-gap: 40px;
        }

        .quick-view-left {
          ${({ theme }) => theme.media.lessThan(theme.size.xxl)} {
            padding-right: 20px;
          }

          ${({ theme }) => theme.media.lessThan(theme.size.xl)} {
            padding-right: 40px;
          }

          ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
            padding-right: 0;
          }
          .left {
            .name {
              ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
                font-size: 24px;
                line-height: 20px;
              }
              .horse-level {
                left: 34%;
              }
            }

            .background-container {
              display: flex;
              align-items: center;
              justify-content: center;
              
              .background {
                background-image: url(${HORSE_AVATAR_BG});
                background-repeat: no-repeat;
                background-position: center;
                position: relative;
                background-size: contain;
                width: 300px;

                ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
                  background-size: cover;
                }

                .avatar {
                  width: 300px;
                  aspect-ratio: 300 / 300;
                  object-fit: contain;
                  object-position: center;

                  ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
                    width: 100%;
                  }
                }

                .item {
                  width: 300px;
                  height: 300px;
                  position: absolute;
                  top: 0;
                  left: 0;

                  &.head {
                    z-index: 2;
                    width: 150px;
                    height: 150px;
                  }

                  &.body {
                    z-index: 3;
                    height: 300px;
                    top: 50%;
                    transform: translateY(-50%);
                  }

                  &.foot {
                    z-index: 4;
                    width: 300px;
                    height: auto;
                    left: 0%;
                    bottom: 0%;
                    top: unset;
                  }

                  &.skin {
                    z-index: 1;
                  }

                  &.shoes{
                    width: 300px;
                    height: auto;
                    left: 0%;
                    bottom: 0%;
                    top: unset;
                    z-index: 3;
                  }
                  &.glass{
                    height: auto;
                    width: 150px;
                    top: 0%;
                    left: 0%;
                    z-index: 3;
                  }
                }
              }
            }

            .energy-container {
              .custom-energy {
                width: 60%;
              }
            }

            .detail-btn-container {

              padding: 1px;
              width: 146px;
              height: 34px;
              aspect-ratio: 146 / 42;

              .detail-btn {
                width: 150px;
                height: 45px;
                border: none;
                background: transparent;
                cursor: pointer;
                font-size: 14px;
                text-transform: uppercase;
                position: relative;
                padding: 0;
                &:hover{
                  filter: brightness(1.5);
                } 
                span {
                  position: absolute;
                  left: 50%;
                  transform: translate(-50%, 0);
                  top: 30%;
                  width: 100%;
                  color: #fff;
                }
              }
            }
          }
        }
      }
    }
  }
`

export default AvatarEnergyItemStyled
