import { INCOME_BG } from 'assets/images'
import styled from 'styled-components'

const DisbandGuildContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
min-height: 40vh;
padding-top: 40px;
position: relative;

p {
  text-transform: uppercase;
  background: linear-gradient(90deg, #ffd901 0%, #fd6401 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 30px;
}

.form-content {
  width: 800px;
  min-height: 400px;
  margin: 0 auto;
  background: url(${INCOME_BG});

  .row-content {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%)
  }
  .income-row {
    margin-bottom: 40px;
    align-items: center;
    position: relative;
    width: 100%;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }

    &.disabled {
      .input-box {
        span,
        input,
        .percent {
          color: #9d9d9d;
        }
      }
    }

    span {
      display: inline-block;
      color: #9d9d9d;
      font-size: 16px;
      text-transform: uppercase;

      &:last-child {
        background: #30343f;
        padding: 4px 30px;
        border: 1px solid #9c9c9c;
        min-width: 99px;
        text-align: center;
        display: inline-block;
      }

      &.requied {
        color: #e01621;
        border: none;
        background: transparent;
        min-width: 10px;
        padding: 0;
      }
    }
  }
}

.button-edit {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.error {
  color: #e01621;
  font-size: 18px;
  text-align: center;
}

.ant-spin-dot-item {
  background-color: #4ef076 !important;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #252B43;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
  border-color: #ff4d4f;
  box-shadow: none;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  border-color: #ff4d4f;
}
.align-items-center {
  height: 300px
}
.search-input {
  font-size: 19px;
  line-height: 25px;
  height: 250px !important;
}

.guild__textarea {
  color: #f47351;
}
`

export default DisbandGuildContainer
