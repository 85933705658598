import styled from 'styled-components'

const CurrentEventStyled = styled.div`
  .special-race {
    .line,
    .text {
      filter: hue-rotate(260deg);
    }
  }
  .current-event-container {
    .time-open-container {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 80px;
      .time-open {
        display: flex;
        align-items: center;
        flex-direction: column;
      }
      .time {
        color: #f1a533;
        font-size: 50px;
      }
    }
    .current-event {
      .tab-lend {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        .tab-block {
          display: flex;
          gap: 20px;
        }
        .search-container {
          display: flex;
          align-items: center;
          gap: 20px;
        }
        display: flex;
        .tab-link {
          width: 220px;
          height: 40px;
          font-size: 22px;
          color: #f1a533;
          cursor: pointer;
          .line {
            position: relative;
            top: -20px;
            width: 100%;
          }
          .day-text {
            color: #f1a533;
            opacity: 0.7;
          }
        }
      }
    }

    .top-lending {
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
      .search-horse-input {
        height: 34px;
      }
      .lending-search {
        display: flex;
        gap: 20px;
        .btn-profit {
          cursor: pointer;
        }
        .btn-profit:hover {
          filter: brightness(1.5);
        }
      }
      .lending-sort {
        width: 200px;
      }
    }
  }
`

export default CurrentEventStyled
