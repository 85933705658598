/* eslint-disable @typescript-eslint/no-explicit-any */
import { Container } from './styled'
import { INPUT_PREVIEW, BODER_CIRCLE } from 'assets/images'
import RateStar from '../../RateStar'
import { useToggle } from 'hooks'
import { ItemInformationModal } from 'features/Profile/components'
import { Item } from 'models'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import { useTranslation } from 'react-i18next'
interface iprops {
  img: string
  amount: number
  onSuccess?: () => void
  horseId?: number
  level?: number
  name: string
  type: string
  itemDetails?: Item
}

const ItemBoxDetail = ({ img, amount, level, name, type, itemDetails }: iprops) => {
  const [isInforModal, toggleIsInforModal] = useToggle(false)
  const { t } = useTranslation()

  const generateRateLevel = (maxLevel: number, currentLevel: number): JSX.Element[] => {
    const rateStars: JSX.Element[] = []
    for (let i = 0; i < maxLevel; i++) {
      rateStars.push(<RateStar key={i} isActive={i < currentLevel} />)
    }
    return rateStars
  }

  const onOpenInformationModal = () => {
    toggleIsInforModal(true)
  }

  return (
    <Container>
      <div
        className='meta-block'
      >
        <div className="item-block">
          <div className="sub-block">
            <div className={`${type} item-dp`}>
              <img src={img} alt={img} />
            </div>
            <div className="amount d-flex align-items-center">
              {type === 'DOPING' &&
                <div className="star d-flex align-items-center">
                  {level && generateRateLevel(3, level)}
                </div>
              }
            </div>
          </div>
          {amount && <div className="numb">
            <img src={BODER_CIRCLE} alt="" width={44} />
            <span className='amount-item'>{amount}</span>
          </div>}
        </div>
        <div className="count-item">
          <img src={INPUT_PREVIEW} alt="" />
          <span className='color-white'>{name}</span>
        </div>
        <div className='btn-detail-container'>
          <button className='font-bold btn-detail'  onClick={onOpenInformationModal}>
            <span className='color-white'>{t(`${NOTIFICATION_MESSAGE}.details`)}</span>
          </button>
        </div>
      </div>

      {!!itemDetails && isInforModal &&
        <ItemInformationModal itemData={itemDetails} open={isInforModal}
          onCancel={toggleIsInforModal}
          onConfirm={toggleIsInforModal}
          isShowBtn={false}
        />
      }
    </Container>
  )
}

export default ItemBoxDetail
