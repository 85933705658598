import TwoLineTitleStyled from 'shared/TwoLineTitle'
import HeadContainer from './styled'

interface iprop {
  name: string
}

const Head = ({ name }: iprop) => {
  return (
    <HeadContainer>
      <div className='title-tabs color-primary'>
        <TwoLineTitleStyled text={name} classGuild={'line__create'} />
      </div>
    </HeadContainer>
  )
}

export default Head
