/* eslint-disable @typescript-eslint/no-explicit-any */
import IncomeBoxContainer from './styled'
import { convertTimestampToDate } from 'utils/helper'

interface IncomeBoxProps {
  data: any
}

const IncomeBox = ({ data }: IncomeBoxProps) => {

  return (
    <IncomeBoxContainer>
      <div className='content-left'>
        <div>
          <p className='title'>Member since</p>
          <span>{convertTimestampToDate(data?.join_date) || '---'}</span>
        </div>
        <div>
          <p className='title'>Horse in guild farm</p>
          <span>{data?.horse_in_guild}</span>
        </div>
        <div>
          <p className='title'>Rented guild horse</p>
          <span>{data?.total_rented_horse}</span>
        </div>
      </div>
    </IncomeBoxContainer>
  )
}

export default IncomeBox
