import styled from 'styled-components'

const RankCountContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  img {
    margin-right: 10px;
  }

  p {
    margin-bottom: 0;
  }
`

export default RankCountContainer
