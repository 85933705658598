import styled from 'styled-components'

const SellItemsStyled = styled.div`
  width: 100%;
  .container {
    padding: 0px 20px;
    .is-comming{
      color: rgb(255, 122, 0);
      margin-top: 16px;
      text-align: center;
      width: 100%;
      background-color: rgb(25, 29, 44);
      padding: 20px;
    }
    .buy-top {
      width: 995px;
      padding-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .total-items {
        span {
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          color: #4ef076;
        }
      }
      .filter-items {
        gap: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .select-box-customs {
          width: 136px;
          .select-drop-icon {
            left: 45px;
            filter: brightness(0) invert(1);
          }

          .orange-line {
            bottom: -0.5px;
          }
        }
        .search-horse-input {
          width: 174px;
          clip-path: polygon(165px 0, 100% 16px, 100% 100%, 0 100%, 0 0);
        }
      }
    }
    .items {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    .view-more-container {
      text-align: center;
    }
    button.btn-view-more {
      background: linear-gradient(
        178.19deg,
        rgb(240 241 240 / 82%) 1.64%,
        rgb(235 245 241 / 48%) 47.93%,
        rgb(251 251 251 / 63%) 97.84%
      );
      border-radius: 4px;
      height: 100%;
      width: 250px;
      font-size: 16px;
      height: 60px;
      &:after {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #f9fafb;
        content: '';
        position: relative;
        right: -20px;
        top: 11px;
      }
    }
    .btn-view-more:hover {
      background: linear-gradient(
        178.19deg,
        rgb(120 229 147 / 82%) 1.64%,
        rgb(52 255 184 / 48%) 47.93%,
        rgb(78 240 118 / 63%) 97.84%
      );
    }
    .btn-view-more:after {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #f9fafb;
      content: '';
      position: relative;
      right: -5px;
      top: 11px;
    }
    .no-data {
      color: #ff7a00;
      font-size: 20px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      text-align: center;
      width: 100%;
      text-transform: uppercase;
      background-color: #191d2c;
      padding: 20px;
    }
  }
`

export default SellItemsStyled
