import styled from 'styled-components'

const TopMembersContainer = styled.div`
  margin-top: 50px;

  .head {
    position: relative;
    margin-bottom: 80px;

    h3 {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #4EF076;
      text-transform: uppercase;
      margin-bottom: 0;
      flex-direction: column;
      font-size: 26px;

      img {
        width: 200px;
      }
    }
    a {
      position: absolute;
      top: 0;
      right: 0;
      color: #fff;
      font-size: 23px;
      text-decoration: none;

      &:hover {
        color: #fff;
      }
    }

    img.link {
      margin-left: 5px;
      margin-top: -5px;
    }
  }

  .content-box {
    display: flex;
    justify-content: flex-start;

    &.top-one {
      justify-content: center;
    }
  }
`

export default TopMembersContainer
