import styled from 'styled-components'

interface ItemContainerStyledProps {
  color: string
}

const ItemBoxContainer = styled.div`
  margin: 20px 0;
  .row-item {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
  }

  .content {
    text-align: center;
    margin-bottom: 20px;
  }
`

const ListContainer = styled.div`
  margin: 20px;

  .row {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
`

const ItemContainer = styled.div<ItemContainerStyledProps>`
  cursor: pointer;
  width: 72px;
  height: 72px;
  position: relative;
  background-color: ${({ color }) => color};
  /* &.use-nft{
    .img-box {
      img {
        transform: scaleX(1);
      }
    }
  } */
  &.custom {
    width: 115px;
    height: 110px;

    &:before {
      width: 113px;
      height: 108px;
    }

    &.HEAD {
      img {
      }
    }
  }

  &.active {
    background: #57ed78;

    &:before {
      background: #57ed78;
    }
  }

  -webkit-clip-path: polygon(
    78% 0%,
    67% 0%,
    33% 0%,
    13% 0%,
    0% 12%,
    0% 27%,
    0% 82%,
    0% 100%,
    40% 100%,
    63% 100%,
    100% 100%,
    100% 75%,
    100% 46%,
    100% 25%
  );
  clip-path: polygon(
    78% 0%,
    67% 0%,
    33% 0%,
    13% 0%,
    0% 12%,
    0% 27%,
    0% 82%,
    0% 100%,
    40% 100%,
    63% 100%,
    100% 100%,
    100% 75%,
    100% 46%,
    100% 25%
  );
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 70px;
    height: 70px;
    top: 1px;
    left: 1px;
    background-color: #191d2c;
    display: block;
    -webkit-clip-path: polygon(
      78% 0%,
      67% 0%,
      33% 0%,
      13% 0%,
      0% 12%,
      0% 27%,
      0% 82%,
      0% 100%,
      40% 100%,
      63% 100%,
      100% 100%,
      100% 75%,
      100% 46%,
      100% 25%
    );
    clip-path: polygon(
      78% 0%,
      67% 0%,
      33% 0%,
      13% 0%,
      0% 12%,
      0% 27%,
      0% 82%,
      0% 100%,
      40% 100%,
      63% 100%,
      100% 100%,
      100% 75%,
      100% 46%,
      100% 25%
    );
  }
  .img-box {

    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }

    /* .img-default {
      transform: scaleX(-1);
    } */
  }

`

export { ItemBoxContainer, ListContainer, ItemContainer }
