/* eslint-disable @typescript-eslint/no-explicit-any */
import { Navigate, Route, Routes } from 'react-router-dom'

import { constants, paths } from 'apps'
import { RaceDetail } from 'features/Race/pages'
import { useLocalStorage } from 'hooks'
import { CommonLayout } from 'layouts'
import { useEffect } from 'react'
import { PublicRoute } from 'routes'
import { handleAsyncRequest } from 'utils/helper'
import { getSigner } from 'utils/metamask'
import { CurrentEvent, OldFarm, RaceHistory, RaceHistoryDetail, SpecialRaceMain } from './pages'
import HorseDetail from './pages/Detail'

function SpecialRace() {
  const [, setUserId] = useLocalStorage(constants.USER_ID_KEY, 0)
  const [, setAccessToken] = useLocalStorage(constants.ACCESS_TOKEN_KEY, null)
  const [, setRefreshToken] = useLocalStorage(constants.REFRESH_TOKEN_KEY, null)

  const checkSignerProfile = async () => {
    const [signerError] = await handleAsyncRequest(getSigner())
    if (signerError) {
      setUserId(0)
      setAccessToken('')
      setRefreshToken('')
    }
  }
  useEffect(() => {
    checkSignerProfile()
  }, [])
  return (
    <Routes>
      <Route
        path={paths.default()}
        element={
          <PublicRoute layout={CommonLayout}>
            <SpecialRaceMain />
          </PublicRoute>
        }
      >
        <Route path={paths.specialRace.raceHistory()} element={<RaceHistory />} />
        <Route path={paths.specialRace.historyDetail()} element={<RaceHistoryDetail />} />
        <Route path={paths.specialRace.currentRace()} element={<CurrentEvent />} />
        <Route path={paths.specialRace.oldFarm()} element={<OldFarm />} />
      </Route>
      <Route
        path={paths.specialRace.historyHorseDetail()}
        element={
          <PublicRoute layout={CommonLayout}>
            <HorseDetail />
          </PublicRoute>
        }
      />
      <Route
        path={paths.specialRace.currentHorseDetail()}
        element={
          <PublicRoute layout={CommonLayout}>
            <HorseDetail />
          </PublicRoute>
        }
      />
      <Route
        path={paths.specialRace.raceDetail()}
        element={
          <PublicRoute layout={CommonLayout}>
            <RaceDetail />
          </PublicRoute>
        }
      />
      <Route path='*' element={<Navigate replace to={paths.notFound.feature()} />} />
    </Routes>
  )
}

export default SpecialRace
