import { Route, Routes } from 'react-router-dom'
import { constants, paths } from 'apps'
import { CommonLayout } from 'layouts'
import { PublicRoute } from 'routes'
import { useEffect } from 'react'
import { handleAsyncRequest } from 'utils/helper'
import { getSigner } from 'utils/metamask'
import { useLocalStorage } from 'hooks'
import ShopMain from './pages/Main'
import { BuyItems, InventoryItems, ItemDetails, ItemInventoryDetails, SellItems, ItemSellingDetails } from './pages'

function Shop() {
  const [, setUserId] = useLocalStorage(constants.USER_ID_KEY, 0)
  const [, setAccessToken] = useLocalStorage(constants.ACCESS_TOKEN_KEY, null)
  const [, setRefreshToken] = useLocalStorage(constants.REFRESH_TOKEN_KEY, null)

  const checkSignerProfile = async () => {
    const [signerError] = await handleAsyncRequest(getSigner())
    if (signerError) {
      setUserId(0)
      setAccessToken('')
      setRefreshToken('')
    }
  }
  useEffect(() => {
    checkSignerProfile()
  }, [])
  return (
    <Routes>
      <Route
        path={paths.default()}
        element={
          <PublicRoute layout={CommonLayout}>
            <ShopMain />
          </PublicRoute>
        }
      >
        <Route path={paths.shop.buyItems()} element={<BuyItems />} />
        <Route path={paths.shop.inventoryItems()} element={<InventoryItems />} />
        <Route path={paths.shop.sellItems()} element={<SellItems />} />
        <Route path={paths.shop.buyItemsDetails()} element={<ItemDetails />} />
        <Route path={paths.shop.inventoryItemsDetails()} element={<ItemInventoryDetails />} />
        <Route path={paths.shop.sellItemsDetails()} element={<ItemSellingDetails />} />
      </Route>
      {/* <Route path='*' element={<Navigate replace to={paths.notFound.feature()} />} /> */}
    </Routes>
  )
}

export default Shop
