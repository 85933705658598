import { SPEED_UP } from 'apps/constants'
import { CROWN_BRONZE, CROWN_GOLD, CROWN_SILVER } from 'assets/images'
import styled from 'styled-components'

interface HorseTrackStyledProps {
  distance: number
}

interface HorseAvatarStyledProps {
  distanceHorseMoved: number
}

interface trackListTranslateProps {
  trackListTranslate: number
}

const HOSRE_AVATAR_WIDTH = 43
const START_AREA_WIDTH = 58
const MILESTONE_DISTANCE = 300
export const EXTRA_DISTANCE = 50

export const HorseAvatarStyled = styled.div.attrs<HorseAvatarStyledProps>(({ distanceHorseMoved, ...props }) => ({
  style: {
    left: `${START_AREA_WIDTH + distanceHorseMoved}px`,
    ...props
  }
}))
<HorseAvatarStyledProps>`
transition-duration: 0.5s;
transition-timing-function: linear;

img{
  width: 50px;
  height: 50px;
  object-fit: contain;
  object-position: right;
  transform: scaleX(-1);
}
`

export const TrackListTransLate = styled.div.attrs<trackListTranslateProps>(({ trackListTranslate }) => ({
  style: {
    transform: `translateX(${trackListTranslate >= 0 && trackListTranslate}px)`
  }
}))<trackListTranslateProps>`
  z-index: 10;
  transition-duration: 0.5s;
  transition-timing-function: linear;
  position: absolute;
  height: 50px;
  left: 0;
  padding: 8px 12px;
  background: #191d2c;
`

const HorseTrackStyled = styled.div<HorseTrackStyledProps>`
  height: 50px;
  position: relative;
  &.active {
    &:after {
      content: '';
      width: ${({ distance }) => START_AREA_WIDTH + HOSRE_AVATAR_WIDTH + distance * SPEED_UP + EXTRA_DISTANCE + 4}px;
      height: calc(100% + 2px);
      background: #2c7033;
      display: block;
      position: absolute;
      top: -2px;
      left: 0;
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 14px 100%, 0% calc(100% - 14px));
      clip-path: polygon(0 0, 100% 0, 100% 100%, 14px 100%, 0% calc(100% - 14px));
    }
    &:before {
      content: '';
      width: 2px;
      height: 100%;
      // background: ${({ theme }) => theme.color.primary};
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 2;
    }
  }
  .horse-track {
    z-index: 1;
    height: 100%;
    width: ${({ distance }) => START_AREA_WIDTH + HOSRE_AVATAR_WIDTH + distance * SPEED_UP + EXTRA_DISTANCE}px;
    background-color: ${({ theme }) => theme.color.neutral};
    padding: 12px;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 14px 100%, 0% calc(100% - 14px));

    &-active {
      left: 2px;
      height: calc(100% - 2px);
      background: #191d2cf2;
    }

    .background{
      width: 50px;
      background-image: unset;
      .item{
        transform: scaleX(-1);
        &.shoes{
          width: 50px;
          height: auto;
          left: 0%;
          bottom: 0%;
          top: unset;
          z-index: 2;
        }
        &.glass {
          height: auto;
          width: 25px;
          top: 0%;
          right: 0%;
          left: unset;
          z-index: 2;
        }
        &.body, &.skin {
          height: auto;
          width: 50px;
          top: 0%;
          right: 0%;
          left: unset;
        }
      }
    }

    .gate-container {
      width: 34px;
      aspect-ratio: 1;
      background-color: ${({ theme }) => theme.color.positionTrack};
      clip-path: polygon(0 0, 100% 0, 100% 100%, 8px 100%, 0% calc(100% - 8px));
      &.no-1{
        background: #ffffff;
        color: #000000;
      }
      &.no-2{
        background: #000000;
        color: #ffffff;
      }
      &.no-3{
        background: red;
        color: #ffffff;
      }
      &.no-4{
        background: #3366FF;
        color: #ffffff;
      }
      &.no-5{
        background: yellow;
        color: #000000;
      }
      &.no-6{
        background: yellow;
        color: #000000;
      }
      &.no-7{
        background: green;
        color: #ffffff;
      }
      &.no-8{
        background: green;
        color: #ffffff;
      }
      &.no-9{
        background: #FF8C00;
        color: #ffffff;
      }
      &.no-10{
        background: #FF8C00;
        color: #ffffff;
      }
      &.no-11{
        background: #FF33CC;
        color: #ffffff;
      }
      &.no-12{
        background: #FF33CC;
        color: #ffffff;
      }
      .gate-number {
        font-size: 16px;
        line-height: 20px;
      }
    }

    .milestone-list-container {
      left: ${START_AREA_WIDTH + HOSRE_AVATAR_WIDTH}px;

      .milestone-block {
        width: ${MILESTONE_DISTANCE}px;
        height: 34px;
        border-right: 2px solid ${({ theme }) => theme.color.yellow};
      }

      .milestone-block:last-child {
        border-right: 2px solid #15e133;
      }
    }
  }
  .first-rank-track {
    background: #3a342f;
    .milestone-block:last-child {
      &:before {
        background-image: url(${CROWN_GOLD});
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        position: relative;
        left: ${MILESTONE_DISTANCE + 10}px;
        background-repeat: no-repeat;
        top: 8px;
      }
    }
  }
  .second-rank-track {
    background: #2b2a2a;
    .milestone-block:last-child {
      &:before {
        background-image: url(${CROWN_SILVER});
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        position: relative;
        left: ${MILESTONE_DISTANCE + 10}px;
        background-repeat: no-repeat;
        top: 8px;
      }
    }
  }
  .third-rank-track {
    background: #232128;
    .milestone-block:last-child {
      &:before {
        background-image: url(${CROWN_BRONZE});
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        position: relative;
        left: ${MILESTONE_DISTANCE + 10}px;
        background-repeat: no-repeat;
        top: 8px;
      }
    }
  }
`

export default HorseTrackStyled
