import styled from 'styled-components'

const TimeRaceStyled = styled.div`
  p {
    font-size: 20px;
    line-height: 20px;
    margin: 0px;
  }
`

export default TimeRaceStyled
