/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { FX_1, FX_2 } from 'assets/images'
import Loading from '../../Loading'
import IncomeBox from './income-box'
import InfoBox from './info-box'
import RaceBox from './race-box'
import HorseInfoContainer from './styled'
import HorseStats from './horse-stats'

interface DetailProps {
  loading?: boolean
  horse: any
}

const HorseInfo = ({ loading, horse }: DetailProps) => {

  return (
    <HorseInfoContainer>
      {loading ? (
        <Loading />
      ) : (
        <>
          {
            horse?.guild_info ?
              <div className='head'>
                <img src={horse?.guild_info?.avatar} alt='avatar' />
                <span className='color-yellow'>[{horse?.horse?.guild?.guild_tag}]</span>
                <span>{horse?.guild_info?.name}</span>
              </div> : <div className='mt-5' />
          }
          <div className='content-horse'>
            <img src={FX_2} alt='line' className='line-1' />
            <img src={FX_1} alt='line' className='line-2' />
            <InfoBox data={horse} />
            <HorseStats data={horse} />
            <RaceBox data={horse} />
            <IncomeBox data={horse} />
          </div>
        </>
      )}
    </HorseInfoContainer>
  )
}

export default HorseInfo
