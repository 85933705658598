import { HORSE_AVATAR_BG } from 'assets/images'
import styled from 'styled-components'

const HorseAvatarStyled = styled.div`
  height: 100%;
  width: 100%;
  .horse-container {
    height: 100%;
    .top-horse {
      color: white;
      width: 100%;
      padding-bottom: 50px;
      .horse-info {
        display: flex;
        gap: 10px;
        font-size: 22px;
        .horse-name {
          width: 180px;
          font-size: 20px;
          font-weight: 600;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .icon-gender {
          width: 20px;
          margin-top: -4px;
        }

        .region {
          width: 20px;
          height: 20px;
          margin-top: -3px;
        }

        .vip-icon {
          margin-left: -10px;
          margin-top: -3px;
        }
      }
      .horse-name-info {
        span {
          color: #4ef076;
        }
      }
      .horse-lending {
        font-size: 20px;
        color: #ffc700;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .horse-level {
        position: relative;
        .horse-level-number {
          font-size: 20px;
          position: absolute;
          right: 15px;
          top: 18%;
        }
        .level-length-max {
          right: 17%;
        }
      }
    }

    .background-container {
      .background {
        background-image: url(${HORSE_AVATAR_BG});
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
          background-size: cover;
        }

        .avatar {
          width: 100%;
          aspect-ratio: 300 / 300;
          object-fit: contain;
          object-position: center;

          ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
            width: 100%;
          }
        }
      }
    }
    .time-container {
      color: white;
      padding: 20px 0 0 28px;
      font-size: 18px;
      width: 255px;

      .time {
        display: flex;
        gap: 10px;
        justify-content: space-between;
        .title {
          color: #4ef076;
        }
        .day-time {
          color: #e8c033;
        }
      }
    }
    .detail-btn-container {
      clip-path: polygon(0 0, calc(100% - 14px) 0, 100% 14px, 100% 100%, 14px 100%, 0% calc(100% - 14px));
      background: ${({ theme }) => theme.color.primary};

      padding: 1px;
      width: 146px;
      aspect-ratio: 146 / 42;

      .detail-btn {
        clip-path: polygon(0 0, calc(100% - 14px) 0, 100% 14px, 100% 100%, 14px 100%, 0% calc(100% - 14px));
        border: none;
        background-color: ${({ theme }) => theme.color.black};

        font-size: 20px;
        line-height: 20px;
      }
    }
  }
`

export default HorseAvatarStyled
