import { encodeURI } from 'js-base64'

export default {
  home: {
    index: () => '/',
    help: () => '/help',
    terms: () => '/terms',
    privacy: () => 'https://head2head.gitbook.io/metahorse/privacy-poricy',
    cookie: () => 'https://head2head.gitbook.io/metahorse/cookie-policy',
    guideline: () => 'https://head2head.gitbook.io/metahorse/fan-fiction-guideline'
  },
  race: {
    index: () => '/race',
    scheduledRaces: () => '/race/scheduled-races',
    open: () => '/race/open',
    detail: (raceId: string | undefined) => `/race/detail/${encodeURI(raceId || '')}`,
    result: () => '/race/result'
  },
  auth: {
    index: () => '/login'
  },
  horse: {
    index: () => '/horse',
    detail: (horse_id: number) => `/horse/detail/${horse_id}`,
    detailByToken: (contract: string | undefined, token_id: number) => `/horse/detail/${contract}/${token_id}`,
    detailRaceView: (horse_id: number, raceId: number) => `/horse/detail/${horse_id}/race/${raceId}`
  },
  profile: {
    index: () => '/profile',
    myProfile: () => '/profile/my-profile',
    information: () => '/profile/item-information'
  },
  market: {
    index: () => '/lending',
    market: () => '/lending/market',
    myAsset: () => '/lending/my-asset',
    myLending: () => '/lending/my-lending',
    myBorrow: () => '/lending/my-borrow',
    detail: (horse_id: number) => `/lending/detail/${horse_id}`,
    lending: (horse_id: number) => `/lending/detail/${horse_id}/lending`
  },
  specialRace: {
    index: () => 'special-race',
    currentRace: () => '/special-race/current-event',
    raceHistory: () => '/special-race/event-history',
    currentFarm: () => '/special-race/current-farm',
    oldFarm: () => '/special-race/old-farm',
    detail: (horse_id: number) => `/special-race/detail/${horse_id}`,
    raceDetail: (raceId: string | undefined) => `/special-race/race-detail/${encodeURI(raceId || '')}`,
    eventHistoryDetail: (eventId: number | string) => `/special-race/event-history/detail/${eventId}`,
    horseHistoryDetail: (eventId: string, horseId: string | number) =>
      `/special-race/event-history/detail/${eventId}/${horseId}`,
    currentHorseDetail: (eventId: string, horseId: string | number) =>
      `/special-race/current-event/detail/${eventId}/${horseId}`
  },
  balance: {
    index: () => '/balance'
  },
  maintenace: {
    index: () => '/maintenace'
  },
  disableAccount: {
    index: () => '/disable-account'
  },
  loggedAccount: {
    index: () => '/logged-account'
  },
  notFound: {
    index: () => '/page-not-found'
  },
  mobile: {
    index: () => '/mobile'
  },
  mailbox: {
    index: () => '/mail-box',
    raceMail: () => '/mail-box/race-mail',
    eventMail: () => '/mail-box/event-mail',
    systemMail: () => '/mail-box/system-mail'
  },
  guild: {
    index: () => '/guild',
    createGuild: () => '/guild/create-guild',
    joinGuild: () => '/guild/join-guild',
    information: () => '/guild/information',
    members: () => '/guild/members',
    membersId: (guild_id: string | number) => `/guild/members/${guild_id}`,
    guildFarm: () => '/guild/guild-farm',
    guildFarmId: (guild_id: string | number) => `/guild/guild-farm/${guild_id}`,
    yourHorse: () => '/guild/your-horse',
    manage: () => '/guild/manage',
    detail: (guild_id: string | number, horse_id: number) => `/guild/${guild_id}/horse/${horse_id}`,
    detailMember: (guild_id: string | number, user_id: number) => `/guild/${guild_id}/member/${user_id}`,
    informationDetail: (guild_id: number) => `/guild/information/${guild_id}`,
    horseOwner: () => '/guild/horse-owner',
    horseOwnerId: (guild_id: string | number) => `/guild/horse-owner/${guild_id}`,
    updateInfo: () => '/guild/edit/update-info',
    disbandGuild: () => '/guild/edit/disband-guild',
    inCome: () => '/guild/edit/income',
    guildList: () => '/guild/guild-list',
    detailOwner: (guild_id: string | number, user_id: number) => `/guild/${guild_id}/owner/${user_id}`,
    myHorses: () => '/guild/my-horses',
    myProfile: () => '/guild/my-profile',
    horseDetailInfarm: (horse_id: number) => `/guild/horse/in-farm/${horse_id}`,
    horseDetail: (guild_id: string | number, horse_id: number) => `/guild/my-horse/${guild_id}/horse/${horse_id}`,
    applicationGuild: () => '/guild/management/application',
    applicationDetailMember: (guild_id: string | number, user_id: number, management_id: number) => `/guild/${guild_id}/application/${user_id}/${management_id}`,
    userBlacklistGuild: () => '/guild/management/user-blacklist',
    userBlacklistDetail: (guild_id: string | number, user_id: number, management_id: number) => `/guild/${guild_id}/user-blacklist/${user_id}/${management_id}`,
    horseBlacklistGuild: () => '/guild/management/horse-blacklist',
    horseBlacklistDetail: (guild_id: string | number, horse_id: number, management_id: number) => `/guild/${guild_id}/horse-blacklist/${horse_id}/${management_id}`,
    BalanceGuild: () => '/guild/management/guild-balance',
  },
  ranking: {
    index: () => '/ranking',
    topHorses: () => '/ranking/top-horses',
    topUsers: () => '/ranking/top-users'
  },
  shop: {
    index: () => '/shop',
    buyItems: () => '/shop/buy_items',
    inventoryItems: () => '/shop/inventory_items',
    sellItems: () => '/shop/sell_items',
    buyItemsDetails: (id: string | number) => `/shop/buy_items/${id}`,
    inventoryItemsDetails: (id: string | number) => `/shop/inventory_items/${id}`,
    sellItemsDetails: (id: string | number) => `/shop/sell_items/${id}`
  }
}
