/* eslint-disable @typescript-eslint/no-explicit-any */
import { SellItemNFTParams } from 'models'

import { ApiResponse } from 'models'
import axiosClient from './axiosClient'

const itemShopsApi = {
  putBosstItem(
    data: { horseId: string | number },
    params: { recover_type?: string; pay_for_energy?: boolean }
  ): Promise<ApiResponse<any>> {
    const url = `/items/horses/${data.horseId}/energy`
    return axiosClient.put(url, params)
  },

  getItemShops(params?: any): Promise<ApiResponse<any>> {
    const url = '/item-shops'
    return axiosClient.get(url, { params })
  },

  getItemShopsDetails(id: number | string): Promise<ApiResponse<any>> {
    const url = `/item-shops/${id}/details`
    return axiosClient.get(url)
  },

  getItemInventory(params?: any): Promise<ApiResponse<any>> {
    const url = '/items/users/boost'
    return axiosClient.get(url, { params })
  },
  getItemInventoryDetail(userId: any, boostId: any): Promise<ApiResponse<any>> {
    const url = `/items/user/${userId}/boost/${boostId}/equipped-detail`
    return axiosClient.get(url)
  },
  postBuyBoosterShop(data: any): Promise<ApiResponse<any>> {
    const url = '/booster-shops/buy'
    return axiosClient.post(url, data)
  },
  checkBoosterValue(boosterId: number): Promise<any> {
    const url = `/booster-shops/check/${boosterId}`
    return axiosClient.get(url)
  },
  postSellItemShopNFT(params: SellItemNFTParams): Promise<any> {
    const url = '/item-shops/sell'
    return axiosClient.post(url, params)
  },
  postBuyItemShopNFT(data: any): Promise<ApiResponse<any>> {
    const url = '/item-shops/buy'
    return axiosClient.post(url, data)
  },
  postCheckNonceItemShopNFT(data: any): Promise<ApiResponse<any>> {
    const url = '/item-shops/check-nonce'
    return axiosClient.post(url, data)
  },
  postCancelSellItemShopNFT(data: any): Promise<ApiResponse<any>> {
    const url = '/item-shops/cancel'
    return axiosClient.post(url, data)
  }
}

export default itemShopsApi
