/* eslint-disable @typescript-eslint/no-explicit-any */
import { HorseRank } from 'models'
import { getWinRate, shortenRaceNameV2 } from 'utils/helper'
import { constants } from 'apps'
import { useLocalStorage } from 'hooks'
import RankCount from '../RankCount'
import { checkExistItem } from 'features/Horse/components/HorseItem/utils'
import VipIcon from 'features/components/VipIcon'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import MyHorseItemStyled from './styled'
import { getImageGender, getImageRegion } from 'utils/getLinkImage'

interface MyHorseItemProps {
  horse: HorseRank
}

const typeRuntype = 'RUNTYPE'
const typeChar = 'CHARACTERISTIC'
const typeAll = 'CHARACTERISTIC_RUNTYPE'
const glassUrl = '/_horse/_avatar/_glass/g_'
const shoesUrl = '/_horse/_avatar/_shoes/sh_'
const armorUrl = '/_horse/_avatar/_armor/'
const skinUrl = '/_horse/_avatar/_skin/'
const domain = process.env.REACT_APP_ROOT_DOMAIN

function MyHorseITem({ horse }: MyHorseItemProps) {
  const [userId] = useLocalStorage(constants.USER_ID_KEY, 0)
  const converToUtf8 = (value: string) => {
    return value.replace('#', '%23')
  }
  const horseColorCode = converToUtf8(horse?.bloodline?.color_code || horse?.blood_line?.color_code || '')
  
  const weapon = horse?.weapon_items_equipped
  const headItem = checkExistItem('HEAD', weapon)
  const bodyItem = checkExistItem('BODY', weapon)
  const footItem = checkExistItem('LEG', weapon)
  const skinItem = checkExistItem('SKIN', weapon)
  const fullURLGlass = horse?.characteristic ? `${domain}${glassUrl}${horse?.characteristic}.png` : `${domain}${glassUrl}char_01.png`
  const fullURLShoes = horse?.run_type ? `${domain}${shoesUrl}${horse?.run_type}.png` : `${domain}${shoesUrl}rt_01.png`
  const typeOfMaterial = bodyItem?.material_type?.toUpperCase()

  const fullURLBody = (typeOfMaterial: any) => {
    switch (typeOfMaterial) {
      case typeRuntype: {
        return domain + armorUrl + bodyItem?.model_name + '_' + horse?.run_type + '.png'
      }
      case typeChar: {
        return domain + armorUrl + bodyItem?.model_name + '_' + horse?.characteristic + '.png'
      }
      case typeAll: {
        return domain + armorUrl + bodyItem?.model_name + '_' + horse?.characteristic + '_' + horse?.run_type + '.png'
      }
      default:
        return domain + armorUrl + bodyItem?.model_name + '.png'
    }
  }

  const headtUrl = domain + armorUrl + headItem?.model_name + '_' + horse?.characteristic + '.png'

  const footUrl = domain + armorUrl + footItem?.model_name + '_' + horse?.run_type + '.png'

  const fullSkinUrl = (hairColor: any) => {
    switch (hairColor) {
      case 'SAME_BODY': {
        return domain + skinUrl + skinItem?.model_name + '_' + horse?.hair_color + '_' + horseColorCode + '.png'
      }
      default:
        return domain + skinUrl + skinItem?.model_name + '_' + horse?.hair_color + '.png'
    }
  }

  return (
    <MyHorseItemStyled mySelf={userId === horse?.owner_id}>
      <td className='color-white stt width-15 center'>
        <RankCount rank={horse?.rank} />
      </td>
      <td className='color-white width-21 horse-name left'>
        <div className='horse-name-content'>
          <div className='background d-flex align-items-center justify-content-center'>
            <img src={horse?.horse_avatar} alt='list-horse' className='avatar' />

            {headItem && headItem?.model_name
              ? <img src={headtUrl} alt={headItem.name} className='item head' /> 
                : <img src={fullURLGlass} className="item glass" /> }

            {bodyItem && bodyItem?.model_name && <img src={fullURLBody(typeOfMaterial)} alt={bodyItem.name} className='item body' />}
      
            {footItem && footItem?.model_name
              ? <img src={footUrl} alt={footItem.name} className='item foot' />
              : <img src={fullURLShoes} className="item shoes" /> }

            {skinItem && skinItem?.model_name && <img src={fullSkinUrl(horse?.hair_color)} alt={skinItem.name} className='item skin' />}
          </div>
          {/* <img src={horse?.horse_avatar} alt='list-horse' className='horse-avatar' /> */}
          <span className="horse__name">
            {shortenRaceNameV2(horse?.horse_name, 18)}
          </span>
        </div>
      </td>
      <td className='width-3'>
        <span className='horse-gender'>
          <MemoizedLoadImageCommon srcImg={getImageGender(horse?.gender)} />
        </span>
      </td>
      <td className='width-3'>
        <span className='region'>
          <MemoizedLoadImageCommon srcImg={getImageRegion(horse?.region) } />
        </span>
      </td>
      <td className='width-3 mg_left_10'>
        <div className='vip__pass'>
          {horse?.vip_pass && <VipIcon />}
        </div>
      </td>
      <td className='color-white width-20 left owner'>
        <span>{shortenRaceNameV2(horse?.owner, 18)}</span>
      </td>
      <td className='color-white width-15 left care'>
        <span>
          {horse?.total_number_of_races} {horse?.first_count}/{horse?.second_count}/{horse?.third_count}
        </span>
      </td>
      <td className='color-white width-20 left race'>{getWinRate(horse)}</td>
    </MyHorseItemStyled>
  )
}

export default MyHorseITem
