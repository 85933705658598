/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import styled from 'styled-components'

const ContentStyled = styled.div`
  height: 100%;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
  }
`

const MemoizedLoadImageCommon = ({ srcImg, id = '', className = '', alt = '' }: any) => {
  return (
    <ContentStyled>
      <img src={srcImg} id={id} className={className} alt={alt} loading="lazy" />
    </ContentStyled>
  )
}
export default React.memo(MemoizedLoadImageCommon) 
