/* eslint-disable @typescript-eslint/no-explicit-any */
import { notification } from 'antd'
import guildApi from 'apis/guildApi'
import { constants, links } from 'apps'
import ChooseHorseModalGuildFarm from 'features/Guild/components/ChooseHoreModalGuildFarm'
import ChooseHorseModalPutToGuild from 'features/Guild/components/ChooseHoreModalPutToGuild'
import ConfirmGuildFarmModal from 'features/Guild/components/ConfirmGuildFarmModal'
import { RaceTable, RequestLoginModal, SwitchBtn } from 'features/Race/components'
import { useAppSelector, useDebounce, useFocusTopScreen, usePreventBodyScroll, useToggle, useUpdateEffect } from 'hooks'
import { GUILD_MESSAGE, NOTIFICATION_MESSAGE } from 'i18n/constants'
import { Career, defaultRegionFilters, GuildListSortType, HorseAvailable, MyHorseListParams, TypeSorts } from 'models'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { SearchInput } from 'shared'
import ButtonCustom from 'shared/ButtonCustom'
import SelectCustom from 'shared/SelectCustom'
import ModalWarning from 'shared/ModalWarning'
import { guildFarmColumns } from 'utils/columns'
import { convertStatusGuild, handleAsyncRequest, shortenUserName } from 'utils/helper'
import GuildFarmStyled from './styled'
import { NUMBER, STRING_REGION } from 'apps/constants'

const defaultTypeFilters: TypeSorts = [
  {
    name: GuildListSortType.RankingAsc,
    isActive: false
  },
  {
    name: GuildListSortType.RankingDesc,
    isActive: true
  },
  {
    name: GuildListSortType.Newest,
    isActive: false
  },
  {
    name: GuildListSortType.Oldest,
    isActive: false
  },
  {
    name: GuildListSortType.Ascending,
    isActive: false
  },
  {
    name: GuildListSortType.Decrease,
    isActive: false
  }
]

export default function GuildFarm() {
  const myHorseListParams: MyHorseListParams = {
    limit: NUMBER.TWENTY,
    page: NUMBER.ONE,
    sort: 'rank-desc'
  }
  useFocusTopScreen()
  const { t } = useTranslation()
  const { profile } = useAppSelector(state => state)
  const [api] = notification.useNotification()
  const { auth } = useAppSelector(state => state)
  const [, setLoading] = useState<boolean>(false)
  const memoizedResultListSelectionColumns = useMemo(() => guildFarmColumns, [])
  const [params, setParams] = useState<MyHorseListParams>(myHorseListParams)
  const [total, setTotal] = useState()
  const [classSelect, setClassSelect] = useState<any>(GuildListSortType.RankingDesc)
  const [isRequestLoginModalOpen, toggleIsRequestLoginModalOpen] = useToggle(false)
  const [listHorse, setListHorse] = useState([])
  const [isMyHorseOn, toggleIsMyHorseOn] = useToggle(false)
  const [isOnMarket, toggleOnMarket] = useToggle(false)
  const useRefSwitch = useRef<HTMLButtonElement>(null)
  const useRefOnMarket = useRef<HTMLButtonElement>(null)
  const [searchValue, setSearchValue] = useState<string>('')
  const debounceSearchValue = useDebounce<string>(searchValue, constants.DEBOUNCE_TIME)
  const { guild_id } = useParams()
  const [isModalChooseHorseOpen, toggleIsChooseHorseModalOpen] = useToggle(false)
  const [isModalChoosePutToGuildOpen, toggleIsChoosPutToGuildModalOpen] = useToggle(false)
  const [horseDetails, setHorseDetails] = useState<HorseAvailable>()
  const [isModalSpecialRaceOpen, toggleIsModalSpecialRaceOpen] = useToggle(false)
  const [isRefresh, toggleIsRefresh] = useToggle(false)
  const [openWarmningPutModal, toggleOpenWarmningPutModal] = useToggle(false)
  const [regionSelect, setRegionSelect] = useState('Region')
  usePreventBodyScroll(isModalChoosePutToGuildOpen)

  const currentUser = useAppSelector(state => state.profile)
  const navigate = useNavigate()

  const openNotification = (message: string, description: string) => {
    notification.config({
      duration: 4
    })

    api.info({
      message,
      description,
      placement: 'top',
      className: message === 'ERROR' ? 'ant-custom-error' : 'ant-custom-success'
    })
  }

  useEffect(() => {
    if (currentUser?.guild_id || guild_id) {
      getGuildFarms()
    }
  }, [currentUser, params])

  useUpdateEffect(() => {
    setListHorse([])
    setParams({ ...params, myHorse: isMyHorseOn, page: NUMBER.ONE, search: searchValue, status: isOnMarket ? 'ON_MARKET' : '' })
  }, [isMyHorseOn, debounceSearchValue, isOnMarket])

  const handleCareer = (career: Career) => {
    const totalRace = career?.total_number_of_races
    const firstClass = career?.first_count
    const secondClass = career?.second_count
    const thirdClass = career?.third_count
    const tltClass = (firstClass + secondClass + thirdClass) / totalRace
    const newTltClass = isNaN(tltClass) ? 0 : tltClass * 100
    const winRateResult = `${Number.isInteger((firstClass / totalRace) * 100)
      ? (firstClass / totalRace) * 100
      : ((firstClass / totalRace) * 100).toFixed(2)
      }% - ${Number.isInteger(newTltClass) ? newTltClass : newTltClass.toFixed(2)}%`
    let valueDisplay = ''
    if (totalRace === 0) {
      valueDisplay = '---'
    }
    if (totalRace > 0 && firstClass === 0 && secondClass === 0 && thirdClass === 0) {
      valueDisplay = '0.00% - 0.00%'
    }
    if (totalRace !== 0 && (firstClass !== 0 || secondClass !== 0 || thirdClass !== 0)) {
      valueDisplay = winRateResult
    }
    return valueDisplay
  }


  const getGuildFarms = async () => {
    setLoading(true)
    const [error, result]: any = await handleAsyncRequest(
      guildApi.getGuildFarms(guild_id ? guild_id : currentUser.guild_id, { ...params })
    )

    if (error) {
      if (error.code === 403) {
        toggleIsRequestLoginModalOpen()
        setLoading(false)
        return
      }
      openNotification('ERROR', error.message || error.errors[0]?.message)
    }

    if (result) {
      const formatData = result.data.records.map((record: any) => {
        return {
          ...record,
          horse_name: record.horse.name,
          horse_gender: record.horse.gender,
          owner_name: record.owner.name,
          status: convertStatusGuild(record.lending_status),
          sub_avatar: record.horse.sub_avatar,
          racing_class_name: record.horse.racing_class,
          winRate: handleCareer(record.career),
          renterName: shortenUserName(record?.renter?.name) || '---',
          guildGender: record.horse.gender,
          guildRegion: record?.horse?.region,
        }
      })
      setListHorse(params.page > NUMBER.ONE ? [...listHorse, ...formatData] : formatData)
      setTotal(result.data?.total)
    }
    setLoading(false)
  }

  const handleRowItemClick = (value: any) => {
    navigate(links.guild.detail(guild_id ? guild_id : currentUser.guild_id, value?.horse?.id))
  }

  const handleSearchValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const handleClassFilterClicked = (item: string) => {
    const handleKeySort = () => {
      switch (item) {
        case GuildListSortType.RankingAsc:
          return 'rank-asc'
        case GuildListSortType.RankingDesc:
          return 'rank-desc'
        case GuildListSortType.Newest:
          return 'gh.in_farm_date-desc'
        case GuildListSortType.Oldest:
          return 'gh.in_farm_date-asc'
        case GuildListSortType.Ascending:
          return 'gh.name-asc'
        case GuildListSortType.Decrease:
          return 'gh.name-desc'
        default:
          return 'rank-desc'
      }
    }
    setParams({
      ...params, page: NUMBER.ONE, sort: handleKeySort()
    })
    setClassSelect(item)
  }

  const handlePutToGuildModal = () => {
    if (!auth.isLogged) {
      toggleIsRequestLoginModalOpen()
      return
    }
    
    if ([...(profile?.black_guilds || [])]?.includes(Number(guild_id))) {
      return toggleOpenWarmningPutModal(true)
    }

    toggleIsChoosPutToGuildModalOpen(true)
  }

  const onGetHorseItem = (horse: HorseAvailable) => {
    setHorseDetails(horse)
  }

  useEffect(() => {
    if (isRefresh) {
      toggleIsModalSpecialRaceOpen(false)
      toggleIsChooseHorseModalOpen(false)
      setParams({ ...params, page: NUMBER.ONE })
      toggleIsRefresh(false)
    }
  }, [isRefresh])

  const handleRegionFilterClicked = (item: string) => {
    setListHorse([])
    if (item?.toUpperCase() === STRING_REGION.SEOUL || item?.toUpperCase() === STRING_REGION.TOKYO) {
      setParams({
        ...params,
        page: NUMBER.ONE,
        region: item?.toUpperCase()
      })
    } else {
      setParams({
        ...params,
        page: NUMBER.ONE,
        region: ''
      })
    }
    setRegionSelect(item)
  }

  return (
    <GuildFarmStyled>
      <div className='guild-farm'>
        <div className='guild-header'>
          <ButtonCustom onClickButton={handlePutToGuildModal} buttonName='Put to guild' />
          <div className='btn-group gap-3'>
            <SwitchBtn
              title={t(`${NOTIFICATION_MESSAGE}.myHorse`)}
              isOn={isMyHorseOn}
              handleSwitchBtnClicked={toggleIsMyHorseOn}
              refSwitch={useRefSwitch}
            />
            <SwitchBtn
              title='On Market'
              isOn={isOnMarket}
              handleSwitchBtnClicked={toggleOnMarket}
              refSwitch={useRefOnMarket}
            />
          </div>

          {/* region */}
          <SelectCustom
            dataSelect={defaultRegionFilters}
            nameSelect={regionSelect}
            onSelected={handleRegionFilterClicked}
            customClass='select-custom'
          />
          <SelectCustom
            dataSelect={defaultTypeFilters}
            nameSelect={classSelect}
            onSelected={handleClassFilterClicked}
            customClass='select-custom'
          />
          <SearchInput
            searchValue={searchValue}
            handleSearchValueChanged={handleSearchValueChanged}
            customClass='search-horse-input'
            placeholder={t(`${GUILD_MESSAGE}.search_horse_name`)}
          />
        </div>
        <RaceTable
          columns={memoizedResultListSelectionColumns}
          data={listHorse ?? []}
          raisePage={setParams}
          loader={true}
          params={params}
          isRowClickable
          checkLoadingPage={false}
          totalRace={total}
          onRowClick={handleRowItemClick}
          currentUser={currentUser}
          noDataText={t(`${NOTIFICATION_MESSAGE}.dataNotFound`)}
          isHorseTable
          isShowVipIcon={true}
        />
      </div>
      {isModalChooseHorseOpen && (
        <ChooseHorseModalGuildFarm
          getHorseDetail={onGetHorseItem}
          toggleIsModalSpecialRaceOpen={toggleIsModalSpecialRaceOpen}
          toggleIsModalOpen={toggleIsChooseHorseModalOpen}
          onCloseButtonClick={() => toggleIsChooseHorseModalOpen(false)}
        />
      )}
      {isModalChoosePutToGuildOpen && (
        <ChooseHorseModalPutToGuild
          toggleIsModalOpen={toggleIsChoosPutToGuildModalOpen}
          onCloseButtonClick={() => toggleIsChoosPutToGuildModalOpen(false)}
          onRefresh={toggleIsRefresh}
        />
      )}

      {openWarmningPutModal &&
        <ModalWarning
          message={
            <div>
              {t(`${GUILD_MESSAGE}.warning_put_horse_1`)}
            </div>
          }
          onClose={toggleOpenWarmningPutModal}
          title={t(`${GUILD_MESSAGE}.warning`)}
          widthM={480}
          heightM={240}
        />
      }

      {isModalSpecialRaceOpen && (
        <ConfirmGuildFarmModal
          toggleIsRefresh={toggleIsRefresh}
          horse={horseDetails}
          toggleIsModalOpen={toggleIsModalSpecialRaceOpen}
          onCloseButtonClick={() => toggleIsModalSpecialRaceOpen(false)}
        />
      )}
      {isRequestLoginModalOpen && <RequestLoginModal toggleIsModalOpen={toggleIsRequestLoginModalOpen} />}
    </GuildFarmStyled>
  )
}
