import styled from 'styled-components'

const CareerBoxContainer = styled.div`
  width: 390px;
  position: relative;
  flex: 1;

  .right-bg {
    position: absolute;
    top: 11px;
    right: -26px;
    height: 260px;
    img {
      height: 100%;
    }
  }

  .bottom-bg {
    position: absolute;
    width: 352px;
    bottom: -35px;
    right: -12px;
    width: 352px;

    img {
      width: 100%;
    }
  }

  .career-box {
    .head {
      position: relative;
      margin-bottom: 0;
      padding-top: 10px;
      p {
        color: #fff566;
        margin-bottom: 0;
        font-size: 18px;
        text-transform: uppercase;
      }

      img {
        position: absolute;
        bottom: -2px;
        left: 0;
      }
    }

    .row-cus {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 20px;

      .race {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;

        span {
          display: inline-block;
          font-size: 18px;
          color: #4ef076;
          text-transform: uppercase;

          &:last-child {
            font-size: 18px;
            color: #fff;
            text-align: center;
          }
        }
      }
    }

    .row__total {
      margin-top: -10px;
    }

    .revenue-box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .row-revenue-total {
        display: flex;
        width: 100%;
      }

      .row-revenue {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img {
          width: 20px;
          height: 20px;
        }

        span {
          font-size: 16px;
        }
      }

      p {
        font-size: 18px;
        margin-bottom: 0;
        margin-top: 10px;
        color: #4ef076;
      }
    }
  }

  .total__content {
    flex: 1;
    display: flex;
    justify-content: flex-start;;
    align-items: center;

    .row-left {
      display: flex;
      align-items: baseline;
      justify-content: flex-start;

      .revenue-box {
        display: flex;
        justify-content: end;
        align-items: center;
        width: 260px;
        margin-right: 10px;

        .row-revenue-total {
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          width: 100%;

          .row-revenue {
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: start;
            -webkit-justify-content: flex-start;
            -ms-flex-pack: start;
            justify-content: end;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            width: 50%;

            
          }
        }

        &.income {
          margin-left: 15px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 105px;
          margin-right: 10px;

          .row-revenue {
            padding-right: 0;
            span {
              font-size: 18px;
              &:first-child {
                width: 70px;
                color: #C8C8C8;
                text-transform: uppercase;
              }
            }
          }
        }

        p {
          font-size: 18px;
          margin-bottom: 0;
          margin-top: 10px;
          color: #4ef076;
          width: 100%;
          text-transform: uppercase;
        }
      }

      /* .career {
        margin-left: 50px;
        display: flex;
        -webkit-box-pack: start;
        justify-content: flex-start;
        flex-direction: column;

        .title {
          font-size: 18px;
          color: #4ef076;
          text-transform: uppercase;
        }
        .row-career {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          line-height: 18px;

          &.first {
            span {
              &:first-child {
                background: linear-gradient(90deg, #ffd901 0%, #fd6401 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
              }
            }
          }

          &.second {
            span {
              &:first-child {
                background: linear-gradient(90deg, #f2f2f2 0%, #4c4c4c 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
              }
            }
          }
          &.thrid {
            span {
              &:first-child {
                background: linear-gradient(90deg, #e69d68 0%, #7e3f1b 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
              }
            }
          }
          span {
            display: inline-block;
            font-size: 18px;

            &:first-child {
              width: 30px;
            }

            &:last-child {
              padding-left: 5px;
              padding-top: 3px;
            }
          }
        }
      } */
    }
  }

  .left-content {
    /* width: 80%; */
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 5px;

    .row-left {
      display: flex;
      align-items: baseline;
      justify-content: flex-start;
      width: 265px;

      .career {
        margin-left: 50px;
        display: flex;
        -webkit-box-pack: start;
        justify-content: flex-start;
        flex-direction: column;

        .title {
          font-size: 18px;
          color: #4ef076;
          text-transform: uppercase;
        }
        .row-career {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          line-height: 18px;

          &.first {
            span {
              &:first-child {
                background: linear-gradient(90deg, #ffd901 0%, #fd6401 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
              }
            }
          }

          &.second {
            span {
              &:first-child {
                background: linear-gradient(90deg, #f2f2f2 0%, #4c4c4c 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
              }
            }
          }
          &.thrid {
            span {
              &:first-child {
                background: linear-gradient(90deg, #e69d68 0%, #7e3f1b 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
              }
            }
          }
          span {
            display: inline-block;
            font-size: 18px;

            &:first-child {
              width: 30px;
            }

            &:last-child {
              padding-left: 5px;
              padding-top: 3px;
            }
          }
        }
      }
    }

    .revenue-total-box {
      width: 240px 
    }

    .revenue-box {
      &.income {
        margin-left: 15px;

        .row-revenue {
          padding-right: 0;
          span {
            font-size: 18px;
            &:first-child {
              width: 70px;
              color: #C8C8C8;
              text-transform: uppercase;
            }
          }
        }
      }

      .row-revenue {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        padding-right: 5px;

        span {
          font-size: 18px;
        }

        img {
          margin-left: 5px;
        }
      }

      p {
        font-size: 18px;
        margin-bottom: 0;
        margin-top: 10px;
        color: #4ef076;
        width: 100%;
        text-transform: uppercase;
      }
    }
  }

  .right-content {
    width: 130px;
    margin-top: 7px;

    /* .progress-custom {
      margin-left: 5px;
    } */
  }

  .icon__old {
    opacity: 0.5;
  }
`

export default CareerBoxContainer
