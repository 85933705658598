import styled from 'styled-components'

const IconMerahOrKudatyled = styled.span`
  color: white;
  .icon {
    padding-bottom: 2px;
  }
`

export default IconMerahOrKudatyled
