import { BUTTON_DT_BG } from 'assets/images'
import styled from 'styled-components'

const InfoBoxContainer = styled.div`
  .content-box {
    display: flex;

    .name {
      margin-bottom: 0;
    }

    .img-box {
      width: 134px;
      height: 134px;
      margin-right: 10px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      /* background: linear-gradient(90deg, #403F3E 0%, #424240 50%, #30313D 100%); */
      overflow: hidden;

      .img-inside {
        /* width: 125px;
        height: 125px;
        overflow: hidden;
        border-radius: 50%; */
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .info {
      p {
        font-size: 18px;
        text-transform: uppercase;
        color: #fff;
      }

      .row-info {
        display: flex;
        span {
          color: #fff;
          font-size: 18px;
          display: inline-block;
        }

        &.first {
          span {
            color: #b3b3b3;
          }
        }
        &.second {
          margin-top: 10px;
        }
      }
    }
  }

  .button-detail {
    background: url(${BUTTON_DT_BG});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 146px;
    height: 42px;
    border: none;
    color: #4ef076;
    font-size: 18px;
    margin-top: 50px;
  }
`

export default InfoBoxContainer
