/* eslint-disable @typescript-eslint/no-explicit-any */
import { VIP_ICON } from "assets/images";
import VipIconContainer from "./styled";

interface vipProps {
  left?: number
  top?: number
}

const VipIcon = ({ left, top }: vipProps) => {
  return (
    <VipIconContainer style={{ marginLeft: left, marginTop: top }}>
      <img src={VIP_ICON} alt="vip" />
    </VipIconContainer>
  )
}

export default VipIcon;