/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ApiResponse,
  GetMyHorseListResponse,
  MyHorseListParams,
  UserInformationResponse
} from 'models'
import axiosClient from './axiosClient'

const rankingApi = {
  getTopHorses(params: MyHorseListParams): Promise<ApiResponse<GetMyHorseListResponse>> {
    const url = 'horse/top-victory-horses'

    return axiosClient.get(url, { params })
  },

  getTopUsers(params: MyHorseListParams): Promise<ApiResponse<GetMyHorseListResponse>> {
    const url = 'horse/top-victory-stables'
    return axiosClient.get(url, { params })
  },

  getUserRanking(params: any): Promise<ApiResponse<UserInformationResponse>> {
    const url = 'user/my-top-victory-stables'
    return axiosClient.get(url, { params })
  }
}

export default rankingApi
