import styled from 'styled-components'

const TopRankingContainer = styled.div`
  width: 100%;
  p {
    color: #fff;
  }

  .head-box {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin-bottom: 40px;
    column-gap: 38px;
  }
`

const ListContainer = styled.div`
  .my-horse-container {
    background-color: #121520;
    padding: 32px 0px;
    width: 100%;

    ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
      padding: 32px;
    }

    ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
      padding: 16px 12px;
    }
    ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
      overflow-x: scroll;
    }

    .header-horse {
      font-size: 16px;
      justify-content: space-between;

      span {
        color: #8d8d8d;
      }

      .horse {
        width: 20%;
        text-align: center;
      }

      .name {
        width: 20%;
        text-align: center;
      }

      .gender {
        width: 20%;
        text-align: center;
      }

      .blood-line {
        width: 20%;
        text-align: center;
      }
      .classes {
        width: 20%;
        text-align: center;
      }

      .status {
        margin-left: 35px;
      }

      .text_align_left {
        text-align: left;
      }
    }

    .my-horse-table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0px 16px;

      ${({ theme }) => theme.media.lessThan(theme.size.lg)} {
        min-width: 760px;
      }
    }

    .margin-left-11 {
      margin-left: 11px;
    }

    .width-27 {
      width: 27% !important;
    }

    .width-25 {
      width: 25% !important;
    }

    .width-20 {
      width: 20% !important;
    }

    .width-21 {
      width: 22% !important;
    }

    .width-22 {
      width: 22% !important;
    }

    .width-15 {
      width: 15% !important;
    }

    .width-3 {
      width: 3% !important;
    }

    .width-5 {
      width: 5% !important;
    }

    .width-30 {
      width: 30% !important;
    }

    .center {
      text-align: center;
    }

    .paginate {
      display: flex;
      justify-content: center;
      &.custom {
        flex-direction: column;
        .pagination {
          margin:  0 auto;
        }
      }
      .pagination {
        .page-item {
          .page-link {
            z-index: 3;
            color: #fff;
            background-color: #191d2c;
            border-color: #51c21a;
            &:focus {
              box-shadow: none;
            }
          }
        }

        .page-item.active {
          .page-link {
            z-index: 3;
            color: #fff;
            background-color: #135200;
            border-color: #135200;
            font-weight: 700;
          }
        }

        .page-item.disabled {
          cursor: not-allowed;
          .page-link {
            z-index: 3;
            color: #fff;
            background-color: #191d2c;
            border-color: #191d2c;
          }
        }

        .page-item:hover {
          .page-link {
            z-index: 3;
            color: #fff;
            background-color: #135200;
            border-color: #191d2c;
          }
        }
      }
    }
  }
`

export { TopRankingContainer, ListContainer }
