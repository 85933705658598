import { RaceClassNumber, RaceFieldType } from './race'

export interface TournamentStatus {
  // close_at: string
  // close_date: string
  // id: number
  // lock_pool_time: string
  // public_pool_time: string
  // schedule_at: string
  // start_date: string
  // tournament_status: TOURNAMENT_STATUS
  // selection_start: string
  // public_viewer_at: string
  create_time: string
  event_open_time: string
  lock_farm_time: string
  race_start_time: string
  event_end_time: string
  public_event_time: string
  race_time_waiting_time: string
  tournament_status: TOURNAMENT_STATUS
  time_open: ITimeOpenParams[]
  id: string
}

export enum TOURNAMENT_STATUS {
  PENDING = 'PENDING',
  POOL_OPEN = 'POOL_OPEN',
  SELECTION_HORSE = 'SELECTION_HORSE',
  SCHEDULE_PUBLIC = 'SCHEDULE_PUBLIC',
  END = 'END'
}

export enum OWN_STATUS {
  STABLE = 'STABLE'
}

export enum EVENT_DAY {
  FIRST = 'FIRST',
  LAST = 'LAST'
}

export interface ITimeOpenParams {
  race_start_time: number
  total_race: number
  event_day: string
  day_order: string
}

export interface GetCurrentRaceListParams {
  limit: number
  page: number
  raceClass?: RaceClassNumber
  fieldType?: RaceFieldType
  sort?: string[]
  search?: string
  dayOrder?: EVENT_DAY | string
  myHorse?: boolean
}

export enum SpecialSortName {
  Newest = 'Newest',
  Oldest = 'Oldest',
  ASC = 'Name ASC',
  Desc = 'Name Desc'
}

export type ISpecialTypeSort = SpecialTypeSort[]

export type SpecialTypeSort = {
  name: SpecialSortName | 'Default'
  key?: string | ''
  isActive: boolean
}
