import { TWO_LINE } from 'assets/images'
import classNames from 'classnames'
import TwoLineTitleStyled from './styled'

interface TwoLineTitleProps {
  text: string
  customClass?: string
  classGuild?: string
}

function TwoLineTitle({ text, customClass = '', classGuild = '' }: TwoLineTitleProps) {
  const customClassTwoLine = classNames('text-color', customClass)

  return (
    <TwoLineTitleStyled className={customClassTwoLine}>
      <div className='text'>{text}</div>
      {text.length ? <img src={TWO_LINE} alt='' className={`line ${classGuild}`} /> : ''}
    </TwoLineTitleStyled>
  )
}

export default TwoLineTitle
