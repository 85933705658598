/* eslint-disable @typescript-eslint/no-explicit-any */
import { RACE_FREE, RACE_FREE_PRIZE, RACE_LICENSE, RACE_PAID } from "assets/images"
// import { ReactComponent as IC_INFO } from 'assets/images/icons/info.svg'
import { IMG_INFO } from 'assets/images'
import ToolTipInfo from "shared/ToolTipInfo"
import { useSelector } from "react-redux"
import { tooltipInfoSelector } from "apps/redux/tooltip/selector"
import MspIconContainer from "./styled"

interface RaceTypeProps {
  raceType: string
  width?: number
  height?: number
  fontSize?: number
  leftText?: number
  topText?: number
  topICInfo?: number
  rigthICInfo?: number
  posTooltip?: boolean
  widthICInfo?: number
  heightICInfo?: number
}

function RaceType({ raceType, width = 110, height = 40, fontSize = 17, leftText = 35, topText = 9, topICInfo = 0, rigthICInfo = 3, widthICInfo = 14, heightICInfo = 14, posTooltip = true }: RaceTypeProps) {
  const tooltipInfo: any[] = useSelector(tooltipInfoSelector)
  const getRaceType = (type: string) => {
    switch (type) {
      case 'FREE': {
        const findFree = tooltipInfo?.find((info: any) => {
          return info?.name === 'FREE';
        }) || []
        return getRaceChild(findFree?.description, RACE_FREE, "FREE", 0, 2)
      }
      case 'FREE_PRIZE': {
        const findFreePrize = tooltipInfo?.find((info: any) => {
          return info?.name === 'FREE_PRIZE';
        })
        return getRaceChild(findFreePrize?.description, RACE_FREE_PRIZE, "KUDA", 1, 2)
      }        
      case 'PAID': {
        const findPaid = tooltipInfo?.find((info: any) => {
          return info?.name === 'PAID';
        })
        return getRaceChild(findPaid?.description, RACE_PAID, "MERAH", 1, 3)
      }
        
      case 'LICENSE': {
        const findLicense = tooltipInfo?.find((info: any) => {
          return info?.name === 'LICENSE';
        });
        return getRaceChild(findLicense?.description, RACE_LICENSE, "LICENSE", 1, 3)
      }
      default:
        return type
    }
  }

  const getRaceChild = (description: string, linkImg: any, type: string, bt: number, mgTop: number) => {
    return <span className="contain__img">
        <ToolTipInfo name={description} posTooltip={posTooltip} bt={bt} mgTop={mgTop} />
        <img src={linkImg} alt={type} />
        <span className="img__text">{ type }</span>
        <span className="ic__info">
          <img src={IMG_INFO} alt='' className='info' />
        </span>
      </span>
  }

  return (
    <MspIconContainer
      width={width}
      height={height}
      fontSize={fontSize}
      leftText={leftText}
      topText={topText}
      topICInfo={topICInfo}
      rigthICInfo={rigthICInfo}
      widthICInfo={widthICInfo}
      heightICInfo={heightICInfo}
    >
      {getRaceType(raceType)}
    </MspIconContainer>
  )
}

export default RaceType
