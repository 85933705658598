/* eslint-disable @typescript-eslint/no-explicit-any */

import { notification } from 'antd'
import guildApi from 'apis/guildApi'
import { constants, links } from 'apps'
import ChooseHorseModalGuildFarm from 'features/Guild/components/ChooseHoreModalGuildFarm'
import ConfirmGuildFarmModal from 'features/Guild/components/ConfirmGuildFarmModal'
import { RaceTable, RequestLoginModal } from 'features/Race/components'
import { useAppSelector, useDebounce, useFocusTopScreen, useToggle, useUpdateEffect } from 'hooks'
import { GUILD_MESSAGE, NOTIFICATION_MESSAGE } from 'i18n/constants'
import { Career, HorseAvailable, GuildListSortType, MyHorseListParams, TypeSorts } from 'models'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { SearchInput } from 'shared'
import SelectCustom from 'shared/SelectCustom'
import { guildHorseOwnerColumns } from 'utils/columns'
import { handleAsyncRequest, shortenUserName } from 'utils/helper'
import HorseOwnerStyled from './styled'

const defaultTypeFilters: TypeSorts = [
  {
    name: GuildListSortType.RankingAsc,
    isActive: false
  },
  {
    name: GuildListSortType.RankingDesc,
    isActive: false
  },
  {
    name: GuildListSortType.Ascending,
    isActive: false
  },
  {
    name: GuildListSortType.Decrease,
    isActive: false
  }
]

export default function HorseOwner() {
  const myHorseListParams: MyHorseListParams = {
    limit: 20,
    page: 1,
    sort: 'rank-desc'
  }
  useFocusTopScreen()
  const { t } = useTranslation()
  const [api] = notification.useNotification()
  const [, setLoading] = useState<boolean>(false)
  const memoizedResultListSelectionColumns = useMemo(() => guildHorseOwnerColumns, [])
  const [params, setParams] = useState<MyHorseListParams>(myHorseListParams)
  const [total, setTotal] = useState()
  const [isRequestLoginModalOpen, toggleIsRequestLoginModalOpen] = useToggle(false)
  const [listHorse, setListHorse] = useState([])
  const [isModalChooseHorseOpen, toggleIsChooseHorseModalOpen] = useToggle(false)
  const [horseDetails, setHorseDetails] = useState<HorseAvailable>()
  const [isRefresh, toggleIsRefresh] = useToggle(false)
  const [isModalSpecialRaceOpen, toggleIsModalSpecialRaceOpen] = useToggle(false)
  const { guild_id } = useParams()
  const [searchValue, setSearchValue] = useState<string>('')
  const debounceSearchValue = useDebounce<string>(searchValue, constants.DEBOUNCE_TIME)
  const [classSelect, setClassSelect] = useState<any>(GuildListSortType.RankingDesc)
  const navigate = useNavigate()
  const selectRef = useRef<any>(null)

  const { profile } = useAppSelector(state => state)

  const openNotification = (message: string, description: string) => {
    notification.config({
      duration: 4
    })

    api.info({
      message,
      description,
      placement: 'top',
      className: message === 'ERROR' ? 'ant-custom-error' : 'ant-custom-success'
    })
  }

  useEffect(() => {
    if (isRefresh) {
      toggleIsChooseHorseModalOpen()
      setParams({ ...params, page: 1 })
    }
  }, [isRefresh])

  useUpdateEffect(() => {
    setListHorse([])
    setParams({ ...params, page: 1, sort: selectRef.current, search: debounceSearchValue })
  }, [debounceSearchValue])

  useEffect(() => {
    if (profile.id) {
      getGuildOwner()
    }
  }, [profile, params])

  const handleCareer = (career: Career) => {
    const totalRace = career?.total_number_of_races
    const firstClass = career?.first_count
    const secondClass = career?.second_count
    const thirdClass = career?.third_count
    const tltClass = (firstClass + secondClass + thirdClass) / totalRace
    const newTltClass = isNaN(tltClass) ? 0 : tltClass * 100
    const winRateResult = `${Number.isInteger((firstClass / totalRace) * 100)
      ? (firstClass / totalRace) * 100
      : ((firstClass / totalRace) * 100).toFixed(2)
      }% - ${Number.isInteger(newTltClass) ? newTltClass : newTltClass.toFixed(2)}%`
    let valueDisplay = ''
    if (totalRace === 0) {
      valueDisplay = '---'
    }
    if (totalRace > 0 && firstClass === 0 && secondClass === 0 && thirdClass === 0) {
      valueDisplay = '0.00% - 0.00%'
    }
    if (totalRace !== 0 && (firstClass !== 0 || secondClass !== 0 || thirdClass !== 0)) {
      valueDisplay = winRateResult
    }
    return valueDisplay
  }

  const getGuildOwner = async () => {
    setLoading(true)
    const [error, result]: any = await handleAsyncRequest(guildApi.getHorseOwner(guild_id ? guild_id : profile.guild_id, { ...params }))
    if (error) {
      if (error.code === 403) {
        toggleIsRequestLoginModalOpen()
        setLoading(false)
        return
      }
      openNotification('ERROR', error.message || error.errors[0]?.message)
    }
    if (result) {
      const formatData = result.data.records.map((record: any) => {
        return {
          ...record,
          member: shortenUserName(record.user_name),
          winRate: handleCareer(record.career),
          farmHorse: record.horse_in_guild,
          role: record.role
        }
      })
      setListHorse(params.page > 1 ? [...listHorse, ...formatData] : formatData)
      setTotal(result.data?.total)
    }
    setLoading(false)
    toggleIsRefresh(false)
  }

  const handleRowItemClick = (value: any) => {
    navigate(links.guild.detailOwner(guild_id ? guild_id : profile.guild_id, value?.id))
  }

  const handleHorseItem = (horse: HorseAvailable) => {
    setHorseDetails(horse)
  }

  const handleSearchValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const handleClassFilterClicked = (item: string) => {
    const handleKeySort = () => {
      switch (item) {
        case GuildListSortType.RankingAsc:
          return 'rank-asc'
        case GuildListSortType.RankingDesc:
          return 'rank-desc'
        case GuildListSortType.Ascending:
          return 'name-asc'
        case GuildListSortType.Decrease:
          return 'name-desc'
        default:
          return 'rank-desc'
      }
    }

    selectRef.current = handleKeySort()
    setParams({
      ...params, page: 1, sort: selectRef.current, search: debounceSearchValue
    })
    setClassSelect(item)
  }

  return (
    <HorseOwnerStyled>

      <div className='search-box'>
        <SelectCustom
          dataSelect={defaultTypeFilters}
          nameSelect={classSelect}
          onSelected={handleClassFilterClicked}
          customClass='select-custom'
        />

        <SearchInput
          searchValue={searchValue}
          handleSearchValueChanged={handleSearchValueChanged}
          customClass='search-horse-input'
          placeholder={t(`${GUILD_MESSAGE}.search_member`)}
        />
      </div>
      <div className='your-horse'>
        <RaceTable
          columns={memoizedResultListSelectionColumns}
          data={listHorse ?? []}
          raisePage={setParams}
          loader={true}
          params={params}
          isRowClickable
          checkLoadingPage={false}
          totalRace={total}
          onRowClick={handleRowItemClick}
          currentUser={profile}
          noDataText={t(`${NOTIFICATION_MESSAGE}.dataNotFound`)}
          isHorseTable
          isShowVipIcon={true}
        />
      </div>

      {isModalChooseHorseOpen && (
        <ChooseHorseModalGuildFarm
          getHorseDetail={handleHorseItem}
          toggleIsModalSpecialRaceOpen={toggleIsModalSpecialRaceOpen}
          toggleIsModalOpen={toggleIsChooseHorseModalOpen}
          onCloseButtonClick={() => toggleIsChooseHorseModalOpen(false)}
        />
      )}
      {isModalSpecialRaceOpen && (
        <ConfirmGuildFarmModal
          toggleIsRefresh={toggleIsRefresh}
          horse={horseDetails}
          toggleIsModalOpen={toggleIsModalSpecialRaceOpen}
          onCloseButtonClick={() => toggleIsModalSpecialRaceOpen(false)}
        />
      )}
      {isRequestLoginModalOpen && <RequestLoginModal toggleIsModalOpen={toggleIsRequestLoginModalOpen} />}
    </HorseOwnerStyled>
  )
}
