/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal } from 'shared'
import { useTranslation } from 'react-i18next'
import SelectItemModalStyled from './styled'
import { BG_BUTTON, CLOSE_BTN, IFRAME_ITEM, BUTTON_REDUCE, BODER_CIRCLE, BUTTON_ADD } from 'assets/images'
import { RateStar } from 'features/Horse/components'
import { ITEMS_MESSAGE } from 'i18n/constants'

interface SelectItemModalProps {
  onCloseButtonClick: () => void
  onConfirmUse: () => void
  dataPreview: any
  handleSearchValueChanged: (value: number) => void

  searchValue: number,
  isChangeItem: boolean
}

function SelectItemModal({ 
  onCloseButtonClick, onConfirmUse, handleSearchValueChanged, searchValue, dataPreview}: SelectItemModalProps) {
  const { t } = useTranslation()
  const VALIDATE_MAX = "You don't have enough item"
  const VALIDATE_MIN = 'You must enter the number greater than 1'
  const handleBackModalCancel = () => {
    onCloseButtonClick()
  }
  const generateRateLevel = (maxLevel: number, currentLevel: number): JSX.Element[] => {
    const rateStars: JSX.Element[] = []
    for (let i = 0; i < maxLevel; i++) {
      rateStars.push(<RateStar key={i} isActive={i < currentLevel} />)
    }
    return rateStars
  }
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.replace(/[^0-9]/g, '')
    handleSearchValueChanged(Number(newValue) ? Number(newValue) : 0)
  }
  const onDecreaseClicked = () => {
    handleSearchValueChanged(Number(searchValue) - 1)    
  }
  const onIncreaseClicked = () => {
    handleSearchValueChanged(Number(searchValue) + 1)    
  }
  
  return (
    <Modal>
      <SelectItemModalStyled>
        <div className='choose-horse-modal modal-select-item'>
          <button className='close-btn p-0 position-absolute' role='button' onClick={handleBackModalCancel}>
            <img src={CLOSE_BTN} alt='close' />
          </button>
          <div className='confirm-horse'>
            <h4>{t(`${ITEMS_MESSAGE}.select_item`)}</h4>
            <img className='iframe' src={IFRAME_ITEM} alt="" />
            <p className='confirm-horse-title color-white'>{t(`${ITEMS_MESSAGE}.enter_item`)}</p>
            <div className="list-item">
              <div 
                className='meta-block'
              >
                <div className="item-block">
                  <div className="sub-block">
                    <button className="item-dp">
                      <img src={dataPreview.boost.img} alt={dataPreview.boost.description} />
                    </button>
                    <div className="amount d-flex align-items-center">
                      <div className="star d-flex align-items-center">
                        { generateRateLevel(3, dataPreview.boost.level) }
                      </div>
                    </div>
                    <div className="numb">
                      <img src={BODER_CIRCLE} alt="" />
                      <span>{dataPreview.amount}</span>
                    </div>
                  </div>
                </div>
                <div className="count-item">
                  <button
                    className={`decrease font-bold ${Number(searchValue) === 0 && 'disabled'}`}
                    onClick={onDecreaseClicked}
                    disabled={Number(searchValue) === 0}
                  >
                    <img src={BUTTON_REDUCE} alt="" />
                  </button>
                  <input 
                    onChange={handleInputChange}
                    type="text"
                    placeholder='0'
                    value={searchValue}
                    autoFocus = { true }
                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                  />
                  <button
                    className={`decrease font-bold 
                      ${(searchValue == dataPreview.amount  || searchValue > dataPreview.amount) 
                        && 'disabled'}`
                    }
                    onClick={onIncreaseClicked}
                    disabled={searchValue == dataPreview.amount  || searchValue > dataPreview.amount}
                  >
                    <img src={BUTTON_ADD} alt="" />
                  </button>
                </div>
              </div>
            </div>
            <p className='err'> 
              {
                searchValue > dataPreview.amount 
                  ? VALIDATE_MAX : ( searchValue && Number(searchValue) < 1) ? VALIDATE_MIN : ''} 
            </p>

            <div className='confirm-horse-btns d-flex align-items-center justify-content-center'>
              <button
                onClick={
                  onConfirmUse
                }
                className={
                  `confirm-btn ${
                    Number.isInteger(Number(searchValue)) && 
                    Number(searchValue) > 0 && 
                    (Number(searchValue) < dataPreview.amount || Number(searchValue) == dataPreview.amount)
                      ? 'active' : 'disable'
                }`
              }>
                <img src={BG_BUTTON} alt='confirm' />
                <span className="btn-name">Use</span>
              </button>
            </div>
          </div>
        </div>
      </SelectItemModalStyled>
    </Modal>
  )
}

export default SelectItemModal
