/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback } from 'react'
import { useToggle } from 'hooks'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { RateStarItems } from 'features/Horse/components'
import AbilityBoxStyled from './styled'
import SelectItemModal from 'features/Horse/components/SelectItemModal'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import { NEXT_RACE_BOTTOM_FRAME, ICON_DOPING, SHADOW_DOPING } from 'assets/images'
import { handleAsyncRequest } from 'utils/helper'
import horseApi from 'apis/horseApi'
import RemoveItemModal from 'features/Horse/components/RemoveItemModal'
import openNotification from 'utils/toast'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import {
  ListBootsType
} from 'models'
import { getAbility } from 'utils/getLinkImage'
interface AbilityBoxProps {
  customClass?: string
  name: string
  level?: number
  horse_id: string
  currentStatsSelected: string
  currentStats: () => void,
  onConfirmFinal: () => void,
  getPosition: (data: any) => void,
  setCurrentStatsSelected: (data: any) => void,
  onRemoveSuccess: () => void,
  toggleIsChangeItem: (data: any) => void,
  onCloseModalSelectItem: (data: any) => void,
  listTypeBoots: any,
  currentAmountStats: number
  currentPosition: number,
  idItemsSelected: number,
  idItemsUseSelected: number,
  listGetPosition: any,
  horse: any,
  isChangeItem: boolean,
  isLoading: boolean,
  isMyHorse: boolean,
  isMyLending: boolean,
}

function AbilityBoxItem({ 
  customClass = '', 
  name, level = 1, 
  currentStats, 
  listTypeBoots, 
  horse_id,
  currentStatsSelected,
  onConfirmFinal,
  onCloseModalSelectItem,
  setCurrentStatsSelected,
  getPosition,
  onRemoveSuccess,
  listGetPosition,
  isChangeItem,
  toggleIsChangeItem,
  isLoading,
  isMyHorse,
  isMyLending,
  horse }: AbilityBoxProps) {
  const { t } = useTranslation()
  const abilityBoxClass = classNames('position-relative', customClass)
  const [isSelectItemModal, toggleIsSelectItemModal] = useToggle(false)  
  const [currentPosision, setCurrentPosision] = useState<string>('')
  const [isModalRemoveItemOpen, toggleIsRemoveItemOpen] = useToggle(false)
  const [itemDetail, setItemDetail] = useState<ListBootsType[]>([])
  const [idCurrentRemove, setIdCurrentRemove] = useState<string>('')
  const [isLoadingShowRemove, setIsLoadingRemove] = useState(false)
  listGetPosition = listGetPosition.sort((a: any, b: any) => a.position - b.position)
  const generateRateLevel = useCallback(
    (maxLevel: number, currentLevel: number, afterUseDoping: number): JSX.Element[] => {
      const rateStars: JSX.Element[] = []

      for (let i = 0; i < maxLevel; i++) {
        rateStars.push(<RateStarItems isUseDoping={i < afterUseDoping}  key={i} isActive={i < currentLevel} />)
      }

      return rateStars
    },
    [level]
  )

  const hanldeConfirmUsedItems = async () => {
    toggleIsSelectItemModal(false)
    onConfirmFinal()
    onCloseModalSelectItem(false)
  }
  const showModalSelectItem  = (position: any) => {
    toggleIsSelectItemModal(true)
    currentStats()
    setCurrentPosision(position)
    getPosition(position)
  }
    
  const showModalRemoveItem  = async (idDetail: string, position: any) => {
    const [error, result]: any = await handleAsyncRequest(horseApi.getBoostItemsDetail(idDetail))
    if (result) {
      toggleIsRemoveItemOpen(true)
      setItemDetail(result?.data)
      setIdCurrentRemove(result?.data.id)
    }
    if (error) {
      setCurrentStatsSelected('')
      toggleIsRemoveItemOpen(false)
      openNotification({
        message: 'ERROR',
        description: error.message || error.errors[0]?.message,
        className: 'ant-custom-error'
      })
      setItemDetail([])
      onRemoveSuccess()
      toggleIsSelectItemModal(false)
    }
    setCurrentPosision(position)
    getPosition(position)   
    setTimeout(() => {
      setIsLoadingRemove(false)
    }, 1000); 
  }

  const pushNotification = (description: string, isSuccess?: boolean) => {
    openNotification({
      message: '',
      description: description,
      className: isSuccess ? 'toast-success' : 'toast-error'
    })
  }
  const handleRemoveItem = async () => {
    const [error, result]: any = await handleAsyncRequest(horseApi.deleteHorseBoosterItem(horse_id, String(idCurrentRemove)))
    if (result) {
      onRemoveSuccess()
      pushNotification(t(`${NOTIFICATION_MESSAGE}.successfullyRemovedBootsItems`), true)
    }
    if (error) {
      openNotification({
        message: 'ERROR',
        description: error.message || error.errors[0]?.message,
        className: 'ant-custom-error'
      })
    }
    toggleIsRemoveItemOpen(false)
  }  
  const handleChangeItem = async () => {
    toggleIsSelectItemModal(false)
    toggleIsChangeItem(true)
    currentStats()
    toggleIsSelectItemModal(true)
    toggleIsRemoveItemOpen(false)
  }

  const handleCloseModal = () => {
    toggleIsRemoveItemOpen(false)
    toggleIsChangeItem(false)
  }
  const handleCloseModalSelectItem = () => {
    setCurrentStatsSelected('')
    toggleIsSelectItemModal(false)
    toggleIsChangeItem(false)
    onRemoveSuccess()
  }
  const foundOne = listGetPosition.find((element: any) => element.position == 1);
  const foundTwo = listGetPosition.find((element: any) => element.position == 2);
  const checkStar = listGetPosition.filter((element: any) => element.slot == element.boost.effect_type);
  const isDopingUsedByMyPosisionOne = ((horse && horse?.user?.id) == (foundOne && foundOne?.item_owner?.id) || horse?.user === null) 
  const isDopingUsedByMyfoundTwo = ((horse && horse?.user?.id) == (foundTwo && foundTwo?.item_owner?.id) || horse?.user === null)
  
  return (
    <AbilityBoxStyled className={abilityBoxClass}>
      <div className={`ability-box-container ${(isMyLending || isMyHorse) ? 'availaible' : 'only-view' }`}>
        <div className='ability-box d-flex justify-content-between align-items-center'>
          <div className='title color-white text-uppercase img__ability'>
            <MemoizedLoadImageCommon srcImg={getAbility(name)} />
          </div>
          <div className='level d-flex align-items-center'>
            {generateRateLevel(3, level, (isMyLending || isMyHorse) && (level + checkStar.length))}
              <>
                { foundOne 
                  ? (
                      !(level == 3)  && (
                          <button
                            className={`push-doping use ${( isDopingUsedByMyPosisionOne ) ? '' : 'disabled'} `}
                            onClick={ () => showModalRemoveItem(String(foundOne.id), '1')} 
                          >
                            <img className='shadown' src={SHADOW_DOPING} alt="" />
                            <img src={foundOne.boost?.img} alt='' className='d-block' />
                            <span> {foundOne.amount}</span>
                          </button>  
                      )
                  ) 
                  : ( 
                    <button
                      className={`push-doping new ${(level == 3 )? 'disable' : ''} `}
                      onClick={ () => showModalSelectItem('1')} 
                    >
                      <img src={ ICON_DOPING } alt='' className='' />
                    </button>  
                  )
                }  
                { foundTwo 
                  ? (
                    !(level > 2 || level == 2) && (
                      <button
                        className={`push-doping use ${( isDopingUsedByMyfoundTwo) ? '' : 'disabled'} `}
                        onClick={ () => showModalRemoveItem(String(foundTwo.id), '2')} 
                      >
                        <img className='shadown' src={SHADOW_DOPING} alt="" />
                        <img src={foundTwo.boost?.img} alt='' className='d-block' />
                        <span> {foundTwo.amount}</span>
                      </button>  
                    )
                  ) 
                  : ( 
                    <button
                      className={`push-doping new ${(level > 2 || level == 2 )? 'disable' : ''} `}
                      onClick={ () => showModalSelectItem('2')} 
                    >
                      <img src={ ICON_DOPING } alt='' className='' />
                    </button>  
                  )
                }
              </>
        </div>
        </div>
      </div>
      <img src={NEXT_RACE_BOTTOM_FRAME} alt='' className='position-absolute bottom-frame d-none d-xl-inline-block' />
      { isSelectItemModal &&
        <SelectItemModal 
          listTypeBoots={listTypeBoots}
          onConfirm={hanldeConfirmUsedItems}
          onCloseButtonClick={handleCloseModalSelectItem}
          horse_id={horse_id}
          position={currentPosision}
          currentStatsSelected={currentStatsSelected}
          isChangeItem={isChangeItem}
          oldItems={itemDetail}
          isLoading={isLoading}
        />
      }
      {isModalRemoveItemOpen &&
        <RemoveItemModal
          dataPreview={itemDetail}
          onRemove={handleRemoveItem}
          onChange={handleChangeItem}
          onCloseButtonClick={handleCloseModal}
          isLoading={isLoadingShowRemove}
        />}
    </AbilityBoxStyled>
  )
}

export default AbilityBoxItem
