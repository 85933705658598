/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { GUILD_MESSAGE } from 'i18n/constants'

import ModalConfirmJoinGuild from 'features/Guild/components/ModalConfirmJoinGuild'
import { handleAsyncRequest } from 'utils/helper'
import guildApi from 'apis/guildApi'
import { getCurrentUser } from 'utils/metamask'
import { useAppDispatch, useAppSelector } from 'hooks'
import { setCurrentUser } from 'features/Profile/profile.slice'

import JoindGuildContainer from './styled';
import { BUTTON_JOIN_BG } from 'assets/images';

const JoindGuild = ({ informationData, onJoinGuildSuccess, type, disabled = false }: any) => {
  const { t } = useTranslation()
  const [showModalConfirmJoinGuild, setShowModalConfirmJoinGuild] = useState<boolean>(false);
  const [loadingJoinGuild, setLoadingJoinGuild] = useState<boolean>(false);
  const [error, setError] = useState<string>('')
  const dispatch = useAppDispatch()
  const { profile } = useAppSelector(state => state)

  const handleToggleModal = () => {
    setShowModalConfirmJoinGuild(!showModalConfirmJoinGuild);
  }

  const handleJoinGuild = async () => {
    setLoadingJoinGuild(true)
    const guildId = informationData?.id
    const [error, result]: any = await handleAsyncRequest(guildApi.joinGuild(guildId))

    if (error) {
      if (error?.message?.includes('members at the limit')) {
        setError('The number of guild members is full. <br />Please choose another guild.')
      } else {
        setError(error?.message)
      }
    }
    if (result) {
      handleToggleModal()
      setError('');

      const [, currentUser]: any = await handleAsyncRequest(getCurrentUser())
      if (!currentUser) return
      if (type === t(`${GUILD_MESSAGE}.type_apply`)) {
        dispatch(setCurrentUser({ ...profile, ...currentUser, apply_guilds: [...(profile?.apply_guilds || []), ...[guildId]] }))
      } else {
        dispatch(setCurrentUser({ ...profile, ...currentUser, guild_id: guildId }))
      }
      onJoinGuildSuccess()
    }
    setLoadingJoinGuild(false)
  }

  return (
    <JoindGuildContainer>
      <div className={`btn-container ${disabled ? 'disabled' : ''}`} onClick={!disabled ? handleToggleModal : undefined}>
        <img src={BUTTON_JOIN_BG} alt='' className='background-btn' />
        <span className='btn-name'>
          {type === t(`${GUILD_MESSAGE}.type_apply`) ? t(`${GUILD_MESSAGE}.btn_apply`) : t(`${GUILD_MESSAGE}.btn_join`)}
        </span>
      </div>
      {showModalConfirmJoinGuild && (
        <ModalConfirmJoinGuild
          onClose={handleToggleModal}
          onConfirm={handleJoinGuild}
          loading={loadingJoinGuild}
          title={type === t(`${GUILD_MESSAGE}.type_apply`) ? t(`${GUILD_MESSAGE}.title_modal_apply`) : t(`${GUILD_MESSAGE}.title_modal_join`)}
          message={type === t(`${GUILD_MESSAGE}.type_apply`) ? t(`${GUILD_MESSAGE}.content_modal_apply`) : t(`${GUILD_MESSAGE}.content_modal_join`)}
          error={error}
        />
      )}
    </JoindGuildContainer>
  )
}

export default JoindGuild;