/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BOTTOM_RIGHT_ICON,
  CAREER_MERAH_ICON,
  RECTANGLE,
  RIGHT_ICON,
  CAREER_KUDA_ICON,
  CAREER_MERAH_OLD_ICON,
  CAREER_BIRU_OLD_ICON
} from 'assets/images'
import ProgressCircle from 'features/Guild/components/ProgressCircle'
import CareerBoxContainer from './styled'

interface career {
  first_count: number
  second_count: number
  third_count: number
  total_number_of_races: number
}

interface masterGuild {
  max_member: number
  exp_to_level_up: number
  total_race: number
}

interface infoProps {
  informationData: {
    avatar: string
    guild_tag: string
    name: string
    level: string
    role_in_guild: string
    total_member: number
    master_guild_level: masterGuild
    total_horse: number
    total_exp: number
    career: career
    prize_biru: number
    prize_merah: number
    prize_kuda: number
    prize_biru_old: number
    prize_merah_old: number
    profit_owner: number
    profit_renter: number
  }
}

const CareerBox = ({ informationData }: infoProps) => {
  return (
    <CareerBoxContainer>
      <div className='right-bg'>
        <img src={RIGHT_ICON} alt='bg' />
      </div>
      <div className='bottom-bg'>
        <img src={BOTTOM_RIGHT_ICON} alt='bg' />
      </div>
      <div className='career-box'>
        <div className='head'>
          <p>Guild career</p>
          <img src={RECTANGLE} alt='line' />
        </div>

        <div className='row-cus'>
          <div className='left-content'>
            <div className='row-left'>
              <div className='race'>
                <span>Total race</span>
                <span>{informationData?.career?.total_number_of_races}</span>
              </div>
              <div className='career'>
                <span className='title'>Career</span>
                <div className='row-career first'>
                  <span>1st</span>
                  <span>{informationData?.career?.first_count}</span>
                </div>
                <div className='row-career second'>
                  <span>2nd</span>
                  <span>{informationData?.career?.second_count}</span>
                </div>
                <div className='row-career thrid'>
                  <span>3rd</span>
                  <span>{informationData?.career?.third_count}</span>
                </div>
              </div>
            </div>
          </div>

          <div className='right-content'>
            <ProgressCircle career={informationData?.career} className='progress-custom' />
          </div>
        </div>

        <div className='row-cus row__total'>
          <div className='total__content'>
            <div className='row-left'>
              <div className='revenue-box revenue-total-box'>
                <p>Total Revenue</p>
                <div className='row-revenue-total'>
                  <div className='row-revenue'>
                    <span>{informationData?.prize_merah || 0}</span>
                    <img src={CAREER_MERAH_ICON} alt='merah' />
                  </div>
                  <div className='row-revenue '>
                    <span>{informationData?.prize_merah_old || 0}</span>
                    <img src={CAREER_MERAH_OLD_ICON} alt='merah' />
                  </div>
                </div>
                <div className='row-revenue-total'>
                  <div className='row-revenue'>
                    <span>{informationData?.prize_kuda || 0}</span>
                    <img src={CAREER_KUDA_ICON} alt='biru' />
                  </div>
                  <div className='row-revenue'>
                    <span>{informationData?.prize_biru_old || 0}</span>
                    <img src={CAREER_BIRU_OLD_ICON} alt='merah' />
                  </div>
                </div>
              </div>

              <div className='revenue-box income'>
                <p>Income</p>
                <div className='row-revenue'>
                  <span>Owner</span>
                  <span>{informationData?.profit_owner ?? 0}%</span>
                </div>
                <div className='row-revenue'>
                  <span>Member</span>
                  <span>{informationData?.profit_renter ?? 0}%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CareerBoxContainer>
  )
}

export default CareerBox
