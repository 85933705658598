/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { notification } from 'antd'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { RaceTable, RequestLoginModal, SwitchBtn } from 'features/Race/components'
import { useAppDispatch, useAppSelector, useToggle, useUpdateEffect } from 'hooks'
import { GUILD_MESSAGE, NOTIFICATION_MESSAGE } from 'i18n/constants'
import { MyHorseListParams } from 'models'

import guildApi from 'apis/guildApi'
import { constants, links } from 'apps'
import { useDebounce } from 'hooks'
import { guildSelectionColumns } from 'utils/columns'
import { getGuildTag, handleAsyncRequest, shortenUserName } from 'utils/helper'

import { SearchInput } from 'shared'

import ModalConfirmJoinGuild from 'features/Guild/components/ModalConfirmJoinGuild'
import { setCurrentUser } from 'features/Profile/profile.slice'
import { GuildListSortType } from 'models/user'
import SelectCustom from 'shared/SelectCustom'
import TwoLineTitleStyled from 'shared/TwoLineTitle'
import { getCurrentUser } from 'utils/metamask'
import GuildListStyled from './styled'

const defaultSortType: any = [
  {
    name: GuildListSortType.CreateNewest
  },
  {
    name: GuildListSortType.CreateOldest
  },
  {
    name: GuildListSortType.Ascending
  },
  {
    name: GuildListSortType.Decrease
  }
]

export default function GuildList() {
  const { t } = useTranslation()
  const [api, contextHolder] = notification.useNotification()
  const navigate = useNavigate()

  const myHorseListParams: MyHorseListParams = {
    limit: 20,
    page: 1,
    sort: 'created-desc'
  }

  const memoizedResultListSelectionColumns = useMemo(() => guildSelectionColumns, [])
  const [listHorse, setListHorse] = useState([])
  const [params, setParams] = useState<MyHorseListParams>(myHorseListParams)
  const [total, setTotal] = useState()
  const [loading, setLoading] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [showModalConfirmJoinGuild, setShowModalConfirmJoinGuild] = useState<boolean>(false)
  const [guildSelect, setGuildSelect] = useState<any>('')
  const [loadingJoinGuild, setLoadingJoinGuild] = useState<boolean>(false)
  const [isRequestLoginModalOpen, toggleIsRequestLoginModalOpen] = useToggle(false)
  const [error, setError] = useState<string>('')
  const dispatch = useAppDispatch()
  const [classSelect, setClassSelect] = useState<any>(t(`${GUILD_MESSAGE}.sort_by`))
  const selectRef = useRef<any>('created-desc')
  const [isMyHorseOn, toggleIsMyHorseOn] = useToggle(false)
  const useRefSwitch = useRef<HTMLButtonElement>(null)
  const { pathname } = useLocation()

  const debounceSearchValue = useDebounce<string>(searchValue, constants.DEBOUNCE_TIME)
  const { auth, profile } = useAppSelector(state => state)

  useEffect(() => {
    getListGuild()
  }, [params])

  useEffect(() => {
    getUserProfile()
  }, [])

  useUpdateEffect(() => {
    setListHorse([])
    setParams({ ...params, myHorse: isMyHorseOn, page: 1, sort: selectRef.current, search: debounceSearchValue })
  }, [isMyHorseOn, debounceSearchValue])

  const openNotification = (message: string, description: string) => {
    notification.config({
      duration: 4
    })

    api.info({
      message,
      description,
      placement: 'top',
      className: message === 'ERROR' ? 'ant-custom-error' : 'ant-custom-success'
    })
  }

  const getListGuild = async () => {
    setLoading(true)
    const [error, result]: any = await handleAsyncRequest(guildApi.getListGuild({ ...params }))

    if (error) {
      if (error.code === 403) {
        toggleIsRequestLoginModalOpen()
        setLoading(false)
        return
      }
      openNotification('ERROR', error.message || error.errors[0]?.message)
    }

    if (result) {
      setTotal(result?.data?.total)
      const formatData = result.data.records.map((record: any) => {
        return {
          ...record,
          guildName: `<span class='color-yellow'> ${getGuildTag(record.guild_tag)}</span> ${record.name}`,
          guildLevel: record.level,
          exp: record.total_exp,
          member: `${record.total_member}/${record.master_guild_level.max_member}`,
          memberGuildList: `${record.total_member}/${record.master_guild_level.max_member}`,
          maxMember: record.master_guild_level.max_member,
          disabled: +record.master_guild_level.max_member - (+record.total_member) <= 0,
          totalHorse: `${record.total_horse}/${record.master_guild_level.max_horse}`,
          winRate: handleWinRate(record.career.total_number_of_races, record.career.first_count, record.career.second_count, record.career.third_count),
          guildMaster: shortenUserName(record?.guild_master)
        }
      })
      setListHorse(params.page > 1 ? [...listHorse, ...formatData] : formatData)

    }
    setLoading(false)
  }

  const handleWinRate = (totalRace = 0, firstClass = 0, secondClass = 0, thirdClass = 0) => {
    let valueDisplay = ''
    const tltClass = (firstClass + secondClass + thirdClass) / totalRace
    const newTltClass = isNaN(tltClass) ? 0 : tltClass * 100
    if (totalRace === 0) {
      valueDisplay = '---'
    }

    if (totalRace > 0 && firstClass === 0 && secondClass === 0 && thirdClass === 0) {
      valueDisplay = '0.00% - 0.00%'
    }

    if (totalRace !== 0 && (firstClass !== 0 || secondClass !== 0 || thirdClass !== 0)) {
      valueDisplay = `${Number.isInteger((firstClass / totalRace) * 100)
        ? (firstClass / totalRace) * 100
        : ((firstClass / totalRace) * 100).toFixed(2)
        }% - ${Number.isInteger(newTltClass) ? newTltClass : newTltClass.toFixed(2)}%`
    }

    return valueDisplay
  }

  const handleSearchValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const onClickJoinGuild = (id: number) => {
    setGuildSelect(id)
    setShowModalConfirmJoinGuild(true)
  }

  const handleCloseModalJoinGuild = () => {
    setError('')
    setShowModalConfirmJoinGuild(false)
  }

  const handleJoinGuild = async () => {
    setLoadingJoinGuild(true)
    const [error, result]: any = await handleAsyncRequest(guildApi.joinGuild(guildSelect))
    if (error) {
      if (error?.message?.includes('members at the limit')) {
        setError('The number of guild members is full. <br />Please choose another guild.')
      } else {
        setError(error?.message)
      }
    }
    if (result) {
      handleCloseModalJoinGuild()
      openNotification('SUCCESS', "Join guild successfully!")
      setError('')

      getUserProfile()

      navigate(links.guild.information())
    }
    setLoadingJoinGuild(false)
  }

  const getUserProfile = async () => {
    const [, currentUser]: any = await handleAsyncRequest(getCurrentUser())
    if (!currentUser) return
    dispatch(setCurrentUser({ ...profile, ...currentUser }))
  }

  const handleRowItemClick = (value: any) => {
    navigate(links.guild.informationDetail(value))
  }

  const handleSortAscDesc = (item: string) => {
    const handleKeySort = () => {
      switch (item) {
        case GuildListSortType.CreateNewest:
          return 'created-desc'
        case GuildListSortType.CreateOldest:
          return 'created-asc'
        case GuildListSortType.Ascending:
          return 'name-asc'
        case GuildListSortType.Decrease:
          return 'name-desc'
        default:
          return 'created-desc'
      }
    }
    selectRef.current = handleKeySort()
    setParams({
      ...params, myHorse: isMyHorseOn, page: 1, sort: selectRef.current, search: debounceSearchValue
    })
    setClassSelect(item)
  }

  return (
    <GuildListStyled>
      {contextHolder}
      <div className='title-tabs-container'>
        <div className='title-tabs color-primary'>
          <TwoLineTitleStyled text={pathname === links.guild.joinGuild() ? t(`${GUILD_MESSAGE}.join_guild`) : t(`${GUILD_MESSAGE}.guild_list`)} classGuild={"line__create"} />
        </div>
      </div>
      <div className="guild__note">{t(`${GUILD_MESSAGE}.choose_guild`)}</div>
      <div className='search-box'>
        <div className='mt-1'>
          <SwitchBtn
            title={t(`${NOTIFICATION_MESSAGE}.myJoinGuild`)}
            isOn={isMyHorseOn}
            handleSwitchBtnClicked={toggleIsMyHorseOn}
            refSwitch={useRefSwitch}
          />
        </div>
        <SelectCustom
          dataSelect={defaultSortType}
          nameSelect={classSelect}
          onSelected={handleSortAscDesc}
        />
        <SearchInput
          searchValue={searchValue}
          handleSearchValueChanged={handleSearchValueChanged}
          customClass='search-horse-input'
          placeholder={t(`${NOTIFICATION_MESSAGE}.searchJoinGuild`)}
        />
      </div>

      <div className='guild'>
        <RaceTable
          columns={memoizedResultListSelectionColumns}
          data={listHorse ?? []}
          raisePage={setParams}
          loader={!loading}
          params={params}
          isRowClickable
          checkLoadingPage={false}
          totalRace={total}
          onRowClick={handleRowItemClick}
          currentUser={profile}
          noDataText={isMyHorseOn ? t(`${GUILD_MESSAGE}.not_in_the_guild`) : t(`${GUILD_MESSAGE}.guild_not_found`)}
          // isHorseTable
          onClickOneCell={(id) => onClickJoinGuild(id)}
        />
      </div>
      {showModalConfirmJoinGuild && (
        <ModalConfirmJoinGuild
          onClose={handleCloseModalJoinGuild}
          onConfirm={handleJoinGuild}
          loading={loadingJoinGuild}
          title='Join guild'
          message='Do you want to join this Guild?'
          error={error}
        />
      )}
      {isRequestLoginModalOpen && <RequestLoginModal toggleIsModalOpen={toggleIsRequestLoginModalOpen} />}
    </GuildListStyled>
  )
}