import { BUTTON_DT_BG } from 'assets/images'
import styled from 'styled-components'

const InfoBoxContainer = styled.div`
  .content-box {
    display: flex;
    gap: 16px;
    .name {
      flex: 1;
      font-size: 18px;
      color: #fff;
      margin-bottom: 0;
      padding-right: 5px;
      /* width: 180px; */
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-word;
    }

    .img-box {
      width: 150px;
      height: 150px;
      position: relative;
      .background{
        width: unset;
      }
      img {
        width: 100%;
      }
      .item{
        &.shoes, &.foot {
          width: 150px;
          height: auto;
          left: 0%;
          bottom: 0%;
          top: unset;
        }
        &.glass {
          width: 75px;
          height: auto;
          top: 0%;
          left: 0%;
        }
        &.body, &.skin {
          height: 150px;
        }
        &.head {
          width: 75px;
          height: 75px;
          margin: 0;
          background: transparent;
        }
      }
    }

    .info {
      flex: 1;
      max-width: 210px;
      p {
        font-size: 18px;
        color: #fff;
        .used-doping{
          width: 40px;
          margin-left: 10px;
        }
      }

      .row-info {
        display: flex;
        align-items: center;
        
        span {
          color: #fff;
          font-size: 18px;
          display: inline-block;

          &:last-child {
            padding-left: 5px;
          }
        }

        .icon-vip {
          width: 30px;
          height: 30px;
          margin-left: 10px;
        }

        &.first {
          span {
            color: #B3B3B3;
          }
        }
        &.thrid {
          span {
            color: #4EF076;
          }

          .color__white {
            color: #fff;
            font-size: 16px;
          }

          .color__fff566 {
            color: #fff566;
            font-size: 16px;
          }
        }
        .text__ellipsis {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }

        /* div {
          img {
            margin-left: -10px;
          }
        } */

        .used-doping{
          width: 40px;
        }
      }
      .align_items_end {
        align-items: flex-start;
      }
    }
  }

  .horse-gender {
    width: 42px;
    display: flex;
    margin-left: 4px;
  }

  .horse-gender__img {
    width: 18px;
    height: 18px;
  }

  .region {
    width: 20px;
    height: 20px;
    margin-left: 4px;
  }

  .button-detail {
    background: url(${BUTTON_DT_BG});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 146px;
    height: 42px;
    border: none;
    color: #4EF076;
    font-size: 18px;
    margin-top: 30px;
    a {
      text-decoration: none;
    }
  }
`

export default InfoBoxContainer
