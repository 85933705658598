import { BTN_DANGER, BTN_OUTLINE, BTN_WARNING } from 'assets/images'
import styled from 'styled-components'

const ButtonCustomStyled = styled.div`
  cursor: pointer;
  column-gap: 8px;
  width: fit-content;

  .btn-container.btn-danger {
    filter: hue-rotate(270deg);
  }

  .btn-container.btn-danger:hover {
    filter: hue-rotate(240deg);
  }

  .btn-container {
    background-color: ${({ theme }) => theme.color.transparent};
    background-image: url(${BTN_OUTLINE});
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    color: #4ef076;

    width: 118px;
    height: 34px;
    position: relative;
    padding: 0px;
    display: inline-block;
    display: flex;
    align-items: center;
    justify-content: center;

    text-decoration: none;
    opacity: 0.8;

    span {
      font-size: 16px;
    }

    img {
      width: 21px;
      height: 12px;
      object-fit: contain;

      bottom: -6px;
      right: 22px;
    }
  }

  .btn-container:hover {
    opacity: 1;
  }

  .disabled {
    .disabled,
    .btn-container:hover {
      opacity: 0.3;
    }
  }

  .danger {
    color: orange;
    background-image: url(${BTN_DANGER});
  }
  .warning {
    color: orange;
    background-image: url(${BTN_WARNING});
  }
`

export default ButtonCustomStyled
