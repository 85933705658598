import { APP_BACKGROUND } from 'assets/images'
import styled from 'styled-components'

const StyledMobile = styled.div`
  background-color: #121520;
  color: #fff;
  // clip-path: polygon(0 0, 100% 0, 100% 100%, 40px 100%, 0 calc(100% - 40px));
  height: 100vh;
  text-align: center;
  position: relative;
  .loading-icon {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    color: white;
    z-index: 1;
    opacity: 1;
  }
  .header {
    padding: 20px 100px;
    border-bottom: 1px solid #2d3936;
  }
  .mobile {
    .btn-metamask {
      color: #000000;
    }
    height: 100vh;
    .title {
      text-decoration: none;
      font-size: 20px;
      line-height: 26px;
      margin: 0 10px;
    }
    .title-404 {
      font-size: 30px;
    }
    .watting-transaction {
      font-size: 16px;
    }

    .btn-back {
      font-size: 24px;
      padding-top: 20px;
      a {
        text-decoration: none;
      }
      .btn-download {
        border-radius: 4px;
        padding: 4px;
        color: #000000;
        background: #adb5bd;
      }
    }

    .content {
      font-size: 16px;
    }
    .mobile-container {
      padding-top: 20px;
    }
  }
  .app-container {
    background-image: url(${APP_BACKGROUND});
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    width: 100%;
    .app-background {
      background-color: #2f1c79;
      height: 100vh;
      opacity: 0.8;
    }
    .app {
      height: 100vh;
    }
    .app-block {
      font-family: Orbitron;
      top: 25%;
      position: relative;
      font-size: 18px;
      padding: 20px;
    }

    .btn-custom {
      position: relative;
      width: 100%;
      /* align-items: center; */
      display: flex;
      justify-content: center;
      margin-top: 12px;
      cursor: pointer;
      .btn-text {
        position: absolute;
        top: 15%;
      }
    }
  }
`

export default StyledMobile
