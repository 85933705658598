/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ApiResponse, ListWeatherModel,
} from 'models'
import axiosClient from './axiosClient'

const weatherApi = {
  getWeatherCurrentScheduled(): Promise<ApiResponse<any>> {
    const url = '/weather/current-scheduled'
    return axiosClient.get(url)
  },

  getListWeather(params: ListWeatherModel): Promise<ApiResponse<ListWeatherModel>> {
    const url = '/weather/scheduled'
    return axiosClient.get(url, { params })
  }
}

export default weatherApi
