/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiResponse } from 'models'
import axiosClient from './axiosClient'

const itemApi = {
  putBosstItem(
    data: { horseId: string | number },
    params: { recover_type?: string; pay_for_energy?: boolean }
  ): Promise<ApiResponse<any>> {
    const url = `/items/horses/${data.horseId}/energy`
    return axiosClient.put(url, params)
  },

  getBoostItems(params?: any): Promise<ApiResponse<any>> {
    const url = '/items/users/boost'
    return axiosClient.get(url, { params })
  },

  getItemsMaterData(): Promise<ApiResponse<any>> {
    const url = '/items/master-data'
    return axiosClient.get(url)
  },
  getItemsShopMaterData(): Promise<ApiResponse<any>> {
    const url = '/item-shops/master-data'
    return axiosClient.get(url)
  },

  getItemsWeapons(params?: any): Promise<ApiResponse<any>> {
    const url = '/items/users/weapons'
    return axiosClient.get(url, { params })
  },
  getItemsWeaponsDetail(itemToken?: any): Promise<ApiResponse<any>> {
    const url = `/items/users/weapon/${itemToken}/detail`
    return axiosClient.get(url)
  }
}

export default itemApi
