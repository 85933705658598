/* eslint-disable @typescript-eslint/no-explicit-any */
import { checkExistItem } from '../utils'
import HorseImageContainer from './styled'
import {
  StepHorse
} from 'models'
interface iprops {
  avatar: string
  name: string
  items: any
  horse?: StepHorse
}

const HorseAvatar = ({ horse, avatar, name, items = [] }: iprops) => {
  const glassUrl = '/_horse/_avatar/_glass/g_'
  const shoesUrl = '/_horse/_avatar/_shoes/sh_'
  const armorUrl = '/_horse/_avatar/_armor/'
  const skinUrl = '/_horse/_avatar/_skin/'
  const typeRuntype = 'RUNTYPE'
  const typeChar = 'CHARACTERISTIC'
  const typeAll = 'CHARACTERISTIC_RUNTYPE'
  const converToUtf8 = (value: string) => {
    return value.replace('#', '%23')
  }
  const headItem = checkExistItem('HEAD', items)
  const bodyItem = checkExistItem('BODY', items)
  const footItem = checkExistItem('LEG', items)
  const skinItem = checkExistItem('SKIN', items)
  const modelBodyName = bodyItem?.modelName || bodyItem?.model_name
  const modelHeadName = headItem?.modelName || headItem?.model_name
  const modelFootName = footItem?.modelName || footItem?.model_name
  const modelSkinName = skinItem?.modelName || skinItem?.model_name
  const typeOfMaterial = (bodyItem?.materialType || bodyItem?.material_type)
  const horseCharacteristicCode = horse?.characteristic_code || horse?.characteristic
  const horseRunTpeCode = horse?.runTypeCode || horse?.run_type_code
  
  const fullURLGlass = process.env.REACT_APP_ROOT_DOMAIN + glassUrl + horseCharacteristicCode + '.png'
  const defautlShoes = process.env.REACT_APP_ROOT_DOMAIN + shoesUrl + horseRunTpeCode + '.png'

  const fullURLBody = 
  typeOfMaterial?.toUpperCase() === typeRuntype 
    ? process.env.REACT_APP_ROOT_DOMAIN + armorUrl + modelBodyName + '_' + horseRunTpeCode + '.png'
  : typeOfMaterial?.toUpperCase() === typeChar
    ? process.env.REACT_APP_ROOT_DOMAIN + armorUrl + modelBodyName + '_' + horseCharacteristicCode + '.png'
  : typeOfMaterial?.toUpperCase() === typeAll 
    ? process.env.REACT_APP_ROOT_DOMAIN + armorUrl + modelBodyName + '_' + horseCharacteristicCode + '_' + horseRunTpeCode + '.png'
    : process.env.REACT_APP_ROOT_DOMAIN + armorUrl + modelBodyName + '.png' 

  const footArmorUrl = process.env.REACT_APP_ROOT_DOMAIN + armorUrl + modelFootName + '_' + horseRunTpeCode + '.png'
  const headtUrl = process.env.REACT_APP_ROOT_DOMAIN + armorUrl + modelHeadName + '_' + horseCharacteristicCode + '.png'
  const fullSkinUrl = skinItem && (horse?.hairColor === 'SAME_BODY' || horse?.hair_color === 'SAME_BODY') 
    ? process.env.REACT_APP_ROOT_DOMAIN + skinUrl + modelSkinName + '_' + (horse?.hairColor || horse?.hair_color) + '_' + converToUtf8(horse?.bloodLine?.colorCode || horse?.blood_line?.color_code) + '.png'
    : process.env.REACT_APP_ROOT_DOMAIN + skinUrl + modelSkinName + '_' + (horse?.hairColor || horse?.hair_color) + '.png'
  
  return (
    <HorseImageContainer>
      <div className='background d-flex align-items-center justify-content-center'>
        <img src={avatar} alt={name} className='avatar' />
        {headItem && modelHeadName
          ? <img src={headtUrl} alt={headItem.name} className='item head' /> 
            : <img src={fullURLGlass} className="item glass" /> }

        {bodyItem && modelBodyName
          ? <img src={fullURLBody} alt={bodyItem.name} className='item body' /> : ''}
        
        {footItem && modelFootName
          ? <img src={footArmorUrl} alt={footItem.name} className='item foot' />
          : <img src={defautlShoes} className={`item shoes ${horseRunTpeCode ? '' : 'd-none'}`} />}

        {skinItem && modelSkinName && <img src={fullSkinUrl} alt={skinItem.name} className='item skin' />}

      </div>
    </HorseImageContainer>
  )
}

export default HorseAvatar
