import styled from 'styled-components'

const Container = styled.div`
  .meta-block {
    width: 100%;
    margin-right: 20px;
    position: relative;

    &:nth-child(4n) {
      margin-right: 0px;
    }
    .btn-detail-container {
      position: absolute;
      top: 30%;
      left: 20%;
      display: none;
      clip-path: polygon(0 0, calc(100% - 14px) 0, 100% 14px, 100% 100%, 14px 100%, 0% calc(100% - 14px));
      background: linear-gradient(90deg, #4ef07621 0%, #48f7ba17 100%);
      z-index: 5;
      padding: 1px;
      width: 103px;
      height: 34px;
      aspect-ratio: 100 / 42;

      .btn-detail {
        clip-path: polygon(0 0, calc(100% - 14px) 0, 100% 14px, 100% 100%, 14px 100%, 0% calc(100% - 14px));
        border: none;
        background: linear-gradient(
          178.19deg,
          rgba(78, 240, 118, 0.5) 1.64%,
          rgba(72, 247, 186, 0) 47.93%,
          rgba(78, 240, 118, 0.5) 97.84%
        );
        font-size: 16px;
        line-height: 20px;
        width: 100%;
        height: 100%;
      }
    }
    &:hover {
      .btn-detail-container{
        display: block;
      }
    }
  }
  .item-block {
    clip-path: polygon(7% 0, 84% 0, 100% 19%, 100% 100%, 0 100%, 0 8%);
    background: #515460;
    padding: 2px;
    position: relative;
    .numb {
      position: absolute;
      top: 5%;
      left: 5%;
      img{
          width: 38px;
      }
      span{
          color: #FFF566;
          font-size: 18px;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 15%;
      }
    }
    .sub-block {
      background: #030317;
      clip-path: polygon(7% 0, 84% 0, 100% 19%, 100% 100%, 0 100%, 0 8%);
      position: relative;
      .amount {
        position: relative;
        justify-content: center;
        padding-bottom: 3px;
        min-height: 17px;
        .amount-item {
          font-size: 16px;
        }
      }
      .star {
        div {
          width: 18px;
          height: 18px;
        }
        .rate-star{
          &.disable{
            display: none;
          }
        }
        div:has(div.disable) {
          display: none;
        }

      }
    }
  }
  .item-dp {
    background: transparent;
    border: none;
    padding: 25px 0;
    width: 183px;
    img {
      height: 100px;
    }
  }

  .HEAD {
    img {
      transform: scale(1.5);
    }
  }
  .BODY {
    img {
      transform: translateX(-10px) translateY(12px) scale(1.25);
    }
  }
  .LEG {
    img {
      transform: translateX(-10px) translateY(10px) scale(1.25);
    }
  }
  .SKIN {
    img {
      transform: scale(1.5);
    }
  }
  .DOPING {
    img {
      transform: scale(1);
    }
  }
  .ENERGY {
    img {
      transform: scale(1);
    }
  }
  .count-item {
    position: relative;
    span {
      position: absolute;
      width: 100%;
      text-align: center;
      z-index: 1;
      top: 12px;
      left: 0px;
      font-size: 16px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      padding: 0 5px;
    }
    img {
      width: 100%;
      height: 44px;
      margin-top: 4px;
    }
  }
`

export { Container }
