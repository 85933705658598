/* eslint-disable @typescript-eslint/no-explicit-any */
import specialRaceApi from 'apis/specialRaceApi'
import { links } from 'apps'
import { RaceTable, RequestLoginModal, SwitchBtn } from 'features/Race/components'
import ChooseHoresModalSpecialRace from 'features/SpecialRace/components/ChooseHoresModalSpecialRace'
import ConfirmChooseHorseModalSpecialRace from 'features/SpecialRace/components/ConfirmSpecialRaceModal'
import { useAppSelector, usePreventBodyScroll, useToggle } from 'hooks'
import { NOTIFICATION_MESSAGE, SPECIAL_MESSAGE } from 'i18n/constants'
import {
  defaultRegionFilters,
  HorseAvailable,
  ISpecialTypeSort,
  LendingHorse,
  MyHorseListParams,
  SpecialSortName,
  TOURNAMENT_STATUS
} from 'models'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { OneLineTitle } from 'shared'
import Button from 'shared/Button'
import SelectCustom from 'shared/SelectCustom'
import { specialHorseColumns, specialHorseSelectionColumns } from 'utils/columns'
import { getKeySort, handleAsyncRequest } from 'utils/helper'
import CurrentFarmStyled from './styled'
import { NUMBER, STRING_REGION } from 'apps/constants'

interface ICurrentFarmProps {
  status?: TOURNAMENT_STATUS
  isDetail?: boolean
  tournamentId?: string
}

const defaultTypeSort: ISpecialTypeSort = [
  {
    name: SpecialSortName.Newest,
    isActive: false
  },
  {
    name: SpecialSortName.Oldest,
    isActive: false
  },
  {
    name: SpecialSortName.ASC,
    isActive: false
  },
  {
    name: SpecialSortName.Desc,
    isActive: false
  }
]

export default function CurrentFarm({ status, isDetail, tournamentId }: ICurrentFarmProps) {
  const myHorseListParams: MyHorseListParams = {
    limit: NUMBER.TWENTY,
    page: NUMBER.ONE
  }

  const { POOL_OPEN, SELECTION_HORSE } = TOURNAMENT_STATUS
  const [params, setParams] = useState<MyHorseListParams>(myHorseListParams)
  const [total, setTotal] = useState()
  const memoizedResultListColumns = useMemo(() => specialHorseColumns, [])
  const memoizedResultListSelectionColumns = useMemo(() => specialHorseSelectionColumns, [])

  const [classSort, setClassSort] = useState('Sorted by...')
  const currentUser = useAppSelector(state => state.profile)
  const auth = useAppSelector(state => state.auth)

  const [listHorse, setListHorse] = useState<LendingHorse[]>([])

  const [, setIsLoading] = useState(false)
  const [isFetch, setIsFetch] = useState(false)
  const [isRequestLoginModalOpen, toggleIsRequestLoginModalOpen] = useToggle(false)
  const [isMyHorseOn, toggleIsMyHorseOn] = useToggle(false)
  const useRefSwitch = useRef<HTMLButtonElement>(null)
  const [isChooseHorseOpen, toggleIsChooseHorseOpen] = useToggle(false)
  const [isModalSpecialRaceOpen, toggleIsModalSpecialRaceOpen] = useToggle(false)
  const [isRefresh, toggleIsRefresh] = useToggle(false)
  const [horseDetails, setHorseDetails] = useState<HorseAvailable>()
  const [isWithdraw, setIsWithdraw] = useState(true)
  const [regionSelect, setRegionSelect] = useState('Region')

  const { raceId } = useParams()

  usePreventBodyScroll(isChooseHorseOpen)

  const navigate = useNavigate()

  const { t } = useTranslation()

  const fetchListHorse = async () => {
    setIsLoading(true)
    const [error, result]: any = await handleAsyncRequest(
      isDetail
        ? specialRaceApi.getHorseHistoryDetail(raceId || '', { ...params, myHorse: isMyHorseOn })
        : specialRaceApi.getHorseSpecial({ ...params, myHorse: isMyHorseOn })
    )
    if (error) {
      setIsLoading(false)
      if (error.code === 403) {
        toggleIsRequestLoginModalOpen(true)
      }
      return
    }
    const records = result?.data.records
    setListHorse(params.page > NUMBER.ONE ? [...listHorse, ...records] : records)
    setTotal(result?.data.total)
    setIsFetch(false)
    if (records && records.length === 0) {
      // setLoader(true)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    // fetchTournamentStatus()
    if (isFetch) {
      setListHorse([])
      setParams({ ...params, page: NUMBER.ONE })
    }
  }, [isFetch])

  useEffect(() => {
    toggleIsChooseHorseOpen(false)
    fetchListHorse()
  }, [params, isRefresh])

  const handleSortClicked = (item: string) => {
    setParams({
      ...params,
      page: NUMBER.ONE,
      sort: getKeySort(item)
    })
    setClassSort(item)
  }

  const handleAddHorseFarm = () => {
    if (!auth.isLogged) {
      toggleIsRequestLoginModalOpen()
      return
    }
    toggleIsChooseHorseOpen()
    setIsWithdraw(false)
  }
  const handleHorseItem = (horse: HorseAvailable) => {
    setHorseDetails(horse)
    // setIsWithdraw(false)
  }

  const handleRowItemClick = (value: any) => {
    if (raceId) {
      navigate(links.specialRace.horseHistoryDetail(raceId || '', value.horse_id))
    } else {
      navigate(links.specialRace.currentHorseDetail(tournamentId || '', value.horse_id))
    }
  }

  const handleIsMyHorse = () => {
    setParams({ ...params, page: 1 })
    toggleIsMyHorseOn()
  }

  const handleRegionFilterClicked = (item: string) => {
    if (item?.toUpperCase() === STRING_REGION.SEOUL || item?.toUpperCase() === STRING_REGION.TOKYO) {
      setParams({
        ...params,
        page: NUMBER.ONE,
        region: item?.toUpperCase()
      })
    } else {
      setParams({
        ...params,
        page: NUMBER.ONE,
        region: ''
      })
    }
    setRegionSelect(item)
  }

  return (
    <CurrentFarmStyled>
      {status === POOL_OPEN && (
        <div className='btn-register'>
          <Button
            buttonName='Register your horse'
            onClickButton={handleAddHorseFarm}
            isLoading={false}
            width={200}
            height={52}
          />
        </div>
      )}

      <div className='pt-5'>
        <OneLineTitle text='Registered horse' isLending />
      </div>

      <div className='top-lending'>
        <div className='total-horse'>
          <span>
            Total: {total || 0} {total && total <= 1 ? 'horse' : 'horses'}
          </span>
        </div>
        <div className='lending-search'>
          {/* region */}
          <SelectCustom
            dataSelect={defaultRegionFilters}
            nameSelect={regionSelect}
            onSelected={handleRegionFilterClicked}
          />

          <SelectCustom dataSelect={defaultTypeSort} nameSelect={classSort} onSelected={handleSortClicked} />
          {auth.isLogged && (
            <SwitchBtn
              title={t(`${NOTIFICATION_MESSAGE}.myHorse`)}
              isOn={isMyHorseOn}
              handleSwitchBtnClicked={handleIsMyHorse}
              refSwitch={useRefSwitch}
            />
          )}
        </div>
      </div>
      <RaceTable
        columns={
          status === SELECTION_HORSE || status === POOL_OPEN
            ? memoizedResultListSelectionColumns
            : memoizedResultListColumns
        }
        data={listHorse ?? []}
        raisePage={setParams}
        loader={true}
        params={params}
        isRowClickable
        checkLoadingPage={false}
        totalRace={total}
        onRowClick={handleRowItemClick}
        currentUser={currentUser}
        noDataText={t(`${SPECIAL_MESSAGE}.noRegisteredHorse`)}
        isHorseTable
      />

      {/* <HorseItem isLoading={isLoading} total={total} listHorse={listHorse} onViewMore={hanldeViewMore} /> */}
      {isRequestLoginModalOpen && <RequestLoginModal toggleIsModalOpen={toggleIsRequestLoginModalOpen} />}
      {isChooseHorseOpen && (
        <ChooseHoresModalSpecialRace
          isWithdraw={isWithdraw}
          getHorseDetail={handleHorseItem}
          toggleIsModalSpecialRaceOpen={toggleIsModalSpecialRaceOpen}
          toggleIsModalOpen={toggleIsChooseHorseOpen}
          onCloseButtonClick={() => toggleIsChooseHorseOpen(false)}
        />
      )}
      {isModalSpecialRaceOpen && (
        <ConfirmChooseHorseModalSpecialRace
          toggleIsRefresh={toggleIsRefresh}
          horse={horseDetails}
          toggleIsModalOpen={toggleIsModalSpecialRaceOpen}
          onCloseButtonClick={() => toggleIsModalSpecialRaceOpen(false)}
          isWithdraw={isWithdraw}
        />
      )}
    </CurrentFarmStyled>
  )
}
