import { NFT_BODY, NFT_LEG, NFT_HEAD, NFT_SKIN } from "assets/images"

/* eslint-disable @typescript-eslint/no-explicit-any */
const checkExistItem = (type: string, items: any) => {
  if (items?.length <= 0) return null

  const res = items?.find((item: any) => (item?.body_part || item?.bodyPart) === type)
  return res
}

const getImageItem = (type: string, items: any) => {
  const res = items?.find((item: any) => (item?.body_part || item?.bodyPart) === type)
  switch(type) {
    case 'HEAD':
      return res ? res.img : NFT_HEAD;
    case 'BODY':
      return res ? res.img : NFT_BODY;
    case 'LEG':
      return res ? res.img : NFT_LEG
    default:
      return res ? res.img : NFT_SKIN
  }
}
const isUseItem = (type: string, items: any) => {
  const res = items?.find((item: any) => (item?.body_part || item?.bodyPart) === type)
  switch(type) {
    case 'HEAD':
      return res ? true : false;
    case 'BODY':
      return res ? true : false;
    case 'LEG':
      return res ? true : false;
    default:
      return res ? true : false;
  }
}

export { checkExistItem, getImageItem, isUseItem}
