/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { notification } from 'antd'
import { links } from 'apps'
import { FX_1, FX_2 } from 'assets/images'
import { useAppSelector } from 'hooks'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GUILD_MESSAGE } from 'i18n/constants'
import Loading from '../../Loading'
import DemoteMember from '../demote-member'
import PromoteMember from '../promote-member'
import CedeMember from '../cede-member'
import RemoveMember from '../remove-member'
import AddMemberToBlackList from '../add-blacklist'
import IncomeBox from './income-box'
import InfoBox from './info-box'
import LeaveGuild from './leave-guild'
import RaceBox from './race-box'
import HorseInfoContainer from './styled'

interface DetailProps {
  loading?: boolean
  data: any
  upgradeSuccess: (v: string) => void
  downgradeSuccess: (v: string) => void
}

const MemberInfo = ({ loading, data, upgradeSuccess, downgradeSuccess }: DetailProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { guild_id, user_id } = useParams()
  const { profile } = useAppSelector(state => state)

  const isMaster = profile?.role_in_guild?.toUpperCase() === 'MASTER'
  const isManager = profile?.role_in_guild?.toUpperCase() === 'MANAGER'
  const isMyGuild = profile?.guild_id?.toString() === guild_id && profile?.id?.toString() === user_id || profile?.id === data?.id
  const isMemberOfGuild = profile?.guild_id?.toString() === guild_id && profile?.id?.toString() !== user_id

  const handleLeaveSuccess = () => {
    notification.success({
      message: 'SUCCESS',
      description: 'Leave guild successfully',
      placement: 'bottomRight',
      duration: 4,
      className: 'ant-custom-success'
    })

    navigate(links.guild.joinGuild())
  }

  const handleDowngradeSuccess = (role: string) => {
    notification.success({
      message: 'SUCCESS',
      description: 'Demote member successfully',
      placement: 'bottomRight',
      duration: 4,
      className: 'ant-custom-success'
    })
    downgradeSuccess(role)
  }

  const handleUpgradeSuccess = (role: string) => {
    notification.success({
      message: 'SUCCESS',
      description: 'Promote member successfully',
      placement: 'bottomRight',
      duration: 4,
      className: 'ant-custom-success'
    })
    upgradeSuccess(role)
  }

  const handleRemoveMemberSuccess = () => {
    notification.success({
      message: 'SUCCESS',
      description: 'Kick member successfully',
      placement: 'bottomRight',
      duration: 4,
      className: 'ant-custom-success'
    })
    navigate(-1)
  }

  const handleUpgradeCedeSuccess = () => {
    notification.success({
      message: t(`${GUILD_MESSAGE}.success`),
      description: t(`${GUILD_MESSAGE}.message.cede_success`),
      placement: 'bottomRight',
      duration: 4,
      className: 'ant-custom-success'
    })
    navigate(-1)
  }

  const handleSendMemberToBlacklistSuccess = () => {
    notification.success({
      message: 'SUCCESS',
      description: 'Send member to blacklist successfully',
      placement: 'bottomRight',
      duration: 4,
      className: 'ant-custom-success'
    })
    navigate(-1)
  }

  return (
    <HorseInfoContainer>
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className='head'>
            {(data?.guild_logo) && <img src={data?.guild_logo} alt='avatar' />}
            <span className='color-yellow'>[{data?.guild_tag}]</span>
            <span>{data?.name || data?.guild_name}</span>
          </div>
          <div className='content-horse'>
            <img src={FX_2} alt='line' className='line-1' />
            <img src={FX_1} alt='line' className='line-2' />
            <InfoBox data={data} />
            <IncomeBox data={data} />
            <RaceBox data={data} />
          </div>
          <div className='group-button-action'>
            {!isMaster && isMyGuild && data.role !== 'GUEST' && <LeaveGuild onLeaveGuildSuccess={handleLeaveSuccess} />}
            {isMaster && isMemberOfGuild && data?.role === 'MEMBER' && (
              <PromoteMember upgradeSuccess={handleUpgradeSuccess} />
            )}
            {isMaster && isMemberOfGuild && data?.role === 'MANAGER' && (
              <DemoteMember downgradeSuccess={handleDowngradeSuccess} />
            )}
            {(isManager) && isMemberOfGuild && data?.role === 'MEMBER' && (
              <RemoveMember onRemoveMemberSuccess={handleRemoveMemberSuccess} />
            )}
            {isMaster && isMemberOfGuild && (data?.role === 'MEMBER' || data?.role === 'MANAGER') && (
              <RemoveMember onRemoveMemberSuccess={handleRemoveMemberSuccess} />
            )}
            {isMaster && isMemberOfGuild && (data?.role === 'MEMBER' || data?.role === 'MANAGER') && (
              <CedeMember upgradeSuccess={handleUpgradeCedeSuccess} />
            )}
            {(isMaster || isManager) && isMemberOfGuild && (data?.role === 'GUEST' || data?.role === 'MEMBER' || data?.role === 'MANAGER') && (
              <AddMemberToBlackList onRemoveMemberSuccess={handleSendMemberToBlacklistSuccess} />
            )}
          </div>
        </>
      )}
    </HorseInfoContainer>
  )
}

export default MemberInfo
