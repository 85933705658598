/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ApiResponse,
  ClaimResponses,
  DepositRequest,
  DepositResponses,
  NonceRequest,
  NonceResponses,
  SwapETokenRequest,
  SwapETokenResponses,
  resultItemNFT
} from 'models'
import axiosClient from './axiosClient'

const exchangeApi = {
  postExchange(data: DepositRequest): Promise<ApiResponse<DepositResponses>> {
    const url = '/exchange/deposit-mare'
    return axiosClient.post(url, data)
  },

  postClaimEMAS(data: DepositRequest): Promise<ApiResponse<ClaimResponses>> {
    const url = '/exchange/claim-emas'
    return axiosClient.post(url, data)
  },

  postClaimMerahToMare(data: DepositRequest): Promise<ApiResponse<ClaimResponses>> {
    const url = '/exchange/merah-to-mare'
    return axiosClient.post(url, data)
  },

  postClaimFreeEMAS(data: DepositRequest): Promise<ApiResponse<ClaimResponses>> {
    const url = '/exchange/claim-biru'
    return axiosClient.post(url, data)
  },

  postClaimBiruToMare(data: DepositRequest): Promise<ApiResponse<ClaimResponses>> {
    const url = '/exchange/biru-to-mare'
    return axiosClient.post(url, data)
  },

  postSwapEToken(data: SwapETokenRequest): Promise<ApiResponse<SwapETokenResponses>> {
    const url = '/exchange/swap-etoken'
    return axiosClient.post(url, data)
  },

  postCheckNonce(data: NonceRequest): Promise<ApiResponse<NonceResponses>> {
    const url = '/exchange/check-nonce'
    return axiosClient.post(url, data)
  },

  postDepositAdil(data: SwapETokenRequest): Promise<ApiResponse<resultItemNFT>> {
    const url = '/exchange/deposit-adil'
    return axiosClient.post(url, data)
  },

  postCheckNonceExchangeAdil(data: any): Promise<ApiResponse<any>> {
    const url = '/exchange/check-nonce'
    return axiosClient.post(url, data)
  },

  postMerahToAdilClaim(data: SwapETokenRequest): Promise<ApiResponse<resultItemNFT>> {
    const url = '/exchange/merah-to-adil'
    return axiosClient.post(url, data)
  },

  postKudaToAdilClaim(data: SwapETokenRequest): Promise<ApiResponse<resultItemNFT>> {
    const url = '/exchange/kuda-to-adil'
    return axiosClient.post(url, data)
  },
}

export default exchangeApi
