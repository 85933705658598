import styled from 'styled-components'

const InformationStyled = styled.div`
  width: 100%;

  .guild-information {
    width: 100%;
    /* height: 266px; */
    .basic-infor {
      width: 100%;
      height: 100%;
      padding: 2px;
      color: white;
      border: 1px solid #47F0B1;
      max-width: 975px;
      margin: 0 auto;
    }

    .line {
      width: 100%;
      margin-top: 30px;

      img {
        width: 100%;
      }
    }

    .basic-infor-group {
      background: linear-gradient(90deg, #121520 -1.15%, #191d2c 100%);
      justify-content: space-between;
      display: flex;
      font-size: 26px;
      .text-label {
        font-size: 18px;
        color: #FFF566;
      }
      .basic-infor-item {
        align-items: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        font-size: 18px;
      }
    }

    .guild-description {
      width: 100%;
      color: white;
      font-size: 20px;
      border: 1px solid #47F0B1;
      margin: 0 auto;
      margin-top: 50px;
      max-width: 975px;
     
      .bottom-left {
        position: absolute;
        border: 0;
        left: 0;
      }
    }

    .description-content {
      background: #191d2c;
      min-height: 260px;
      position: relative;

      .bottom-left {
        position: absolute;
        bottom: -16px;
        left: 0;
      }
      .bottom-right {
        position: absolute;
        bottom: -16px;
        right: 0;
      }
    }

    .guild-avatar {
      border-radius: 50%;
      width: 160px;
      height: 160px;
    }
    .description-text {
      text-align: left;
      width: 100%;
      overflow: overlay;
      padding: 27px 107px;

      p {
        word-break: break-word;
        font-size: 18px;
      }

      pre {
        font-size: 14px;
        font-weight: normal;
        font-family: 'BlenderPro-Book';
      }
    }
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-spin-dot-item {
      background-color: #4ef076 !important;
    }
  }

  .career-horse {
    display: inline-block;
    padding-right: 5px;
  }

  .button-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 80px 0;
    justify-content: space-evenly;
  }

  .line-box {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
  }

  .bg-ellipse {
    img {
      width: 100%;
    }
  }

  .list {
    max-width: 975px;
    margin: 0 auto;
    margin-top: -150px;
  }

  .line-bottom {
    margin-top: 30px;
  }
`

export default InformationStyled
