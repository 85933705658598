import styled from 'styled-components'

interface MyHorseItemStyled {
  mySelf: boolean
}

const MyHorseItemStyled = styled.tr<MyHorseItemStyled>`
  clip-path: polygon(0 0, 100% 0, 100% 100%, 14px 100%, 0 calc(100% - 14px));
  vertical-align: middle;
  background: ${({ mySelf }) => (mySelf ? 'linear-gradient(90deg, rgba(86,36,95,1) 0%, rgba(114,68,160,1) 80%, rgba(4,19,37,1) 100%)' : 'rgb(37,43,67)')};

  cursor: pointer;
  td {
    font-size: 16px;
    line-height: 20px;

    .horse-avatar {
      width: 40px;
      margin-right: 10px;
    }

    .horse-class {
      top: 4px;
    }
  }

  .width-25 {
    width: 25%;
    text-align: left;
  }

  .padding-left-name {
    padding-left: 7.5%;
  }

  .padding-left {
    padding-left: 7.5%;
  }

  .padding-left-5 {
    padding-left: 5%;
  }

  .padding-left-4 {
    padding-left: 4%;
  }

  .padding-left-59 {
    padding-left: 5.8%;
  }

  .padding-left-6 {
    padding-left: 6.4%;
  }

  .width-15 {
    width: 15%;
  }

  .width-30 {
    width: 30%;
  }

  .width-5 {
    width: 5%;
  }

  .stt {
    height: 52px;

    img {
      margin-right: 10px;
    }
  }

  .gender {
    text-transform: lowercase;
  }
  .gender:first-line {
    text-transform: capitalize;
  }
  .horse-name {
    justify-content: center;
    align-items: center;
  }
  .horse-name-content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* padding-left: 50px; */
  }

  .center {
    text-align: center;
  }

  .left {
    text-align: left;
    padding-left: 105px;
  }
`

export default MyHorseItemStyled
