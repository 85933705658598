/* eslint-disable @typescript-eslint/no-explicit-any */
import raceApi from 'apis/raceApi'
import { constants } from 'apps'
import { FILTER_ICON } from 'assets/images'
import { RaceTable, RequestLoginModal, SwitchBtn } from 'features/Race/components'
import FilterResultRaceModal from 'features/Race/components/FilterResultRaceModal'
import { useAppSelector, usePreventBodyScroll, useToggle, useUpdateEffect } from 'hooks'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import { GetRaceListParams, GetRaceListPopupParams, RaceStatus, RecordRace, RegionFilters, RegionOption, RegionOptionFilter } from 'models'
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { OneLineTitle } from 'shared'
import { resultListColumns } from 'utils/columns'
import { handleAsyncRequest, shortenRaceCourseName } from 'utils/helper'
import { convertFullDateLocal } from 'utils/time'
import { NUMBER, STRING_REGION } from 'apps/constants'
import ResultStyled from './styled'
import Select from 'shared/Select'

const defaultParams: GetRaceListParams = {
  limit: NUMBER.TWENTY,
  page: NUMBER.ONE,
  status: RaceStatus.RESULT,
  freeRace: false,
  myHorse: true,
  sort: ['close_at-desc']
}

const date = new Date()
const defaultParamsFilterResult: GetRaceListPopupParams = {
  limit: NUMBER.TWENTY,
  page: NUMBER.ONE,
  startAt: convertFullDateLocal(date),
  endAt: convertFullDateLocal(date),
  startInstance: '1000',
  endInstance: '3200',
  sort: 'close_at-desc'
}

const defaultRegionFilters: RegionFilters = [
  {
    name: 'All',
    isActive: true
  },
  {
    name: RegionOption.TOKYO,
    isActive: false
  },
  {
    name: RegionOption.SEOUL,
    isActive: false
  }
]

let isRefresh = false

function Result() {
  const token =
    localStorage.getItem(constants.ACCESS_TOKEN_KEY) && localStorage.getItem(constants.ACCESS_TOKEN_KEY) !== 'null'
      ? localStorage.getItem(constants.ACCESS_TOKEN_KEY)
      : false
  const [params, setParams] = useState<GetRaceListParams>(defaultParams)
  const [paramsFilterResult, setParamsFilterResult] = useState<GetRaceListPopupParams>(defaultParamsFilterResult)
  const [isMyHorseOn, toggleIsMyHorseOn] = useToggle(true)
  const [races, setRaces] = useState<RecordRace[]>([])
  const [loader, setLoader] = useState(true)
  const auth = useAppSelector(state => state.auth)
  const [openModalFilter, setOpenModalFilter] = useState(false)
  usePreventBodyScroll(openModalFilter)
  const [checkLoadingPage, setCheckLoadingPage] = useState<boolean>(false)
  const [totalRace, setTotalRace] = useState(NUMBER.ZERO)
  // const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const memoizedResultListColumns = useMemo(() => resultListColumns, [])
  const [searchParams] = useSearchParams()
  const paramsSearch = Object.fromEntries(searchParams.entries())
  const [isRequestLoginModalOpen, toggleIsRequestLoginModalOpen] = useToggle(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [regionFilters, setRegionFilters] = useState<RegionFilters>(defaultRegionFilters)
  const [regionSelect, setRegionSelect] = useState(paramsSearch?.region || 'Region')

  useLayoutEffect(() => {
    const handleLoadingRaces = () => {
      if (races.length < NUMBER.TWENTY && races.length > NUMBER.ZERO) {
        setLoader(true)
      }
    }

    handleLoadingRaces()
  }, [])

  const fetchListRaces = async () => {
    setLoader(false)
    const [, result] = await handleAsyncRequest(raceApi.getRaceList({ ...params, myHorse: isMyHorseOn }))
    
    const records = result?.data.records
    setTotalRace(result?.data?.total || NUMBER.ZERO)

    if (records && records.length > NUMBER.ZERO) {
      isRefresh ? setRaces([...records]) : setRaces([...races, ...records])
    }
    if ( records && (clonedRates.length < records.length) && result?.data?.total > NUMBER.TWENTY) {
      setParams({...params, page: params.page + NUMBER.ONE })
    }
    setLoader(true)
    isRefresh = false
  }

  const fetchListRacesFilter = async () => {
    setLoader(false)
    let param: any = ''
    if (regionSelect === STRING_REGION.Tokyo || regionSelect === STRING_REGION.Seoul) {
      let region: any = ''
      if (regionSelect === STRING_REGION.Tokyo) {
        region = STRING_REGION.TOKYO
      } else {
        region = STRING_REGION.SEOUL
      }
      param = { ...paramsSearch, myHorse: isMyHorseOn, page: paramsFilterResult.page, region }
    } else {
      param = { ...paramsSearch, myHorse: isMyHorseOn, page: paramsFilterResult.page }
    }
    const [error, result]: any = await handleAsyncRequest(
      raceApi.getRaceResultPopup(param)
    )
    
    if (error) {
      setErrorMessage(error.message)
      setLoader(true)
      return
    }
    setErrorMessage('')
    handleCloseModal()
    setTotalRace(result?.data?.total || NUMBER.ZERO)
    const records = result?.data.records
    if (records && records.length > NUMBER.ZERO) {
      (isRefresh) ? setRaces([...records]) : setRaces(result?.data.page === NUMBER.ONE ? [...records] : [...races, ...records])
    }
    if (records && result.data.total === NUMBER.ZERO) {
      setRaces([])
    }
    setLoader(true)
    isRefresh = false
  }

  useEffect(() => {
    if (token) {
      if (Object.keys(paramsSearch).length === NUMBER.ZERO) {
        fetchListRaces()
      } else {
        fetchListRacesFilter()
      }
    } else {
      toggleIsRequestLoginModalOpen(true)
    }
  }, [params, paramsFilterResult])

  useUpdateEffect(() => {
    setRaces([])
    setParams({ ...params, myHorse: isMyHorseOn, page: NUMBER.ONE })
    setParamsFilterResult({ ...paramsFilterResult, myHorse: isMyHorseOn, page: NUMBER.ONE })
  }, [isMyHorseOn])

  const clonedRates = races.reduce((accumulator: any, race: any) => {
    race.course.name = shortenRaceCourseName(race.course.name)
    if (!race.name.includes("   ")) {
      accumulator.push(race);
    }
    return accumulator;
  }, []);
  const handleOpenModalFilter = () => {
    setOpenModalFilter(true)
  }

  const handleCloseModal = () => {
    setOpenModalFilter(false)
    setCheckLoadingPage(true)
  }

  const handleCheckLoading = useCallback(() => {
    if (checkLoadingPage === true) return setParamsFilterResult
    return setParams
  }, [checkLoadingPage])

  const updateRegionFilters = useCallback((regionFilters: RegionFilters, clickedFilter: RegionOptionFilter) => {
    const clonedRegionFilters = [...regionFilters]

    clonedRegionFilters.forEach(filter => {
      if (filter.name === clickedFilter.name) {
        filter.isActive = true
        setRegionSelect(filter.name)
      } else {
        filter.isActive = false
      }
    })

    return clonedRegionFilters
  }, [])

  const updateParamsAfterRegionClicked = useCallback((params: GetRaceListParams, clickedFilter: RegionOptionFilter) => {
    const clonedParams = { ...params, page: 1 }
    
    if (clickedFilter.name !== 'All') {
      if (clickedFilter.name === STRING_REGION.Tokyo) {
        clonedParams.region = STRING_REGION.TOKYO
      } else {
        clonedParams.region = STRING_REGION.SEOUL
      }
    } else {
      delete clonedParams.region
    }

    return clonedParams
  }, [])

  const handleRegionFilterClicked = useCallback(
    (filterName: string) => () => {
      const clickedFilter = regionFilters.find(filter => filter.name === filterName)

      if (!clickedFilter) return

      const newRegionFilters = updateRegionFilters(regionFilters, clickedFilter)
      const newParams = updateParamsAfterRegionClicked(params, clickedFilter)

      setRaces([])
      setRegionFilters(newRegionFilters)
      setParams(newParams)
    },
    [regionFilters, params]
  )

  return (
    <ResultStyled>
      <div className='head-container'>
        <OneLineTitle text='results' customClass='title' />
        <FilterResultRaceModal
          isOpen={openModalFilter}
          setRacesPopup={setRaces}
          handleCloseModal={handleCloseModal}
          setParamsFilter={setParamsFilterResult}
          paramsFilter={paramsFilterResult}
          errorMessage={errorMessage}
        />
      </div>
      <div className='search-container d-flex align-items-cente'>
        <div className='filter-container d-flex align-items-center justify-content-center'>
          <div className='filter d-flex align-items-center justify-content-center'>
            <button onClick={handleOpenModalFilter} className='button-filter' disabled={!loader}>
              <img src={FILTER_ICON} alt='' className='filter-icon' />
              <span className='filter-text color-grey'>{t(`${NOTIFICATION_MESSAGE}.filter`)}</span>
            </button>
          </div>
        </div>
        <div className='d-flex flex-wrap align-items-center gap-4 dropdown'>
          <Select
            dataSelect={regionFilters}
            nameSelect={regionSelect}
            onSelected={(filterName: string) => handleRegionFilterClicked(filterName)()}
          />
        </div>
        {auth.isLogged ? (
          <SwitchBtn
            title={t(`${NOTIFICATION_MESSAGE}.myHorse`)}
            isOn={isMyHorseOn}
            handleSwitchBtnClicked={toggleIsMyHorseOn}
            disabled={!loader}
          />
        ) : null}
      </div>
      <div className='content-container pt-0'>
        <RaceTable
          columns={memoizedResultListColumns}
          data={clonedRates ?? []}
          isRowClickable
          raisePage={handleCheckLoading()}
          loader={loader}
          params={params}
          paramsFilterResult={paramsFilterResult}
          checkLoadingPage={checkLoadingPage}
          totalRace={totalRace}
        />
      </div>
      {isRequestLoginModalOpen && <RequestLoginModal toggleIsModalOpen={toggleIsRequestLoginModalOpen} />}
    </ResultStyled>
  )
}

export default Result
