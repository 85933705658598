/* eslint-disable @typescript-eslint/no-explicit-any */
import guildApi from 'apis/guildApi'
import RaceHistory from 'features/Guild/components/RaceHistory'
import { setCurrentUser } from 'features/Profile/profile.slice'
import { useAppDispatch } from 'hooks'
import { ApiResponse, GUILD_STATUS, LendingHorseMarket } from 'models'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { handleAsyncRequest } from 'utils/helper'
import { getCurrentUser } from 'utils/metamask'
import Loading from '../Loading'
import MemberInfo from './member-info'
import GuildDetailContainer from './styled'
import { getTooltipInfo } from 'apps/redux/tooltip/action'

const GuildMemberDetail = () => {
  const { guild_id, user_id } = useParams()
  const [data, setData] = useState<any>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getTooltipInfo())
  }, [])

  useEffect(() => {
    fetchHorse()
    fetchInformation()
  }, [guild_id, user_id])

  const fetchHorse = async () => {
    setIsLoading(true)
    const [, horseResponse]: any = await handleAsyncRequest<ApiResponse<LendingHorseMarket>>(
      guild_id ? guildApi.getMemberDetails(guild_id || '', user_id || '') :
        guildApi.getMyProfile()
    )

    if (horseResponse) {
      setData(horseResponse?.data)
    }
    setIsLoading(false)
  }

  const fetchInformation = async () => {
    setIsLoading(true)
    const [, currentUser] = await handleAsyncRequest(getCurrentUser())
    if (currentUser) {
      dispatch(setCurrentUser(currentUser))
    }
    setIsLoading(false)
  }

  const updateData = (role: string) => {
    setData({
      ...data,
      role
    })
  }

  return (
    <GuildDetailContainer>
      {isLoading && (
        <Loading />
      )}
      {!isLoading && data && (
        <>
          <MemberInfo
            loading={isLoading}
            data={data}
            upgradeSuccess={updateData}
            downgradeSuccess={updateData}
          />
          <RaceHistory isInFarm={data.lending_info?.lending_status === GUILD_STATUS.InFarm} />
        </>
      )}
    </GuildDetailContainer>
  )
}

export default GuildMemberDetail
