import styled from 'styled-components'

const ShopeMainStyled = styled.div`
  .shop-container {
    display: flex;
    padding-top: 50px;
    .content {
      width: 100%;
    }
    .ant-menu-title-content {
      text-transform: uppercase;
      color: #fff;
      font-size: 18px;
    }
    .ant-menu-sub {
      .ant-menu-title-content {
        text-transform: capitalize;
      }
    }
    .ant-menu-item-selected {
      border: 1px solid;
      border-image-slice: 1;
      border-image-source: linear-gradient(to right, #4ef076, #4ef07600);
      position: relative;
      &:after {
        border-style: solid;
        border-width: 0 0 12px 12px;
        width: 0;
        border-color: transparent;
        border-bottom-color: #4ef076;
        content: '';
        display: inline-block;
        height: 12px;
        left: -7px;
        position: absolute;
        top: 35%;
        -webkit-transform: rotate(315deg);
        -ms-transform: rotate(315deg);
        transform: rotate(315deg);
      }
      .ant-menu-title-content {
        color: #4ef076;
      }
    }
  }
`

export default ShopeMainStyled
