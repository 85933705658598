import { Skeleton } from 'antd';


interface SkeletonDetailProps {
  customClass?: string
  type?: string
  rows?: number
}

function SkeletonDetail({ customClass = '', rows = 15 }: SkeletonDetailProps) {
  return (
    <div className={customClass}>
      <Skeleton avatar paragraph={{ rows }} active />
    </div>
  )
}

export default SkeletonDetail
