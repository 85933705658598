/* eslint-disable @typescript-eslint/no-explicit-any */
import specialRaceApi from 'apis/specialRaceApi'
import { RaceTable, RequestLoginModal } from 'features/Race/components'
import TimeLine from 'features/components/TimeLine'
import { useAppDispatch, useToggle } from 'hooks'
import { EVENT_DAY, GetCurrentRaceListParams, RecordRace, TournamentStatus } from 'models'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import { openListEventColumns } from 'utils/columns'
import { convertPluralText, getWeekDays, handleAsyncRequest } from 'utils/helper'
import CurrentFarm from '../../CurrentFarm'

import { SPECIAL_MESSAGE } from 'i18n/constants'
import { OneLineTitle } from 'shared'
import { convertShortDay } from 'utils/time'
import { NUMBER } from 'apps/constants'
import EventHistoryDetailStyled from './styled'
import { getTooltipInfo } from 'apps/redux/tooltip/action'

export type IMyLendingProps = {
  isActive: boolean
}

const defaultParams: GetCurrentRaceListParams = {
  limit: NUMBER.TWENTY,
  page: NUMBER.ONE
}

export default function EventHistoryDetail() {
  const [params, setParams] = useState<GetCurrentRaceListParams>(defaultParams)
  const [, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()
  const [isRequestLoginModalOpen, toggleIsRequestLoginModalOpen] = useToggle(false)
  const [dayType, setDayType] = useState<string>(EVENT_DAY.FIRST)
  const memoizedResultListColumns = useMemo(() => openListEventColumns, [])
  const [currentRaces, setCurrentRaces] = useState<RecordRace[]>([])
  const [totalFirstDay, setTotalFirstDay] = useState(NUMBER.ZERO)
  const [totalLastDay, setTotalLastDay] = useState(NUMBER.ZERO)
  const [tournament, setTournament] = useState<TournamentStatus>()
  const { t } = useTranslation()

  const { pathname: currentPathname } = useLocation()
  const { raceId } = useParams()

  const slicePathName = currentPathname?.substring(currentPathname.lastIndexOf('/') + NUMBER.ONE)

  const fetchTournamentStatus = async () => {
    const [, result] = await handleAsyncRequest(specialRaceApi.getTournamentDetail(raceId || NUMBER.ZERO))
    if (!result) return
    setTournament(result.data)

    const firstDay = result.data.time_open.find((item: any) => item.day_order === EVENT_DAY.FIRST)
    const lastDay = result.data.time_open.find((item: any) => item.day_order === EVENT_DAY.LAST)

    setTotalFirstDay(firstDay.total_race)
    setTotalLastDay(lastDay.total_race)
  }

  const fetchListRaces = async () => {
    setIsLoading(true)
    const [error, result] = await handleAsyncRequest(
      specialRaceApi.getEventHistoryDetail(slicePathName, {
        ...params,
        dayOrder: dayType
      })
    )
    if (error) {
      setIsLoading(false)
      return
    }
    if (result) {
      const records = result?.data?.records
      setCurrentRaces(records || [])
      if (dayType === EVENT_DAY.FIRST) {
        setTotalFirstDay(result?.data?.total)
      } else {
        setTotalLastDay(result?.data?.total)
      }
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchTournamentStatus()
    dispatch(getTooltipInfo())
  }, [])

  useEffect(() => {
    fetchListRaces()
  }, [params])

  useEffect(() => {
    if (tournament) {
      if (tournament.time_open[NUMBER.ZERO]?.total_race === NUMBER.ZERO && tournament.time_open[NUMBER.ONE]?.total_race !== NUMBER.ZERO) {
        setDayType(EVENT_DAY.LAST)
      }
    }
  }, [tournament])

  const handleClickTab = (type: string) => {
    if (dayType === type) return
    setDayType(type)
    setParams({ ...params, page: NUMBER.ONE })
  }

  return (
    <EventHistoryDetailStyled>
      <div className='current-event-container'>
        <div>
          <div className='special-name color-orange-200'>SPECIAL RACE {raceId}</div>
          <TimeLine isTournamentEnd status={tournament} />
          <div className='current-event'>
            <OneLineTitle text='Race list' isLending />
            <div className='tab-lend'>
              <div className='tab-block'>
                {tournament &&
                  tournament.time_open.map((item, index) =>
                    (totalFirstDay !== NUMBER.ZERO || totalLastDay !== NUMBER.ZERO) && item.total_race === NUMBER.ZERO ? (
                      <Fragment key={index}></Fragment>
                    ) : (
                      <div
                        key={index}
                        className={`tab-link text-center ${dayType === item.day_order ? 'active' : 'disabled'} ${totalFirstDay || totalLastDay ? 'w-100' : ''
                          }`}
                        onClick={() => handleClickTab(item.day_order)}
                      >
                        <div>
                          {convertShortDay(item.race_start_time)} {`(${getWeekDays(item.race_start_time)})`}
                        </div>
                        <div className='total-race'>{convertPluralText(item.total_race, 'race')}</div>
                      </div>
                    )
                  )}
              </div>
            </div>
            <RaceTable
              columns={memoizedResultListColumns}
              data={currentRaces ?? []}
              raisePage={setParams}
              loader={true}
              params={params}
              isRowClickable
              checkLoadingPage={false}
              totalRace={dayType === EVENT_DAY.FIRST ? totalFirstDay : totalLastDay}
              noDataText={t(`${SPECIAL_MESSAGE}.noRaceInEvent`)}
            />
          </div>
          <CurrentFarm isDetail tournamentId={tournament?.id} />
          <div></div>
        </div>
      </div>
      {isRequestLoginModalOpen && <RequestLoginModal toggleIsModalOpen={toggleIsRequestLoginModalOpen} />}
    </EventHistoryDetailStyled>
  )
}
