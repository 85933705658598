/* eslint-disable @typescript-eslint/no-explicit-any */
import Button from 'shared/Button'

import guildApi from 'apis/guildApi'
import horseApi from 'apis/horseApi'
import { configs, links } from 'apps'
import { STATUS_TRANSACTION, timeCheckNonce } from 'apps/constants'
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import InProgressBalanceModal from 'features/Balance/components/InProgress'
import ModalConfirmGuildCommon from 'features/Guild/components/ModalConfirmGuildCommon'
import { setCurrentUser } from 'features/Profile/profile.slice'
import { RequestLoginModal } from 'features/Race/components'
import ConfirmOkModal from 'features/Race/components/ConfirmOkModal'
import { useAppDispatch, useAppSelector, useToggle } from 'hooks'
import { GUILD_MESSAGE, LENDING_MESSAGE, NOTIFICATION_MESSAGE } from 'i18n/constants'
import guildFarm from 'json/GuildFarm.json'
import guildFarm_V2 from 'json/GuildFarm_V2.json'
import {
  ApiResponse,
  GUILD_ROLE,
  GUILD_STATUS,
  GuildInforManager,
  LendingHorseMarket,
  MODAL_TYPE,
  TypeModal,
  notificationTemplate
} from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { convertGasPrice, handleAsyncRequest } from 'utils/helper'
import { getCurrentUser } from 'utils/metamask'
import openNotification from 'utils/toast'
import HorseDetail from '../../HorseDetail'
import ButtonGroupContainer from './styled'
import { useLocalStorage } from 'hooks'
import { constants } from 'apps'

const contract = {
  horseNFT: configs.horseNFT,
  horseNFT_v2: configs.horseNFT_v2,
  horseFarm: configs.horseFarm,
  transporter: configs.transporter,
  guildFarm: configs.guildFarm,
  guildFarm_v2: configs.guildFarm_V2
}
interface BtnGroupProps {
  loading?: boolean
  data: any
  onRefresh: () => void
  isMyHorse?: boolean
}

const ButtonGroup = ({ data, onRefresh, isMyHorse }: BtnGroupProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { horse_id, guild_id } = useParams()
  const [isPutToGuildModalOpen, toggleIsPutToGuildModalOpen] = useToggle(false)
  const [isSendHorseToBlackListModalOpen, toggleIsSendHorseToBlackListModalOpen] = useToggle(false)
  const [isPutWithdrawModalOpen, toggleIsPutWithdrawModalOpen] = useToggle(false)
  const [isPutWithdrawRentingModalOpen, toggleIsPutWithdrawRentingModalOpen] = useToggle(false)
  const [isHorseDetailModalOpen, toggleIsHorseDetailModalOpen] = useToggle(false)
  const [openConfirmWithDrawModal, toggleOpenConfirmWithDrawModal] = useToggle(false)
  const [openNotificationModal, toggleOpenNotificationModal] = useToggle(false)
  const [openInProgressBalanceModal, toggleOpenInProgressBalanceModal] = useToggle(false)
  const [message, setMessage] = useState<notificationTemplate>()
  const [resultWithdrawHorse, setResultWithdrawHorse] = useState<any>()
  const [isCancelLendingModal, toggleIsCancelLendingModal] = useToggle(false)
  const [isCancelRentingModal, toggleIsCancelRentingModal] = useToggle(false)
  const [checkModal, setCheckModal] = useState('')
  const [isHorseInRace, toggleIsHorseInRace] = useToggle(false)
  const [isRequestLoginModalOpen, toggleIsRequestLoginModalOpen] = useToggle(false)
  const { profile } = useAppSelector(state => state)
  const [typeBtn, setTypeBtn] = useState<string>()
  const [roleStatus, setRoleStatus] = useState({
    status: GUILD_STATUS.InFarm,
    role: GUILD_ROLE.GUEST,
    isOwner: false,
    isGuild: false
  })
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [guildInfor, setGuildInfor] = useState<GuildInforManager>()
  const [userId] = useLocalStorage(constants.USER_ID_KEY, 0)
  const [ownerName, setOwnerName] = useState<string>(() => "")

  useEffect(() => {
    if (!data) return
    setRoleStatus({
      status: data?.lending_info?.lending_status,
      role: profile.role_in_guild as GUILD_ROLE,
      isOwner: data?.lending_info?.is_owner,
      isGuild: profile.guild_id === data?.guild_info?.id ? true : false
    })
    setOwnerName(() => data?.horse?.owner?.name || "")
  }, [data, profile])

  const putHorseGuildApi = async () => {
    setIsLoading(true)
    const [error, horseResponse]: any = await handleAsyncRequest<ApiResponse<LendingHorseMarket>>(
      guildApi.putHorseToGuild(horse_id || '', guild_id || '')
    )

    if (error) {
      pushNotification(error?.message)
      toggleIsPutToGuildModalOpen(true)
    }
    if (horseResponse) {
      // setData(horseResponse?.data)
      pushNotification(t(`${GUILD_MESSAGE}.successPutToGuild`), true)
      onRefresh()
    }
    toggleIsPutToGuildModalOpen()
    setIsLoading(false)
  }

  const putWithdrawFromGuild = async () => {
    setIsLoading(true)
    const [error, horseResponse]: any = await handleAsyncRequest<ApiResponse<LendingHorseMarket>>(
      guildApi.putWithdrawFromGuild(horse_id || '')
    )
    if (error) {
      fetchInformation()
      pushNotification(error?.message)
      toggleIsPutWithdrawModalOpen(false)
      toggleIsPutWithdrawRentingModalOpen(false)
      onRefresh()
      return
    }

    if (horseResponse) {
      onRefresh()
      pushNotification(onCheckRoleGuild() ? t(`${GUILD_MESSAGE}.successRemoveFromGuild`) : t(`${GUILD_MESSAGE}.successWithdrawFromGuild`), true)
    }
    toggleIsPutWithdrawModalOpen(false)
    toggleIsPutWithdrawRentingModalOpen(false)
    setIsLoading(false)
  }
  const _handleCancelLending = () => {
    toggleIsCancelLendingModal(true)
    setCheckModal(() => 'CancelLending')
  }

  const _handleCancelRenting = () => {
    toggleIsCancelRentingModal(true)
    setCheckModal(() => 'CancelRenting')
  }

  const _handlePutToGuild = () => {
    putHorseGuildApi()
  }

  const _handlePutWithdrawFromGuild = () => {
    putWithdrawFromGuild()
  }
  const _handleSendHorseToBlackList = async () => {

    const [error, result]: any = 
      await handleAsyncRequest(guildApi.putHorseToBlackListFromGuild(guild_id || '', horse_id || ''))
    if (error) {
      setMessage?.({
        body: error.message,
        title: MODAL_TYPE.failed
      })
      toggleOpenNotificationModal(true)
    }
    if (result) {
      pushNotification(t(`You have successfully blacklisted ${data?.horse?.name}`), true)
      navigate(-1)
    }
    toggleIsSendHorseToBlackListModalOpen(false)
  }

  const _handleLendingSetting = (type: string) => {
    setTypeBtn(type)
    toggleIsHorseDetailModalOpen()
  }

  const handleBtnWithdrawClick = async () => {
    toggleOpenConfirmWithDrawModal(false)
    setIsLoading(true)
    const [error, result]: any = await handleAsyncRequest(guildApi.postWithrawHorse({ horse_ids: [horse_id] }))
    setIsLoading(false)
    if (error) {
      if (error.code === 400) {
        setMessage?.({
          body: error.message,
          title: MODAL_TYPE.failed
        })
      }
      toggleOpenNotificationModal(true)
    }
    if (result) {
      toggleOpenInProgressBalanceModal(true)
      setMessage({ title: 'WITHDRAW' })
      setResultWithdrawHorse(() => result.data)
    }
  }  

  useEffect(() => {
    if (!resultWithdrawHorse) return
    handleWithDrawHorseContract()
  }, [resultWithdrawHorse])

  const handleWithDrawHorseContract = async () => {
    if (!resultWithdrawHorse) return
    try {
      const coverBlockExpired = new BigNumber(resultWithdrawHorse.block_expired).toFixed()
      const coverHorseId = new BigNumber(resultWithdrawHorse.token_ids || '').toFixed()
      let withdrawContract: any = '';
      let param: any = '';
      
      if (resultWithdrawHorse?.horse_farm_address?.toUpperCase() === contract.guildFarm?.toUpperCase()) {
        withdrawContract = new ethers.Contract(contract.guildFarm, guildFarm.contract.abi, signer)
        param = {
          owner: resultWithdrawHorse.owner,
          horseId: resultWithdrawHorse.token_ids,
          blockExpired: coverBlockExpired,
          nonce: resultWithdrawHorse.nonce,
          v: resultWithdrawHorse.v,
          r: resultWithdrawHorse.r,
          s: resultWithdrawHorse.s
        }
      } else {
        withdrawContract = new ethers.Contract(contract.guildFarm_v2, guildFarm_V2.contract.abi, signer)
        param = {
          owner: resultWithdrawHorse.owner,
          horseAddress: resultWithdrawHorse?.horse_contract,
          horseId: coverHorseId,
          blockExpired: coverBlockExpired,
          nonce: resultWithdrawHorse.nonce,
          v: resultWithdrawHorse.v,
          r: resultWithdrawHorse.r,
          s: resultWithdrawHorse.s
        }
      }

      try {
        await withdrawContract.withdraw(
          param,
          { gasPrice: convertGasPrice(resultWithdrawHorse.gas_price) }
        )
        checkNonceWithDrawContract(resultWithdrawHorse.nonce)
      } catch (err: any) {
        setMessage?.({
          body: t(`${LENDING_MESSAGE}.failedTransaction`),
          title: MODAL_TYPE.failed
        })
        toggleOpenNotificationModal?.(true)
        toggleOpenInProgressBalanceModal(false)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const checkNonceWithDrawContract = async (nonce: string) => {
    let checkNonceExits = null as any
    let x = 0
    const intervalID = setInterval(async () => {
      const [, result] = await handleAsyncRequest(guildApi.postCheckNonce({ nonce }))
      if (!result) return
      checkNonceExits = result.data.status
      if (checkNonceExits === STATUS_TRANSACTION.success) {
        clearInterval(intervalID)
        toggleOpenInProgressBalanceModal(false)
        pushNotification(t(`${GUILD_MESSAGE}.successWithdrawToWallet`), true)

        if (isMyHorse) {
          navigate(links.guild.myHorses())
        } else {
          navigate(links.guild.guildFarmId(guild_id ? guild_id : 0))
        }
      }

      if (checkNonceExits === STATUS_TRANSACTION.expired) {
        clearInterval(intervalID)
        toggleOpenInProgressBalanceModal(false)
        setMessage?.({
          body: t(`${LENDING_MESSAGE}.failedTransaction`),
          title: MODAL_TYPE.failed
        })
        toggleOpenNotificationModal?.(true)
      }

      if (++x === 10) {
        if (checkNonceExits === STATUS_TRANSACTION.pending) {
          clearInterval(intervalID)
          toggleOpenInProgressBalanceModal(false)
          setMessage?.({
            body: t(`${NOTIFICATION_MESSAGE}.transferredPending`),
            title: 'warning'
          })
          toggleOpenNotificationModal?.(true)
        }
      }
    }, timeCheckNonce)
  }

  const checkHorseIsInRace = async () => {
    setIsLoading(true)
    const [, result]: any = await handleAsyncRequest(horseApi.getHorseIsInRace(horse_id || '', { statuses: 'OPEN' }))
    setIsLoading(false)
    if (result?.data?.is_in_race) {
      toggleIsHorseInRace(true)
      setMessage({ body: t(`${LENDING_MESSAGE}.successRentedHorse`), title: MODAL_TYPE.warning })
      toggleIsCancelLendingModal(false)
      toggleIsCancelRentingModal(false)
      setCheckModal(() => '')
      return
    } else {
      handleHorseBackToFarm()
    }
  }

  const handleHorseBackToFarm = async () => {
    let err: any = ''
    let response: any = ''
    setIsLoading(true)
    if (checkModal === 'CancelLending') {
      const [error, result]: any = await handleAsyncRequest(guildApi.postHorseBackToFarm(horse_id || ''))
      err = error
      response = result
    } else {
      const [error, result]: any = await handleAsyncRequest(guildApi.postWithdrawFromMarket(horse_id || ''))
      err = error
      response = result
    }
    setIsLoading(false)
    if (err) {
      if (err.code === 403) {
        toggleIsRequestLoginModalOpen(true)
      } else {
        pushNotification(err?.message)
        fetchInformation()
        onRefresh()
      }
    }
    if (response) {
      pushNotification(isCancelLendingModal ? t(`${GUILD_MESSAGE}.message.successCancelLending`) : t(`${GUILD_MESSAGE}.message.successCancelRenting`), true)
      onRefresh()
      // fetchHorse()
    }
    toggleIsCancelLendingModal(false)
    toggleIsCancelRentingModal(false)
    setCheckModal(() => '')
    toggleIsHorseInRace(false)
  }

  const pushNotification = (description: string, isSuccess?: boolean) => {
    openNotification({
      message: '',
      description: description,
      className: isSuccess ? 'toast-success' : 'toast-error'
    })
  }

  const onCheckRoleGuild = () => {
    if (data && data.lending_info.is_owner === true) return false
    if (profile?.role_in_guild === 'MASTER' || profile?.role_in_guild === 'MANAGER') {
      return true
    } else return false
  }

  const fetchInformation = async () => {
    setIsLoading(true)
    const [, currentUser] = await handleAsyncRequest(getCurrentUser())
    if (currentUser) {
      dispatch(setCurrentUser(currentUser))
    }
    setIsLoading(false)
  }

  const _onClickBtnPutToGuild = () => {
    toggleIsPutToGuildModalOpen()
    getGuildInforApi()
  }

  const getGuildInforApi = async () => {
    // setIsLoading(true)
    const [error, result]: any = await handleAsyncRequest(
      guildApi.getGuildInfomationById(guild_id || '')
    )

    if (error) {
      pushNotification(error?.message)
    }
    if (result) {
      setGuildInfor(result?.data)
    }
    // setIsLoading(false)
  }

  const getWidthdrawMessage = () => {
    return !onCheckRoleGuild() && roleStatus.status === GUILD_STATUS.Renting
      ? 'This horse is joining in the race. Do you want to continue?'
      : onCheckRoleGuild()
      ? 'Do you want to remove this horse from the guild?'
      : 'Do you want to cancel the lease and withdraw this horse from the guild? '
  }
  
  return (
    <ButtonGroupContainer>
      {data && (
        <>
          {roleStatus.isOwner && roleStatus.status === GUILD_STATUS.InFarm && !isMyHorse && (
            <Button buttonName='Put to guild' onClickButton={_onClickBtnPutToGuild} width={147} height={42} />
          )}

          {roleStatus.isOwner && roleStatus.status === GUILD_STATUS.InFarm && (
            <Button
              buttonName={'Withdraw to wallet'}
              onClickButton={() => toggleOpenConfirmWithDrawModal(true)}
              width={147}
              height={42}
              type="btnRemove"
            />
          )}
          {((roleStatus.isOwner && roleStatus.isGuild) ||
            ((roleStatus.role === GUILD_ROLE.MANAGER || roleStatus.role === GUILD_ROLE.MASTER) &&
              roleStatus.isGuild)) &&
            roleStatus.status === GUILD_STATUS.GuildFarm && (
              <Button buttonName='Lending setting' onClickButton={() => _handleLendingSetting(GUILD_STATUS.GuildFarm)} width={147} height={42} />
            )}

          {roleStatus.status === GUILD_STATUS.OnMarket && roleStatus.isGuild && (
            <Button buttonName='Borrow' onClickButton={() => _handleLendingSetting(GUILD_STATUS.OnMarket)} width={147} height={42} />
          )}

          {((roleStatus.isOwner && roleStatus.isGuild) ||
            ((roleStatus.role === GUILD_ROLE.MANAGER || roleStatus.role === GUILD_ROLE.MASTER) &&
              roleStatus.isGuild)) &&
            roleStatus.status === GUILD_STATUS.OnMarket && (
              <Button
                buttonName='Cancel Lending'
                onClickButton={_handleCancelLending}
                type="btnCancel"
                width={147}
                height={42}
              />
            )}

          {((roleStatus.isOwner && roleStatus.isGuild) ||
            ((roleStatus.role === GUILD_ROLE.MANAGER || roleStatus.role === GUILD_ROLE.MASTER) && roleStatus.isGuild) ||
            userId === data?.lending_info?.renter?.id) &&
            roleStatus.status === GUILD_STATUS.Renting && (
            <Button
              buttonName='Cancel Renting'
              onClickButton={_handleCancelRenting}
              type="btnCancel"
              width={147}
              height={42}
            />
          )}

          {roleStatus.isOwner && roleStatus.status === GUILD_STATUS.Renting && (
            <Button
              buttonName={'Withdraw from guild'}
              onClickButton={toggleIsPutWithdrawRentingModalOpen}
              width={180}
              height={42}
              type="btnRemove"
            />
          )}

          {(roleStatus.status === GUILD_STATUS.GuildFarm || roleStatus.status === GUILD_STATUS.OnMarket) &&
            (((roleStatus.role === GUILD_ROLE.MANAGER || roleStatus.role === GUILD_ROLE.MASTER) &&
              roleStatus.isGuild) ||
              roleStatus.isOwner) && (
              <Button
                buttonName={onCheckRoleGuild() ? 'Remove from guild' : 'Withdraw from guild'}
                onClickButton={toggleIsPutWithdrawModalOpen}
                width={180}
                height={42}
                type="btnRemove"
              />
            )}

          {isPutToGuildModalOpen && (
            <ModalConfirmGuildCommon
              message={<div>
                  <div className='head-guild'>
                    <div>
                      <img src={guildInfor?.avatar} alt='avatar' width={55} />
                      <span className='color-yellow'>[{guildInfor?.guild_tag}] </span>
                      <span>{guildInfor?.name}</span>
                    </div>
                  </div>
                  When horse are put on the guild farm, the guild managers have the right to manage them.
                  <br />
                  Are you sure you want to put this horse on the guild farm?
              </div>}
              onClose={toggleIsPutToGuildModalOpen}
              onConfirm={_handlePutToGuild}
              loading={isLoading}
              title='PUT HORSE TO GUILD'
              size='MD'
            />
          )}
          {(roleStatus.role === GUILD_ROLE.MANAGER || roleStatus.role === GUILD_ROLE.MASTER) && ownerName !== profile?.name && (
             <Button
              buttonName={'Send to blacklist'}
              onClickButton={toggleIsSendHorseToBlackListModalOpen}
              width={180}
              height={42}
              type="btnSendToBlacklist"
            />
          )}

          {isPutWithdrawModalOpen && (
            <ModalConfirmGuildCommon
              message={
                onCheckRoleGuild()
                  ? 'Do you want to remove this horse from the guild?'
                  : roleStatus.status === GUILD_STATUS.OnMarket
                  ? `Do you want to cancel setting lending and withdraw this horse from the guild?`
                  : 'Do you want to withdraw this horse from the guild?'
              }
              onClose={toggleIsPutWithdrawModalOpen}
              onConfirm={_handlePutWithdrawFromGuild}
              loading={isLoading}
              title={
                onCheckRoleGuild()
                  ? 'REMOVE FROM GUILD'
                  : roleStatus.status === GUILD_STATUS.OnMarket
                  ? 'CANCEL LENDING & WITHDRAW'
                  : 'WITHDRAW FROM GUILD'
              }
              type={TypeModal.warning}
            />
          )}          
          {isSendHorseToBlackListModalOpen && (
            <ModalConfirmGuildCommon
              message={
                <span>
                  You are blacklisting this horse. <br />
                  When a horse is blacklisted, it will be kicked out of the guild and all transactions within the guild will be canceled. <br />
                  Players will also not be able to bring this horse into the clan from now on. <br />
                  Do you want to blacklist this horse?
                </span>
              }
              onClose={toggleIsSendHorseToBlackListModalOpen}
              onConfirm={_handleSendHorseToBlackList}
              loading={isLoading}
              title={'SEND TO BLACKLIST'}
              type={TypeModal.warning}
              size='MD'
            />
          )}

          {isPutWithdrawRentingModalOpen && (
            <ModalConfirmGuildCommon
              message={getWidthdrawMessage()}
              onClose={toggleIsPutWithdrawRentingModalOpen}
              onConfirm={_handlePutWithdrawFromGuild}
              loading={isLoading}
              title={onCheckRoleGuild() ? 'REMOVE FROM GUILD' : 'CANCEL RENTING & WITHDRAW'}
              type={TypeModal.warning}
            />
          )}
          {isHorseDetailModalOpen && (
            <HorseDetail typeBtnClick={typeBtn} onRefresh={onRefresh} onOverlayClick={toggleIsHorseDetailModalOpen} />
          )}
          {isCancelLendingModal && (
            <ModalConfirmGuildCommon
              message={'Do you want to cancel lending this horse?'}
              onClose={toggleIsCancelLendingModal}
              onConfirm={checkHorseIsInRace}
              loading={isLoading}
              title='CANCEL LENDING'
              type={TypeModal.warning}
            />
          )}

          {isCancelRentingModal && (
            <ModalConfirmGuildCommon
              message={'Do you want to cancel the lease of this horse?'}
              onClose={toggleIsCancelRentingModal}
              onConfirm={checkHorseIsInRace}
              loading={isLoading}
              title='CANCEL RENTING'
              type={TypeModal.warning}
            />
          )}

          {isHorseInRace && (
            <ConfirmOkModal
              toggleIsModalOpen={toggleIsHorseInRace}
              onCloseButtonClick={toggleIsHorseInRace}
              onConfirm={handleHorseBackToFarm}
              message={<span> {message?.body} </span>}
              title={message?.title}
              isLoading={isLoading}
            />
          )}

          {openConfirmWithDrawModal && (
            <ModalConfirmGuildCommon
              message={
                <div className='font-size-24  message-content'>
                  To register to guild farm, your NFT horse will be tranfer to farm.
                  <br />
                  A fee (ADIL) is required for farming.
                  <br />
                  After put into farm, your authority over your horse will temporary locked.
                  <br />
                  Withdraw NFT horse form farm to your wallet will also require fee (ADIL).
                  <br />
                  Do you want to withdraw your NFT horse your wallet?
                </div>
              }
              onClose={toggleOpenConfirmWithDrawModal}
              onConfirm={handleBtnWithdrawClick}
              loading={isLoading}
              title='WITHDRAW HORSE TO WALLET'
              type={TypeModal.warning}
              size='MD'
            />
          )}

          {openNotificationModal && (
            <ConfirmOkModal
              toggleIsModalOpen={toggleOpenNotificationModal}
              onCloseButtonClick={toggleOpenNotificationModal}
              onConfirm={toggleOpenNotificationModal}
              message={<span> {message?.body} </span>}
              title={message?.title}
            />
          )}
          {openInProgressBalanceModal && <InProgressBalanceModal title={`${message?.title} IN PROGRESS`} />}
          {isRequestLoginModalOpen && <RequestLoginModal toggleIsModalOpen={toggleIsRequestLoginModalOpen} />}
        </>
      )}
    </ButtonGroupContainer>
  )
}

export default ButtonGroup
