import styled from 'styled-components'

const JoindGuildContainer = styled.div`
  .btn-container {
    position: relative;
    width: 275px;
    height: 80px;
    cursor: pointer;

    .btn-name {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 24px;
    }
  }

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`

export default JoindGuildContainer
