import styled from 'styled-components'

const RaceHistoryStyled = styled.div`
  width: 100%;
  --bs-body-bg: transparent;

  .head {
    /* border-bottom: 1px solid #4EF076; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 20px;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #4ef076;
      bottom: 8px;
    }

    span {
      color: #4ef076;
      text-transform: uppercase;
      font-size: 18px;
      background-color: #121520;
      z-index: 1;
      padding: 0 5px;
    }
  }
  .guild-header {
    display: flex;
    justify-content: end;
    gap: 20px;
  }
`

export default RaceHistoryStyled
