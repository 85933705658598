/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApiResponse, GetCurrentRaceListParams } from 'models'
import axiosClient from './axiosClient'

const specialRaceApi = {
  // current farm
  postHorseSpecial(data: any): Promise<ApiResponse<any>> {
    const url = '/tournament/current-farm/add-horse'
    return axiosClient.post(url, data)
  },
  getHorseSpecial(params: any): Promise<ApiResponse<any>> {
    const url = '/tournament/current-farm/search-horses'
    return axiosClient.get(url, { params })
  },
  getMyHorseSpecial(params: any): Promise<ApiResponse<any>> {
    const url = '/tournament/current-farm/my-horses'
    return axiosClient.get(url, { params })
  },
  postWithdrawSpecial(data: any): Promise<ApiResponse<any>> {
    const url = '/tournament/current-farm/withdraw-horse'
    return axiosClient.post(url, data)
  },
  getMyHorseInFarm(params: any): Promise<ApiResponse<any>> {
    const url = '/tournament/current-farm/my-horse-in-farm'
    return axiosClient.get(url, { params })
  },
  postCheckNonce(data: any): Promise<ApiResponse<any>> {
    const url = '/tournament/current-farm/check-nonce'
    return axiosClient.post(url, data)
  },
  getTournamentStatus(): Promise<ApiResponse<any>> {
    const url = '/tournament/current-farm/tournament-status'
    return axiosClient.get(url)
  },

  getHorseDetail(horseId: string, eventId: string): Promise<ApiResponse<any>> {
    const url = `/tournament/current-farm/${horseId}/${eventId}/detail`
    return axiosClient.get(url)
  },

  // old farm
  getOldFarmDetail(horseId: string, eventId: string): Promise<ApiResponse<any>> {
    const url = `/tournament/old-farm/${horseId}/${eventId}/detail`
    return axiosClient.get(url)
  },
  postOldFarmCheckNonce(data: any): Promise<ApiResponse<any>> {
    const url = '/tournament/old-farm/check-nonce'
    return axiosClient.post(url, data)
  },
  getOldFarmMyHorseInFarm(params: any): Promise<ApiResponse<any>> {
    const url = '/tournament/old-farm/my-horse-in-farm'
    return axiosClient.get(url, { params })
  },
  getOldFarmHorse(params: any): Promise<ApiResponse<any>> {
    const url = '/tournament/old-farm/search-horses'
    return axiosClient.get(url, { params })
  },
  postOldFarmWithdraw(data: any): Promise<ApiResponse<any>> {
    const url = '/tournament/old-farm/withdraw-horse'
    return axiosClient.post(url, data)
  },
  getTournamentDetail(id: string | number): Promise<ApiResponse<any>> {
    const url = `/tournament/old-farm/${id}/tournament-status`
    return axiosClient.get(url)
  },
  // end old farm

  // start current event
  getCurrentEvent(params: GetCurrentRaceListParams): Promise<ApiResponse<any>> {
    const url = '/tournament/current-race'
    return axiosClient.get(url, { params })
  },
  // end current event
  // start event history
  getEventHistory(params: GetCurrentRaceListParams): Promise<ApiResponse<any>> {
    const url = '/tournament/old-farm/event-history'
    return axiosClient.get(url, { params })
  },
  getEventHistoryDetail(raceId: string, params: any): Promise<ApiResponse<any>> {
    const url = `/tournament/old-farm/${raceId}/races-in-event`
    return axiosClient.get(url, { params })
  },
  getHorseHistoryDetail(raceId: string, params: any): Promise<ApiResponse<any>> {
    const url = `/tournament/old-farm/${raceId}/horses-in-event`
    return axiosClient.get(url, { params })
  }
  // end event history
}

export default specialRaceApi
