/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import Item from '../ItemBox/item'
import ItemDetail from '../ItemDetail'
import ListItemContainer from './styled'
import Button from 'shared/Button'
import { handleAsyncRequest } from 'utils/helper'
import horseApi from 'apis/horseApi'
import Loading from 'shared/Loading'
import UseButton from '../ButtonAction/use'

interface iprops {
  type: string
  onItemChooseSuccess: () => void
  item?: number
  isChangeItem?: boolean
  onButtonClick?: () => void
  horseId: number
}

const ListItem = ({ type, onItemChooseSuccess, item, isChangeItem = false, onButtonClick, horseId }: iprops) => {
  const [showModal, setShowModal] = useState(false)
  const [itemSelect, setItemSelect] = useState<any>(item)
  const [items, setItems] = useState<any>()
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    fetchSyncHorseItems()
  }, [type])

  const fetchSyncHorseItems = async () => {
    setLoading(true)
    const [, data]: any = await handleAsyncRequest(horseApi.getItemsWeapons(horseId, { type, change: isChangeItem, limit: 100 }))
    setLoading(false)

    if (data) {
      setItems(data?.data?.records)
    }
  }

  const _onToggleModal = () => {
    setShowModal(!showModal)
    onButtonClick && onButtonClick();
  }

  const _onClickItem = (item: any) => {
    setItemSelect(item)
  }

  const _onSuccess = async () => {
    _onToggleModal()
    onItemChooseSuccess()
  }
  const _onSuccessCustom = async () => {
    onItemChooseSuccess()
  }

  return (
    <ListItemContainer>
      {loading ? (
        <Loading />
      ) : (
        <>
          {items?.length > 0 ? (
            <>
              {items?.map((d: any) => (
                <Item
                  className={`${d.id === itemSelect?.id ? 'active' : ''} custom ${type}`}
                  key={d.id}
                  imgUrl={d.img}
                  onClickItem={() => _onClickItem(d)}
                />
              ))}
              <div className='row button-group d-flex align-items-center justify-content-center'>
                <Button type="btnView" buttonName='View' onClickButton={_onToggleModal} disabled={!itemSelect} width={150} />
                <UseButton itemToken={itemSelect?.item_token} horseId={horseId} onSuccess={_onSuccessCustom} isChangeItem={isChangeItem} isDisabled={!itemSelect}/>
              </div>
              <ItemDetail
                open={showModal}
                onCancel={_onToggleModal}
                itemToken={itemSelect?.item_token}
                onSuccess={_onSuccess}
                isChangeItem={isChangeItem}
                className={`${type}`}
                horseId={horseId}
              />
            </>
          ) : (
            <p className='not-found d-flex align-items-center justify-content-center'>No item</p>
          )}
        </>
      )}
    </ListItemContainer>
  )
}

export default ListItem
