/* eslint-disable @typescript-eslint/no-explicit-any */
import { notification } from 'antd'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import Button from 'shared/Button'

import guildApi from 'apis/guildApi'
import ModalManagerMember from 'features/Guild/components/ModalAddMemberToBlackList'
import { handleAsyncRequest } from 'utils/helper'

import RemoveMemberContainer from './styled'

const AddMemberToBlackList = ({onRemoveMemberSuccess}: any) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [api, contextHolder] = notification.useNotification()

  const { guild_id, user_id} = useParams()

  const openNotification = (message: string, description: string) => {
    notification.config({
      duration: 4
    })

    api.info({
      message,
      description,
      placement: 'bottomRight',
      className: message === 'ERROR' ? 'ant-custom-error' : 'ant-custom-success'
    })
  }

  const _handleToggleModal = () => {
    setShowModal(!showModal)
  }

  const _handleUpgrade = async () => {
    setIsLoading(true)

    const [error, result]: any = await handleAsyncRequest(guildApi.addMembertoBlackList(Number(guild_id), Number(user_id)));
    setIsLoading(false);
    if (error) {
      openNotification('ERROR', error.message || error.errors[0]?.message)
    }

    if (result) {
      _handleToggleModal()
      onRemoveMemberSuccess()
    }
  }

  return (
    <RemoveMemberContainer>
      {contextHolder}
      <Button
        buttonName='Send To Blacklist'
        onClickButton={_handleToggleModal}
        type="btnSendToBlacklist"
        height={42}
        width={180}
        classNames='remove-btn'
      />
      {showModal && (
        <ModalManagerMember
          title='SEND TO BLACKLIST'
          message={
            <p>
              You blacklist this player.  <br />
              When a player is blacklisted, he will be kicked out of the clan. <br />
              All of his horses will also be kicked out of the guild. <br />
              He also cannot bring horses into the guild from now on. <br />
              Do you want to blacklist him?
            </p>
          }
          onClose={_handleToggleModal}
          onConfirm={_handleUpgrade}
          loading={isLoading}
          titleColor='#F8AB3C'
        />
      )}
    </RemoveMemberContainer>
  )
}

export default AddMemberToBlackList
