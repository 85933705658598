import styled from 'styled-components'

const ModalConfirmJoinGuildContainer = styled.div`
  width: 515px;
  height: 274px;
  position: relative;
  background-color: #EF902F;
  -webkit-clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);
  clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);
  // box-sizing: border-box;

  &:before {
    content: '';
    position: absolute;
    width: 513px;
    height: 272px;
    top: 1px;
    left: 1px;
    background-color: #191D2C;
    display: block;
    -webkit-clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);
    clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);
  }

  .header {
    margin-bottom: 16px;
    p {
      text-align: center;
      padding-top: 20px;
      position: relative;

      &:after {
        content: '';
        width: 80%;
        height: 1px;
        background: #5e6684;
        bottom: -4px;
        left: 10%;
        position: absolute;
      }

      .title {
        display: inline-block;
        width: fit-content;
        color: #4ef076;
        font-size: 24px;
        line-height: 24px;
        text-transform: uppercase;
      }
    }
  }

  .close-btn {
    top: 10px;
    right: 10px;
    background-color: ${({ theme }) => theme.color.transparent};
    border: none;
    z-index: 1;
  }

  .content-box {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    p {
      color: #fff;
      font-size: 20px;
      text-align: center;
      padding-top: 15px;

      &.error {
        color: #e01621;
      }
    }
  }

  .button-box {
    display: flex;
    bottom: 30px;
    position: absolute;
    justify-content: center;
    width: 100%;
    gap: 170px;
  }
`

export { ModalConfirmJoinGuildContainer }
