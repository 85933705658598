/* eslint-disable @typescript-eslint/no-explicit-any */
import { paths, store } from 'apps'
import { GlobalStyles, GlobalTheme } from 'assets/styles'
import { useEffect } from 'react'
import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import GlobalRoute from 'routes'

function App() {
  useEffect(() => {
    if (window.location.pathname === paths.mobile.feature()) return
    if (window.location.pathname === paths.maintenace.detail()) return
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent)) {
      window.location.href = paths.mobile.feature()
    }
  }, [])

  return (
    <Provider store={store}>
      <GlobalTheme>
        <GlobalStyles />
        <div className='App'>
          <GlobalRoute />
          <ToastContainer />
        </div>
      </GlobalTheme>
    </Provider>
  )
}

export default App
