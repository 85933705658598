import { useState } from "react"
import TabContainer from "./styled";

interface iprops {
  onTabClick: (v: string) =>  void
}

const Tabs = ({ onTabClick }: iprops) => {
  const [tabActive, setTabActive] = useState('YEAR_MONTH');

  const handleClickTab = (value: string) => () => {
    if (value === tabActive) return
    setTabActive(value)
    onTabClick(value)
  };

  return (
    <TabContainer>
      <div className='tab-lend'>
        <div className='tab-block'>
          <div
            className={`tab-link text-center ${tabActive === 'YEAR_MONTH' ? 'active' : 'disabled'}`}
            onClick={handleClickTab('YEAR_MONTH')}
          >
            <div className='total-race'>This month</div>
          </div>
          <div
            className={`tab-link text-center ${tabActive === 'ALL_TIME' ? 'active' : 'disabled'}`}
            onClick={handleClickTab('ALL_TIME')}
          >
            <div className='total-race'>All time</div>
          </div>
        </div>
      </div>
    </TabContainer>
  )
}

export default Tabs
