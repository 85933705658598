/* eslint-disable @typescript-eslint/no-explicit-any */
import guildApi from 'apis/guildApi'
import RaceHistory from 'features/Guild/components/RaceHistory'
import { setCurrentUser } from 'features/Profile/profile.slice'
import { useAppDispatch, useToggle } from 'hooks'
import { ApiResponse, GUILD_STATUS, LendingHorseMarket } from 'models'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { handleAsyncRequest } from 'utils/helper'
import { getCurrentUser } from 'utils/metamask'
import ButtonGroup from './button-group'
import HorseInfo from './horse-info'
import GuildDetailContainer from './styled'

const GuildDetail = () => {
  const { horse_id } = useParams()
  const [data, setData] = useState<any>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isRefresh, toggleIsRefresh] = useToggle(false)
  const dispatch = useAppDispatch()

  const local = useLocation();
  const isMyHorse = local.pathname.search('in-farm') !== -1 || local.pathname.search('my-horse') !== -1

  useEffect(() => {
    fetchInformation()
    fetchHorse()
  }, [horse_id, isRefresh])

  const fetchHorse = async () => {
    setIsLoading(true)
    const [, horseResponse]: any = await handleAsyncRequest<ApiResponse<LendingHorseMarket>>(
      guildApi.getHorseGuildDetails(horse_id || '')
    )

    if (horseResponse) {
      setData(horseResponse?.data)
    }
    setIsLoading(false)
  }
  const fetchInformation = async () => {
    setIsLoading(true)
    const [, currentUser] = await handleAsyncRequest(getCurrentUser())
    if (currentUser) {
      dispatch(setCurrentUser(currentUser))
    }
    setIsLoading(false)
  }

  return (
    <GuildDetailContainer>
      <>
        <div className='contain_info_borse'>
          <HorseInfo loading={isLoading} horse={data} />
          <ButtonGroup data={data} onRefresh={toggleIsRefresh} isMyHorse={isMyHorse} />
        </div>

        {data && (
          <RaceHistory loading={isLoading} isInFarm={data.lending_info.lending_status === GUILD_STATUS.InFarm} isHorse />
        )}
        
      </>
    </GuildDetailContainer>
  )
}

export default GuildDetail
