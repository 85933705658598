import styled from 'styled-components'

export const ItemDetailsStyled = styled.div`
  width: 100%;
  color: white;
  padding: 20px;
  padding-top: 0px;

  .container {
    padding: 1px;
    position: relative;
    .f-top{
      background: transparent;
      position: absolute;
      top: 17px;
      left: 21%;
    }
    .f-bottom{
      transform: rotate(180deg);
      background: transparent;
      position: absolute;
      bottom: -21px;
      left: 21%;
    }
    .f-left{
      position: absolute;
      top: 0;
      height: 100%;
      left: 0;
    }
    .f-right{
      position: absolute;
      top: 0;
      height: 100%;
      right: 0;
    }
    .item-block {
      position: relative;
      margin-top: 37px;
      .body-item {
        padding: 40px;
        &.body{
          .item{
            .avatar{
              .avatar-img{
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(1.75);
              }
            }
          }
        }
        &.leg{
          .item{
            .avatar{
              .avatar-img{
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(1.5);
              }
            }
          }
        }
        &.head{
          .item{
            .avatar{
              .avatar-img{
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) scale(1.5);
              }
            }
          }
        }
        &.skin{
          .item{
            .avatar{
              .avatar-img{
                transform: scale(1.5);
                left: 20%;
              }
            }
          }
        }
        .item {
          display: flex;
          .avatar {
            position: relative;
            .star-amount{
              display: flex;
              align-items: center;
              justify-content: center;
              margin-bottom: 5px;
              position: absolute;
              bottom: 5%;
              left: 50%;
              transform: translateX(-50%);
            }
            .numb{
              width: 48px;
              position: relative;
              img{
                width: 48px;
                -webkit-filter: brightness(0) invert(1);
                filter: brightness(0) invert(1);
              }
              span {
                position: absolute;
                left: 0;
                color: #fff;
                font-size: 15px;
                width: 100%;
                text-align: center;
                bottom: 0px;
              }
            }
            .level{
              .rate-star{
                &.disable{
                  display: none;
                }
              }
            }
            .item {
              width: 300px;
              height: 300px;
            }
            .sub-tract {
              width: 185px;
            }
            .avatar-img{
              position: absolute;
              left: 50%;
              -webkit-transform: translate(-50%,0);
              -ms-transform: translate(-50%,0);
              transform: translate(-50%,0);
              height: 100px;
              top: 15%;
            }
          }
          .name {
            padding: 0px 20px;
            .item-name {
              font-size: 20px;
              font-weight: 400;
            }
            .field-name {
              font-size: 18px;
              font-weight: 400;
              line-height: 37px;
              color: #fff566;
              display: block;
              width: 70px;
            }
            .icon-rarity{
              width: 35px;
            }
          }
        }
        .information {
          margin-top: 20px;
          border: 1px solid #47f0b1;
          padding: 20px;
          background: #121520;
          .content{
            white-space-collapse: preserve-breaks;
          }
          .information-box {
            display: flex;
            gap: 20px;
            font-size: 18px;
            margin-bottom: 10px;
            cursor: pointer;
            .description, .attribute{
              color: #8D8D8D;
            }
            .line-text {
              display: none;
            }
            .active {
              color: #4ef076;
              position: relative;
              .line-text {
                display: block;
                position: absolute;
                bottom: 0px;
                left: 50%;
                transform: translate(-50%);
              }
            }
          }
        }
      }
    }
  }
  .price{
    margin-bottom: 10px;
    img{
      width: 15px;
      margin-right: 5px;
    }
  }
`

export const TitleStyled = styled.div`
  font-size: 24px;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: 0px;
  color: #4ef076;
  display: flex;
  justify-content: center;
`

export const BtnBacktSyled = styled.div`
  .btn-back {
    width: fit-content;
    cursor: pointer;
    display: flex;
    .text-btn {
      font-size: 20px;
    }
  }
`

export const ButtonSyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
  flex-direction: column;
`
