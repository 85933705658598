import { useAppSelector } from 'hooks'
import { GUILD_ROLE, ISubMenu } from 'models'
import { Link, useLocation } from 'react-router-dom'
import SubMenuStyled from './styled'

interface SubMenuProps {
  subMenu: ISubMenu[]
}
export default function SubMenu({ subMenu }: SubMenuProps) {
  const { auth, profile } = useAppSelector(state => state)

  const { pathname } = useLocation()

  return (
    <SubMenuStyled>
      <div className='sub-menu'>
        <ul className='select-content-dropdown'>
          {subMenu &&
            subMenu.map((item: ISubMenu, index: number) => {
              // role not login
              if (item.isLogged === undefined && item.isGuild === undefined && item.isGuildMaster === undefined) {
                return (
                  <li key={index}>
                    <Link to={item.path} className={pathname === item.path ? 'active' : ''}>
                      {item.name}
                    </Link>
                  </li>
                )
              }
              // role nornal user
              if (auth.isLogged && item.isLogged === true && profile.role_in_guild === null && item.isGuild !== true) {
                return (
                  <li key={index}>
                    <Link to={item.path} className={pathname === item.path ? 'active' : ''}>
                      {item.name}
                    </Link>
                  </li>
                )
              }
              // role guild member + manager
              if (
                auth.isLogged &&
                item.isLogged === true &&
                item.isGuild !== false &&
                item.isGuildMaster !== true &&
                (profile.role_in_guild === GUILD_ROLE.MANAGER || profile.role_in_guild === GUILD_ROLE.MEMBER)
              ) {
                return (
                  <li key={index}>
                    <Link to={item.path} className={pathname === item.path ? 'active' : ''}>
                      {item.name}
                    </Link>
                  </li>
                )
              }
              if (auth.isLogged &&
                item.isLogged === true &&
                item.isGuild !== false &&
                item.path === "/guild/management/application" &&
                (profile.role_in_guild === GUILD_ROLE.MANAGER)) {
                return (
                  <li key={index}>
                    <Link to={item.path} className={pathname === item.path ? 'active' : ''}>
                      {item.name}
                    </Link>
                  </li>
                )
              }
              // role guild master
              if (auth.isLogged &&
                item.isLogged === true &&
                item.isGuild !== false &&
                (profile.role_in_guild === GUILD_ROLE.MASTER)) {
                return (
                  <li key={index}>
                    <Link to={item.path} className={pathname === item.path ? 'active' : ''}>
                      {item.name}
                    </Link>
                  </li>
                )
              }
              if (item.isGuild === true && item.isLogged === undefined && (profile.guild_id || !auth.isLogged)) {
                return (
                  <li key={index}>
                    <Link to={item.path} className={pathname === item.path ? 'active' : ''}>
                      {item.name}
                    </Link>
                  </li>
                )
              }

            })}
          <div className='polygon'>
            <div className='rect'></div>
            <div className='triangle'>
              <div className='triangle-border'></div>
            </div>
          </div>
        </ul>
      </div>
    </SubMenuStyled>
  )
}
