import styled from 'styled-components'

const EventHistoryDetailStyled = styled.div`
  .special-race {
    .line,
    .text {
      filter: hue-rotate(260deg);
    }
  }
  .special-name {
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .current-event-container {
    .btn-register {
      display: flex;
      justify-content: center;
    }
    .time-open-container {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 80px;
      .time-open {
        display: flex;
        align-items: center;
        flex-direction: column;
        .text-orange {
          color: #f1a533;
        }
      }
      .time {
        color: #f1a533;
        font-size: 50px;
      }
    }
    .current-event {
      padding-top: 20px;
      .tab-lend {
        width: 100%;
        display: flex;
        /* justify-content: space-between; */
        align-items: center;
        padding-bottom: 10px;
        .tab-block {
          display: flex;
          width: 100%;
        }
        .search-container {
          display: flex;
          align-items: center;
          gap: 20px;
        }
        display: flex;
        .tab-link {
          transition: 0.3s;
          width: 50%;
          height: 61px;
          font-size: 22px;
          color: white;
          cursor: pointer;
          opacity: 0.4;
          background: radial-gradient(70.81% 41.01% at 50.17% 99.66%, #1ded83 0%, rgba(35, 39, 33, 0.43) 100%),
            radial-gradient(219.74% 55.37% at 51.76% 0%, #00ff7d 0%, rgba(35, 39, 33, 0.4) 100%);
          .total-race {
            line-height: 25px;
          }
        }

        .active {
          opacity: 1;
        }
        .disabled:hover {
          opacity: 0.8;
        }
      }
    }

    .top-lending {
      display: flex;
      justify-content: space-between;
      padding-bottom: 20px;
      .search-horse-input {
        height: 34px;
      }
      .lending-search {
        display: flex;
        gap: 20px;
        .btn-profit {
          cursor: pointer;
        }
        .btn-profit:hover {
          filter: brightness(1.5);
        }
      }
      .lending-sort {
        width: 200px;
      }
    }
    .text-warning {
      font-size: 60px;
      position: absolute;
      left: 50%;
      top: 40%;
      transform: translate(-50%, 50%);
    }
  }
`

export default EventHistoryDetailStyled
