import { TOP_STABLE_BRONZE_MEDAL, TOP_STABLE_GOLD_MEDAL, TOP_STABLE_SILVER_MEDAL } from 'assets/images'
import RankCountContainer from './styled'

interface iprops {
  rank: number
}

const RankCount = ({ rank }: iprops) => {
  return (
    <RankCountContainer>
      {rank < 4 && rank > 0 && (
        <img
          src={
            (rank === 1 && TOP_STABLE_GOLD_MEDAL) ||
            (rank === 2 && TOP_STABLE_SILVER_MEDAL) ||
            (rank === 3 && TOP_STABLE_BRONZE_MEDAL) ||
            ''
          }
          className='left-stick'
        />
      )}
      <p>
        {!rank && '-'}
        {rank === 0 && `0`}
        {rank === 1 && `1st`}
        {rank === 2 && `2nd`}
        {rank === 3 && `3rd`}
        {rank > 3 && `${rank}th`}
      </p>
    </RankCountContainer>
  )
}

export default RankCount
