/* eslint-disable @typescript-eslint/no-explicit-any */
import { checkExistItem } from 'utils/getLinkImage'
import HorseImageContainer from './styled'

interface iprops {
  horse?: any
}

const typeRuntype = 'RUNTYPE'
const typeChar = 'CHARACTERISTIC'
const typeAll = 'CHARACTERISTIC_RUNTYPE'
const glassUrl = '/_horse/_avatar/_glass/g_'
const shoesUrl = '/_horse/_avatar/_shoes/sh_'
const armorUrl = '/_horse/_avatar/_armor/'
const skinUrl = '/_horse/_avatar/_skin/'
const domain = process.env.REACT_APP_ROOT_DOMAIN

const HorseAvatar = ({ horse }: iprops) => {
  const converToUtf8 = (value: string) => {
    return value.replace('#', '%23')
  }
  const horseColorCode = converToUtf8(horse?.bloodline?.color_code || horse?.blood_line?.color_code || '')

  const weapon = horse?.weapon_items_equipped
  const headItem = checkExistItem('HEAD', weapon)
  const bodyItem = checkExistItem('BODY', weapon)
  const footItem = checkExistItem('LEG', weapon)
  const skinItem = checkExistItem('SKIN', weapon)
  const fullURLGlass = domain + glassUrl + horse?.characteristic + '.png'
  const fullURLShoes = domain + shoesUrl + horse?.run_type?.code + '.png'
  const typeOfMaterial = bodyItem?.material_type?.toUpperCase()

  const fullURLBody = (typeOfMaterial: any) => {
    switch (typeOfMaterial) {
      case typeRuntype: {
        return domain + armorUrl + bodyItem?.model_name + '_' + horse?.run_type?.code + '.png'
      }
      case typeChar: {
        return domain + armorUrl + bodyItem?.model_name + '_' + horse?.characteristic + '.png'
      }
      case typeAll: {
        return domain + armorUrl + bodyItem?.model_name + '_' + horse?.characteristic + '_' + horse?.run_type?.code + '.png'
      }
      default:
        return domain + armorUrl + bodyItem?.model_name + '.png'
    }
  }

  const headtUrl = domain + armorUrl + headItem?.model_name + '_' + horse?.characteristic + '.png'

  const footUrl = domain + armorUrl + footItem?.model_name + '_' + horse?.run_type?.code + '.png'

  const fullSkinUrl = (hairColor: any) => {
    switch (hairColor) {
      case 'SAME_BODY': {
        return domain + skinUrl + skinItem?.model_name + '_' + horse?.hair_color + '_' + horseColorCode + '.png'
      }
      default:
        return domain + skinUrl + skinItem?.model_name + '_' + horse?.hair_color + '.png'
    }
  }
  
  return (
    <HorseImageContainer>
      <div className='background d-flex align-items-center justify-content-center'>
        <img src={horse?.avatar} alt={horse?.name} className='avatar' />

        {headItem && headItem?.model_name
          ? <img src={headtUrl} alt={headItem.name} className='item head' /> 
            : <img src={fullURLGlass} className="item glass" /> }

        {bodyItem && bodyItem?.model_name && <img src={fullURLBody(typeOfMaterial)} alt={bodyItem.name} className='item body' />}
  
        {footItem && footItem?.model_name
          ? <img src={footUrl} alt={footItem.name} className='item foot' />
          : <img src={fullURLShoes} className="item shoes" /> }

        {skinItem && skinItem?.model_name && <img src={fullSkinUrl(horse?.hair_color)} alt={skinItem.name} className='item skin' />}
      </div>
    </HorseImageContainer>
  )
}

export default HorseAvatar
