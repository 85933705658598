/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import MemoizedImageHorsePopupClose from 'shared/MemoizedImage/HorsePopupClose'
import { Modal } from 'shared'
import { LINE_TEXT } from 'assets/images'
import { convertTypeItems, getAttribute } from 'utils/helper'
import MemoizedImageRaratyCommon from 'shared/MemoizedImage/RaratyCommon'
import MemoizedImageRaratyEpic from 'shared/MemoizedImage/RaratyEpic'
import MemoizedImageRaratyLegendary from 'shared/MemoizedImage/RaratyLegendary'
import MemoizedImageRaratyMythic from 'shared/MemoizedImage/RaratyMythic'
import MemoizedImageRaratyRare from 'shared/MemoizedImage/RaratyRare'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import { HorseItemStyle } from './styled'
import { ShopTypeRariTy } from 'models'
import { Spin } from 'antd'

interface MProps {
  onClose: () => void
  horseItem?: string
  horseInfo?: any
  width?: any
  height?: any
}

const ModalHorseItem = ({ onClose, horseItem = '', horseInfo = '', width = 550, height = 390 }: MProps) => {
  const [itemDetails, setItemDetails] = useState<any>('') 
  const [loadingModal, setLoadingModal] = useState(true) 
  
  useEffect(() => {
    if (horseItem?.length) {
      horseInfo?.map((item: any) => {
        if (item?.body_part === horseItem) {
          setItemDetails(() => item)
        }
      })
    }
    setLoadingModal(() => false)
  }, [horseItem, horseInfo])

  const showIconRariTyCommon = (valueRariry: string) => {
    const handleKeySort = () => {
      switch (valueRariry) {
        case ShopTypeRariTy.ALL:
          return ''
        case ShopTypeRariTy.COMMON.toLocaleUpperCase(): {
          return <MemoizedImageRaratyCommon />
        }
        case ShopTypeRariTy.EPIC.toLocaleUpperCase(): {
          return <MemoizedImageRaratyEpic />
        }
        case ShopTypeRariTy.LEGENDARY.toLocaleUpperCase(): {
          return <MemoizedImageRaratyLegendary />
        }
        case ShopTypeRariTy.MYTHIC.toLocaleUpperCase(): {
          return <MemoizedImageRaratyMythic />
        }
        case ShopTypeRariTy.RARE.toLocaleUpperCase(): {
          return <MemoizedImageRaratyRare />
        }
        default:
          return valueRariry
      }
    }
    return handleKeySort()
  }
  
  return (
    <Modal onOverlayClick={onClose}>
      <HorseItemStyle width={width} height={height}>
        <button disabled={loadingModal} className='close-btn p-0 position-absolute' role='button' onClick={onClose}>
          <MemoizedImageHorsePopupClose />
        </button>
        {loadingModal ? (
          <div className='loading__item'>
            <Spin />
          </div>
        ) : (
          <div className='content-box'>
            <div className='box__info'>
              <div className='box__img'>
                { itemDetails?.img && <MemoizedLoadImageCommon srcImg={itemDetails?.img} /> }
              </div>
              <div className='box__info__content'>
                <div className='info__title'>{ itemDetails?.name }</div>
                <div>
                  <span className='info__type__left'>Type:</span>
                  <span>{convertTypeItems(itemDetails?.body_part) || 'No Information'}</span>
                </div>
                <div>
                  <span className='info__type__left'>Rarity:</span>
                  <span className='info__type__icon'>{ itemDetails?.rarity_type ? showIconRariTyCommon(itemDetails?.rarity_type) : '---' }</span>
                </div>
              </div>
            </div>

            <div className='information'>
              <div className='information-box'>
                <div
                  className='attribute active'
                >
                  Attribute
                    <img src={LINE_TEXT} alt='' className='line-text' />
                </div>
              </div>
              <div className='content mb-2'>
                  <p>
                    <span>{getAttribute(itemDetails?.effect) || 'No effect'}</span>
                  </p>
              </div>

              <div className='information-box'>
                <div
                  className='description active'
                >
                  Description
                  <img src={LINE_TEXT} alt='' className='line-text' />
                </div>
              </div>
              <div className='content mb-2'>
                {itemDetails?.description || 'No Information'}
              </div>
            </div>
          </div>
        )}
      </HorseItemStyle>
    </Modal>
  )
}

export default  ModalHorseItem
