import RateStarStyled from './styled'

interface RateStarItemsProps {
  isActive: boolean
  isUseDoping: boolean
}

function RateStarItems({ isActive, isUseDoping }: RateStarItemsProps) {
  return (
    <RateStarStyled isActive={isActive}>
      <div className={`rate-star w-100 h-100 ${isUseDoping ? 'item-doping' : 'nomal'} ${isActive ? 'enable' : 'disable'}`} />
    </RateStarStyled>
  )
}

export default RateStarItems
