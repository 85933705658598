import ButtonCustomStyled from './styled'

interface ButtonProps {
  onClickButton: (event: React.MouseEvent<HTMLElement>) => void
  buttonName: string | React.ReactNode
  disabled?: boolean
  width?: number
  btnCancel?: boolean
  isLoading?: boolean
  customClass?: string
  type?: string
}

function ButtonCustom({ onClickButton, buttonName, disabled, isLoading, customClass = '', type = 'default' }: ButtonProps) {

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (disabled || isLoading) return
    onClickButton(event)
  }

  const buttonType = () => {
    switch (type) {
      case 'default': return 'color-primary'
      case 'danger': return 'color-red'
      case 'warning': return 'color-orange'
      default: return 'primary'
    }
  }

  return (
    <ButtonCustomStyled>
      <div onClick={handleClick} className={disabled ? 'disabled' : ''}>
        <div className={`btn-container ${customClass} ${type} ${disabled ? 'disabled' : ''}`}>
          <span className={`font-bold ${buttonType()}`}>{buttonName}</span>
        </div>
      </div>
    </ButtonCustomStyled>
  )
}

export default ButtonCustom
