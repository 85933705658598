import styled from 'styled-components'

const ProgressCircleContainer = styled.div`
  position: relative;
  .progress-box {
  }

  .text-info {
    position: absolute;
    top: 22px;
    left: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 45px;

    .line-progress {
      background-color: #fff;
      height: 1px;
      width: 6px;
      margin: 3px;
    }

    .col {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      span {
        font-size: 16px;
        color: #989898;
        display: inline-block;
        line-height: 15px;
        &:last-child {
          font-size: 16px;
        }
        &.success {
          color: #05CFFF;
        }

        &.place {
          color: #eb00ff;
        }
      }
    }
  }

  .ant-progress-circle {
    .ant-progress-circle-path {
      &:last-child {
        stroke: #05CFFF !important;
      }
    }
  }
`

export default ProgressCircleContainer
