import { TWO_LINE } from 'assets/images'
import classNames from 'classnames'
import TwoLineTitleStyled from './styled'

interface TwoLineTitleProps {
  text: string
  customClass?: string
  classGuild?: string
  fontSize?: number
}

function TwoLineTitle24({ text, customClass = '', classGuild = '', fontSize = 24 }: TwoLineTitleProps) {
  const customClassTwoLine = classNames('text-color', customClass)

  return (
    <TwoLineTitleStyled className={customClassTwoLine} fontSize={fontSize}>
      <div className='text'>{text}</div>
      {text.length ? <img src={TWO_LINE} alt='' className={`line ${classGuild}`} /> : ''}
    </TwoLineTitleStyled>
  )
}

export default TwoLineTitle24
