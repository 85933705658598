import ErrorModalStyled from './styled'
import { Modal } from 'shared'
import { useTranslation } from 'react-i18next'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import { useNavigate } from 'react-router-dom'

interface ErrorModalProps {
  toggleIsModalOpen: (value: boolean) => void
  content?: string
  direction?: string
}

function ErrorModal({ toggleIsModalOpen, content, direction = '/' }: ErrorModalProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleCancelButtonClick = () => {
    toggleIsModalOpen(false)
    navigate(direction)
  }

  return (
    <Modal onOverlayClick={handleCancelButtonClick}>
      <ErrorModalStyled>
        <div className='request-login-modal d-flex flex-column justify-content-between'>
          <p className='color-white text-center font-size-20 mt-4'>{`${content}`}</p>
          <div className='btn-container d-flex justify-content-center'>
            <button className='cancel-btn' onClick={handleCancelButtonClick}>
              <span className='color-primary font-bold'>{t(`${NOTIFICATION_MESSAGE}.cancel`)}</span>
            </button>
          </div>
        </div>
      </ErrorModalStyled>
    </Modal>
  )
}

export default ErrorModal
