import React from 'react'
import styled from 'styled-components'
import { RARITY_MYTHIC } from "assets/images"

const ContentStyled = styled.div`
  height: 100%;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
  }
`

const MemoizedImageRaratyMythic = () => {
  return (
    <ContentStyled>
      <img className='icon-rarity' src={RARITY_MYTHIC} alt='' loading="lazy" />
    </ContentStyled>
  )
}
export default React.memo(MemoizedImageRaratyMythic) 
