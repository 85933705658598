import styled from "styled-components";

const VipIconContainer = styled.div`
  width: 32px;
  height: 34px;
  margin-left: 10px;

  &.custom {
    margin-left: 0;
    margin-top: 8px;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

export default VipIconContainer;