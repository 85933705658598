/* eslint-disable @typescript-eslint/no-explicit-any */

import { ItemNFT } from 'features/ShopAP/components'
import SellItemsStyled from './styled'
import SelectCustom from 'shared/SelectCustom'
import { SearchInput } from 'shared'
import { ItemShop, ItemShopsParams, ShopInventorySort, ShopTypeRariTy, TypeSorts, ShopTypeBodyPart } from 'models'
import { useDebounce, useAppSelector, useToggle } from 'hooks'
import { useState, useEffect } from 'react'
import { convertPluralText, handleAsyncRequest } from 'utils/helper'
import itemShopsApi from 'apis/itemShopsApi'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { constants } from 'apps'
import SkeletonList from 'features/components/SkeletonList'
import { notification } from 'antd'
import { RequestLoginModal } from 'features/Race/components'

export default function SellItems() {
  const { auth } = useAppSelector(state => state)
  const [searchValue, setSearchValue] = useState<string>('')
  const [searchType, setSearchType] = useState<string>('')
  const [searchParams] = useSearchParams()
  const paramsSearch: any= Object.fromEntries(searchParams.entries())
  const [params, setParams] = useState<ItemShopsParams>(paramsSearch)
  const [isLoading, setIsLoading] = useState(false)
  const [listItems, setListItems] = useState<ItemShop[]>([])
  const [total, setTotal] = useState(0)
  const [totalViewMore, setTotalViewMore] = useState(0)
  const debounceSearchValue = useDebounce<string>(searchValue, constants.DEBOUNCE_TIME)
  const [isComingSoon] = useState(false)
  const [isRequestLoginModalOpen, toggleIsRequestLoginModalOpen] = useToggle(false)
  const [api] = notification.useNotification()
  const handleKeySortFullName = (item: string) => {
    switch (item) {
      case '' :
        return ShopInventorySort.DEFAULT
      case paramsSearch.type === 'ENERGY' || paramsSearch.type === 'DOPING' ? 'boost_name' : 'item_name':
        return ShopInventorySort.ASC
      case paramsSearch.type === 'ENERGY' || paramsSearch.type === 'DOPING' ? 'boost_name-desc' : 'item_name-desc':
        return ShopInventorySort.DESC
      case 'price' :
        return ShopInventorySort.LOWEST
      case 'price-desc':
        return ShopInventorySort.HIGHEST
      default:
        return ''
    }
  }
  const handleKeySortRariTy = (item: string) => {
    switch (item) {
      case ShopTypeRariTy.ALL:
        return 'All'
      case 'COMMON':
        return ShopTypeRariTy.COMMON
      case 'EPIC' :
        return ShopTypeRariTy.EPIC
      case 'LEGENDARY' :
        return ShopTypeRariTy.LEGENDARY
      case 'MYTHIC' :
        return ShopTypeRariTy.MYTHIC
      case 'RARE' :
        return ShopTypeRariTy.RARE
      default:
        return 'All'
    }
  }
  const [sortSelect, setSortSelect] = useState(handleKeySortFullName(params?.sort || ''))
  const [classSelectAll, setClassSelectAll] = useState(handleKeySortRariTy(params?.rarity || 'All'))

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const onItemClick = (id: string) => {
    if (!auth.isLogged) {
      localStorage.clear()
      location.reload()
      return
    } else {
      navigate(`${pathname}/${id}`)
    }
  }

  const openNotification = (message: string, description: string) => {
    notification.config({
      duration: 4
    })
    api.info({
      message,
      description,
      placement: 'top',
      className: message === 'ERROR' ? 'ant-custom-error' : 'ant-custom-success'
    })
  }

  const fetchListItems = async () => {
    setIsLoading(true)
    const [error, result]: any  = await handleAsyncRequest(itemShopsApi.getItemShops({ ...params, yourItems: true }))
    const records = result?.data.records
    setListItems(params.page > 1 ? [...listItems, ...records] : records)
    setTotal(result?.data.total)
    setIsLoading(false)
    if (error) {
      setListItems([])
      if (error?.code === 403) {
        toggleIsRequestLoginModalOpen()
        setIsLoading(false)
        return
      }
      openNotification('ERROR', error.message || error.errors[0]?.message)
    }
  }
  const renderQueries = (query : any) => {
    let index = 0;
    let queryText = `?type=${paramsSearch.type}`;
    for (const property in query) {
      if (query[property]) {
        queryText += `${index ? "&" : "&"}${property}=${query[property]}`;
        index++;
      }
    }
    return queryText;
  }
  useEffect(() => {
    if (params.bodyPart || params.boostType) {
      if (params?.limit) {
        fetchListItems()
      }
      const replaceNavigate = renderQueries(params as ItemShopsParams)
      navigate(
        `/shop/sell_items${replaceNavigate}`
      )
    }
  }, [params])

  useEffect(() => {
    setParams({ ...params, search: debounceSearchValue })
  }, [debounceSearchValue])

  const typePart = searchParams.get("type");
  
  const converBodyPart = (typePart: any) => {
    const handleKey = () => {
      switch (typePart) {
        case ShopTypeBodyPart.SELLING_HEAD:
          return 'HEAD'
        case ShopTypeBodyPart.SELLING_BODY:
          return 'BODY'
        case ShopTypeBodyPart.SELLING_LEG:
          return 'LEG'
        case ShopTypeBodyPart.SELLING_SKIN:
          return 'SKIN'
        default:
          return ''
      }
    }
    setSearchType(handleKey())
    setParams({ limit: 20, page: 1, bodyPart: handleKey() })
  }

  const defaultTypeBuy: TypeSorts = [
    {
      name: ShopTypeRariTy.ALL,
      isActive: false
    },
    {
      name: ShopTypeRariTy.COMMON,
      isActive: false
    },
    {
      name: ShopTypeRariTy.RARE,
      isActive: false
    },
    {
      name: ShopTypeRariTy.EPIC,
      isActive: false
    },
    {
      name: ShopTypeRariTy.LEGENDARY,
      isActive: false
    },
    {
      name: ShopTypeRariTy.MYTHIC,
      isActive: false
    },
  ]

  const defaultTypeFilters: TypeSorts = [
    {
      name: ShopInventorySort.DEFAULT,
      isActive: false
    },
    {
      name: ShopInventorySort.LOWEST,
      isActive: false
    },
    {
      name: ShopInventorySort.HIGHEST,
      isActive: false
    },
    {
      name: ShopInventorySort.ASC,
      isActive: false
    },
    {
      name: ShopInventorySort.DESC,
      isActive: false
    }
  ]

  const handleSortClicked = (item: string) => {
    const handleKeySort = () => {
      switch (item) {
        case ShopInventorySort.DEFAULT:
          return ''
        case ShopInventorySort.ASC:
          return paramsSearch.type === 'ENERGY' || paramsSearch.type === 'DOPING' ? 'boost_name' : 'item_name'
        case ShopInventorySort.DESC:
          return paramsSearch.type === 'ENERGY' || paramsSearch.type === 'DOPING' ? 'boost_name-desc' : 'item_name-desc'
        case ShopInventorySort.LOWEST:
          return 'price'
        case ShopInventorySort.HIGHEST:
          return 'price-desc'
        default:
          return ''
      }
    }
    setParams({
      ...params,
      page: 1,
      sort: handleKeySort()
    })
    setSortSelect(item)
  }

  const handleClickedTypeRarity = (item: string) => {
    const handleKeySort = () => {
      switch (item) {
        case ShopTypeRariTy.ALL:
          return ''
        case ShopTypeRariTy.COMMON:
          return 'COMMON'
        case ShopTypeRariTy.EPIC:
          return 'EPIC'
        case ShopTypeRariTy.LEGENDARY:
          return 'LEGENDARY'
        case ShopTypeRariTy.MYTHIC:
          return 'MYTHIC'
        case ShopTypeRariTy.RARE:
          return 'RARE'
        default:
          return 'ALL'
      }
    }
    setParams({
      ...params,
      page: 1,
      rarity: handleKeySort()
    })
    setClassSelectAll(item)
  }
  useEffect(() => {
    converBodyPart(typePart)
  }, [typePart])

  useEffect(() => {
      setParams({ 
        limit: 20, 
        page: params?.page, 
        bodyPart: searchType,
        rarity: params.rarity || '',
        sort: params.sort || '',
        search: params.search || '',
      })
      setSearchValue( params?.search || '')
  }, [searchType])


  const handleSearchValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const hanldeViewMore = () => {
    setParams({ ...params, page: params.page + 1 })
  }

  useEffect(() => {
    if (total > listItems?.length) {
      setTotalViewMore(() => total - (listItems?.length || 0))
    } else {
      setTotalViewMore(() => 0)
    }
  }, [listItems, total])

  return (
    <SellItemsStyled>
      <div className='container'>
        <div className='buy-top'>
          <div className='total-items'>
            <span>{convertPluralText(total, 'item')}</span>
          </div>
          <div className='filter-items'>
            <SelectCustom
              dataSelect={defaultTypeBuy}
              nameSelect={classSelectAll}
              onSelected={handleClickedTypeRarity}
            />
            <SelectCustom dataSelect={defaultTypeFilters} nameSelect={sortSelect} onSelected={handleSortClicked} />
            <SearchInput
              searchValue={searchValue}
              handleSearchValueChanged={handleSearchValueChanged}
              customClass='search-horse-input'
              placeholder={'Search...'}
              width={174}
            />
          </div>
        </div>
        {isComingSoon ? (
          <h4 className=' is-comming color-white text-uppercase d-flex justify-content-center align-items-center'>Coming Soon</h4>
        ) : (
          <>
            {isLoading ? <SkeletonList rows={4} /> :
              total === 0 ?
                <div className='no-data'> NO ITEMS AVAILABLE </div>
                :
                <>
                  <div className='items'>
                    {listItems?.map((item: ItemShop) => (
                      <ItemNFT
                        key={item?.id}
                        itemName={item?.item?.name || item?.boost?.name}
                        price={item?.price}
                        onClick={onItemClick}
                        id={item?.id}
                        avt={item?.item?.img || item?.boost?.img}
                        type={item?.item?.body_part || item?.boost?.boost_type}
                        currency={item?.currency}
                        level={item?.boost?.level}
                      />
                    ))}
                  </div>
                  {totalViewMore && listItems?.length > 0 ? (
                    <div className='color-white view-more-container mt-5 mb-5'>
                      <button className='btn-view-more' onClick={hanldeViewMore}>
                        <span className='btn-view-more-text'>View More {totalViewMore} Item{totalViewMore === 1 ? '' : 's'} </span>
                      </button>
                    </div>
                  ) : ('')
                  }
                </>
              }
          </>
        )}
      </div>
      {isRequestLoginModalOpen && <RequestLoginModal toggleIsModalOpen={toggleIsRequestLoginModalOpen} />}
    </SellItemsStyled>
  )
}
