/* eslint-disable @typescript-eslint/no-explicit-any */
import { notification } from 'antd'
import guildApi from 'apis/guildApi'
import ModalManagerMember from 'features/Guild/components/ModalManagerMember'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import Button from 'shared/Button'
import { handleAsyncRequest } from 'utils/helper'
import PromoteMemberContainer from './styled'

const PromoteMember = ({ upgradeSuccess }: any) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [api, contextHolder] = notification.useNotification()

  const { guild_id, user_id } = useParams()

  const openNotification = (message: string, description: string) => {
    notification.config({
      duration: 4
    })

    api.info({
      message,
      description,
      placement: 'bottomRight',
      className: message === 'ERROR' ? 'ant-custom-error' : 'ant-custom-success'
    })
  }

  const _handleToggleModal = () => {
    setShowModal(!showModal)
  }

  const _handleUpgrade = async () => {
    setIsLoading(true)

    const [error, result]: any = await handleAsyncRequest(guildApi.putRoleUserGuild(Number(guild_id), Number(user_id), { role: 'MANAGER' }));
    setIsLoading(false);
    if (error) {
      openNotification('ERROR', error.message || error.errors[0]?.message)
    }

    if (result) {
      _handleToggleModal()
      upgradeSuccess('MANAGER')
    }
  }

  return (
    <PromoteMemberContainer>
      {contextHolder}
      <Button
        buttonName='Promote to manager'
        onClickButton={_handleToggleModal}
        height={42}
        width={180}
      />
      {showModal && (
        <ModalManagerMember
          title='Upgrade guild role'
          message={<p>Would you like to upgrade this member to a <br /> manager position?</p>}
          onClose={_handleToggleModal}
          onConfirm={_handleUpgrade}
          loading={isLoading}
          titleColor="rgb(78, 240, 118)"
        />
      )}
    </PromoteMemberContainer>
  )
}

export default PromoteMember
