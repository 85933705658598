import styled from 'styled-components'

const GuildFarmStyled = styled.div`
  width: 100%;
  .guild-header {
    display: flex;
    justify-content: end;
    gap: 20px;
    margin-bottom: 20px;
  }
  --bs-body-bg: transparent;
`

export default GuildFarmStyled
