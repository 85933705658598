import styled from 'styled-components'

const MyHorsesContainer = styled.div`
  width: 100%;
  .head {
    position: relative;
    margin-bottom: 50px;

    h3 {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #4EF076;
      text-transform: uppercase;
      margin-bottom: 0;
      flex-direction: column;
      font-size: 32px;

      img {
        width: 200px;
      }
    }
  }

  .button-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 23px;

    span {
      text-transform: uppercase;
      font-size: 18px;
    }
  }

  .search-box {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 20px;
    margin-bottom: 20px;
  }
`

export default MyHorsesContainer
