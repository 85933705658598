import { DELETE_MAIL_ITEM } from 'assets/images'
import { UserNoticationtResponse } from 'models/mailbox'
import { capitalizeOnlyFirstLetter, convertDateTimeMailLocal, shortenRaceMailContent } from 'utils/helper'
import { convertFullLongDate } from 'utils/time'
import { NUMBER } from 'apps/constants'
import MailBoxItemReadStyled from './styled'

interface MailBoxItemReadProps {
  listMailBoxItemRead: UserNoticationtResponse
  openMailItemModal?: boolean
  closeHorseModal?: () => void
  handleOpenModalMailItem?: () => void
  onDeleteMailItem: () => void
}

function MailBoxItemRead({ listMailBoxItemRead, handleOpenModalMailItem, onDeleteMailItem }: MailBoxItemReadProps) {

  return (
    <MailBoxItemReadStyled>
      <td className={`mail-container ${listMailBoxItemRead.seen ? '' : 'mail-viewed'}`}>
        <div onClick={handleOpenModalMailItem} className='block-title-content'>
          <div className='color-white title font-bold title'>
            <span>{(listMailBoxItemRead.title).toLocaleUpperCase()}</span>
          </div>
          <div className='color-white content'>
            <div
              className='content-mail-item '
              dangerouslySetInnerHTML={{
                __html: shortenRaceMailContent(capitalizeOnlyFirstLetter(convertDateTimeMailLocal(listMailBoxItemRead.content)))
              }}
            />
          </div>
        </div>

        <div className='time-mail color-white'>
          {convertFullLongDate(listMailBoxItemRead.created * NUMBER.THOUSAND)}
        </div>
        <div className='color-white delete-mail-item'>
          <img src={DELETE_MAIL_ITEM} alt='' className='delete-item' onClick={onDeleteMailItem} />
        </div>
      </td>
    </MailBoxItemReadStyled>
  )
}

export default MailBoxItemRead
