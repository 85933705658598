/* eslint-disable @typescript-eslint/no-explicit-any */
import ItemListInfomationStyled from './styled'
import { useEffect, useState, useMemo } from 'react'

import { constants, links } from 'apps'
import { Link } from 'react-router-dom'
import { ICON_STORE, LINE_BOT } from 'assets/images'
import { SearchInput } from 'shared'
import { useDebounce, useToggle, useUpdateEffect } from 'hooks'
import { handleAsyncRequest } from 'utils/helper'
import itemApi from 'apis/itemApi'
import ItemBoxDetail from 'features/Horse/components/HorseItem/ItemBoxDetail'
import { Spin } from 'antd'

export default function ItemInformation() {
  const itemsParams = {
    limit: 30,
    page: 1,
    search: ''
  }
  const [searchValue, setSearchValue] = useState<string>('')
  const debounceSearchValue = useDebounce<string>(searchValue, constants.DEBOUNCE_TIME)
  const [tabActive, setTabActive] = useState('HEAD')
  // const [isModalOpen, toggleIsModalOpen] = useToggle(false)
  const [isLoading, setIsLoading] = useToggle(false)
  const [weaponsItems, setWeaponsItems] = useState<any[]>([])
  const [params, setParams] = useState(itemsParams)
  const [total, setTotal] = useState(0)

  const fetchWeaponsItems = async (type: string) => {
    setIsLoading(true)
    const [, result] = await handleAsyncRequest(itemApi.getItemsWeapons({ ...params, type, page: params.page }))
    if (result) {
      const records = result?.data.records
      setWeaponsItems(params.page > 1 ? [...weaponsItems, ...records] : records)
      setTotal(result?.data?.total)
    }
    setIsLoading(false)
  }
  const fetchDopingItems = async (type: string) => {
    setIsLoading(true)
    const [, result] = await handleAsyncRequest(
      itemApi.getBoostItems({ ...params, boostType: type, page: params.page })
    )
    if (result) {
      const records = result?.data.records
      setWeaponsItems(params.page > 1 ? [...weaponsItems, ...records] : records)
      setTotal(result?.data?.total)
    }
    setIsLoading(false)
  }

  useUpdateEffect(() => {
    setParams({ ...params, search: debounceSearchValue })
  }, [debounceSearchValue])

  useEffect(() => {
    if (tabActive === 'DOPING' || tabActive === 'ENERGY') {
      fetchDopingItems(tabActive)
    } else fetchWeaponsItems(tabActive)
  }, [params])

  const listTabs = ['HEAD', 'BODY', 'LEG', 'SKIN', 'DOPING', 'ENERGY']

  // const handleSelectItem = (value: string) => () => {
  //   setItemSelected(value)
  //   toggleIsModalOpen(true)
  //   // return toggleIsModalOpen(true)
  // };

  const handleClickTab = (value: string) => () => {
    if (value === tabActive) return
    setTabActive(value)
    setParams({ ...params, page: 1 })
  }

  const displayListTabs = listTabs.map(tab => (
    <div
      className={`tab-link text-center ${tabActive === tab ? 'active' : 'disabled'}`}
      onClick={handleClickTab(tab)}
      key={tab}
    >
      <div className='total-race color-white'>{tab}</div>
    </div>
  ))

  const hanleTotalViewMore = () => {
    return total && total - ((weaponsItems && weaponsItems?.length) || 0)
  }

  const onViewMore = () => {
    setParams({ ...params, page: params.page + 1 })
  }

  const listItems = weaponsItems?.map(item => (
    <div className='item-list' key={item.id}>
      <ItemBoxDetail
        itemDetails={item}
        type={tabActive}
        img={item.img ?? item.boost.img}
        amount={item.amount}
        level={item?.boost?.level}
        name={item?.name ?? item?.boost?.name}
      />
    </div>
  ))

  const layoutItems = useMemo(() => listItems, [weaponsItems])

  const handleSearchValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  // const handleCloseModal = () => {
  //   return toggleIsModalOpen(false)
  // }
  // const handleConfirmModal = async () => {
  //   return toggleIsModalOpen(false)
  // }

  return (
    <ItemListInfomationStyled>
      <div className='my-item'>
        <div className='nav'>
          <Link className='color-white title' to={links.profile.myProfile()}>
            MY HORSES
          </Link>
          <Link className='color-white title item' to={links.profile.information()}>
            My Items
            <img src={LINE_BOT} className='active' alt='' />
          </Link>
        </div>
        <div className='search-box'>
          <SearchInput
            onAutoFocus={true}
            searchValue={searchValue}
            handleSearchValueChanged={handleSearchValueChanged}
            customClass='search-input'
          />
        </div>
      </div>
      <div className='body-list-item'>
        <div className='tab-block d-flex'>
          {displayListTabs}
          <div className='store-item'>
            <Link to={`${links.shop.buyItems()}?type=HEAD`}>
              <img src={ICON_STORE} alt='' />
            </Link>
          </div>
        </div>
        <div className='tab-container d-flex justify-content-start infinite-scroll-component'>
          {isLoading ? (
            <Spin />
          ) : weaponsItems?.length != 0 ? (
            <>
              {layoutItems}
              {hanleTotalViewMore() > 0 && (
                <div className='color-white view-more-container' onClick={onViewMore}>
                  <button className='btn-view-more'>
                    <span className='btn-view-more-text'>View More {hanleTotalViewMore()} Horses </span>
                  </button>
                </div>
              )}
            </>
          ) : (
            <div className='no-data'>You have no item to use</div>
          )}
        </div>
      </div>
      {/* {isModalOpen &&
        <HourseItemModalDetail onConfirmUseItem={handleConfirmModal} onCloseButtonClick={handleCloseModal} />} */}
    </ItemListInfomationStyled>
  )
}
