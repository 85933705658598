import styled from 'styled-components'

const ManagementUserBlacklistStyled = styled.div`
  width: 100%;

  .search-box {
    display: flex;
    /* justify-content: flex-end; */
    align-items: center;
    justify-content: end;
    gap: 20px;
    margin-bottom: 20px;
  }
`

export default ManagementUserBlacklistStyled
