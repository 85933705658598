import styled from 'styled-components'

interface StyledProps {
  colorHorse: string
}

const HorseBodyInfoStyled = styled.div<StyledProps>`
  width: 100%;
  .right-body {
    .attribute-container {
      .value {
        font-size: 18px;
      }

      .attribute-box {
        padding: 10px;
      }
    }
    .career-win-rates {
      padding-top: 30px;
    }

    .stats-body-container {
      padding: 20px 0px;

      .stats-type {
        font-size: 20px;
      }
      .stats-container {
        .title-stats {
          font-size: 24px;
        }
      }
    }
    .ability-container {
      padding-top: 20px;
      .ability-box-container {
        .ability-box {
          .title {
            font-size: 20px;
          }
        }
      }
    }
  }

  .horse-detail {
    .stats-container  {
      .quick-stats-box {
        .content {
          justify-content: space-between;
        }
      }
    }
  }

  .box__bloodline {
    display: flex;
    align-items: center;

    .image_bloodline {
      height: 80px;
      margin-right: 20px;
    }

    .image_run_type {
      height: 40px;
      margin-right: 20px;
    }

    .image_color {
      display: block;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      background-color: ${({ colorHorse }) => colorHorse};
      border: 1px solid #fff;
    }
  }  
`
export default HorseBodyInfoStyled
