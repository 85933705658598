/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next'
import { GUILD_MESSAGE } from 'i18n/constants'
import { CLOSE_BTN } from 'assets/images'
import { Modal } from 'shared'
import Button from 'shared/Button'
import { ModalConfirmCommon } from './styled'

interface MProps {
  onClose: () => void
  message?: any
  title?: string
  widthM?: number;
  heightM?: number;
}

const ModalWarning = ({ 
  onClose,
  message,
  title,
  widthM = 515,
  heightM = 274
}: MProps) => {
  const { t } = useTranslation()
  return (
    <Modal>
      <ModalConfirmCommon widthM={widthM} heightM={heightM}>
        <div className='header'>
          <p className='title'>
            <span className={`title`}>{title}</span>
          </p>
        </div>
        <button className='close-btn p-0 position-absolute' role='button' onClick={onClose}>
          <img src={CLOSE_BTN} alt='close' />
        </button>
        <div className='content-box'>
          <p>{message}</p>
        </div>
        <div className='button-box'>
          <Button buttonName={t(`${GUILD_MESSAGE}.ok`)} onClickButton={onClose} width={100} height={35} type="btnCancel"/>
        </div>
      </ModalConfirmCommon>
    </Modal>
  )
}

export default ModalWarning
