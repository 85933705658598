import styled from 'styled-components'

const LoadingContainer = styled.div`
  .ant-space {
    gap: 0;
    display: flex;
    flex-direction: column;

    .ant-space-item {
      width: 100%;

      .ant-skeleton-element {
        width: 100%;
        height: 35px;

        margin-bottom: 5px;

        .ant-skeleton-input {
          height: 100%;
          background-color: rgb(37, 43, 67);
        }
      }
    }
  }

  .ant-spin-dot-item {
    background-color: #4ef076 !important;
  }
`

export default LoadingContainer
