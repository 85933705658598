/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spin } from 'antd'
import guildApi from 'apis/guildApi'
import { links } from 'apps'
import { ARROW_RIGHT_ICON, TWO_LINE } from 'assets/images'
import { TopHorse } from 'models/horse'
import { useEffect, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { handleAsyncRequest, moveIndexHorse } from 'utils/helper'
import TopGuild from '../../../components/TopGuild'
import TopMembersContainer from '../top-members/styled'

interface guildProps {
  guildId: number
}

const options = {
  scale: 1.2,
  speed: 1000,
  max: 30
}

const TopHorsese = ({ guildId }: guildProps) => {
  const { guild_id } = useParams()
  const [data, setData] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const memoizedTopHorseList = useMemo<TopHorse[]>(() => moveIndexHorse(data), [data])

  useEffect(() => {
    guildId && getTopHorses()
  }, [])

  const getTopHorses = async () => {
    const [error, result]: any = await handleAsyncRequest(guildApi.getTopHorses(guildId))

    if (error) {
      setLoading(false)
    }

    if (result) {
      setData(result.data)
    }
  }

  const horseLength = memoizedTopHorseList?.length

  return (
    <TopMembersContainer>
      <div className='head'>
        <h3>
          Top horse
          <img src={TWO_LINE} alt='line' />
        </h3>

        <Link to={guild_id ? links.guild.guildFarmId(guild_id) : links.guild.guildFarm()}>
          Guild horse list
          <img src={ARROW_RIGHT_ICON} alt='arow' className='link' />
        </Link>
      </div>

      {loading ? (
        <Spin />
      ) : (
        <div className={`content-box ${horseLength === 1 ? 'top-one' : ''}`}>
          {memoizedTopHorseList.map((horse: any, index: any) => (
            <TopGuild key={horse?.id} horse={horse} customClass='col-12 col-sm-4' selfIndex={horseLength === 1 ? 1 : index} options={options} />
          ))}
        </div>
      )}
    </TopMembersContainer>
  )
}

export default TopHorsese
