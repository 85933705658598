import styled from 'styled-components'

const HorseOwnerStyled = styled.div`
  width: 100%;
  .search-box {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 20px;
    margin-bottom: 20px;
  }
`

export default HorseOwnerStyled
