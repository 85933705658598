/* eslint-disable @typescript-eslint/no-explicit-any */
import ProgressCircle from '../ProgressCircle'
import ProgressCircleV2 from '../ProgressCircleV2'
import GuildInfoContainer from './styled'

interface PProps {
  career: {
    total_number_of_races: number
    first_count: number
    second_count: number
    third_count: number
    win_count?: number
    total_horse_in_guild: number
    total_horse_owner_in_guild: number
  }
  isTopOwners?: boolean
  className?: any
}

const GuildInfo = ({ career, isTopOwners, className }: PProps) => {
  return (
    <GuildInfoContainer className={className}>
      <div className='left-info'>
        {isTopOwners ? (
          <>
            <div className='you-horse'>
              <span>Total horse</span>
              <span>{career?.total_horse_in_guild}</span>
            </div>
            <div className='you-horse'>
              <span>You horse</span>
              <span>{career?.total_horse_owner_in_guild}</span>
            </div>
          </>
        ) : (
          <>
            <div className='row-info total'>
              <span>Total</span>
              <span>{career?.total_number_of_races}</span>
            </div>
            <div className='row-info'>
              <span>1st</span>
              <span>{career?.first_count}</span>
            </div>
            <div className='row-info'>
              <span>2nd</span>
              <span>{career?.second_count}</span>
            </div>
            <div className='row-info'>
              <span>3rd</span>
              <span>{career?.third_count}</span>
            </div>
          </>
        )}
      </div>
      {isTopOwners ? <ProgressCircleV2 career={career} /> : <ProgressCircle career={career} />}
    </GuildInfoContainer>
  )
}

export default GuildInfo
