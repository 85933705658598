/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react'
import { useToggle } from 'hooks'
import MemoizedImageHorseArmorHead from 'shared/MemoizedImage/HorseArmorHead'
import MemoizedImageHorseArmorHeadOff from 'shared/MemoizedImage/HorseArmorHeadOff'
import MemoizedImageHorseArmorBody from 'shared/MemoizedImage/HorseArmorBody'
import MemoizedImageHorseArmorBodyOff from 'shared/MemoizedImage/HorseArmorBodyOff'
import MemoizedImageHorseArmorLeg from 'shared/MemoizedImage/HorseArmorLeg'
import MemoizedImageHorseArmorLegOff from 'shared/MemoizedImage/HorseArmorLegOff'
import MemoizedImageHorseArmorSkin from 'shared/MemoizedImage/HorseArmorSkin'
import MemoizedImageHorseArmorSkinOff from 'shared/MemoizedImage/HorseArmorSkinOff'
import MemoizedImageHorseArmorLine from 'shared/MemoizedImage/HorseArmorLine'
import { STRING_ARMOR_SKIN } from 'apps/constants'
import ModalHorseItem from '../ModalHorseItem'
import ImageArmorSkinContainer from "./styled"

interface RaceTypeProps {
  horseHead?: string
  horseBody?: string
  horseLeg?: string
  horseSkin?: string
  weaponItemsEquipped?: any
  width?: number
  height?: number
}

function ImageArmorSkin({
  horseHead = '',
  horseBody = '',
  horseLeg = '',
  horseSkin = '',
  weaponItemsEquipped = [],
  width =36,
  height = 36
}: RaceTypeProps) {
  const [openHorseItemModal, toggleOpenHorseItemModal] = useToggle(false)
  const [horseItem, setHorseItem] = useState('')
  const [horseInfo, setHorseInfo] = useState([])

  const _handleClickImage = (event: React.MouseEvent, type: string, weaponItemsEquipped: any, enableClick: boolean) => {
    event.stopPropagation()
    if (enableClick) {
      return
    }
    setHorseItem(() => type)
    setHorseInfo(() => weaponItemsEquipped)
    toggleOpenHorseItemModal(true)
  }

  const _handleClosePopup = () => {
    setHorseItem(() => '')
    toggleOpenHorseItemModal(false)
  }

  const renderArmorSkin = (horseHead: string, horseBody: string, horseLeg: string, horseSkin: string, weaponItemsEquipped: any) => {
    return <div className="contain__img_armor_skin">
        <div className='img_armor_skin'>
          {horseHead ?
            <div
              data-value='child'
              onClick={(event) => _handleClickImage(event, STRING_ARMOR_SKIN.HEAD, weaponItemsEquipped, false)}
              className='pointer_armor_skin'
            >
              <MemoizedImageHorseArmorHead />
            </div>
            : <div
                data-value='child'
                onClick={(event) => _handleClickImage(event, STRING_ARMOR_SKIN.HEAD, weaponItemsEquipped, true)}
                className='pointer_armor_skin'
              >
                <MemoizedImageHorseArmorHeadOff />
              </div>}
        </div>
        <div className='img_armor_skin mg__left_5'>
          {horseBody ?
            <div
              data-value='child'
              onClick={(event) => _handleClickImage(event, STRING_ARMOR_SKIN.BODY, weaponItemsEquipped, false)}
              className='pointer_armor_skin'
            >
              <MemoizedImageHorseArmorBody />
            </div>
            :  <div
                data-value='child'
                onClick={(event) => _handleClickImage(event, STRING_ARMOR_SKIN.BODY, weaponItemsEquipped, true)}
                className='pointer_armor_skin'
              >
                <MemoizedImageHorseArmorBodyOff />
              </div>}
        </div>
        <div className='img_armor_skin mg__left_5'>
          {horseLeg ?
            <div
              data-value='child'
              onClick={(event) => _handleClickImage(event, STRING_ARMOR_SKIN.LEG, weaponItemsEquipped, false)}
              className='pointer_armor_skin'
            >
              <MemoizedImageHorseArmorLeg />
            </div>
            : <div
                data-value='child'
                onClick={(event) => _handleClickImage(event, STRING_ARMOR_SKIN.LEG, weaponItemsEquipped, true)}
                className='pointer_armor_skin'
              >
                <MemoizedImageHorseArmorLegOff />
              </div>}
        </div>
        <div className='img__line mg__left_5'>
          <MemoizedImageHorseArmorLine />
        </div>
        <div className='img_armor_skin mg__left_5'>
          {horseSkin ?
            <div
              data-value='child'
              onClick={(event) => _handleClickImage(event, STRING_ARMOR_SKIN.SKIN, weaponItemsEquipped, false)}
              className='pointer_armor_skin'
            >
              <MemoizedImageHorseArmorSkin />
            </div>
            : <div
                data-value='child'
                onClick={(event) => _handleClickImage(event, STRING_ARMOR_SKIN.SKIN, weaponItemsEquipped, true)}
                className='pointer_armor_skin'
              >
                <MemoizedImageHorseArmorSkinOff />
              </div>}
        </div>
      </div>
  }

  return (
    <ImageArmorSkinContainer
      width={width}
      height={height}
    >
      {renderArmorSkin(horseHead, horseBody, horseLeg, horseSkin, weaponItemsEquipped)}

      {openHorseItemModal && (
        <ModalHorseItem
          horseItem={horseItem}
          horseInfo={horseInfo}
          onClose={_handleClosePopup}
        />
      )}
    </ImageArmorSkinContainer>
  )
}

export default ImageArmorSkin