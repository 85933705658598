/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Spin, notification } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import guildApi from 'apis/guildApi'
import links from 'apps/links'
import ModalConfirmLeaveGuild from 'features/Guild/components/ModalConfirmLeaveGuild'
import { setCurrentUser } from 'features/Profile/profile.slice'
import useAppSelector from 'hooks/useAppSelector'
import Button from 'shared/Button'
import { handleAsyncRequest } from 'utils/helper'
import FormIncome, { dataProps } from './FormIncome'
import { ManagerStyled } from './styled'
import { resetGuildId } from 'features/Profile/profile.slice'
import { RETANGLE } from 'assets/images'

export default function InformationDetail() {
  const [api, contextHolder] = notification.useNotification()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [guildData, setGuildData] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [showModalLeaveGuild, setShowModalLeaveGuild] = useState<boolean>(false)
  const [loadingLeave, setLoadingLeave] = useState<boolean>(false)
  const [showModalEditIcome, setShowModalEditIncome] = useState<boolean>(false)
  const [dataIncomeEdit, setDataIncomeEdit] = useState<any>(null)
  const [loadingUpdateIncome, setLoadingUpdateIncome] = useState<boolean>(false)
  const { profile } = useAppSelector(state => state)

  useEffect(() => {
    getManagerData()
  }, [profile])

  const openNotification = (message: string, description: string) => {
    notification.config({
      duration: 4
    })

    api.info({
      message,
      description,
      placement: 'top',
      className: message === 'ERROR' ? 'ant-custom-error' : 'ant-custom-success'
    })
  }

  const getManagerData = async () => {
    const [error, result]: any = await handleAsyncRequest(guildApi.getGuildInfomation())

    if (error) {
      navigate(links.guild.joinGuild())
    }

    if (result) {
      setGuildData(result.data)
    }
    setLoading(false)
  }

  const handleEdit = (value: any) => {
    const { profitGuild, profitOwner, profitRenter } = value
    const totalPercent = +profitGuild + +profitOwner + +profitRenter + +guildData?.profit_system
    if (totalPercent !== 100) {
      openNotification('ERROR', 'The total profit percentage is 100 !')
      return
    }

    setDataIncomeEdit(value)
    setShowModalEditIncome(!showModalEditIcome)
  }

  const handleConfirmEdit = async () => {
    setLoadingUpdateIncome(true)
    const { profitGuild, profitOwner, profitRenter } = dataIncomeEdit

    const payload: dataProps = {
      profit_guild: +profitGuild,
      profit_owner: +profitOwner,
      profit_renter: +profitRenter
    }
    const [error, result]: any = await handleAsyncRequest(guildApi.editProfitGuild(profile?.guild_id, payload))
    setLoadingUpdateIncome(false)

    if (error) {
      openNotification('ERROR', error.message || error.errors[0]?.message)
    }

    if (result) {
      openNotification('SUCCESS', 'Edit profit percentage successfully!')
      setShowModalEditIncome(false)
      getManagerData()
    }
  }

  const handleLeave = async () => {
    setLoadingLeave(true)
    const [error, result]: any = await handleAsyncRequest(guildApi.userLeaveGuild(profile?.guild_id))

    if (error) {
      openNotification('ERROR', error.message || error.errors[0]?.message)
    }

    if (result) {
      handleShowModalLeave()
      openNotification('SUCCESS', 'Leave guild successfully!')
      dispatch(setCurrentUser({ ...profile, guild_id: result?.data?.id }))
      dispatch(resetGuildId())
      navigate(links.guild.joinGuild())
    }
    setLoadingLeave(false)
  }

  const handleShowModalLeave = () => {
    setShowModalLeaveGuild(!showModalLeaveGuild)
  }

  const handleShowModalEditIncome = () => {
    setShowModalEditIncome(!showModalEditIcome)
  }

  const takeDecimalNumber = (num: number, n: number) => {
    const base = 10 ** n
    const result = Math.round(num * base) / base
    return result
  }

  const isMember =
    guildData?.role_in_guild?.toUpperCase() === 'MEMBER' || guildData?.role_in_guild?.toUpperCase() === 'MANAGER'
  const isMaster = guildData?.role_in_guild?.toUpperCase() === 'MASTER'

  const from = guildData?.career?.total_number_of_races
    ? takeDecimalNumber((guildData?.career?.first_count / guildData?.career?.total_number_of_races) * 100, 2)
    : 0

  const to = guildData?.career?.total_number_of_races
    ? takeDecimalNumber(
        ((guildData?.career?.first_count + guildData?.career?.second_count + guildData?.career?.third_count) /
          guildData?.career?.total_number_of_races) *
          100,
        2
      )
    : 0

  const income = {
    profit_guild: guildData?.profit_guild,
    profit_owner: guildData?.profit_owner,
    profit_renter: guildData?.profit_renter
  }

  return (
    <ManagerStyled>
      {contextHolder}
      {loading ? (
        <div className='loading'>
          <Spin className='mt-2 mr-3' />
        </div>
      ) : (
        <div className='guild-manager'>
          {guildData && (
            <>
              <div className='basic-infor'>
                <div className='basic-infor-group d-flex justify-content-between'>
                  <div className='d-flex justify-content-between avatar-box'>
                    <div className='avatar'>
                      {guildData?.avatar && (
                        <img className='guild-avatar' src={guildData?.avatar} width={70} height={70} />
                      )}
                    </div>
                    <div>
                      <div className='name'>
                        {guildData?.guild_tag && `[${guildData?.guild_tag}]`} {guildData?.name}
                      </div>
                      <div className='name role'>
                        {' '}
                        Guild <span>{guildData?.role_in_guild}</span>{' '}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className='head'> Win Rate</div>
                    <div className='infor'>
                      {from > 0 ? from.toFixed(2) : from}% - {to > 0 ? to.toFixed(2) : to}%
                    </div>
                  </div>
                  <div>
                    <div className='head'> Career</div>
                    <div className='infor'>
                      <span className='career-horse'>{guildData?.career?.total_number_of_races}</span>
                      <span>{guildData?.career?.first_count}/</span>
                      <span>{guildData?.career?.second_count}/</span>
                      <span>{guildData?.career?.third_count}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className='line'>
                <img src={RETANGLE} alt='' />
              </div>

              <div className='guild-income'>
                <div className='income-content'>
                  <div className='income-text'>INCOME</div>
                  <div className='income-row'>
                    <span>MH System</span>
                    <span className='text'>{guildData?.profit_system}%</span>
                  </div>
                  <FormIncome
                    isMaster={isMaster}
                    data={income}
                    isEdit={isMaster}
                    form={form}
                    onSubmitForm={handleEdit}
                  />
                </div>
              </div>
            </>
          )}

          <div className='button-box'>
            {isMaster && (
              <Button
                buttonName='Edit'
                onClickButton={form.submit}
                width={130}
                isLoading={loading}
                disabled={isMember}
              />
            )}
            {isMember && (
              <Button
                buttonName='Leave Guild'
                onClickButton={handleShowModalLeave}
                width={130}
                type="btnCancel"
                disabled={loading}
              />
            )}
          </div>
        </div>
      )}
      {showModalLeaveGuild && (
        <ModalConfirmLeaveGuild onClose={handleShowModalLeave} onConfirm={handleLeave} loading={loadingLeave} />
      )}
      {showModalEditIcome && (
        <ModalConfirmLeaveGuild
          onClose={handleShowModalEditIncome}
          onConfirm={handleConfirmEdit}
          loading={loadingUpdateIncome}
          message={'You have changed the income. Are you sure?'}
        />
      )}
    </ManagerStyled>
  )
}
