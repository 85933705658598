/* eslint-disable @typescript-eslint/no-explicit-any */
import ItemDetailContainer from './styled'
import { CLOSE_BTN, RARITY_COMMON, RARITY_EPIC, RARITY_LEGENDARY, RARITY_MYTHIC, RARITY_RARE } from 'assets/images'
import { ItemArmor, ItemBoost } from 'models'
import { Modal, TwoLineTitle } from 'shared'
import Button from 'shared/Button'
import { convertTypeItems, getAttribute } from 'utils/helper'
import { ShopTypeRariTy } from 'models'

interface itemProps {
  open: boolean
  onCancel: () => void
  itemData?: any
  className?: string
  onConfirm: () => void
  isShowBtn?: boolean
}

const ItemInformationModal = ({ onCancel, itemData, className, onConfirm, isShowBtn = true }: itemProps) => {
  const boost = itemData?.boost as ItemBoost
  const itemArmor = itemData as ItemArmor

  const showIconRariTy = () => {
    const valueRariry = itemArmor?.rarity_type || boost?.rarity_type
    const handleKeySort = () => {
      switch (valueRariry) {
        case ShopTypeRariTy.ALL:
          return ''
        case ShopTypeRariTy.COMMON.toLocaleUpperCase():
          return <img className='icon-rarity' src={RARITY_COMMON} alt='' />
        case ShopTypeRariTy.EPIC.toLocaleUpperCase():
          return <img className='icon-rarity' src={RARITY_EPIC} alt='' />
        case ShopTypeRariTy.LEGENDARY.toLocaleUpperCase():
          return <img className='icon-rarity' src={RARITY_LEGENDARY} alt='' />
        case ShopTypeRariTy.MYTHIC.toLocaleUpperCase():
          return <img className='icon-rarity' src={RARITY_MYTHIC} alt='' />
        case ShopTypeRariTy.RARE.toLocaleUpperCase():
          return <img className='icon-rarity' src={RARITY_RARE} alt='' />
        default:
          return valueRariry
      }
    }
    return handleKeySort()
  }
  
  return (
    <Modal onOverlayClick={onCancel}>
      <ItemDetailContainer className={className}>
        <TwoLineTitle text='Item information' customClass='title' />
        <>
          <button className='close-btn p-0 position-absolute' role='button' onClick={onCancel}>
            <img src={CLOSE_BTN} alt='close' />
          </button>
          <div className='attribute-content'>
            <div className='avatar-box'>
              <div className='avatar'>
                <img src={boost?.img || itemArmor?.img} alt='icon' />
              </div>
              <div className='info'>
                <p className='info-title'>{boost?.name || itemArmor?.name}</p>
                <div className="sub-infor">
                  <div className="d-flex align-items-center">
                    <span className='field-name'>Id </span> {itemArmor?.id || boost?.id} <br></br>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className='field-name'>Type </span>  {convertTypeItems(boost?.boost_type || itemArmor?.body_part)} <br></br>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className='field-name rarity'>Rarity </span>{showIconRariTy()} <br></br>
                  </div>
                </div>
              </div>
 
            </div>
            <div className='content-box'>
              <div className='attribute-head'>
                <p>Attributes</p>
              </div>
              <div className='attribute-info'>
                <p>
                  <span>{getAttribute(itemArmor?.effect) || boost?.attribute || 'No effect'}</span>
                </p>
              </div>
              <div className='attribute-head'>
                <p>Informations</p>
              </div>
              <div className='attribute-info'>
                <textarea name="textarea_field" className='infinite-scroll-component' disabled>
                  {boost?.description || itemArmor?.description || '---'}
                </textarea>
              </div>
            </div>
          </div>

          <div className='button-row'>
            {isShowBtn ? (
              <Button buttonName='Use' onClickButton={onConfirm} width={140} />
            ) : (
              <div className='text color-orange'>You must return to the horse details screen to use it.</div>
            )}
          </div>
        </>
      </ItemDetailContainer>
    </Modal>
  )
}

export default ItemInformationModal
