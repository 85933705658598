/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next'
import { GUILD_MESSAGE } from 'i18n/constants'
import { shortenUserName } from 'utils/helper';
import VipIcon from 'features/components/VipIcon';
import { VIEW_DOPING } from 'assets/images';
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import HorseAvatar from '../HorseAvatar';
import { getImageGender, getImageRegion } from 'utils/getLinkImage';
import InfoBoxContainer from './styled';

const InfoBoxHorse = ({ data }: any) => {
  const { t } = useTranslation()
  return (
    <InfoBoxContainer>
      <div className='content-box'>
        <div className='img-box'>
          <HorseAvatar horse={data} />
        </div>
        <div className='info'>
          <div className='row-info'>
            <div className='name'>{data?.name}</div>
            <div className='horse-gender'>
              <div className='horse-gender__img'>
                <MemoizedLoadImageCommon srcImg={getImageGender(data?.gender)} />
              </div>
              <div className='region'>
                <MemoizedLoadImageCommon srcImg={getImageRegion(data?.region)} />
              </div>
            </div>
          </div>
          <div className='row-info'>
            {data?.vip_pass && <VipIcon />}
            {data?.equipped_boost_item && (
              <div className='used-doping'>
                <MemoizedLoadImageCommon srcImg={VIEW_DOPING} />
              </div>
            )}
          </div>


          <div className='row-info first'>
            <span>{t(`${GUILD_MESSAGE}.token_id`)}</span>
            <span>{data?.token_id}</span>
          </div>
          {/* <div className='row-info second'>
            <span>{t(`${GUILD_MESSAGE}.renter`)}</span>
            <span>{data?.lending_info?.renter?.name ? shortenUserName(data?.lending_info?.renter?.name) : '---'}</span>
          </div> */}
          <div className='row-info thrid'>
            <span>{t(`${GUILD_MESSAGE}.owner`)}</span>
            <span className='text__ellipsis'>{shortenUserName(data?.owner?.name)}</span>
          </div>
        </div>
      </div>
    </InfoBoxContainer>
  )
}

export default InfoBoxHorse;