import styled from 'styled-components'

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 80px;

  span {
    i {
      background-color: rgba(78, 242, 125, 0.4);
    }
  }
`

export default LoadingContainer
