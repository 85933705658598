/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spin } from 'antd'
import guildApi from 'apis/guildApi'
import { configs } from 'apps'
import { STATUS_TRANSACTION, timeCheckNonce } from 'apps/constants'
import { CLOSE_BTN } from 'assets/images'
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import InProgressBalanceModal from 'features/Balance/components/InProgress'
import { ChooseHorseItem } from 'features/Race/components'
import { useToggle } from 'hooks'
import { GUILD_MESSAGE, NOTIFICATION_MESSAGE, SPECIAL_MESSAGE } from 'i18n/constants'
import guildFarm_V2 from 'json/GuildFarm_V2.json'
import horseNFTABI from 'json/HorseNFT.json'
import horseNFTABI_V2 from 'json/HorseNFT_V2.json'
import { HorseAvailable } from 'models'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'shared'
import Button from 'shared/Button'
import { convertGasPrice, handleAsyncRequest } from 'utils/helper'
import openNotification from 'utils/toast'
import ModalConfirmGuildCommon from '../ModalConfirmGuildCommon'
import ConfirmGuildFarmModalStyled from './styled'

interface ConfirmGuildFarmModalProps {
  toggleIsModalOpen?: (value?: boolean) => void
  toggleIsChooseHorseModal?: (value?: boolean) => void
  onCloseButtonClick?: () => void
  horse?: HorseAvailable
  toggleIsRefresh: (value?: boolean) => void
}

const contract = {
  specialHorseFarm: configs.specialHorseFarm,
  guildFarm: configs.guildFarm,
  guildFarm_v2: configs.guildFarm_V2,
  transporter: configs.transporter,
  horseNFT: configs.horseNFT,
  horseNFT_v2: configs.horseNFT_v2,
}

function ConfirmGuildFarmModal({
  toggleIsModalOpen,
  onCloseButtonClick,
  toggleIsChooseHorseModal,
  horse,
  toggleIsRefresh
}: ConfirmGuildFarmModalProps) {
  const { t } = useTranslation()
  const [horseDetail, setHorseDetail] = useState<HorseAvailable>()
  const [isLoading, setIsLoading] = useState(false)

  const [resultHorseContract, setResultHorseContract] = useState<any>()
  // const [params,] = useState<MyHorseListParams>(myHorseListParams)
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()

  const [isAddHorse, toggleIsAddHorse] = useToggle(false)
  const [isApprovedHorse, toggleIsApproveHorse] = useToggle(false)
  const [openConfirmLeaseModal, toggleOpenConfirmLeaseModal] = useToggle(false)
  const [openInProgressBalanceModal, toggleOpenInProgressBalanceModal] = useToggle(false)
  const [messageError, setMessageError] = useState('')


  const addHorseSpecial = async () => {
    setIsLoading(true)
    const [error, result]: any = await handleAsyncRequest(
      guildApi.postAddHorseGuildFarm({ horse_ids: [horseDetail?.id] })
    )
    if (error) {
      setIsLoading(false)
      setMessageError(error?.message)
      toggleOpenConfirmLeaseModal(false)
      return
    }
    if (result) {
      setResultHorseContract(result.data)
      toggleIsApproveHorse(true)
    }
    toggleOpenInProgressBalanceModal(true)
    setIsLoading(false)
  }

  useEffect(() => {
    if (isApprovedHorse) {
      approveLeaseHorseContract()
    }
  }, [isApprovedHorse])

  useEffect(() => {
    if (isAddHorse) {
      leaseHorseContract()
    }
  }, [isAddHorse])

  const approveLeaseHorseContract = async () => {
    try {
      const { ethereum } = window
      if (!ethereum) return
      if (!resultHorseContract) return
      let leaseHorseContract: any = '';
      if (resultHorseContract?.horse_contract?.toUpperCase() === contract.horseNFT?.toUpperCase()) {
        leaseHorseContract = new ethers.Contract(contract.horseNFT, horseNFTABI.contract.abi, signer)
      } else {
        leaseHorseContract = new ethers.Contract(contract.horseNFT_v2, horseNFTABI_V2.contract.abi, signer)
      }
      
      // const leaseHorseContract = new ethers.Contract(contract.horseNFT, horseNFTABI.contract.abi, signer)
      const coverTokenId = new BigNumber(resultHorseContract.token_ids).toFixed()
      const coverGasPrice = convertGasPrice(resultHorseContract.gas_price)
      try {
        await leaseHorseContract.approve(contract.transporter, coverTokenId, { gasPrice: coverGasPrice })
        toggleIsAddHorse(true)
      } catch (err) {
        transactionFaild()
      }
    } catch (err) {
      transactionFaild()
    }
  }

  const leaseHorseContract = async () => {
    if (!resultHorseContract) return
    try {
      const addHorseContract = new ethers.Contract(configs.guildFarm_V2, guildFarm_V2.contract.abi, signer)
      const coverBlockExpired = new BigNumber(resultHorseContract.block_expired).toFixed()
      const coverGasPrice = convertGasPrice(resultHorseContract.gas_price)
      const coverTokenId = new BigNumber(resultHorseContract.token_ids).toFixed()

      try {
        const tx = await addHorseContract.lease({
          owner: resultHorseContract.owner,
          horseAddress: resultHorseContract.horse_contract,
          horseId: coverTokenId,
          blockExpired: coverBlockExpired,
          nonce: resultHorseContract.nonce,
          v: resultHorseContract.v,
          r: resultHorseContract.r,
          s: resultHorseContract.s
        },
        resultHorseContract.token_items,
        { gasPrice: coverGasPrice })
        if (tx.hash) {
          await provider.waitForTransaction(tx.hash)
          checkNonceWithDrawContract(resultHorseContract.nonce)
        }
      } catch (err) {
        transactionFaild()
      }
    } catch (err) {
      transactionFaild()
    }
  }

  useEffect(() => {
    setHorseDetail(horse)
  }, [])

  const handleBackModalChooseHorse = () => {
    toggleIsChooseHorseModal?.(true)
    onCloseButtonClick?.()
    toggleOpenInProgressBalanceModal(false)
  }

  const transactionSuccess = () => {
    toggleOpenInProgressBalanceModal(false)
    toggleIsModalOpen?.(false)
    toggleOpenConfirmLeaseModal(false)
    toggleIsApproveHorse(false)
    pushNotification(t(`${GUILD_MESSAGE}.successAddTransaction`), true)
    toggleIsRefresh()
  }

  const transactionFaild = () => {
    toggleOpenInProgressBalanceModal(false)
    setMessageError(t(`${SPECIAL_MESSAGE}.failedTransaction`))
    toggleOpenConfirmLeaseModal(false)
    toggleIsApproveHorse(false)
    toggleIsAddHorse(false)
  }

  const transactionPending = () => {
    toggleOpenInProgressBalanceModal(false)
    setMessageError(t(`${NOTIFICATION_MESSAGE}.transferredPending`))
    toggleOpenConfirmLeaseModal(false)
    toggleIsApproveHorse(false)
    toggleIsAddHorse(false)
  }

  const checkNonceWithDrawContract = async (nonce: string) => {
    let checkNonceExits = null as any
    let x = 0
    const intervalID = setInterval(async () => {
      const [, result] = await handleAsyncRequest(guildApi.postCheckNonce({ nonce }))
      if (!result) {
        transactionFaild()
        clearInterval(intervalID)
        return
      }
      checkNonceExits = result.data.status
      if (checkNonceExits === STATUS_TRANSACTION.success) {
        transactionSuccess()
        clearInterval(intervalID)
      }

      if (checkNonceExits === STATUS_TRANSACTION.expired) {
        transactionFaild()
        clearInterval(intervalID)
      }
      if (++x === 10) {
        if (checkNonceExits === STATUS_TRANSACTION.pending) {
          transactionPending()
          clearInterval(intervalID)
        }
      }
    }, timeCheckNonce)
  }

  const pushNotification = (description: string, isSuccess?: boolean) => {
    openNotification({
      message: '',
      description: description,
      className: isSuccess ? 'toast-success' : 'toast-error'
    })
  }

  const handleClickOpenConfirmModal = () => {
    setMessageError('')
    toggleOpenConfirmLeaseModal(true)
  }

  return (
    <Modal onOverlayClick={toggleIsModalOpen}>
      {!isLoading ? (
        <ConfirmGuildFarmModalStyled>
          <div className='choose-horse-modal'>
            <button className='close-btn p-0 position-absolute' role='button' onClick={handleBackModalChooseHorse}>
              <img src={CLOSE_BTN} alt='close' />
            </button>
            <div className='choose-title line'>
              <div className='color-primary text-uppercase'> {t(`${GUILD_MESSAGE}.registerHorse`)} </div>
            </div>
            <div className='text-content'>You have chosen this horse </div>

            {horseDetail && (
              <div className='horse-list-container d-flex flex-column'>
                <ChooseHorseItem horse={horseDetail} customClass='active' />
              </div>
            )}
            <div className='text-content'>We will process to put your horse into guild farm
              <br />
              Are you OK with that?</div>

            <div className='confirm-horse'>
              {/* <p className='confirm-horse-title color-white'>{t(`${NOTIFICATION_MESSAGE}.okWithThis`)}</p> */}
              {messageError && messageError?.length > 0 ? (
                <div className='color-red d-flex justify-content-center message-err'>
                  {messageError}
                </div>
              ) : (
                ''
              )}
              <div className='confirm-horse-btns d-flex align-items-center justify-content-center'>
                <Button
                  buttonName={'Confirm'}
                  onClickButton={handleClickOpenConfirmModal}
                  width={130}
                  // btnCancel={false}
                />
              </div>
            </div>
          </div>
        </ConfirmGuildFarmModalStyled>
      ) : (
        <Spin className='mt-2 mr-3' />
      )}
      {openConfirmLeaseModal &&
        <ModalConfirmGuildCommon
          message={
            <div>
              To register to guild farm, your NFT horse will be transfer to farm.
              <br />
              A fee (ADIL) is required for farming.
              <br />
              If the horse is wearing armor or skin, a fee must be paid for it.
              <br />
              After put into farm, your authority over your horse will temporary locked.
              <br />
              Withdraw NFT horse from farm to your wallet will also require fee (ADIL).
              <br />
              <br />
              Do you want to register your NFT horse to guild farm?
              <br />
            </div>
          }
          onClose={toggleOpenConfirmLeaseModal}
          onConfirm={addHorseSpecial}
          loading={isLoading}
          title={t(`${GUILD_MESSAGE}.registerHorseFarm`)}
          size='LG'
        />
      }

      {openInProgressBalanceModal && (
        <InProgressBalanceModal title={'FARMING IN PROGRESS'} />
      )}
    </Modal>
  )
}

export default ConfirmGuildFarmModal
