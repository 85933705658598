import styled from 'styled-components'

const InforBoxContainer = styled.div`
  width: 260px;
  padding: 10px;

  .head {
    position: relative;
    margin-bottom: 20px;
    p {
      color: #fff566;
      margin-bottom: 0;
      font-size: 18px;
      text-transform: uppercase;
    }

    img {
      position: absolute;
      bottom: -2px;
      left: 0;
    }
  }

  .row {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    span {
      display: inline-block;
      width: auto;
      font-size: 18px;

      &:first-child {
        text-transform: uppercase;
        color: #4ef282;
        width: 130px;
      }

      &.guild-role {
        text-transform: lowercase;
        &:first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
`

export default InforBoxContainer
