/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from "@reduxjs/toolkit";
import guildApi from "apis/guildApi";
import horseApi from "apis/horseApi";
import specialRaceApi from "apis/specialRaceApi";
import { handleAsyncRequest } from 'utils/helper'

export const getHorseInfo  = createAsyncThunk(
  'armorSkin/getHorseInfo',
  async (payload: any) => {
    const [error, result] = await handleAsyncRequest(horseApi.getHorseInfo(payload?.horseId, payload?.raceId))
    if (error) {
      return (error as any)?.message
    }
    return (result as any)?.data
  }
)

export const getHorseInfoGuild  = createAsyncThunk(
  'armorSkin/getHorseInfoGuild',
  async (payload: any) => {
    const [error, result] = await handleAsyncRequest(guildApi.getHorseGuildDetails(payload?.horseId))
    if (error) {
      return (error as any)?.message
    }
    return (result as any)?.data
  }
)

export const getHorseInfoProfile  = createAsyncThunk(
  'armorSkin/getHorseInfoProfile',
  async (payload: any) => {
    const params = {
      horse_id: payload?.horseId
    }
    const [error, result] = await handleAsyncRequest(horseApi.getHorseDetailByTokenId(params))
    if (error) {
      return (error as any)?.message
    }
    return (result as any)?.data
  }
)

export const getHorseInfoEventHistory  = createAsyncThunk(
  'armorSkin/getHorseInfoEventHistory',
  async (payload: any) => {
    const [error, result] = await handleAsyncRequest(specialRaceApi.getOldFarmDetail(payload?.horseId, payload?.tournamentId))
    if (error) {
      return (error as any)?.message
    }
    return (result as any)?.data
  }
)

export const getHorseInfoHorseBlacklist  = createAsyncThunk(
  'armorSkin/getHorseInfoHorseBlacklist',
  async (payload: any) => {
    const [error, result] = await handleAsyncRequest(guildApi.getHorseBlacklistDetails(payload?.guildId, payload?.horseId))
    if (error) {
      return (error as any)?.message
    }
    return (result as any)?.data
  }
)

export const postHorseItem  = createAsyncThunk(
  'armorSkin/postHorseItem',
  async (payload: any) => {
    return payload
  }
)

