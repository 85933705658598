import classNames from 'classnames'
import { TournamentStatus, TOURNAMENT_STATUS } from 'models'
import { Fragment } from 'react'
import { convertShortDay, convertShortTime } from 'utils/time'
import { NUMBER } from 'apps/constants'
import TimeLineStyled from './styled'

interface TimeLineProps {
  customClass?: string
  status?: TournamentStatus
  isTournamentEnd?: boolean
}

const { POOL_OPEN, SELECTION_HORSE, SCHEDULE_PUBLIC, END } = TOURNAMENT_STATUS

function TimeLine({ customClass = '', status, isTournamentEnd }: TimeLineProps) {
  const TimeLineClass = classNames('', customClass)

  const tournaments = [
    {
      id: NUMBER.ONE,
      name: 'Open',
      time: status?.event_open_time,
      status: POOL_OPEN,
      active: status?.tournament_status === POOL_OPEN && true
    },
    {
      id: NUMBER.TWO,
      name: 'Selection',
      time: status?.lock_farm_time,
      status: SELECTION_HORSE,
      active: status?.tournament_status === SELECTION_HORSE && true

    },
    {
      id: NUMBER.THREE,
      name: 'Public',
      time: status?.public_event_time,
      status: SCHEDULE_PUBLIC,
      active: status?.tournament_status === SCHEDULE_PUBLIC && true

    },
    {
      id: NUMBER.FOUR,
      name: 'End',
      time: status?.event_end_time,
      status: END,
      active: status?.tournament_status === END && true
    }
  ]

  const layoutTimeLine = (time: string | undefined) => {
    if (!time) return
    return (
      <div className='day-time'>
        {convertShortDay(time)} {convertShortTime(time)}
      </div>
    )
  }
  const checkCurrentStatus = () => {
    const tournamentActive = tournaments.find((tournament) => tournament.active === true)
    return tournamentActive?.id || NUMBER.ZERO
  }

  return (
    <TimeLineStyled className={TimeLineClass}>
      <div className='pyro'>
        <div className='before'>
          <div className='container'>
            <ul className='timeline'>
              {tournaments.map((tournament, index) => {
                return (
                  <Fragment key={index}>
                    <li className={`${((index + NUMBER.ONE) < checkCurrentStatus()) || isTournamentEnd ? 'active-ticked' : ''} ${(status?.tournament_status === tournament.status && !isTournamentEnd) ? 'active-tl' : ''}`} >
                      {tournament.name} {tournament.active}
                      <div className='day-time'>{layoutTimeLine(tournament?.time)}</div>
                    </li>
                  </Fragment>
                )
              })}
            </ul>
          </div>
        </div>
        <div className='after'></div>
      </div>
    </TimeLineStyled>
  )
}

export default TimeLine
