/* eslint-disable @typescript-eslint/no-explicit-any */
import userApi from 'apis/userApi'
import { MESSAGE } from 'apps'
import {
  AVATAR_DEFAULT,
  CANCEL_UPDATE_INFOR,
  CIRCEL_PRIMARY,
  COLUMN_PRIMARY,
  PEN_PRIMARY, SAVE_DISABLE, SAVE_UPDATE_INFOR
} from 'assets/images'
import Compressor from 'compressorjs'
import { ResultHorseModal } from 'features/Race/components'
import heic2any from 'heic2any'
import { useAppSelector, useReloadCurrentPage, useToggle } from 'hooks'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import { MyInforMation } from 'models'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Loading from 'react-loading'
import { Modal } from 'shared'
import Input from 'shared/Input'
import { handleAsyncRequest } from 'utils/helper'
import UpdateInforModalStyled from './styled'

interface ResultHorseModalProps {
  toggleIsModalOpen: (value?: boolean) => void
  onCancelUpdate: () => void
}
export default function UpdateInforModal({ toggleIsModalOpen, onCancelUpdate }: ResultHorseModalProps) {
  const myInformationParams: MyInforMation = {
    avatar: '',
    description: '',
    email: '',
    name: '',
    avatarFile: null
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [avatar, setAvatar] = useState<string>('') as any
  const [avatar2, setAvatar2] = useState<string>('') as any
  const profile = useAppSelector(state => state.profile)
  const [nameValue, setNameValue] = useState<string>(profile?.name)
  const [isModalUpdateInforOpen, toggleIsModalUpdateInforOpen] = useToggle(false)
  const [flagMsgError, setFlagMsgError] = useState<boolean>(false)
  const [titleBalace, setTitleBalance] = useState<string>('')
  const [messageFailed, setMessageFailed] = useState<string>(MESSAGE.image_over_5mb)
  const [isLoading, setIsLoading] = useToggle(false)

  const messageSuccess = MESSAGE.successfully_updated
  const { t } = useTranslation()
  const handleCloseModalResult = () => toggleIsModalUpdateInforOpen(false)
  const loadingImage = useRef<any>(false)

  useEffect(() => {
    return () => {
      avatar && URL.revokeObjectURL(avatar.preview)
    }
  }, [avatar])

  const getFileExtension = (filename: any) => {
    const ext = /^.+\.([^.]+)$/.exec(filename);
    return ext == null ? "" : `image/${ext[1]}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handlePreviewAvatar = async (e: any) => {
    setIsLoading(true)
    loadingImage.current = true
    let file = e.target.files[0]
    const ext = getFileExtension(file?.name)
    if (ext === "image/HEIC" || ext === "image/HEIF" || ext === "image/heic" || ext === "image/heif") {
      try {
        file = await heic2any({ blob: file, toType: 'image/png' })
        file.preview = URL.createObjectURL(file)
        setAvatar(() => file)

        await new Compressor(file, {
          quality: 0.6,
          success(result: any) {
            setAvatar2(() => result)
            loadingImage.current = false
          },
          error(err: any) {
            console.log(err.message)
            loadingImage.current = false
          },
        })
      } catch (error) {
        file.preview = window.URL?.createObjectURL(file)
        setAvatar(file)
        setAvatar2(file)
      }
    } else {
      setIsLoading(false)
      file.preview = window.URL?.createObjectURL(file)
      // const blob = new Blob([file], { type: 'image/png' })
      // myInformationParams.avatar = blob.toString()
      setAvatar(file)
      setAvatar2(file)
      loadingImage.current = false
    }
    setIsLoading(false)
  }

  const handleSearchValueChanged = (e: ChangeEvent<HTMLInputElement>) => {
    setNameValue(e.target.value)
  }

  const handleSaveUpdate = async () => {
    const formData = new FormData()
    formData.append('avatarFile', avatar2)
    formData.append('name', nameValue)
    formData.append('email', myInformationParams.email)
    formData.append('description', myInformationParams.description)
    const [error, result]: any = await handleAsyncRequest(userApi.postUpdateUser(formData))
    if (error) {
      toggleIsModalUpdateInforOpen(true)
      setTitleBalance('failed!')
      setFlagMsgError(true)
      if (error.code === 400 || error.code === 500) {
        setMessageFailed(error?.message)
      } else setMessageFailed(MESSAGE.image_over_5mb)
    }

    if (result) {
      toggleIsModalUpdateInforOpen(true)
      setTitleBalance('success!')
      setFlagMsgError(false)
    }
  }

  const handleOk = () => {
    toggleIsModalUpdateInforOpen(false)
    useReloadCurrentPage()
  }

  const handleOkClone = () => {
    toggleIsModalUpdateInforOpen(false)
  }

  const handleAvatar = useCallback(() => {
    if (avatar) {
      return avatar.preview
    }
    return profile.avatar
  }, [profile, avatar])

  const handleDisabledImg = useCallback(() => {
    if (avatar || nameValue && !isLoading) {
      return false
    }

    return true
  }, [nameValue, avatar, isLoading])

  return (
    <Modal onOverlayClick={toggleIsModalOpen}>
      <UpdateInforModalStyled>
        <div className='avatar-design d-flex justify-content-center'>
          <div className='avatar-box overflow-hidden rounded-circle '>
            {isLoading &&
              <div className='loading-avt'>
                <Loading type='spinningBubbles' /></div>
            }
            <div className='avatar-box position-relative overflow-hidden rounded-circle'>
              <span className='center'>
                <img src={handleAvatar() ?? AVATAR_DEFAULT} alt='' className='avatar' />
              </span>
              <label
                htmlFor='avatar'
                className='avatar-overlay position-absolute flex-column align-items-center justify-content-center w-100 h-50'
                role='button'
              >
                <img src={CIRCEL_PRIMARY} alt='' className='circel-primary' />
                <img src={PEN_PRIMARY} alt='' />
              </label>
              <input
                id='avatar'
                type='file'
                accept='image/jpg, image/jpeg, image/png, image/bmp, image/JPG, image/JPEG, image/PNG, image/BMP,.heic,.heif,.HEIC,.HEIF'
                className='d-none'
                onChange={handlePreviewAvatar}
              />
            </div>
          </div>
        </div>
        <div className='user-name-input'>
          <span className='color-white user-name-title'>{t(`${NOTIFICATION_MESSAGE}.userName`)}</span>
          <span className='search-container'>
            <Input
              searchValue={nameValue}
              handleSearchValueChanged={handleSearchValueChanged}
              customClass='search-horse-input'
              maxLength={25}
            />
          </span>
        </div>
        <div className='btn-cancel-save d-flex justify-content-between'>
          <button onClick={onCancelUpdate} className='btn-cancel'>
            <img src={CANCEL_UPDATE_INFOR} alt='' />
          </button>
          <button onClick={handleSaveUpdate} className='btn-save' disabled={handleDisabledImg()}>
            <img src={handleDisabledImg() ? SAVE_DISABLE : SAVE_UPDATE_INFOR} alt='' />
          </button>
        </div>
        <div className='position-absolute column'>
          <img src={COLUMN_PRIMARY} alt='' />
        </div>
      </UpdateInforModalStyled>
      {isModalUpdateInforOpen && (
        <ResultHorseModal
          toggleIsModalOpen={toggleIsModalUpdateInforOpen}
          title={titleBalace}
          onCloseButtonClick={handleCloseModalResult}
          onOk={flagMsgError ? handleOkClone : handleOk}
          message={flagMsgError ? messageFailed : messageSuccess}
        />
      )}
    </Modal>
  )
}
