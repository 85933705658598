/* eslint-disable @typescript-eslint/no-explicit-any */
import { checkExistItem } from '../utils'
import HorseImageContainer from './styled'
import {
  Horse,
} from 'models'
interface iprops {
  avatar: string
  name: string
  items: any
  horse?: Horse
}

const HorseAvatar = ({ horse, avatar, name, items = [] }: iprops) => {
  const converToUtf8 = (value: string) => {
    return value.replace('#', '%23')
  }
  const horseColorCode = converToUtf8(horse?.bloodline?.color_code || horse?.blood_line?.color_code || '')
  const typeRuntype = 'RUNTYPE'
  const typeChar = 'CHARACTERISTIC'
  const typeAll = 'CHARACTERISTIC_RUNTYPE'
  const glassUrl = '/_horse/_avatar/_glass/g_'
  const shoesUrl = '/_horse/_avatar/_shoes/sh_'
  const armorUrl = '/_horse/_avatar/_armor/'
  const skinUrl = '/_horse/_avatar/_skin/'

  const headItem = checkExistItem('HEAD', items)
  const bodyItem = checkExistItem('BODY', items)
  const footItem = checkExistItem('LEG', items)
  const skinItem = checkExistItem('SKIN', items)
  const fullURLGlass = process.env.REACT_APP_ROOT_DOMAIN + glassUrl + horse?.characteristic + '.png'
  const fullURLShoes = process.env.REACT_APP_ROOT_DOMAIN + shoesUrl + horse?.run_type_code + '.png'
  const typeOfMaterial = bodyItem?.material_type?.toUpperCase()

  const fullURLBody = typeOfMaterial === typeRuntype
    ? process.env.REACT_APP_ROOT_DOMAIN + armorUrl + bodyItem?.model_name + '_' + horse?.run_type_code + '.png'
    :  typeOfMaterial === typeChar
    ? process.env.REACT_APP_ROOT_DOMAIN + armorUrl + bodyItem?.model_name + '_' + horse?.characteristic + '.png'
    : typeOfMaterial === typeAll
    ? process.env.REACT_APP_ROOT_DOMAIN + armorUrl + bodyItem?.model_name + '_' + horse?.characteristic + '_' + horse?.run_type_code + '.png'
    : process.env.REACT_APP_ROOT_DOMAIN + armorUrl + bodyItem?.model_name + '.png'
  const footUrl = process.env.REACT_APP_ROOT_DOMAIN + armorUrl + footItem?.model_name + '_' + horse?.run_type_code + '.png'
  const headtUrl = process.env.REACT_APP_ROOT_DOMAIN + armorUrl + headItem?.model_name + '_' + horse?.characteristic + '.png'
  const fullSkinUrl = horse?.hair_color === 'SAME_BODY'
    ? process.env.REACT_APP_ROOT_DOMAIN + skinUrl + skinItem?.model_name + '_' + horse?.hair_color + '_' + horseColorCode + '.png'
    : process.env.REACT_APP_ROOT_DOMAIN + skinUrl + skinItem?.model_name + '_' + horse?.hair_color + '.png'
  
  return (
    <HorseImageContainer>
      <div className='background d-flex align-items-center justify-content-center'>
        <img src={avatar} alt={name} className='avatar' />
        {headItem && headItem?.model_name
          ? <img src={headtUrl} alt={headItem.name} className='item head' /> 
            : <img src={fullURLGlass} className="item glass" /> }
        {bodyItem && bodyItem?.model_name && <img src={fullURLBody} alt={bodyItem.name} className='item body' />}
        {footItem && footItem?.model_name
          ? <img src={footUrl} alt={footItem.name} className='item foot' />
          : <img src={fullURLShoes} className="item shoes" /> }
        {skinItem && skinItem?.model_name && <img src={fullSkinUrl} alt={skinItem.name} className='item skin' />}
      </div>
    </HorseImageContainer>
  )
}

export default HorseAvatar
