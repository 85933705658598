import styled from 'styled-components'

const GuildListStyled = styled.div`
  width: 100%;
  .guild-container {
    padding: 0;
  }
  .search-box {
    display: flex;
    /* justify-content: flex-end; */
    align-items: center;
    justify-content: end;
    gap: 20px;
    margin-bottom: 20px;
  }

  .guild__note {
    background-color: transparent;
    font-size: 17px;
    text-align: center;
    color: #a4a4a4;
    margin-top: -40px;
    margin-top: -55px;
    margin-bottom: 30px;
  }
`

export default GuildListStyled
