/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */

import i18n from 'i18n'
import { Form, Spin, notification } from 'antd'
import heic2any from 'heic2any'
import Compressor from 'compressorjs'
import guildApi from 'apis/guildApi'
import { links } from 'apps'
import { GUILD_LOGO_BTN, LINE_GUILD_GRADIENT, ONE_LINE, ONE_LINE_LEFT, ONE_LINE_RIGTH } from 'assets/images'
import ModalConfirmEditGuild from 'features/Guild/components/ModalConfirmEditGuild'
import { RequestLoginModal } from 'features/Race/components'
import { useToggle } from 'hooks'
import { GUILD_MESSAGE } from 'i18n/constants'
// import { EditGuildRequest } from 'models'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { InputCustomV2 } from 'shared'
import Button from 'shared/Button'
import MemoizedLoadImageCommon from 'shared/MemoizedImage/LoadImageCommon'
import { handleAsyncRequest, getOnlyNumber } from 'utils/helper'
import InputTextarea from 'shared/InputTextarea'
import Loading from '../Loading'
import { NUMBER, STRING } from 'apps/constants'
import { REGEX_FIRST_SPACE, REGEX_NICK_NAME } from 'apps/constants'
import UpdateInfoStyled from './styled'

const regix = /^\d+$/
const textErrorSpaceTag = i18n.t(`${GUILD_MESSAGE}.message.textErrorSpaceTag`)
const textErrorSpace = i18n.t(`${GUILD_MESSAGE}.message.textErrorSpace`)
const textError2Space = i18n.t(`${GUILD_MESSAGE}.message.textError2Space`)
const regex = new RegExp(REGEX_NICK_NAME)
const keyValid = /[`!@#$%^&*~()_+\-=\[\]{};':"\\|,.<>\/?]+/
const imageMimeType = /image\/(jpg|JPG|jpeg|JPEG|png|PNG|bmp|BMP|heic|heif|HEIC|HEIF)/i

export default function UpdateInfo() {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [api, contextHolder] = notification.useNotification()
  
  const [logoChoose, setLogoChoose] = useState<string>('') as any
  const [isShowModalConfirmEditGuild, setIsShowModalConfirmEditGuild] = useState<boolean>(false)
  // const [dataCreateGuild, setDataCreateGuild] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [guildData, setGuildData] = useState<any>(null)
  const [guildDataLoading, setGuildDataLoading] = useState<boolean>(() => true)
  const [isRequestLoginModalOpen, toggleIsRequestLoginModalOpen] = useToggle(false)
  const navigate = useNavigate()
  // const { auth, profile, coinUser } = useAppSelector(state => state)
  const [valCheckbox, setValCheckbox] = useState(() => false)
  const [createGuildError, setCreatGuildError] = useState<string>('')
  const [statusEdit, setStatusEdit] = useState(() => false)
  const [guildId, setGuildId] = useState(() => "")
  const logoRef = useRef<any>(null)
  const logoUploadRef = useRef<any>(null)
  const [logoUpload, setLogoUpload] = useState('') as any
  const [logoUploadPreview, setLogoUploadPreview] = useState('') as any
  const [valCheckboxNeedApproval, setValCheckboxNeedApproval] = useState<boolean>(() => false) as any
  const [checkErrorTotalRace, setCheckErrorTotalRace] = useState<boolean>(() => true)
  const [guildNameError, setGuildNameError] = useState('')
  const guildName = Form.useWatch('guildName', form)
  const guildTag = Form.useWatch('guildTag', form)
  const guildDescription = Form.useWatch('guildDescription', form)
  const guildTotalRace = Form.useWatch('totalRace', form)
  const [isLoading, setIsLoading] = useToggle(false)

  const isTextValid = !guildName || !guildTag || !guildDescription || !valCheckbox || isLoading

  useEffect(() => {
    if (logoUploadRef?.current?.value) {
      logoUploadRef.current.value = ''
    }
    setIsLoading(false)
    getGuildData()
  }, [])

  // useEffect(() => {
  //   if (auth.isLogged && profile.guild_id) {
  //     navigate(links.guild.information())
  //   }
  // }, [auth])

  const getGuildData = async () => {
    const [error, result]: any = await handleAsyncRequest(guildApi.getGuildMaterData())
    if (error) {
      if (error.code === 403) {
        toggleIsRequestLoginModalOpen()
        return
      }
      openNotification(STRING.ERROR, error.message || error.errors[NUMBER.ZERO]?.message)
    }
    if (result) {
      setGuildData(() => result.data)
      logoRef.current = result?.data?.logos[NUMBER.ZERO]
    }
    await getGuildInformation()
    setGuildDataLoading(false)
  }

  const getGuildInformation = async () => {
    const [error, result]: any = await handleAsyncRequest(guildApi.getGuildInformation())

    if (error) {
      if (error?.code === "E1000") {
        navigate(links.guild.joinGuild())
      }
      setLogoChoose(() => logoRef.current)
      openNotification(STRING.ERROR, error.message || error.errors[NUMBER.ZERO]?.message)
    }

    if (result) {
      const description: any = result?.data?.description ? JSON.parse((JSON.stringify(result?.data?.description))?.replace(/\\r\\n/g,'\\n')) : ''
      setLogoChoose(() => result?.data?.avatar)
      setGuildId(() => result?.data?.id)
      form.setFieldValue('guildName', result?.data?.name)
      form.setFieldValue('guildTag', result?.data?.guild_tag)
      form.setFieldValue('guildDescription', description)
      form.setFieldValue('totalRace', result?.data?.guild_policy?.total_race ? result?.data?.guild_policy?.total_race : '0')
      setValCheckboxNeedApproval(() => result?.data?.guild_policy?.need_approval ? result?.data?.guild_policy?.need_approval : false)
    }
  }

  const handleEdit = () => {
    setStatusEdit(() => !statusEdit)
  }

  const handleSubmit = () => {
    // setDataCreateGuild(value)
    setIsShowModalConfirmEditGuild(!isShowModalConfirmEditGuild)
  }

  const handleCloseModalConfirmEditGuild = () => {
    setCreatGuildError('')
    setIsShowModalConfirmEditGuild(!isShowModalConfirmEditGuild)
  }

  const openNotification = (message: string, description: string) => {
    notification.config({
      duration: NUMBER.FOUR
    })

    api.info({
      message,
      description,
      placement: 'bottomRight',
      className: message === STRING.ERROR ? 'ant-custom-error' : 'ant-custom-success'
    })
  }

  const handleConfirmEditGuild = async () => {
    setLoading(true)
    setCreatGuildError('')

    const formData = new FormData()
    if (logoUpload) {
      formData.append('avatarFile', logoUpload)
    } else {
      formData.append('avatar', logoChoose)
    }
    formData.append('name', guildName)
    formData.append('tag', guildTag)
    formData.append('description', guildDescription)
    formData.append('totalRace', guildTotalRace)
    formData.append('needApproval', valCheckboxNeedApproval)

    const [error, result]: any = await handleAsyncRequest(guildApi.putEditGuild(guildId, formData))
    
    if (error) {
      setCreatGuildError(() => error?.message || error?.errors[0]?.message)
    }
    if (result) {
      handleCloseModalConfirmEditGuild()
      setCreatGuildError('')
      setStatusEdit(() => !statusEdit)
      setValCheckbox(() => false)
      openNotification(STRING.SUCCESS, t(`${GUILD_MESSAGE}.message.validate_edit_guild_success`))
      // navigate(links.guild.information())
    }
    setLoading(false)
  }

  const _handleGuildNameChange = (e: any) => {
    setGuildNameError('')
    form.setFieldValue('guildName', e)
  }

  const handleKeyDown = (e: any) => {
    if (keyValid.test(e.key)) {
      return e.preventDefault()
    }

    return
  }

  const _handleChangeGuildTag = (e: any) => {
    form.setFieldValue('guildTag', e)
  }

  const handdleChooseAvatar = (logo: any) => {
    if (isLoading) {
      return
    }
    setLogoUpload(() => '')
    setLogoUploadPreview(() => '')
    logoUploadRef.current.value = ''
    setLogoChoose(() => logo)
  }

  const onChangeCheckbox = () => {
    setValCheckbox(() => !valCheckbox)
  }

  const handleKeyDownTotalRace = (e: any) => {
    if (['e', 'E', '+', '-', '.', ','].includes(e.key) || [38, 40].includes(e.keyCode)) {
      return e.preventDefault()
    }

    if (!regix.test(e.key) &&
      e.key !== 'Backspace' &&
      e.key !== 'Delete' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'ArrowRight') {
      return e.preventDefault()
    }

    if (e.key === 'Unidentified') {
      return
    }
  }

  const _handleChangeGuildTotalRace = (e: any) => {
    setCheckErrorTotalRace(() => true)
    const num = getOnlyNumber(e)
    form.setFieldValue('totalRace', num)
  }

  const onChangeCheckboxNeedApproval = (e: any) => {
    if (!e.target.checked) {
      form.setFieldValue('totalRace', '0')
    }
    setCheckErrorTotalRace(() => false)
    setValCheckboxNeedApproval(() => e.target.checked)
  }

  useEffect(() => {
    if (logoUpload) {
      handleUploadGuildLogo(logoUpload)
    }
  }, [logoUpload])

  const resetImage = () => {
    setLogoUpload(() => '')
    setLogoUploadPreview(() => '')
    logoUploadRef.current.value = ''
  }

  const handleUploadGuildLogo = (logoUpload: any) => {
    if (!logoUpload.type.match(imageMimeType)) {
      resetImage()
      openNotification(STRING.ERROR, t(`${GUILD_MESSAGE}.message.not_format_img`))
      return;
    }

    if (logoUpload.size > 5 * 1024 * 1024) {
      resetImage()
      openNotification(STRING.ERROR, t(`${GUILD_MESSAGE}.message.allowed_size_img`))
      return;
    }
  }

  const getFileExtension = (filename: any) => {
    const ext = /^.+\.([^.]+)$/.exec(filename);
    return ext == null ? "" : `image/${ext[1]}`;
  }

  const handleClickImage = async (e: any) => {
    if(e.target.files?.length === NUMBER.ZERO){
      return
    }
    let file = e.target.files[NUMBER.ZERO]
    setIsLoading(true)
    
    const ext = getFileExtension(file?.name)
    if (ext === "image/HEIC" || ext === "image/HEIF" || ext === "image/heic" || ext === "image/heif") {
      try {
        file = await heic2any({ blob: file, toType: 'image/png' })
        file.preview = URL.createObjectURL(file)
        setLogoUploadPreview(() => file)        

        await new Compressor(file, {
          quality: 0.6,
          success(result: any) {            
            setLogoUpload(() => result)
          },
          error(err: any) {
            console.log(err.message)
            openNotification(STRING.ERROR, err?.message)
          },
        })
      } catch (error: any) {
        console.log("error image =====> ", error);
        openNotification(STRING.ERROR, error?.message)
      }
    } else {
      file.preview = window.URL?.createObjectURL(file)
      setLogoUploadPreview(() => file)
      setLogoUpload(() => file)
    }
    setIsLoading(false)
  }

  const handleGuildAvatar = useCallback(() => {
    if (logoUploadPreview) {
      return logoUploadPreview.preview
    }
  }, [logoUploadPreview])

  return (
    <UpdateInfoStyled>
      {contextHolder}
      {guildDataLoading ? (
        <Loading />
      ) : (
        <Form form={form} name='basic' onFinish={statusEdit ? handleSubmit : handleEdit} autoComplete='off'>
          {(values, instance) => {
            const desError = instance.getFieldError('guildDescription');
            let totalRaceError: any = STRING.EMPTY
            if (checkErrorTotalRace && statusEdit) {
              totalRaceError = instance.getFieldError('totalRace')
            } else {
              totalRaceError = STRING.EMPTY
            }
            
            const isError = desError?.length > 0 || totalRaceError?.length > 0

            return (
              <div className='guild-container'>
                <div className='row line__guild--gradiant'>
                  <MemoizedLoadImageCommon srcImg={LINE_GUILD_GRADIENT} />
                </div>
                <div className='color-DEDEDE row'>
                  <div className={`col-3 guil__content ${statusEdit ? "" : "disableDiv"}`}>
                    <div className='guild-label guild__label--logo'>{t(`${GUILD_MESSAGE}.guild_logo`)}</div>
                    <div className='guild__content--logo'>
                      <MemoizedLoadImageCommon className='orange-line position-absolute' srcImg={ONE_LINE} />
                      <div className='guild__content--avatar'>
                        {logoChoose && <MemoizedLoadImageCommon className='guild-avatar' srcImg={handleGuildAvatar() ?? logoChoose} />}
                      </div>
                      <div className='guild__line'>
                        <img src={ONE_LINE_LEFT} />
                        <span className='guild__line--space'></span>
                        <img src={ONE_LINE_RIGTH} />
                      </div>

                      <div className='guild__content--arr-avatar'>
                        {guildData?.logos?.map((logoItem: string | undefined, i: any) => {
                          return (
                            <div
                              key={i}
                              onClick={() => handdleChooseAvatar(logoItem)}
                              className={`item__avatar ${logoItem === logoChoose ? 'active' : ''} ${!isLoading ? 'cursor__point' : ''}`}
                            >
                              <MemoizedLoadImageCommon className='img-avatar' srcImg={logoItem} />
                            </div>
                          )
                        })}

                        <div className="avatar-upload">
                          {isLoading &&
                            <div className='loading-avt'>
                              <Spin />
                            </div>
                          }
                          <div className="avatar-edit">
                            <input
                              ref={logoUploadRef}
                              type="file"
                              id="logoUpload"
                              accept='image/jpg, image/jpeg, image/png, image/bmp, image/JPG, image/JPEG, image/PNG, image/BMP,.heic,.heif,.HEIC,.HEIF'
                              onChange={handleClickImage}
                            />
                          </div>
                          <div className="avatar-preview">
                            <div className="border-avata">
                              <label htmlFor='logoUpload' className={!isLoading ? 'cursor__point' : ''}>
                                <MemoizedLoadImageCommon id="imagePreview" srcImg={GUILD_LOGO_BTN} />
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-6 guil__content'>
                    <div>
                      <div className='guild-label'>
                        {t(`${GUILD_MESSAGE}.guild_name`)}<span className='request__red'>{t(`${GUILD_MESSAGE}.message.validate_request`)}</span>
                      </div>
                      <div>
                        <Form.Item
                          name='guildName'
                          className='input-box'
                          shouldUpdate
                          {...(guildNameError ? {
                            hasFeedback: true,
                            validateStatus: "error",
                            help: textErrorSpace
                          } : {})}
                          rules={[
                            { required: true, message: t(`${GUILD_MESSAGE}.message.validate_req_guild_name`) },
                            { max: 20, message: t(`${GUILD_MESSAGE}.message.validate_req_guild_name`) },
                            {
                              validator: (_, value) => {
                                if (value && REGEX_FIRST_SPACE.test(value)) {
                                  return Promise.reject(new Error(textErrorSpace))
                                }
                                if (!regex.test(value)) {
                                  return Promise.reject(new Error(t(`${GUILD_MESSAGE}.message.validate_regex_guild_name`)))
                                }
                                if (value && value.includes('  ')) {
                                  return Promise.reject(new Error(textError2Space))
                                }
                                if (value && value && value.trim().length < 3) {
                                  return Promise.reject(new Error(t(`${GUILD_MESSAGE}.message.validate_req_guild_name`)))
                                }
                                return Promise.resolve()
                              }
                            }
                          ]}
                        >
                          <InputCustomV2
                            name='guildName'
                            maxLength={20}
                            type='text'
                            onKeyDown={handleKeyDown}
                            onChange={_handleGuildNameChange}
                            value={values.guildName}
                            placeholder={t(`${GUILD_MESSAGE}.place_guild_name`)}
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <div className='guil__content--mar5'>
                      <div className='guild-label'>
                        {t(`${GUILD_MESSAGE}.guild_tag`)}<span className='request__red'>{t(`${GUILD_MESSAGE}.message.validate_request`)}</span>
                      </div>
                      <div>
                        <Form.Item
                          name='guildTag'
                          className='input-box'
                          shouldUpdate
                          rules={[
                            { required: true, message: t(`${GUILD_MESSAGE}.message.validate_tag_length`) },
                            {
                              validator: (_, value) => {
                                if (value && REGEX_FIRST_SPACE.test(value)) {
                                  return Promise.reject(new Error(textErrorSpaceTag))
                                }
                                if (value && value.includes(' ')) {
                                  return Promise.reject(new Error(t(`${GUILD_MESSAGE}.message.validate_space_guild_name`)))
                                }
                                if (!regex.test(value)) {
                                  return Promise.reject(new Error(t(`${GUILD_MESSAGE}.message.validate_regex_guild_tag`)))
                                }
                                if (value && value.trim().length !== 3) {
                                  return Promise.reject(new Error(t(`${GUILD_MESSAGE}.message.validate_tag_length`)))
                                }
                                return Promise.resolve()
                              }
                            }
                          ]}
                        >
                          <InputCustomV2
                            type='text'
                            maxLength={3}
                            onKeyDown={handleKeyDown}
                            onChange={_handleChangeGuildTag}
                            placeholder={t(`${GUILD_MESSAGE}.place_guild_tag`)}
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <div className='guil__content--mar5'>
                      <div className='guild-label'>
                        {t(`${GUILD_MESSAGE}.guild_description`)}<span className='request__red'>{t(`${GUILD_MESSAGE}.message.validate_request`)}</span>
                      </div>
                      <div>
                        <Form.Item
                          name='guildDescription'
                          rules={[
                            { required: true, message: t(`${GUILD_MESSAGE}.message.validate_req_description`) },
                            { max: 500, message: t(`${GUILD_MESSAGE}.message.validate_max_char`) },
                            {
                              validator: (_, value) => {
                                if (value && value && value?.trim().length < 50) {
                                  return Promise.reject(new Error(t(`${GUILD_MESSAGE}.message.validate_min_char`)))
                                }
                                return Promise.resolve()
                              }
                            }
                          ]}
                          className='input-box'
                        >
                          <InputTextarea
                            type='textarea'
                            minLength={50}
                            maxLength={500}
                            value={values.guildDescription}
                            customClass='guild__textarea'
                            disabled={!statusEdit}
                            placeholder={t(`${GUILD_MESSAGE}.place_guild_description`)}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className='col-3 guil__content'>
                    <div className='guild-label guild__label--logo'>{t(`${GUILD_MESSAGE}.conditions_to_join`)}</div>
                    <div className='guild__content--logo'>
                      <img src={ONE_LINE} alt='' className='orange-line position-absolute' />
                      <div className='guild__content--condition'>
                        <div className='condition__checkbox'>
                          <img src={ONE_LINE} alt='' className='orange__line--checkbox position-absolute' />
                          <div className='condition__checkbox--text'>{t(`${GUILD_MESSAGE}.need_approval`)}</div>
                          <div className='guil__content--checkbox mg__top--0'>
                            <input
                              id="need_approval"
                              className={`guild__create--checkbox ${statusEdit ? '' : 'hidden__cursor'}`}
                              type='checkbox'
                              onChange={onChangeCheckboxNeedApproval}
                              checked={valCheckboxNeedApproval}
                              disabled={!statusEdit}
                            />
                            <label htmlFor='need_approval' className={`checkbox__lable ${statusEdit ? '' : 'hidden__cursor'}`}></label>
                            <div className='border__cover'></div>
                          </div>
                        </div>

                        <div className="guil__content--mar10">
                          <div className='guild-label'>
                            {t(`${GUILD_MESSAGE}.total_race`)}
                          </div>
                          <div>
                            <Form.Item
                              name='totalRace'
                              className='input-box'
                              shouldUpdate
                              help={checkErrorTotalRace ? totalRaceError : ''}
                              validateStatus={checkErrorTotalRace ? 'error' : ''}
                              rules={[
                                { required: true, message: checkErrorTotalRace ? t(`${GUILD_MESSAGE}.message.validate_total_race`) : '' },
                                {
                                  validator: (_, value) => {
                                    if (value?.length > 20) {
                                      return Promise.reject(new Error(t(`${GUILD_MESSAGE}.message.validate_name_max_length`)))
                                    }
                                    return Promise.resolve()
                                  }
                                }
                              ]}
                            >
                              <InputCustomV2
                                maxLength={20}
                                type='text'
                                onKeyDown={handleKeyDownTotalRace}
                                onChange={_handleChangeGuildTotalRace}
                                value={values.totalRace}
                                disabled={!statusEdit}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {statusEdit && <div className='color-DEDEDE row'>
                  <div className='col-4'></div>
                  <div className={`col-8 guil__content--checkbox`}>
                    <input
                      id="guild_create_checkbox"
                      className='guild__create--checkbox'
                      type='checkbox'
                      onChange={() => onChangeCheckbox()}
                    />
                    <label htmlFor='guild_create_checkbox' className='checkbox__lable'>
                    <span className='checkbox_text'>{t(`${GUILD_MESSAGE}.checkbox_accept`)}</span>
                      <a 
                        rel='noopener noreferrer'
                        href={links.home.privacy()}
                        target='_blank'
                      >
                        <span className='checkbox_text'>{t(`${GUILD_MESSAGE}.checkbox_accept2`)}</span>
                      </a>
                    </label>
                    <div className='border__cover'></div>
                  </div>
                </div>}

                <div className='row line__guild--gradiant'>
                  <MemoizedLoadImageCommon srcImg={LINE_GUILD_GRADIENT} />
                </div>

                <div className='btn-create'>
                  {statusEdit &&
                    <Form.Item>
                      <Button
                        buttonName={
                          <div>
                            {t(`${GUILD_MESSAGE}.btn_save`)}
                          </div>
                        }
                        onClickButton={form.submit}
                        width={147}
                        disabled={isTextValid || isError}
                      />
                    </Form.Item>
                    ||
                    <Form.Item>
                      <Button
                        buttonName={
                          <div>
                            {t(`${GUILD_MESSAGE}.btn_edit`)}
                          </div>
                        }
                        onClickButton={form.submit}
                        width={147}
                      />
                    </Form.Item>}
                </div>
              </div>
            )
          }}
        </Form>
      )}
      {isShowModalConfirmEditGuild && (
        <ModalConfirmEditGuild
          onClose={handleCloseModalConfirmEditGuild}
          onConfirm={handleConfirmEditGuild}
          titleModal={t(`${GUILD_MESSAGE}.guild_title_edit`)}
          loading={loading}
          content={t(`${GUILD_MESSAGE}.guild_modal_edit_content`)}
          error={createGuildError}
          margingTop={true}
        />
      )}

      {isRequestLoginModalOpen && <RequestLoginModal toggleIsModalOpen={toggleIsRequestLoginModalOpen} />}
    </UpdateInfoStyled>
  )
}
