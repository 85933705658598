import { GUILD_BG_SELECT } from 'assets/images'
import styled from 'styled-components'

const SelectCustomStyled = styled.div`
  position: absolute;
  background: url(${GUILD_BG_SELECT});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-color: transparent;
  width: 160px;
  height: 42px;

  .select-box-customs {
    position: relative;
    width: 160px;
    color: #6d6d6d;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    height: 40px;
    font-weight: 600;
    font-size: 16px;
    background-color: transparent;
    
    .filter-icon {
      margin-right: 8px;
    }

    span.select-name {
      position: absolute;
      top: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 14px;
      margin-top: 6px;
    }
    img.select-drop-icon {
      position: absolute;
      width: 25px;
      height: 25px;
      transform: translateX(220%);
      top: 9px;
    }
    img.up-down-icon {
      transform: scaleY(-1) translateX(220%);
    }
    .orange-line {
      right: 0;
      bottom: -2px;

      width: 100%;
      height: 3px;
    }

    &.select-custom {
      ul.select-content-dropdown {
        li {
          text-align: left;
        }
      }
    }
  }

  ul.select-content-dropdown {
    z-index: 111;
    position: absolute;
    top: 44px;
    left: 3px;
    right: 0;
    width: 100%;
    background-color: transparent;
    margin: 0;
    margin-top: 2px;
    padding: 0;
    list-style: none;
    background: #232840;
    /* background: linear-gradient(180deg, rgba(25, 29, 43, 0.8) 0%, #090911 100%); */

    li {
      color: #ffffff;
      padding: 6px 12px;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      border-left: 1px solid #eec32c;
      border-right: 1px solid #eec32c;
      cursor: pointer;
      text-align: left;
    }
    li.active {
      background: linear-gradient(
        90deg,
        rgba(227, 109, 2, 0) -2.08%,
        rgba(255, 122, 0, 0.45) 47.38%,
        rgba(227, 109, 2, 0) 101.56%
      );
    }
    li:hover {
      /* background: linear-gradient(
        90deg,
        rgba(227, 109, 2, 0) -2.08%,
        rgba(255, 122, 0, 0.45) 47.38%,
        rgba(227, 109, 2, 0) 101.56%
      ); */
      /* filter: grayscale(1); */
      background: rgb(27,29,38);
      background: linear-gradient(90deg, rgba(27,29,38,1) 0%, rgba(74,75,81,1) 50%, rgba(27,29,38,1) 100%);
    }

    .img_contain {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin-right: 5px;
      margin-top: -5px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .img_select {
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    margin-top: -5px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .bottom_line {
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #eec32c;
  }
`

export default SelectCustomStyled
