import { TICKED } from 'assets/images'
import styled from 'styled-components'

const TimeLineStyled = styled.div`
  display: flow-root;
  h1 {
    text-align: center;
    text-transform: uppercase;
  }

  /* .container {
    width: 1200px;
    margin: auto;
  } */

  .timeline {
    /* counter-reset: test 20; */
    position: relative;
  }

  .timeline li {
    color: #f1f1f1;
    z-index: 0;
    list-style: none;
    float: left;
    width: 25%;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
    .day-time {
      font-size: 16px;
    }
  }

  ul:nth-child(1) {
    color: #4caf50;
  }

  .timeline li:before {
    /* counter-increment: test; */
    content: '';
    width: 30px;
    height: 30px;
    border: 1px solid #4caf50;
    border-radius: 50%;
    display: block;
    text-align: center;
    line-height: 30px;
    margin: 0 auto 10px auto;
    /* background: radial-gradient(50% 50% at 50% 50%, #48f7b7 0%, rgba(78, 242, 130, 0) 100%); */
    color: #000;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
  }

  .timeline .active-ticked:before {
    background-image: url(${TICKED});
    background-repeat: no-repeat;
    background-position: center;
  }

  .timeline li:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background: radial-gradient(50% 50% at 50% 50%, #48f7b7 0%, rgba(78, 242, 130, 0) 100%);
    top: 15px;
    left: -50%;
    transition: all ease-in-out 0.3s;
    z-index: -1;
  }

  .timeline li:first-child:after {
    content: none;
  }
  .timeline li.active-tl {
    color: #45cb4a;
  }
  .timeline li.active-tl:before {
    background: radial-gradient(50% 50% at 50% 50%, #48f7b7 0%, rgba(78, 242, 130, 0) 100%);
    color: #f1f1f1;
  }

  .timeline li.active-tl + li:after {
    background: radial-gradient(50% 50% at 50% 50%, #48f7b7 0%, rgba(78, 242, 130, 0) 100%);
  }
`

export default TimeLineStyled
