/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spin } from 'antd'
import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import { useToggle } from 'hooks'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { APP_BUTTON, APP_ICON_BELL, APP_LOGOMH, TWO_LINE } from 'assets/images'
import { convertGasPrice, getMobileOperatingSystem, handleAsyncRequest } from 'utils/helper'
import { getPostLoginBody, getSigner } from 'utils/metamask'

import { configs } from 'apps'
import {
  CURRENCY_TYPE,
  LINK_APP,
  OPERATING_SYSTEM,
  STATUS_TRANSACTION,
  TIME_CLOSE_STATS_MODAL
} from 'apps/constants'
import ConfirmModal from 'features/Mobile/components/ConfirmModal'
import {NOTIFICATION_MESSAGE} from 'i18n/constants'
import horseFarmABI from 'json/HorseFarm.json'
import horseFarmABI_V2 from 'json/HorseFarm_V2.json'
import horseNFTABI from 'json/HorseNFT.json'
import horseNFTABI_V2 from 'json/HorseNFT_V2.json'
import specialHorseFarm from 'json/SpecialHorseFarm.json'
import specialHorseFarm_V2 from 'json/SpecialHorseFarm_V2.json'
import tokenGateAbi from 'json/NativeVault.json'
import mareABI from 'json/TokenMARE.json'
import nativeVaultAbi from 'json/NativeVault.json'

import { useTranslation } from 'react-i18next'
import StyledMobile from './styled'
import itemNFTABI from "../../../../json/ItemNFT.json";
import shopApNFTABI from "../../../../json/ShopAP.json";
import guildFarm from "../../../../json/GuildFarm.json";
import guildFarm_V2 from "../../../../json/GuildFarm_V2.json";

const ACTIONS = {
  sign: 'sign',
  lease: 'lease',
  withdraw: 'withdraw',
  depositMARE: 'depositMARE',
  claimMareFromBiru: 'claimMareFromBiru',
  depositAdil: 'depositAdil',
  claimAdil: 'claimAdil',
  addStable: 'addStable',
  withdrawStable: 'withdrawStable',
  sellItemNFT: 'sellItemNFT',
  cancelItemNFT: 'cancelItemNFT',
  buyItemNFT: 'buyItemNFT',
  withdrawHorseFromGuildFarm: 'withdrawHorseFromGuildFarm',
  addHorseToGuildFarm: 'addHorseToGuildFarm'
}
export default function Mobile() {
  const [valueCopy, setValueCopy] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [isTransactionModal, toggleIsTransactionModal] = useToggle(false)
  const [searchParams] = useSearchParams()
  const paramsSearch = Object.fromEntries(searchParams.entries())
  const [isDepositCoin, setIsDepositCoin] = useState<boolean>(false)
  const [isApproveContract, setIsApproveContract] = useState<boolean>(false)
  const [isApproveStable, setIsApproveStable] = useState<boolean>(false)
  const [isLoading, toggleIsLoading] = useToggle(true)
  const [isMetamask, toggleIsMetamask] = useToggle(false)
  const [isSuccess, toggleIsSuccess] = useToggle(false)
  const [isLoadingTransaction, toggleIsLoadingTransaction] = useToggle(false)
  const [isApproveSell, setIsApproveSell] = useState<boolean>(false)
  const [isApproveHorseToGuildFarm, setIsApproveHorseToGuildFarm] = useState<boolean>(false)

  const { t } = useTranslation()
  

  const contract = {
    tokenMARE: configs.tokenMARE,
    tokenGate: configs.tokenGate,
    tokenEMAS: configs.tokenEMAS,
    transporter: configs.transporter,
    horseNFT: configs.horseNFT,
    horseNFT_v2: configs.horseNFT_v2,
    horseFarm: configs.horseFarm,
    horseFarm_v2: configs.horseFarm_v2
  }

  const MESSAGES = {
    signOk: 'You are connected to the metamask. <br/> Come back to the game for a great experience.',
    signOkAdilWallet: 'You are connected to the adil wallet. <br/> Come back to the game for a great experience.',
    signFail: 'You cannot connect to metamask. <br/> Come back to the game to try again.',
    signFailAdilWallet: 'You cannot connect to adil wallet. <br/> Come back to the game to try again.',
    transactionOk: 'You have completed the transaction on metamask. <br/> Come back to the game for a great experience.',
    transactionOkAdilWallet: 'You have completed the transaction on adil wallet. <br/> Come back to the game for a great experience.',
    transactionFail: 'You cannot complete the transaction on the metamask. <br/> Come back to the game to try again.',
    transactionFailAdilWallet: 'You cannot complete the transaction on the adil wallet. <br/> Come back to the game to try again.',
    signOkModal: `You have successfully signed. <br/> Let's go back to the game`,
    signFailModal: `You sign failed. <br/> Try again.`,
    transactionOkModal: `Successful transaction. <br/> Let's go back to the game.`,
    transactionFailModal: `Transaction failed. Try again.`,
    transactionPendingModal: `Your transaction was unsuccessful.<br/>It is likely that your transaction is being processed.<br/>Please check back in a few minutes.`,
    approveOkModal: `Successful approved.`,
    approveFailModal: `Approved failed. Try again.`,
  }
  const fetchChainId = async () => {
    try {
      const chainId = await window?.ethereum?.request({ method: 'eth_chainId' })
      if (chainId !== configs.chainIdDirect) {
        switchMetamaskNetwork(chainId)
      } else {
        handleTransactionMetamask(paramsSearch?.action)
      }
    } catch (err) {
      handleTransactionMetamask(paramsSearch?.action)
    }
  }

  useEffect(() => {  
    fetchChainId()
    toggleIsMetamask(parseInt(paramsSearch?.current_time) > 0 ? true : false)
    setTimeout(() => {
      toggleIsLoading(false)
    }, TIME_CLOSE_STATS_MODAL)
  }, [])

  const switchMetamaskNetwork = async (chainId: string) => {
    if (chainId !== configs.chainIdDirect) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: configs.chainIdDirect }]
        })
        window.location.reload()
      } catch (err: any) {
        // This error code indicates that the chain has not been added to MetaMask
        if (JSON.stringify(err?.code) === '4902' || JSON.stringify(err?.code) === '-32603') {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainName: configs.nameChain,
                chainId: configs.chainIdDirect,
                nativeCurrency: {
                  name: configs.nameCurrency,
                  decimals: +configs.valueDecimals,
                  symbol: configs.nameCurrency
                },
                rpcUrls: [configs.linkRpcUrls]
              }
            ]
          })
          window.location.reload()
        }
      }
    }
  }

  useEffect(() => {
    if (isDepositCoin === true) {
      exchangeMareToKudaConfirm()
    }
  }, [isDepositCoin])

  const transactionSuccess = () => {
    toggleIsLoadingTransaction(false)
    toggleIsTransactionModal(true)
    toggleIsLoading(false)
    setMessage(MESSAGES.transactionOkModal)
    setValueCopy([402, MESSAGES.transactionOkModal].join('|'))
    toggleIsSuccess(true)
  }

  const transactionError = (valueError: any, mesage: string) => {
    handleErrorTransaction(valueError, mesage)
    setMessage(mesage)
    toggleIsTransactionModal(true)
    toggleIsLoading(false)
  }

  // start: If you check for more than 30 seconds, it's still pending, the message is pending
  // useEffect(() => {
  //   if (isLoadingTransaction) {
  //     const timer = setTimeout(() => {
  //       toggleIsTransactionModal(true)
  //       setMessage(MESSAGES.transactionFail)
  //     }, TIME_TRANSACTION)
  //     return () => {
  //       clearTimeout(timer)
  //     }
  //   }
  // }, [isLoadingTransaction])
  // end

  const sign = async () => {
    const [, signer] = await handleAsyncRequest(getSigner())
    if (!signer) return
    const [error, postLoginBody] = await handleAsyncRequest(getPostLoginBody(signer, paramsSearch.data))
    if (error) {
      setMessage(MESSAGES.signFailModal)
      toggleIsLoading(true)
      toggleIsTransactionModal(true)
      setValueCopy([400, 'Sign failed'].join('|'))
      return
    }
    if (postLoginBody) {
      setValueCopy([postLoginBody.address, postLoginBody.message, postLoginBody.sign].join('|'))
      setMessage(MESSAGES.signOkModal)
      toggleIsTransactionModal(true)
      toggleIsSuccess(true)
      toggleIsLoading(true)
    }
  }

  const exchangeMareToKuda = async () => {
    const { ethereum } = window
    if (!ethereum) return
    const [, signer] = await handleAsyncRequest(getSigner())
    if (!signer) return
    const paramData = JSON.parse(paramsSearch.data)
    const amountToApprove = new BigNumber(paramData.blockchain_amount).toFixed()
    const mareContract = new ethers.Contract(contract.tokenMARE, mareABI.contract.abi, signer)
    try {
      await mareContract.approve(contract.transporter, amountToApprove, { gasPrice: convertGasPrice(+paramData.gas_price) })
      setIsDepositCoin(true)
      setMessage(MESSAGES.approveOkModal)
      toggleIsLoading(true)
    } catch (err) {
      transactionError(err, MESSAGES.approveFailModal)
    }
  }
  const handleErrorTransaction = async (err: any, message: string) => {
    const [, signer] = await handleAsyncRequest(getSigner())
    if (!signer) return
    const addressUser = await signer.getAddress()
    const { error } = err
    if (error && error?.data?.message) {
      setValueCopy([400, addressUser, error?.data?.message].join('|'))
    } 
	else { 
		if (error && error?.message) {
			setValueCopy([400, addressUser, error?.message].join('|'))
		} 
		else {
			setValueCopy([400, addressUser, message].join('|')) 
		}
	}
  }

  const exchangeMareToKudaConfirm = async () => {
    const { ethereum } = window
    if (!ethereum) return
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const [, signer] = await handleAsyncRequest(getSigner())
    if (!signer) return
    const paramData = JSON.parse(paramsSearch.data)
    try {
      const tokenGateContract = new ethers.Contract(contract.tokenGate, tokenGateAbi.contract.abi, signer)
      const coverBlockExpired = new BigNumber(paramData.block_expired).toFixed()
      const amountToApprove = new BigNumber(paramData.blockchain_amount).toFixed()
      const tx = await tokenGateContract.deposit({
        owner: paramData.owner,
        token: paramData.token,
        blockExpired: coverBlockExpired,
        amount: amountToApprove,
        none: paramData.nonce,
        v: paramData.v,
        r: paramData.r,
        s: paramData.s
      }, { gasPrice: convertGasPrice(+paramData.gas_price) })
      if (tx.hash) {
        toggleIsLoadingTransaction(true)
        const resultTransaction = await provider.waitForTransaction(tx.hash)
        if (resultTransaction.status) {
          transactionSuccess()
        } else {
          transactionError(STATUS_TRANSACTION.expired, MESSAGES.transactionFailModal)
        }
      }

    } catch (err) {
      transactionError(err, MESSAGES.transactionFailModal)
    }
  }

  const claimMareFromBiru = async () => {
    const { ethereum } = window
    if (!ethereum) return
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    try {
      const [, signer] = await handleAsyncRequest(getSigner())
      if (!signer) return
      const paramData = JSON.parse(paramsSearch.data)
      const coverBlockExpired = new BigNumber(paramData.block_expired).toFixed()
      const amountToClaim = new BigNumber(paramData.blockchain_amount).toFixed()
      const tokenGateContract = new ethers.Contract(contract.tokenGate, tokenGateAbi.contract.abi, signer)
      const tx = await tokenGateContract.claim({
        owner: paramData.owner,
        token: paramData.token,
        blockExpired: coverBlockExpired,
        amount: amountToClaim,
        none: paramData.nonce,
        v: paramData.v,
        r: paramData.r,
        s: paramData.s
      }, { gasPrice: convertGasPrice(+paramData.gas_price) })

      if (tx.hash) {
        toggleIsLoadingTransaction(true)
        const resultTransaction = await provider.waitForTransaction(tx.hash)
        if (resultTransaction.status) {
          transactionSuccess()
        } else {
          transactionError(STATUS_TRANSACTION.expired, MESSAGES.transactionFailModal)
        }
      }

    } catch (err) {
      transactionError(err, MESSAGES.transactionFailModal)
    }
  }
  const depositAdil = async ()=>{
    const { ethereum } = window
    if (!ethereum) return
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    try {
      const [, signer] = await handleAsyncRequest(getSigner())
      if (!signer) return
      const paramData = JSON.parse(paramsSearch.data)
      const amountToClaim = new BigNumber(paramData.blockchain_amount).toFixed()
      const nativeVaultContract = new ethers.Contract(contract.tokenGate, nativeVaultAbi.contract.abi, signer)
      const tx = await nativeVaultContract.deposit(paramData.nonce,
      { gasPrice: convertGasPrice(+paramData.gas_price),
          value: amountToClaim
      })
      
      if (tx.hash) {
        toggleIsLoadingTransaction(true)
        const resultTransaction = await provider.waitForTransaction(tx.hash)
        if (resultTransaction.status) {
          transactionSuccess()
        } else {
          transactionError(STATUS_TRANSACTION.expired, MESSAGES.transactionFailModal)
        }
      }

    } catch (err) {
      transactionError(err, MESSAGES.transactionFailModal)
    }
  }
  const claimAdil = async ()=>{
    const { ethereum } = window
    if (!ethereum) return
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    try {
      const [, signer] = await handleAsyncRequest(getSigner())
      if (!signer) return
      const paramData = JSON.parse(paramsSearch.data)
      const coverBlockExpired = new BigNumber(paramData.block_expired).toFixed()
      const amountToClaim = new BigNumber(paramData.blockchain_amount).toFixed()
      const nativeVaultContract = new ethers.Contract(contract.tokenGate, nativeVaultAbi.contract.abi, signer)
      const tx = await nativeVaultContract.swapToken({
        sender: paramData.owner,
        token: paramData.token,
        blockExpired: coverBlockExpired,
        amount: amountToClaim,
        nonce: paramData.nonce,
        v: paramData.v,
        r: paramData.r,
        s: paramData.s
      }, { gasPrice: convertGasPrice(+paramData.gas_price) })

      if (tx.hash) {
        toggleIsLoadingTransaction(true)
        const resultTransaction = await provider.waitForTransaction(tx.hash)
        if (resultTransaction.status) {
          transactionSuccess()
        } else {
          transactionError(STATUS_TRANSACTION.expired, MESSAGES.transactionFailModal)
        }
      }

    } catch (err) {
      transactionError(err, MESSAGES.transactionFailModal)
    }
  }

  useEffect(() => {
    if (isApproveContract === true) {
      handleLeaseHorseContract()
    }
  }, [isApproveContract])

  // const abi = [
  //   "function approve(address to, uint amount)"
  // ]
  // const CONTRACT_ADDRESS = "0x19A15A77a798E6221dddB8911BC4264aa343F1c7";
  // const contract = new ethers.Contract(CONTRACT_ADDRESS, abi);



  // const TO = "0x19A15A77a798E6221dddB8911BC4264aa343F1c7";
  // const transfer = await contract.approve.populateTransaction(TO, '1');
  // console.log({ transfer });

  const leaseHorseApprove = async () => {
    const [, signer] = await handleAsyncRequest(getSigner())
    // if (!signer) return
    // const leaseHorseContract = new ethers.Contract(contract.horseNFT, horseNFTABI.contract.abi, signer)
    const paramData = JSON.parse(paramsSearch.data)
    const tokenId = paramData.token_id
    let contractApprove: any = '';
    let toParam: any = '';

    if (paramData?.horse_contract?.toUpperCase() === contract.horseNFT?.toUpperCase()) {
      contractApprove = new ethers.Contract(contract.horseNFT, horseNFTABI.contract.abi, signer)
      toParam = contract.horseNFT;
    } else {
      contractApprove = new ethers.Contract(contract.horseNFT_v2, horseNFTABI_V2.contract.abi, signer)
      toParam = contract.horseNFT_v2;
    }

    try {
      // await contractApprove.approve(contract.transporter, tokenId, { gasPrice: convertGasPrice(+paramData.gas_price) })
      const approve = await contractApprove.populateTransaction.approve(contract.transporter, tokenId)
      await window.ethereum.request({
        method: 'eth_sendTransaction',
        params: [
          {
            from: window.ethereum.selectedAddress,
            to: toParam,
            value: "0x0",
            data: approve.data
          },
        ],
      })
      setIsApproveContract(true)
      toggleIsLoading(true)
    } catch (err: any) {
      transactionError(err, err)
    }
  }

  const handleLeaseHorseContract = async () => {
    const { ethereum } = window
    if (!ethereum) return
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    const leaseContract = new ethers.Contract(contract.horseFarm_v2, horseFarmABI_V2.contract.abi, signer);
    // const leaseContract = new ethers.Contract(contract.horseFarm, horseFarmABI.contract.abi, signer)
    const paramData = JSON.parse(paramsSearch.data)
    const coverHorseId = new BigNumber(paramData.token_id).toFixed()
    const coverBlockExpired = new BigNumber(paramData.block_expired).toFixed()
    try {
      const tx = await leaseContract.lease({
        owner: paramData.owner,
        horseAddress: paramData.horse_contract,
        horseId: coverHorseId,
        blockExpired: coverBlockExpired,
        nonce: paramData.nonce,
        v: paramData.v,
        r: paramData.r,
        s: paramData.s
      }, 
      paramData.token_items,
      { gasPrice: convertGasPrice(+paramData.gas_price) })

      if (tx.hash) {
        toggleIsLoadingTransaction(true)
        const resultTransaction = await provider.waitForTransaction(tx.hash)
        if (resultTransaction.status) {
          transactionSuccess()
        } else {
          transactionError(STATUS_TRANSACTION.expired, MESSAGES.transactionFailModal)
        }
      }
    } catch (err) {
      transactionError(err, MESSAGES.transactionFailModal)
    }
  }

  const withDrawHorse = async () => {
    const { ethereum } = window
    if (!ethereum) return
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const [, signer] = await handleAsyncRequest(getSigner())
    if (!signer) return
    const paramData = JSON.parse(paramsSearch.data)    
    const coverHorseId = new BigNumber(paramData.token_id).toFixed()
    const coverBlockExpired = new BigNumber(paramData.block_expired).toFixed()
    let leaseContract: any = '';   
    let param: any = '';

    if (paramData?.horse_farm_address?.toUpperCase() === contract.horseFarm?.toUpperCase()) {
      leaseContract = new ethers.Contract(contract.horseFarm, horseFarmABI.contract.abi, signer)

      param = {
        owner: paramData.owner,
        horseId: coverHorseId,
        blockExpired: coverBlockExpired,
        nonce: paramData.nonce,
        v: paramData.v,
        r: paramData.r,
        s: paramData.s
      }
    } else {
      leaseContract = new ethers.Contract(contract.horseFarm_v2, horseFarmABI_V2.contract.abi, signer)

      param = {
        owner: paramData.owner,
        horseAddress: paramData?.horse_contract,
        horseId: coverHorseId,
        blockExpired: coverBlockExpired,
        nonce: paramData.nonce,
        v: paramData.v,
        r: paramData.r,
        s: paramData.s
      }
    }

    try {
      const tx = await leaseContract.withdraw(
        param, 
        { gasPrice: convertGasPrice(+paramData.gas_price) }
      )
      if (tx.hash) {
        toggleIsLoadingTransaction(true)
        const resultTransaction = await provider.waitForTransaction(tx.hash)
        if (resultTransaction.status) {
          transactionSuccess()
        } else {
          transactionError(STATUS_TRANSACTION.expired, MESSAGES.transactionFailModal)
        }
      }
    } catch (err) {
      transactionError(err, MESSAGES.transactionFailModal)
    }
  }

  const copyToClipboard = async function (dataResult: any) {
    try {
      // focus from metamask back to browser
      window?.focus()
      // await new Promise((resolve) => setTimeout(resolve, 300));
      await navigator.clipboard.writeText(dataResult)
      setValueCopy(dataResult)
    } catch (err) {
      const input = document.createElement('input')
      input.type = 'text'
      input.value = dataResult
      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      // for metamask mobile android
    }
  }

  const handleTransactionMetamask = (type: string) => {
    switch (type) {
      case ACTIONS.sign:
        sign()
        break
      case ACTIONS.depositMARE:
        exchangeMareToKuda()
        break
      case ACTIONS.claimMareFromBiru:
        claimMareFromBiru()
        break
      case ACTIONS.depositAdil:
        depositAdil()
        break
      case ACTIONS.claimAdil:
        claimAdil()
        break
      case ACTIONS.lease:
        leaseHorseApprove()
        break
      case ACTIONS.withdraw:
        withDrawHorse()
        break
      case ACTIONS.withdrawStable:
        withdrawStable()
        break
      case ACTIONS.addStable:
        approveStable()
        break
      case ACTIONS.sellItemNFT:
        approveSellItem()
        break
      case ACTIONS.cancelItemNFT:
        cancelSellItemNFT()
        break
      case ACTIONS.buyItemNFT:
        buyItemNFT()
        break
      case ACTIONS.addHorseToGuildFarm:
        approveHorseToGuildFarm()
        break
      case ACTIONS.withdrawHorseFromGuildFarm:
        withdrawHorseFromGuildFarm()
        break

      default:
        break
    }
  }

  const isMobileDevice = () => {
    return 'ontouchstart' in window || 'onmsgesturechange' in window
  }
  const openMetaHorse = () => {
    if (isMobileDevice()) {
      window?.open(LINK_APP)
    } else {
      window?.open('https://metamask.io/', '_blank')
    }
  }

  const getLinkDownLoadApp = () => {
    switch (getMobileOperatingSystem()) {
      case OPERATING_SYSTEM.android:
        return configs.linkAndroid
      case OPERATING_SYSTEM.testflight:
        return configs.linkIOS
      case OPERATING_SYSTEM.window:
      default:
        return configs.linkIOS
    }
  }

  const backToGame = async () => {
    const [, signer] = await handleAsyncRequest(getSigner())
    if (!signer) {
      await copyToClipboard(valueCopy)
      openMetaHorse()
      return
    }
    const addressUser = await signer.getAddress()
    await copyToClipboard(valueCopy?.length > 0 ? valueCopy : [400, addressUser].join('|'))
    openMetaHorse()
  }

  const getMessage = () => {
    const isOpenAdilWallet = window.location.href.includes("adil-wallet");
    
    if (paramsSearch.action === ACTIONS.sign) {
      if (isSuccess) return isOpenAdilWallet? MESSAGES.signOkAdilWallet : MESSAGES.signOk
      else return isOpenAdilWallet? MESSAGES.signFailAdilWallet : MESSAGES.signFail
    } else {
      if (isSuccess) return isOpenAdilWallet? MESSAGES.transactionOkAdilWallet : MESSAGES.transactionOk
      else return isOpenAdilWallet? MESSAGES.transactionFailAdilWallet : MESSAGES.transactionFail
    }
  }

  const handleConfirmModal = async () => {
    toggleIsLoading(false)
    toggleIsTransactionModal(false)
    toggleIsLoadingTransaction(false)
    await copyToClipboard(valueCopy)
    openMetaHorse()
  }

  // start: special race

  const withdrawStable = async () => {
    const { ethereum } = window
    if (!ethereum) return
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const [, signer] = await handleAsyncRequest(getSigner())
    if (!signer) return
    const paramData = JSON.parse(paramsSearch.data)
    const coverBlockExpired = new BigNumber(paramData.block_expired).toFixed()
    const coverHorseId = new BigNumber(paramData.token_ids).toFixed()
    let withdrawContract: any = '';
    let param: any = '';

    if (paramData?.horse_farm_address?.toUpperCase() === configs.specialHorseFarm?.toUpperCase()) {
      withdrawContract = new ethers.Contract(configs.specialHorseFarm, specialHorseFarm.contract.abi, signer)

      param = {
        owner: paramData.owner,
        horseId: paramData.token_ids,
        blockExpired: coverBlockExpired,
        nonce: paramData.nonce,
        v: paramData.v,
        r: paramData.r,
        s: paramData.s
      }
    } else {
      withdrawContract = new ethers.Contract(configs.specialHorseFarm_V2, specialHorseFarm_V2.contract.abi, signer)

      param = {
        owner: paramData.owner,
        horseAddress: paramData?.horse_contract,
        horseId: coverHorseId,
        blockExpired: coverBlockExpired,
        nonce: paramData.nonce,
        v: paramData.v,
        r: paramData.r,
        s: paramData.s
      }
    }

    try {
      const tx = await withdrawContract.withdraw(param, 
        { gasPrice: convertGasPrice(+paramData.gas_price) }
      )
      if (tx.hash) {
        toggleIsLoadingTransaction(true)
        const resultTransaction = await provider.waitForTransaction(tx.hash)
        if (resultTransaction.status) {
          transactionSuccess()
        } else {
          transactionError(STATUS_TRANSACTION.expired, MESSAGES.transactionFailModal)
        }
      }
    } catch (err) {
      transactionError(err, MESSAGES.transactionFailModal)
    }
  }

  const approveStable = async () => {
    const [, signer] = await handleAsyncRequest(getSigner())
    if (!signer) return
    const paramData = JSON.parse(paramsSearch.data)
    let leaseHorseContract: any = '';

    if (paramData?.horse_contract?.toUpperCase() === configs.horseNFT?.toUpperCase()) {
      leaseHorseContract =  new ethers.Contract(contract.horseNFT, horseNFTABI.contract.abi, signer)
    } else {
      leaseHorseContract =  new ethers.Contract(contract.horseNFT_v2, horseNFTABI_V2.contract.abi, signer)
    }

    const coverTokenId = new BigNumber(paramData.token_ids).toFixed()
    try {
      await leaseHorseContract.approve(contract.transporter, coverTokenId, { gasPrice: convertGasPrice(+paramData.gas_price) })
      setIsApproveStable(true)
      toggleIsLoading(true)
    } catch (err) {
      transactionError(err, MESSAGES.approveFailModal)
    }
  }

  const addStable = async () => {
    const { ethereum } = window
    if (!ethereum) return
    const provider = new ethers.providers.Web3Provider(window.ethereum)
    const signer = provider.getSigner()
    const leaseContract = new ethers.Contract(configs.specialHorseFarm_V2, specialHorseFarm_V2.contract.abi, signer)
    const paramData = JSON.parse(paramsSearch.data)
    const coverBlockExpired = new BigNumber(paramData.block_expired).toFixed()
    const coverTokenId = new BigNumber(paramData.token_ids).toFixed()
    
    try {
      const tx = await leaseContract.lease({
        owner: paramData.owner,
        horseAddress: paramData.horse_contract,
        horseId: coverTokenId,
        blockExpired: coverBlockExpired,
        nonce: paramData.nonce,
        v: paramData.v,
        r: paramData.r,
        s: paramData.s
      }, 
      paramData.token_items,
      { gasPrice: convertGasPrice(+paramData.gas_price) })

      if (tx.hash) {
        toggleIsLoadingTransaction(true)
        const resultTransaction = await provider.waitForTransaction(tx.hash)
        if (resultTransaction.status) {
          transactionSuccess()
        } else {
          transactionError(STATUS_TRANSACTION.expired, MESSAGES.transactionFailModal)
        }
      }
    } catch (err) {
      transactionError(err, MESSAGES.transactionFailModal)
    }
  }

  useEffect(() => {
    if (isApproveStable) {
      addStable()
    }
  }, [isApproveStable])

  // end: special race
  
  //start: shop
  const approveSellItem = async () => {
    try {
      const { ethereum } = window
      if (!ethereum) return
      const [, signer] = await handleAsyncRequest(getSigner())
      if (!signer) return
      const paramData = JSON.parse(paramsSearch.data)
      const itemNFTContract = new ethers.Contract(configs.itemNFT, itemNFTABI.contract.abi, signer)
      const gasPrice = convertGasPrice(+paramData.gas_price)
      const tokenId = Number(paramData.token_id);
      try {
        await itemNFTContract.approve(configs.shopAP, tokenId, { gasPrice: gasPrice })
        setIsApproveSell(true);
        toggleIsLoading(true)
      } catch (err) {
        transactionError(err, MESSAGES.approveFailModal)
      }
    } catch (err) {
      transactionError(err, MESSAGES.transactionFailModal)
    }
  }

  const sellItemNFT = async () => {
    try {
      const { ethereum } = window
      if (!ethereum) return
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()
      const paramData = JSON.parse(paramsSearch.data)
      const sellItemContract = new ethers.Contract(configs.shopAP, shopApNFTABI.contract.abi, signer)
      const gasPrice = convertGasPrice(+paramData.gas_price)
      const price = paramData.currency == CURRENCY_TYPE.MERAH? "0" : ethers.utils.parseEther(paramData.price.toString()).toString()
      const tokenId = Number(paramData.token_id);
      try {
        const tx = await sellItemContract.sell(configs.itemNFT, tokenId, price, paramData.token_address, 'adil', {
          gasPrice: gasPrice
        })
        if (tx.hash) {
          toggleIsLoadingTransaction(true)
          const resultTransaction = await provider.waitForTransaction(tx.hash)
          if (resultTransaction.status) {
            transactionSuccess()
          } else {
            transactionError(STATUS_TRANSACTION.expired, MESSAGES.transactionFailModal)
          }
        }
      } catch (err) {
        transactionError(err, MESSAGES.transactionFailModal)
      }
    } catch (err) {
      transactionError(err, MESSAGES.transactionFailModal)
    }
  }

  useEffect(() => {
    if (isApproveSell) {
      sellItemNFT()
    }
  }, [isApproveSell])
  
  const cancelSellItemNFT = async () => {
    try {
      const { ethereum } = window
      if (!ethereum) return
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()
      const paramData = JSON.parse(paramsSearch.data)
      const shopAPContract = new ethers.Contract(configs.shopAP, shopApNFTABI.contract.abi, signer)
      const gasPrice = convertGasPrice(+paramData.gas_price)
      const orderId = Number(paramData.order_id);
      try {
        const tx = await shopAPContract.cancelSell(orderId, configs.itemNFT, {
          gasPrice: gasPrice
        })
        if (tx.hash) {
          toggleIsLoadingTransaction(true)
          const resultTransaction = await provider.waitForTransaction(tx.hash)
          if (resultTransaction.status) {
            transactionSuccess()
          } else {
            transactionError(STATUS_TRANSACTION.expired, MESSAGES.transactionFailModal)
          }
        }
      } catch (err) {
        transactionError(err, MESSAGES.transactionFailModal)
      }
    }
    catch (err) {
      transactionError(err, MESSAGES.transactionFailModal)
    }
  }

  const buyItemNFT = async () => {
    try {
      const { ethereum } = window
      if (!ethereum) return
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()
      const paramData = JSON.parse(paramsSearch.data)
      const shopAPContract = new ethers.Contract(configs.shopAP, shopApNFTABI.contract.abi, signer)
      const gasPrice = convertGasPrice(+paramData.gas_price)
      const orderId = Number(paramData.order_id);
      try {
        const tx = await shopAPContract.buyNative(orderId, {
          gasPrice: gasPrice,
          value: ethers.utils.parseEther(paramData.price.toString())
        })
        if (tx.hash) {
          toggleIsLoadingTransaction(true)
          const resultTransaction = await provider.waitForTransaction(tx.hash)
          if (resultTransaction.status) {
            transactionSuccess()
          } else {
            transactionError(STATUS_TRANSACTION.expired, MESSAGES.transactionFailModal)
          }
        }
      } catch (err) {
        transactionError(err, MESSAGES.transactionFailModal)
      }
    }
    catch (err) {
      transactionError(err, MESSAGES.transactionFailModal)
    }
  }
  //end: shop
  
  //start: guild
  const withdrawHorseFromGuildFarm = async () => {
    try {
      const {ethereum} = window
      if (!ethereum) return
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()
      const paramData = JSON.parse(paramsSearch.data)
      const coverBlockExpired = new BigNumber(paramData.block_expired).toFixed()
      const coverHorseId = new BigNumber(paramData.token_ids || '').toFixed()
      let guildWithdrawContract: any = '';
      let param: any = '';

      if (paramData?.horse_farm_address?.toUpperCase() === configs.guildFarm?.toUpperCase()) {
        guildWithdrawContract = new ethers.Contract(configs.guildFarm, guildFarm.contract.abi, signer)

        param = {
          owner: paramData.owner,
          horseId: paramData.token_ids,
          blockExpired: coverBlockExpired,
          nonce: paramData.nonce,
          v: paramData.v,
          r: paramData.r,
          s: paramData.s
        }
      } else {
        guildWithdrawContract = new ethers.Contract(configs.guildFarm_V2, guildFarm_V2.contract.abi, signer)

        param = {
          owner: paramData.owner,
          horseAddress: paramData?.horse_contract,
          horseId: coverHorseId,
          blockExpired: coverBlockExpired,
          nonce: paramData.nonce,
          v: paramData.v,
          r: paramData.r,
          s: paramData.s
        }
      }

      try {
        const tx = await guildWithdrawContract.withdraw(
          param,
          { gasPrice: convertGasPrice(paramData.gas_price) }
        )
        if (tx.hash) {
          toggleIsLoadingTransaction(true)
          const resultTransaction = await provider.waitForTransaction(tx.hash)
          if (resultTransaction.status) {
            transactionSuccess()
          } else {
            transactionError(STATUS_TRANSACTION.expired, MESSAGES.transactionFailModal)
          }
        }
      } catch (err) {
        transactionError(err, MESSAGES.transactionFailModal)
      }
    } catch (err) {
      transactionError(err, MESSAGES.transactionFailModal)
    }
  }

  const approveHorseToGuildFarm = async () => {
    try {
      const { ethereum } = window
      if (!ethereum) return
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()
      const paramData = JSON.parse(paramsSearch.data)
      let horseContract: any = '';

      if (paramData?.horse_contract?.toUpperCase() === configs.horseNFT?.toUpperCase()) {
        horseContract = new ethers.Contract(configs.horseNFT, horseNFTABI.contract.abi, signer)
      } else {
        horseContract = new ethers.Contract(configs.horseNFT_v2, horseNFTABI_V2.contract.abi, signer)
      }

      const coverTokenId = new BigNumber(paramData.token_ids).toFixed()
      const coverGasPrice = convertGasPrice(paramData.gas_price)
      try {
        await horseContract.approve(contract.transporter, coverTokenId, { gasPrice: coverGasPrice })
        setIsApproveHorseToGuildFarm(true);
        toggleIsLoading(true)
      } catch (err) {
        transactionError(err, MESSAGES.transactionFailModal)
      }
    } catch (err) {
      transactionError(err, MESSAGES.transactionFailModal)
    }
  }

  useEffect(() => {
    if (isApproveHorseToGuildFarm) {
      addHorseToGuildFarm()
    }
  }, [isApproveHorseToGuildFarm])
  
  const addHorseToGuildFarm = async () => {
    try {
      const { ethereum } = window
      if (!ethereum) return
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()
      const paramData = JSON.parse(paramsSearch.data)
      const addHorseContract = new ethers.Contract(configs.guildFarm_V2, guildFarm_V2.contract.abi, signer)
      const coverBlockExpired = new BigNumber(paramData.block_expired).toFixed()
      const coverGasPrice = convertGasPrice(paramData.gas_price)
      const coverTokenId = new BigNumber(paramData.token_ids).toFixed()

      try {
        const tx = await addHorseContract.lease({
          owner: paramData.owner,
          horseAddress: paramData.horse_contract,
          horseId: coverTokenId,
          blockExpired: coverBlockExpired,
          nonce: paramData.nonce,
          v: paramData.v,
          r: paramData.r,
          s: paramData.s
        }, 
        paramData.token_items,
        { gasPrice: coverGasPrice })
        if (tx.hash) {
          toggleIsLoadingTransaction(true)
          const resultTransaction = await provider.waitForTransaction(tx.hash)
          if (resultTransaction.status) {
            transactionSuccess()
          } else {
            transactionError(STATUS_TRANSACTION.expired, MESSAGES.transactionFailModal)
          }
        }
      } catch (err) {
        transactionError(err, MESSAGES.transactionFailModal)
      }
    } catch (err) {
      transactionError(err, MESSAGES.transactionFailModal)
    }
  }
  //end: guild

  return (
    <StyledMobile>
      <div className='mobile app-container'>
        {((isLoading && isMetamask && !isTransactionModal) || isLoadingTransaction) && <Spin className='loading-icon' size='large' />}
        <div className={`${isMetamask && !isLoading ? 'app-background' : 'app'}`}>
          <img src={APP_LOGOMH} width={170} className='pt-4' />
          <div className={`${isMetamask ? 'app-block' : 'mobile-block'}`}>
            {isMetamask ? (
              !isLoading && !isLoadingTransaction && !isTransactionModal ? (
                <div className='app-content'>
                  <img src={APP_ICON_BELL} alt='icon-bell' width={60} />
                  <div >
                    <span dangerouslySetInnerHTML={{ __html: getMessage() }} />
                  </div>
                  {getMobileOperatingSystem() !== OPERATING_SYSTEM.testflight && (
                    <div className='btn-custom' onClick={backToGame}>
                      <img src={APP_BUTTON} width={170} alt='app-button' />
                      <span className='btn-text'>Back To Game</span>
                    </div>
                  )}
                </div>
              ) : ''
            ) : (
              <div className='mobile-container'>
                <div className='title-404 text-uppercase color-primary'>No mobile support</div>
                <img src={TWO_LINE} alt='' className='line' />
                <div className='title color-orange pt-4'>{t(`${NOTIFICATION_MESSAGE}.notSupportMobile`)}</div>
                <div className='btn-back'>
                  <a href={getLinkDownLoadApp()}>
                    <span className='btn-download'>Click Download {getMobileOperatingSystem()} </span>
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isTransactionModal && <ConfirmModal onConfirm={handleConfirmModal} message={<span dangerouslySetInnerHTML={{ __html: message }} />} />}
    </StyledMobile>
  )
}
