/* eslint-disable @typescript-eslint/no-explicit-any */
import { DATE_FORMAT, FULL_DATE_LOCAL, LONG_DATE, LONG_TIME, SHORT_DAY, SHORT_TIME, SHORT_TIME_MINUS } from 'apps/constants'
import dayjs from 'dayjs'

export const getTimeStampCurrent = () => {
  return dayjs().unix()
}

export const convertFullLongDate = (val: any) => {
  return dayjs(val).format(LONG_DATE)
}

export const convertFullDate = (val: any) => {
  return dayjs(val).format(DATE_FORMAT)
}

export const convertFullDateLocal = (val: any) => {
  return dayjs(val).format(FULL_DATE_LOCAL)
}

export const convertShortDay = (val: any) => {
  return dayjs(val).format(SHORT_DAY)
}

export const convertShortDayUnix = (val: any) => {
  return dayjs.unix(parseInt(val)).format(SHORT_DAY)
}

export const convertShortTime = (val: any) => {
  return dayjs(val).format(SHORT_TIME)
}

export const convertShortTimeUnix = (val: any) => {
  return dayjs.unix(parseInt(val)).format(SHORT_TIME)
}

export const convertShortTimeMinus = (val: any) => {
  return dayjs(val).format(SHORT_TIME_MINUS)
}

export const convertLongTime = (val: any) => {
  return dayjs(val).format(LONG_TIME)
}
