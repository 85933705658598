import styled from 'styled-components'

const ItemNFTStyled = styled.div`
  .item {
    width: 175px;
    height: 175px;
    position: relative;
    .btn-detail-container {
      position: absolute;
      top: 45%;
      left: 20%;
      display: none;
      clip-path: polygon(0 0, calc(100% - 14px) 0, 100% 14px, 100% 100%, 14px 100%, 0% calc(100% - 14px));
      background: linear-gradient(90deg, #4ef07621 0%, #48f7ba17 100%);
      z-index: 5;
      padding: 1px;
      width: 103px;
      height: 34px;
      aspect-ratio: 100 / 42;

      .btn-detail {
        clip-path: polygon(0 0, calc(100% - 14px) 0, 100% 14px, 100% 100%, 14px 100%, 0% calc(100% - 14px));
        border: none;
        background: linear-gradient(
          178.19deg,
          rgba(78, 240, 118, 0.5) 1.64%,
          rgba(72, 247, 186, 0) 47.93%,
          rgba(78, 240, 118, 0.5) 97.84%
        );
        font-size: 16px;
        line-height: 20px;
        width: 100%;
        height: 100%;
      }
    }
    &:hover {
      .btn-detail-container{
        display: block;
      }
    }

    .numb {
      position: absolute;
      top: 6%;
      left: 6%;
      span{
        color: #FFF566;
        font-size: 18px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 15%;
      }
    }
    .avt-item {
      position: absolute;
      height: 100px;
      top: 10%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1) !important;
    }
    .description {
      margin-bottom: 5px;

      span {
        img {
          width: 12px;
        }
      }
    }
    .description {
      position: absolute;
      width: 100%;
      bottom: 0;
      text-align: center;
      .star-amount{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;
        .numb{
          width: 48px;
          position: relative;
          img{
            width: 48px;
            -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
          }
          span {
            position: absolute;
            left: 0;
            color: #fff;
            font-size: 15px;
            width: 100%;
            text-align: center;
            bottom: 0px;
          }
        }
        .level{
          .rate-star{
            &.disable{
              display: none;
            }
          }
        }
      }
      h4 {
        color: #fff;
        font-size: 16px;
        margin-bottom: 5px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        padding: 0 5px;
      }
      span {
        color: #fff;
        font-size: 16px;
      }
    }
  }
  .item{
    overflow: hidden;
    .avt-item{
      top: 40%;
      left: 50%;
      transform: translate(-50%,-50%) scale(1.5);
    }
    &.body{
      .avt-item{
        top: 40%;
      }
    }
    &.leg{
      .avt-item{
        top: 45%;
      }
    }
    &.head{
      .avt-item{
        top: 40%;
      }
    }
  }
`

export default ItemNFTStyled
