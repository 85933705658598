/* eslint-disable @typescript-eslint/no-explicit-any */
import { Modal } from 'antd'
import { useState } from 'react'

import { Container, ModalContainer } from '../Armor/styled'

import ListItem from '../ListItem'
import Head from '../Head'
import Button from 'shared/Button'

const ChangeButton = ({ type, horseId, item, onChange, onSuccess }: any) => {
  const [showModal, setShowModal] = useState(false)

  const _onToggleModal = () => {
    setShowModal(!showModal)
    onChange();
  }

  const _onSuccess = () => {
    setShowModal(!showModal)
    onSuccess()
  }

  return (
    <Container>
      <Button buttonName='Change' onClickButton={_onToggleModal} width={130} />
      <Modal
        open={showModal}
        onCancel={_onToggleModal}
        footer={false}
        width={615}
        className='modal-item'
        style={{ background: 'transparent' }}
        bodyStyle={{
          background: 'transparent',
          padding: 0
        }}
      >
        <ModalContainer>
          <Head name='Select item' />
          <ListItem horseId={horseId} item={item} type={type} onItemChooseSuccess={_onSuccess} isChangeItem onButtonClick={_onToggleModal} />
        </ModalContainer>
      </Modal>
    </Container>
  )
}

export default ChangeButton
