/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spin } from 'antd'
import specialRaceApi from 'apis/specialRaceApi'
import { constants, paths } from 'apps'
import { CLOSE_BTN } from 'assets/images'
import { ChooseHorseItem } from 'features/Race/components'
import { useDebounce, useUpdateEffect } from 'hooks'
import { SPECIAL_MESSAGE } from 'i18n/constants'
import { defaultRegionFilters, HorseAvailable, MyHorseListParams } from 'models'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useLocation } from 'react-router-dom'
import { Modal, SearchInput } from 'shared'
import Button from 'shared/Button'
import { handleAsyncRequest } from 'utils/helper'
import ChooseHorseModalSpecialRaceStyled from './styled'
import SelectCustom from 'shared/SelectCustom'
import { NUMBER, STRING_REGION } from 'apps/constants'

interface ChooseHorseModalSpecialRaceProps {
  toggleIsModalOpen: (value?: boolean) => void
  onCloseButtonClick: () => void
  toggleIsModalSpecialRaceOpen: (value?: boolean) => void
  getHorseDetail: (value: HorseAvailable) => void
  isWithdraw: boolean
}

const myHorseListParams: MyHorseListParams = {
  limit: NUMBER.TEN,
  page: NUMBER.ONE
  // sort: 'horse_id-asc'
}

function ChooseHorseModalSpecialRace({
  toggleIsModalOpen,
  onCloseButtonClick,
  toggleIsModalSpecialRaceOpen,
  getHorseDetail,
  isWithdraw
}: ChooseHorseModalSpecialRaceProps) {
  const horseListRef = useRef<HTMLDivElement>(null)
  const [horseList, setHorseList] = useState<HorseAvailable[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [params, setParams] = useState<MyHorseListParams>(myHorseListParams)
  const { pathname: currentPathname } = useLocation()
  const [total, setTotal] = useState(NUMBER.ONE)
  const debounceSearchValue = useDebounce<string>(searchValue, constants.DEBOUNCE_TIME)
  const [regionSelect, setRegionSelect] = useState('Region')

  const handleOnChange = (horse: HorseAvailable) => {
    getHorseDetail(horse)
    toggleIsModalSpecialRaceOpen(true)
  }

  const checkIsOldFarm = () => {
    const slicePathName = currentPathname?.split('/').slice(2)[0]
    if (slicePathName === paths.subDomainSpecialRace.oldFarm()) return true
    else return false
  }

  const { t } = useTranslation()

  const fetchListRaces = async () => {
    setIsLoading(true)
    const [error, result] = await handleAsyncRequest(
      isWithdraw
        ? checkIsOldFarm()
          ? specialRaceApi.getOldFarmMyHorseInFarm({ ...params })
          : specialRaceApi.getMyHorseInFarm({ ...params })
        : specialRaceApi.getMyHorseSpecial({ ...params })
    )
    if (error) {
      setIsLoading(false)
      return
    }
    const records = result?.data.records
    if (records && records.length === 0) {
      // setLoader(true)
    }
    setTotal(result?.data.total)
    setHorseList([...horseList, ...records])
    setIsLoading(false)
  }

  useEffect(() => {
    fetchListRaces()
  }, [params])

  const handleSearchValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  useUpdateEffect(() => {
    setHorseList([])
    setParams({ ...params, page: 1, horseName: debounceSearchValue })
  }, [debounceSearchValue])

  const fetchMoreRaces = () => {
    setParams({ ...params, page: params.page + NUMBER.ONE })
  }

  const checkNoHorse = () => (total === 0 && debounceSearchValue.length === 0 && !isLoading)

  const layoutBodySpecialHorseModal = () => {
    if (checkNoHorse()) {
      return layoutNoHorse()
    } else return layoutHorseList()
  }

  const handleRegionFilterClicked = (item: string) => {
    setHorseList([])
    if (item?.toUpperCase() === STRING_REGION.SEOUL || item?.toUpperCase() === STRING_REGION.TOKYO) {
      setParams({
        ...params,
        page: NUMBER.ONE,
        region: item?.toUpperCase()
      })
    } else {
      setParams({
        ...params,
        page: NUMBER.ONE,
        region: ''
      })
    }
    setRegionSelect(item)
  }

  const layoutHorseList = () => {
    return (
      <>
        <div className='search-horse-container d-flex align-items-center justify-content-between'>
          <div className='search-title color-white'> Total: {!isLoading && total} </div>
          <div className='search__box'>
            <SearchInput
              onAutoFocus={true}
              searchValue={searchValue}
              handleSearchValueChanged={handleSearchValueChanged}
              customClass='search-input'
            />

            {/* region */}
            <SelectCustom
              dataSelect={defaultRegionFilters}
              nameSelect={regionSelect}
              onSelected={handleRegionFilterClicked}
              customClass='select-custom'
            />
          </div>
        </div>

        {total === 0 && debounceSearchValue.length != 0 && !isLoading ?
          <div
            className='no-horse pt-5'
            dangerouslySetInnerHTML={{
              __html: t(`${SPECIAL_MESSAGE}.noHorseSearch`, { search: searchValue })
            }}
          /> :
          <InfiniteScroll
            dataLength={horseList.length}
            next={fetchMoreRaces}
            hasMore={true}
            loader={isLoading ? <Spin className='mt-2 mr-3 d-flex justify-content-center pt-5' /> : ''}
            height={500}
          >
            {horseList.length > 0 && (
              <div className='horse-list-container d-flex flex-column' ref={horseListRef}>
                {horseList?.map((horse, index: number) => (
                  <div key={index} className='horse-item'>
                    <>
                      <ChooseHorseItem horse={horse} onHorseClick={() => handleOnChange(horse)} displayVip={false} />
                    </>
                  </div>
                ))}
              </div>
            )}
          </InfiniteScroll>}
      </>
    )
  }

  const layoutNoHorse = () => {
    return <>
      <div className='no-horse-title'>{isWithdraw ? 'THERE ARE NO HORSE ON THE FARM' : 'THERE IS NO HORSE AVAILABLE'}</div>
      <div className='btn-container'>
        <Button buttonName='Close' onClickButton={onCloseButtonClick} width={150} type="btnCancel" />
      </div>
    </>
  }

  return (
    <Modal onOverlayClick={toggleIsModalOpen}>
      <ChooseHorseModalSpecialRaceStyled>
        <div className='choose-horse-modal'>
          <button className='close-btn p-0 position-absolute' role='button' onClick={onCloseButtonClick}>
            <img src={CLOSE_BTN} alt='close' />
          </button>
          {isWithdraw ? (
            <div className={`race-info-container color-orange ${checkNoHorse() ? '' : 'line'}`}>
              {t(`${SPECIAL_MESSAGE}.withdrawToWallet`)}
            </div>
          ) : (
            <div className={`race-info-container color-primary ${checkNoHorse() ? '' : 'line'}`}>
              {t(`${SPECIAL_MESSAGE}.registerHorse`)}
            </div>
          )}
          {layoutBodySpecialHorseModal()}
          {/* {layoutNoHorse()} */}
        </div>
      </ChooseHorseModalSpecialRaceStyled>
    </Modal>
  )
}

export default ChooseHorseModalSpecialRace
