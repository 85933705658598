/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Form, notification } from 'antd'
import guildApi from 'apis/guildApi'
import { links } from 'apps'
// import ModalChooseLogo from 'features/Guild/components/ModalChooseLogo'
import ModalConfirmCreateGuild from 'features/Guild/components/ModalConfirmCreateGuild'
import { RequestLoginModal } from 'features/Race/components'
import { useAppSelector, useToggle } from 'hooks'
import { CreateGuildRequest } from 'models'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { InputCustomV2 } from 'shared'
import { handleAsyncRequest, getOnlyNumber } from 'utils/helper'
import i18n from 'i18n'
import { useTranslation } from 'react-i18next'
import { GUILD_MESSAGE } from 'i18n/constants'
import { REGEX_FIRST_SPACE, REGEX_NICK_NAME } from 'apps/constants'
import { GAME_TOKEN_KUDA } from 'assets/images'
import { ONE_LINE, ONE_LINE_RIGTH, ONE_LINE_LEFT, LINE_GUILD_GRADIENT } from 'assets/images'
import CreateGuildStyled from './styled'
import TwoLineTitleStyled from 'shared/TwoLineTitle'
import ButtonCreate from 'shared/ButtonCreate'
import InputTextarea from 'shared/InputTextarea'

const lastSpacingValid = /(.*)\s+$/
const keyValid = /[`!@#$%^&*~()_+\-=\[\]{};':"\\|,.<>\/?]+/
const textErrorSpace = i18n.t(`${GUILD_MESSAGE}.message.textErrorSpace`)
const textErrorSpaceTag = i18n.t(`${GUILD_MESSAGE}.message.textErrorSpaceTag`)
const textError2Space = i18n.t(`${GUILD_MESSAGE}.message.textError2Space`)
const regex = new RegExp(REGEX_NICK_NAME)
const regix = /^\d+$/

export default function CreateGuild() {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [api, contextHolder] = notification.useNotification()

  const [logoChoose, setLogoChoose] = useState<string>('')
  const [isShowModalConfirmCreateGuild, setIsShowModalConfirmCreateGuild] = useState<boolean>(false)
  const [dataCreateGuild, setDataCreateGuild] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [guildData, setGuildData] = useState<any>(null)
  const [guildDataLoading, setGuildDataLoading] = useState<boolean>(false)
  const [isRequestLoginModalOpen, toggleIsRequestLoginModalOpen] = useToggle(false)
  const navigate = useNavigate()
  const { auth, profile, coinUser } = useAppSelector(state => state)
  const balanceKUDA = coinUser?.items?.find((coin: any) => coin.item_type === 'KUDA_NEW')?.amount || 0
  const [valCheckbox, setValCheckbox] = useState(() => false)
  const [valCheckboxNeedApproval, setValCheckboxNeedApproval] = useState(() => false)
  const [guildNameError, setGuildNameError] = useState('')
  const [createGuildError, setCreatGuildError] = useState<string>('')
  const [checkErrorTotalRace, setCheckErrorTotalRace] = useState(true)

  const guildName = Form.useWatch('guildName', form)
  const guildTag = Form.useWatch('guildTag', form)
  const guildDescription = Form.useWatch('guildDescription', form)

  const isTextValid = !guildName || !guildTag || !guildDescription || !valCheckbox

  useEffect(() => {
    form.setFieldValue('totalRace', '0')
    getGuildData()
  }, [])

  useEffect(() => {
    if (auth.isLogged && profile.guild_id) {
      navigate(links.guild.information())
    }
  }, [auth])

  const getGuildData = async () => {
    setGuildDataLoading(true)
    const [error, result]: any = await handleAsyncRequest(guildApi.getGuildMaterData())

    if (error) {
      if (error.code === 403) {
        toggleIsRequestLoginModalOpen()
        return
      }
      openNotification('ERROR', error.message || error.errors[0]?.message)
    }

    if (result) {
      setGuildData(result.data)
      setLogoChoose(result.data.logos[0])
    }
    setGuildDataLoading(false)
  }

  const disableCreateBtn = guildData && balanceKUDA < guildData?.fee

  const handleSubmit = (value: any) => {
    const request = {
      ...value,
      needApproval: valCheckboxNeedApproval
    }
    
    const name = value.guildName
    if (!lastSpacingValid.test(name) && !lastSpacingValid.test(value.guildTag)) {
      setGuildNameError('')
      setDataCreateGuild(request)
      setIsShowModalConfirmCreateGuild(!isShowModalConfirmCreateGuild)
    } else {
      if (lastSpacingValid.test(name)) {
        setGuildNameError(textErrorSpace)
      }
    }
  }

  const handleKeyDown = (e: any) => {
    if (keyValid.test(e.key)) {
      return e.preventDefault()
    }

    return
  }

  const handleKeyDownTotalRace = (e: any) => {
    if (['e', 'E', '+', '-', '.', ','].includes(e.key) || [38, 40].includes(e.keyCode)) {
      return e.preventDefault()
    }

    if (!regix.test(e.key) &&
      e.key !== 'Backspace' &&
      e.key !== 'Delete' &&
      e.key !== 'ArrowLeft' &&
      e.key !== 'ArrowRight') {
      return e.preventDefault()
    }

    if (e.key === 'Unidentified') {
      return
    }
  }

  const handleCloseModalConfirmCreateGuild = () => {
    // form.resetFields();
    // setLogoChoose('');
    setCreatGuildError('')
    setIsShowModalConfirmCreateGuild(!isShowModalConfirmCreateGuild)
  }

  const openNotification = (message: string, description: string) => {
    notification.config({
      duration: 4
    })

    api.info({
      message,
      description,
      placement: 'bottomRight',
      className: message === 'ERROR' ? 'ant-custom-error' : 'ant-custom-success'
    })
  }

  const handleConfirmCreateGuild = async () => {
    setLoading(true)
    setCreatGuildError('')

    // const trimStr = dataCreateGuild?.guildDescription?.trim()?.replace(/  +/g, ' ');

    const dataResquest: CreateGuildRequest = {
      avatar: logoChoose,
      description: dataCreateGuild?.guildDescription,
      guild_tag: dataCreateGuild.guildTag,
      name: dataCreateGuild.guildName,
      need_approval: dataCreateGuild.needApproval,
      total_race: dataCreateGuild.totalRace
    }

    const [error, result]: any = await handleAsyncRequest(guildApi.createGuild(dataResquest))

    if (error) {
      if (error?.code === 'E1001') {
        setCreatGuildError(t(`${GUILD_MESSAGE}.message_error_e1001`))
      } else {
        setCreatGuildError(error.message || error.errors[0]?.message)
      }
    }
    if (result) {
      handleCloseModalConfirmCreateGuild()
      openNotification('SUCCESS', t(`${GUILD_MESSAGE}.message.validate_create_guild_success`))
      setCreatGuildError('')
      navigate(links.guild.information())
    }
    setLoading(false)
  }

  const _handleGuildNameChange = (e: any) => {
    setGuildNameError('')
    form.setFieldValue('guildName', e)
  }

  const _handleChangeGuildTotalRace = (e: any) => {
    setCheckErrorTotalRace(() => true)
    const num = getOnlyNumber(e)
    form.setFieldValue('totalRace', num)
  }

  const _handleChangeGuildTag = (e: any) => {
    form.setFieldValue('guildTag', e)
  }
  
  const handdleChooseAvatar = (logo: any) => {
    setLogoChoose(() => logo)
  }

  const onChangeCheckbox = () => {
    setValCheckbox(() => !valCheckbox)
  }

  const onChangeCheckboxNeedApproval = (e: any) => {
    if (!e.target.checked) {
      form.setFieldValue('totalRace', '0')
    }
    setCheckErrorTotalRace(() => false)
    setValCheckboxNeedApproval(() => e.target.checked)
  }

  return (
    <CreateGuildStyled>
      {contextHolder}
      <div className='title-tabs-container'>
        <div className='title-tabs color-primary'>
          <TwoLineTitleStyled text={t(`${GUILD_MESSAGE}.guild_title_create`)} classGuild={"line__create"} />
        </div>
      </div>
      <Form form={form} name='basic' onFinish={handleSubmit} autoComplete='off'>
        {(values, instance) => {
          const nameError = instance.getFieldError('guildName');
          const tagError = instance.getFieldError('guildTag');
          const desError = instance.getFieldError('guildDescription');
          let totalRaceError: any = ''
          if (checkErrorTotalRace) {
            totalRaceError = instance.getFieldError('totalRace')
          } else {
            totalRaceError = ''
          }

          const isError = nameError.length > 0 || tagError.length > 0 || desError?.length > 0 || totalRaceError?.length > 0
          
          return (
            <div className='guild-container'>
              <div className='row line__guild--gradiant'>
                <img src={LINE_GUILD_GRADIENT} />
              </div>
              <div className='color-DEDEDE row'>
                <div className='col-3 guil__content'>
                  <div className='guild-label guild__label--logo'>{t(`${GUILD_MESSAGE}.guild_logo`)}</div>
                  <div className='guild__content--logo'>
                    <img src={ONE_LINE} alt='' className='orange-line position-absolute' />
                    <div className='guild__content--avatar'>
                      {logoChoose && <img className='guild-avatar' src={logoChoose} />}
                    </div>
                    <div className='guild__line'>
                      <img src={ONE_LINE_LEFT} />
                      <span className='guild__line--space'></span>
                      <img src={ONE_LINE_RIGTH} />
                    </div>

                    <div className='guild__content--arr-avatar'>
                      {guildData?.logos?.map((logoItem: string | undefined, i: any) => {
                        return (
                          <div
                            key={i} 
                            onClick={() => handdleChooseAvatar(logoItem)}
                            className={`item__avatar ${logoItem === logoChoose ? 'active' : ''}`}
                          >
                            <img className='img-avatar' src={logoItem} />
                          </div>
                      )})}
                    </div>
                  </div>
                </div>
                <div className='col-6 guil__content'>
                  <div>
                    <div className='guild-label'>
                      {t(`${GUILD_MESSAGE}.guild_name`)}<span className='request__red'>{t(`${GUILD_MESSAGE}.message.validate_request`)}</span>
                    </div>
                    <div>
                      <Form.Item
                        name='guildName'
                        className='input-box'
                        shouldUpdate
                        {...(guildNameError ? {
                          hasFeedback: true,
                          validateStatus: "error",
                          help: textErrorSpace
                        } : {})}
                        rules={[
                          { required: true, message: t(`${GUILD_MESSAGE}.message.validate_req_guild_name`) },
                          { max: 20, message: t(`${GUILD_MESSAGE}.message.validate_req_guild_name`) },
                          {
                            validator: (_, value) => {
                              if (value && REGEX_FIRST_SPACE.test(value)) {
                                return Promise.reject(new Error(textErrorSpace))
                              }
                              if (!regex.test(value)) {
                                return Promise.reject(new Error(t(`${GUILD_MESSAGE}.message.validate_regex_guild_name`)))
                              }
                              if (value && value.includes('  ')) {
                                return Promise.reject(new Error(textError2Space))
                              }
                              if (value && value && value.trim().length < 3) {
                                return Promise.reject(new Error(t(`${GUILD_MESSAGE}.message.validate_req_guild_name`)))
                              }
                              return Promise.resolve()
                            }
                          }
                        ]}
                      >
                        <InputCustomV2
                          name='guildName'
                          maxLength={20}
                          type='text'
                          onKeyDown={handleKeyDown}
                          onChange={_handleGuildNameChange}
                          value={values.guildName}
                          placeholder={t(`${GUILD_MESSAGE}.place_guild_name`)}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className='guil__content--mar5'>
                    <div className='guild-label'>
                      {t(`${GUILD_MESSAGE}.guild_tag`)}<span className='request__red'>{t(`${GUILD_MESSAGE}.message.validate_request`)}</span>
                    </div>
                    <div>
                      <Form.Item
                        name='guildTag'
                        className='input-box'
                        shouldUpdate
                        rules={[
                          { required: true, message: t(`${GUILD_MESSAGE}.message.validate_tag_length`) },
                          {
                            validator: (_, value) => {
                              if (value && REGEX_FIRST_SPACE.test(value)) {
                                return Promise.reject(new Error(textErrorSpaceTag))
                              }
                              if (value && value.includes(' ')) {
                                return Promise.reject(new Error(t(`${GUILD_MESSAGE}.message.validate_space_guild_name`)))
                              }
                              if (!regex.test(value)) {
                                return Promise.reject(new Error(t(`${GUILD_MESSAGE}.message.validate_regex_guild_tag`)))
                              }
                              if (value && value.trim().length !== 3) {
                                return Promise.reject(new Error(t(`${GUILD_MESSAGE}.message.validate_tag_length`)))
                              }
                              return Promise.resolve()
                            }
                          }
                        ]}
                      >
                        <InputCustomV2
                          type='text'
                          maxLength={3}
                          onKeyDown={handleKeyDown}
                          onChange={_handleChangeGuildTag}
                          placeholder={t(`${GUILD_MESSAGE}.place_guild_tag`)}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div className='guil__content--mar5'>
                    <div className='guild-label'>
                      {t(`${GUILD_MESSAGE}.guild_description`)}<span className='request__red'>{t(`${GUILD_MESSAGE}.message.validate_request`)}</span>
                    </div>
                    <div>
                      <Form.Item
                        name='guildDescription'
                        rules={[
                          { required: true, message: t(`${GUILD_MESSAGE}.message.validate_req_description`) },
                          { max: 500, message: t(`${GUILD_MESSAGE}.message.validate_max_char`) },
                          {
                            validator: (_, value) => {
                              if (value && value && value.trim().length < 50) {
                                return Promise.reject(new Error(t(`${GUILD_MESSAGE}.message.validate_min_char`)))
                              }
                              return Promise.resolve()
                            }
                          }
                        ]}
                        className='input-box'
                      >
                        <InputTextarea 
                          type='textarea'
                          minLength={50}
                          maxLength={500}
                          value={values.guildDescription}
                          customClass='guild__textarea'
                          placeholder={t(`${GUILD_MESSAGE}.place_guild_description`)}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div className='col-3 guil__content'>
                  <div className='guild-label guild__label--logo'>{t(`${GUILD_MESSAGE}.conditions_to_join`)}</div>
                  <div className='guild__content--logo'>
                    <img src={ONE_LINE} alt='' className='orange-line position-absolute' />
                    <div className='guild__content--condition'>
                      <div className='condition__checkbox'>
                        <img src={ONE_LINE} alt='' className='orange__line--checkbox position-absolute' />
                        <div className='condition__checkbox--text'>{t(`${GUILD_MESSAGE}.need_approval`)}</div>
                        <div className='guil__content--checkbox mg__top--0'>
                          <input
                            id="need_approval"
                            className='guild__create--checkbox'
                            type='checkbox'
                            onChange={onChangeCheckboxNeedApproval}
                          />
                          <label htmlFor='need_approval' className='checkbox__lable'></label>
                          <div className='border__cover'></div>
                        </div>
                      </div>

                      <div className="guil__content--mar10">
                        <div className='guild-label'>
                          {t(`${GUILD_MESSAGE}.total_race`)}
                        </div>
                        <div>
                          <Form.Item
                            name='totalRace'
                            className='input-box'
                            shouldUpdate
                            help={checkErrorTotalRace ? totalRaceError : ''}
                            validateStatus={checkErrorTotalRace ? 'error' : ''}
                            rules={[
                              { required: true, message: checkErrorTotalRace ? t(`${GUILD_MESSAGE}.message.validate_total_race`) : '' },
                              { max: 20, message: t(`${GUILD_MESSAGE}.message.validate_name_max_length`) },
                            ]}
                          >
                            <InputCustomV2
                              maxLength={20}
                              type='text'
                              onKeyDown={handleKeyDownTotalRace}
                              onChange={_handleChangeGuildTotalRace}
                              value={values.totalRace}
                              // disabled={!valCheckboxNeedApproval}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='color-DEDEDE row'>
                <div className='col-4'></div>
                <div className='col-8 guil__content--checkbox'>
                  <input
                    id="guild_create_checkbox"
                    className='guild__create--checkbox'
                    type='checkbox'
                    onChange={() => onChangeCheckbox()}
                  />
                  <label htmlFor='guild_create_checkbox' className='checkbox__lable'>
                    <span className='checkbox_text'>{t(`${GUILD_MESSAGE}.checkbox_accept`)}</span>
                    <a 
                      rel='noopener noreferrer'
                      href={links.home.privacy()}
                      target='_blank'
                    >
                      <span className='checkbox_text'>{t(`${GUILD_MESSAGE}.checkbox_accept2`)}</span>
                    </a>
                  </label>
                  <div className='border__cover'></div>
                </div>
              </div>

              <div className='row line__guild--gradiant'>
                <img src={LINE_GUILD_GRADIENT} />
              </div>

              <div className='btn-create'>
                <Form.Item>
                  <ButtonCreate
                    buttonName={
                      <div>
                        <span className='btn__create--guild'>{t(`${GUILD_MESSAGE}.btn_create`)}</span> <span className='btn__create--guild'>{guildData?.fee} <img src={GAME_TOKEN_KUDA} width={20} /></span>
                      </div>
                    }
                    onClickButton={form.submit}
                    width={147}
                    disabled={guildDataLoading || disableCreateBtn || isTextValid || isError}
                  />
                </Form.Item>
              </div>
            </div>
          )
        }}
      </Form>
      
      {isShowModalConfirmCreateGuild && (
        <ModalConfirmCreateGuild
          onClose={handleCloseModalConfirmCreateGuild}
          onConfirm={handleConfirmCreateGuild}
          titleModal={t(`${GUILD_MESSAGE}.guild_title_create`)}
          fee={guildData.fee}
          loading={loading}
          error={createGuildError}
        />
      )}

      {isRequestLoginModalOpen && <RequestLoginModal toggleIsModalOpen={toggleIsRequestLoginModalOpen} />}
    </CreateGuildStyled>
  )
}
