import { SubMenu } from 'features/ShopAP/components'
import ShopeMainStyled from './styled'
import { useOutlet } from 'react-router-dom'

function ShopMain() {
  const outlet = useOutlet()

  return (
    <ShopeMainStyled>
      <div className='container'>
        <div className='shop-container'>
          <SubMenu />
          <div className='content'>{outlet}</div>
        </div>
      </div>
    </ShopeMainStyled>
  )
}

export default ShopMain
