/* eslint-disable @typescript-eslint/no-explicit-any */
import { BtnBacktSyled, ButtonSyled, ItemDetailsStyled } from './styled'
import { useEffect, useState } from 'react'
import { useToggle, useAppSelector } from 'hooks'
import { ApiResponse, ItemResponse } from 'models'
import {
  CARET_LEFT,
  SUB_TRACT,
  ITEM_NUMBER,
  FRAME_TOP,
  LINE_ITEM,
  LINE_TEXT,
  RARITY_COMMON,
  RARITY_RARE,
  RARITY_EPIC,
  RARITY_LEGENDARY,
  RARITY_MYTHIC
} from 'assets/images'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Button from 'shared/Button'
import { ItemShop, SellItemNFTParams, ShopTypeRariTy, resultItemNFT } from 'models'
import { convertGasPrice, getAttribute, handleAsyncRequest } from 'utils/helper'
import itemShopsApi from 'apis/itemShopsApi'
import { RateStar } from 'features/Horse/components'
import itemApi from 'apis/itemApi'
import SkeletonDetail from 'features/components/SkeletonDetail'
import SellItemModal from 'features/ShopAP/components/SellItemModal'
import ConfirmOkModal from 'features/Race/components/ConfirmOkModal'
import { ethers } from 'ethers'
import { configs } from 'apps'
import itemNFTABI from 'json/ItemNFT.json'
import shopApNFTABI from 'json/ShopAP.json'
import { STATUS_TRANSACTION, timeCheckNonce } from 'apps/constants'
import { notification } from 'antd'
import { useTranslation } from 'react-i18next'
import InProgressBalanceModal from 'features/Balance/components/InProgress'
import BigNumber from 'bignumber.js'
import { LENDING_MESSAGE, NOTIFICATION_MESSAGE } from 'i18n/constants'

const contract = {
  shopAP: configs.shopAP,
  itemNFT: configs.itemNFT
}

export default function ItemInventoryDetails() {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const typePart = searchParams.get('type')
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [itemDetails, setItemDetails] = useState<ItemShop>()
  const { id } = useParams()
  const [isSellItemModal, toggleSellItemModal] = useToggle(false)
  const [isConfirmSellItemModal, toggleConfirmSellItemModal] = useToggle(false)
  const [sellParams, setSellParams] = useState<SellItemNFTParams>({ price: 0, currency: 'ADIL' })
  const currentUser = useAppSelector(state => state.profile)
  const [masterItems, setMasterItems] = useState<any>()

  // start: sell metamask
  const [openInProgressBalanceModal, toggleOpenInProgressBalanceModal] = useToggle(false)
  const provider = new ethers.providers.Web3Provider(window.ethereum)
  const signer = provider.getSigner()
  // end

  // start: sell itemNFT contract
  const sellItemNFTAPI = async () => {
    setIsLoading(true)
    const [error, result]: any = await handleAsyncRequest(
      itemShopsApi.postSellItemShopNFT({ ...sellParams, token_id: itemDetails?.item_token })
    )
    if (result) {
      toggleOpenInProgressBalanceModal(true)
      approveSellItemContract(result.data)
    }
    if (error) {
      transactionFaild(error.message)
      toggleConfirmSellItemModal(false)
      toggleSellItemModal(false)
    }
    setIsLoading(false)
  }

  const approveSellItemContract = async (value: resultItemNFT) => {
    try {
      const { ethereum } = window
      if (!ethereum) return
      if (!value) return
      const itemNFTContract = new ethers.Contract(contract.itemNFT, itemNFTABI.contract.abi, signer)
      const gasPrice = convertGasPrice(value.gas_price)
      try {
        await itemNFTContract.approve(contract.shopAP, value.token_id, { gasPrice: gasPrice })
        sellItemNFTContract(value)
      } catch (err) {
        transactionFaild(t(`${LENDING_MESSAGE}.failedTransaction`))
        toggleConfirmSellItemModal(false)
        toggleSellItemModal(false)      }
    } catch (err) {
      transactionFaild(t(`${LENDING_MESSAGE}.failedTransaction`))
      toggleConfirmSellItemModal(false)
      toggleSellItemModal(false)
    }
  }

  const sellItemNFTContract = async (value: resultItemNFT) => {
    if (!value) return
    try {
      const sellItemContract = new ethers.Contract(contract.shopAP, shopApNFTABI.contract.abi, signer)
      const gasPrice = convertGasPrice(value.gas_price)
      const price = new BigNumber(value.price).toFixed()
      try {
        const tx = await sellItemContract.sell(contract.itemNFT, value.token_id, ethers.utils.parseEther(price), value.token_address, 'adil', {
          gasPrice: gasPrice
        })
        if (tx.hash) {
          await provider.waitForTransaction(tx.hash)
          checkNonceContract(value.nonce)
        }
      } catch (err) {
        console.log({ err })
      }
    } catch (err) {
      console.log({ err })
    }
  }

  const checkNonceContract = async (nonce: string) => {
    let checkNonceExits = null as any
    let x = 0
    const intervalID = setInterval(async () => {
      const [, result] = await handleAsyncRequest(itemShopsApi.postCheckNonceItemShopNFT({ nonce }))
      if (!result) return
      checkNonceExits = result.data.status
      if (checkNonceExits === STATUS_TRANSACTION.success) {
        clearInterval(intervalID)
        toggleOpenInProgressBalanceModal(false)
        transactionSuccess()
        toggleConfirmSellItemModal(false)
        navigate(-1)
      }

      if (checkNonceExits === STATUS_TRANSACTION.expired) {
        clearInterval(intervalID)
        toggleOpenInProgressBalanceModal(false)
        toggleConfirmSellItemModal(false)
        toggleSellItemModal(false)
        transactionFaild(t(`${LENDING_MESSAGE}.failedTransaction`))
      }

      if (++x === 10) {
        if (checkNonceExits === STATUS_TRANSACTION.pending) {
          clearInterval(intervalID)
          toggleOpenInProgressBalanceModal(false)
          transactionFaild(t(`${NOTIFICATION_MESSAGE}.transferredPending`))
          toggleConfirmSellItemModal(false)
          toggleSellItemModal(false)
        }
      }
    }, timeCheckNonce)
  }

  const transactionSuccess = () => {
    toggleOpenInProgressBalanceModal(false)
    notification.success({
      message: 'SUCCESS',
      description: `Sell item successfully!`,
      placement: 'bottomRight',
      duration: 4,
      className: 'ant-custom-success'
    })
  }

  const transactionFaild = (value: any) => {
    toggleOpenInProgressBalanceModal(false)
    notification.error({
      message: 'ERROR',
      description: value ?? 'Sorry! Your transaction has failed. Please go back and try again.',
      placement: 'bottomRight',
      duration: 4,
      className: 'ant-custom-error',
      icon: <></>
    })
  }
  // end

  const handleOnChangeInput = (params: { price: number; currency: string }) => {
    setSellParams({ ...params })
  }

  const onBuyItemClick = () => {
    toggleSellItemModal()
  }

  const fetchItem = async () => {
    setIsLoading(true)
    const [, result] = typePart
      ? await handleAsyncRequest(itemApi.getItemsWeaponsDetail(id))
      : await handleAsyncRequest(itemShopsApi.getItemInventoryDetail(currentUser?.id, id))

    const records = result?.data
    setItemDetails(records)
    setIsLoading(false)
  }

  const generateRateLevel = (maxLevel: number, currentLevel: number): JSX.Element[] => {
    const rateStars: JSX.Element[] = []
    for (let i = 0; i < maxLevel; i++) {
      rateStars.push(<RateStar key={i} isActive={i < currentLevel} />)
    }
    return rateStars
  }

  const fetchItemsMasterData = async () => {
    setIsLoading(true)

    const [, result] = await handleAsyncRequest<ApiResponse<ItemResponse>>(itemApi.getItemsShopMaterData())
    if (result) {
      const { data } = result
      setMasterItems(data);
    }
    setIsLoading(false)
  }

  useEffect(() => {
    setItemDetails(undefined)
    if(currentUser?.id){
      fetchItem()
    }
    fetchItemsMasterData()
  }, [id, currentUser])

  const converType = () => {
    if (typePart) {
      if (itemDetails?.body_part === 'SKIN') {
        return 'SKIN'
      } else { return 'ARMOR' }
    } else { return 'BOOSTER'}
  }
  const coverName = () => {
    return typePart ? itemDetails?.name : itemDetails?.boost?.name
  }
  const showIconRariTy = () => {
    const valueRariry = itemDetails?.boost?.rarity_type ?? itemDetails?.rarity_type
    const handleKeySort = () => {
      switch (valueRariry) {
        case ShopTypeRariTy.ALL:
          return ''
        case ShopTypeRariTy.COMMON.toLocaleUpperCase():
          return <img className='icon-rarity' src={RARITY_COMMON} alt='' />
        case ShopTypeRariTy.EPIC.toLocaleUpperCase():
          return <img className='icon-rarity' src={RARITY_EPIC} alt='' />
        case ShopTypeRariTy.LEGENDARY.toLocaleUpperCase():
          return <img className='icon-rarity' src={RARITY_LEGENDARY} alt='' />
        case ShopTypeRariTy.MYTHIC.toLocaleUpperCase():
          return <img className='icon-rarity' src={RARITY_MYTHIC} alt='' />
        case ShopTypeRariTy.RARE.toLocaleUpperCase():
          return <img className='icon-rarity' src={RARITY_RARE} alt='' />
        default:
          return valueRariry
      }
    }
    return handleKeySort()
  }

  return (
    <ItemDetailsStyled>
      <BtnBacktSyled>
        <div className='btn-back' onClick={() => navigate(-1)}>
          <img src={CARET_LEFT} alt='' />
          <span className='text-btn'>BACK</span>
        </div>
      </BtnBacktSyled>
      {isLoading ? (
        <SkeletonDetail rows={10} />
      ) : (
        <>
          <div className='container'>
            <img className='f-top' src={FRAME_TOP} alt='' />
            <div className='item-block'>
              <div className={`body-item ${typePart && itemDetails?.body_part?.toLowerCase()}`}>
                <div className='item'>
                  <div className='avatar'>
                    <img className='sub-tract' src={SUB_TRACT} alt='' />
                    <img
                      className='avatar-img'
                      src={itemDetails?.img ?? itemDetails?.boost?.img}
                      alt={itemDetails?.boost?.name}
                    />
                    {typePart ? (
                      ''
                    ) : (
                      <div className='star-amount'>
                        {itemDetails?.boost?.effect_type !== 'ENERGY' ? (
                          <>
                            {itemDetails?.amount && (
                              <div className='numb'>
                                <img src={ITEM_NUMBER} alt='' />
                                <span>{itemDetails?.amount}</span>
                              </div>
                            )}
                            {itemDetails?.boost?.level ? (
                              <div className='level d-flex ps-2'>
                                {generateRateLevel(3, Number(itemDetails?.boost?.level))}
                              </div>
                            ) : ( '' )}
                          </>
                        ) : ( '' )}
                      </div>
                    )}
                  </div>
                  <div className='name'>
                    <div className='d-flex align-items-center item-name'>{coverName()}</div>
                    <div className='d-flex align-items-center'>
                      {' '}
                      <span className='field-name'>Id</span> {typePart ? itemDetails?.item_token : itemDetails?.boost?.id}
                    </div>
                    <div className='d-flex align-items-center'>
                      {' '}
                      <span className='field-name'>Owner</span> {currentUser?.name}
                    </div>
                    <div className='d-flex align-items-center'>
                      {' '}
                      <span className='field-name'>Type</span> {converType()}
                    </div>
                    <div className='d-flex'>
                      <span className='field-name'> Rarity </span> {showIconRariTy()}
                    </div>
                  </div>
                </div>
                <div className='information'>
                  <div className='information-box'>
                    <div
                      className='attribute active'
                    >
                      Attribute
                      <img src={LINE_TEXT} alt='' className='line-text' />
                    </div>
                  </div>
                  <div className='content mb-3'>
                      <p className='mb-0'>
                        <span>{getAttribute(itemDetails?.effect) || itemDetails?.boost?.attribute || 'No effect'}</span>
                      </p>
                  </div>  
                  <div className='information-box'>
                    <div
                      className='description active'
                    >
                      Description
                      <img src={LINE_TEXT} alt='' className='line-text' />
                    </div>
                  </div>
                  <div className='content'>
                    {typePart ? (
                      itemDetails?.description
                    ) : (
                      itemDetails?.boost?.description
                    )}
                  </div>
                </div>
              </div>
              <img className='f-bottom' src={FRAME_TOP} alt='' />
              <img className='f-left' src={LINE_ITEM} alt='' />
              <img className='f-right' src={LINE_ITEM} alt='' />
            </div>
          </div>
          {typePart ? (
            <ButtonSyled>
              <Button buttonName='Sell' type="btnSell" onClickButton={onBuyItemClick} width={150} />
            </ButtonSyled>
          ) : (
            ''
          )}
        </>
      )}

      {isSellItemModal && (
        <SellItemModal
          masterItems={masterItems}
          toggleIsModalOpen={toggleSellItemModal}
          onCloseButtonClick={toggleSellItemModal}
          itemData={itemDetails}
          onConfirm={toggleConfirmSellItemModal}
          onChangeInput={handleOnChangeInput}
          value={sellParams?.price}
        />
      )}
      {isConfirmSellItemModal && (
        <ConfirmOkModal
          toggleIsModalOpen={toggleConfirmSellItemModal}
          onCloseButtonClick={toggleConfirmSellItemModal}
          onConfirm={sellItemNFTAPI}
          message={`You are about to sell ${coverName()} with ${sellParams?.price}  ${sellParams?.currency}. \nAre you sure? `}
          isLoading={isLoading}
          heading='Confirm'
          isContentCenter
        />
      )}
      {openInProgressBalanceModal && <InProgressBalanceModal title={`SELL ITEM IN PROGRESS`} />}
    </ItemDetailsStyled>
  )
}
