import styled from 'styled-components'

const TabContainer = styled.div`
  .tab-lend {
    width: 100%;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    padding-bottom: 10px;
    .tab-block {
      display: flex;
      width: 100%;
    }
    .search-container {
      display: flex;
      align-items: center;
      gap: 20px;
    }
    display: flex;
    .tab-link {
      transition: 0.3s;
      width: 50%;
      height: 61px;
      font-size: 22px;
      color: white;
      cursor: pointer;
      opacity: 0.4;
      background: radial-gradient(70.81% 41.01% at 50.17% 99.66%, #1ded83 0%, rgba(35, 39, 33, 0.43) 100%),
        radial-gradient(219.74% 55.37% at 51.76% 0%, #00ff7d 0%, rgba(35, 39, 33, 0.4) 100%);
      .total-race {
        line-height: 25px;
        text-transform: uppercase;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .active {
      opacity: 1;
    }
    .disabled:hover {
      opacity: 0.8;
    }
  }
`

export default TabContainer
