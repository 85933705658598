/* eslint-disable @typescript-eslint/no-explicit-any */
import { notification } from 'antd'
import guildApi from 'apis/guildApi'
import ModalManagerMember from 'features/Guild/components/ModalManagerMember'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { GUILD_MESSAGE } from 'i18n/constants'
import { useAppDispatch, useAppSelector } from 'hooks'
import { getCurrentUser } from 'utils/metamask'
import Button from 'shared/Button'
import { setCurrentUser } from 'features/Profile/profile.slice'
import { handleAsyncRequest } from 'utils/helper'
import CedeMemberContainer from './styled'

const CedeMember = ({ upgradeSuccess }: any) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [api, contextHolder] = notification.useNotification()

  const { guild_id, user_id } = useParams()
  const { profile } = useAppSelector(state => state)

  const openNotification = (message: string, description: string) => {
    notification.config({
      duration: 4
    })

    api.info({
      message,
      description,
      placement: 'bottomRight',
      className: message === 'ERROR' ? 'ant-custom-error' : 'ant-custom-success'
    })
  }

  const _handleToggleModal = () => {
    setShowModal(!showModal)
  }

  const _handleUpgrade = async () => {
    setIsLoading(true)

    const [error, result]: any = await handleAsyncRequest(guildApi.postCedeTo(Number(guild_id), Number(user_id)));
    
    setIsLoading(false);
    if (error) {
      openNotification('ERROR', error.message || error.errors[0]?.message)
    }

    if (result) {
      _handleToggleModal()
      const [, currentUser]: any = await handleAsyncRequest(getCurrentUser())
      if (!currentUser) return
      dispatch(setCurrentUser({ ...profile, ...currentUser, role_in_guild: "MEMBER" }))
      upgradeSuccess()
    }
  }

  return (
    <CedeMemberContainer>
      {contextHolder}
      <Button
        buttonName={t(`${GUILD_MESSAGE}.btn_cede`)}
        onClickButton={_handleToggleModal}
        type="btnCede"
        height={42}
        width={180}
      />
      {showModal && (
        <ModalManagerMember
          title={t(`${GUILD_MESSAGE}.title_modal_cede`)}
          message={
            <p>
              {t(`${GUILD_MESSAGE}.note1_modal_cede`)}<br />
              {t(`${GUILD_MESSAGE}.note2_modal_cede`)}<br />
              {t(`${GUILD_MESSAGE}.note3_modal_cede`)}<br />
              {t(`${GUILD_MESSAGE}.note4_modal_cede`)}
            </p>}
          onClose={_handleToggleModal}
          onConfirm={_handleUpgrade}
          loading={isLoading}
          titleColor="rgb(78, 240, 118)"
          width={515}
          heigth={290}
        />
      )}
    </CedeMemberContainer>
  )
}

export default CedeMember
