import styled from "styled-components"

const GuildDetailContainer = styled.div`
  width: 100%;
  
  @media (max-width: 1400px) {
    .contain_info_borse {
      overflow-x: scroll;
    }
  }
`

export default GuildDetailContainer