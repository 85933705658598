import styled from 'styled-components'

const ListItemContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 25px;
  .button-group {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    >div {
      margin: 0 16.5px;
      width: auto;
    }
  }

  .not-found {
    font-size: 24px;
    color: #fff;
    text-transform: uppercase;
    width: 100%;
  }
`

export default ListItemContainer
