import styled from 'styled-components'

const GuildInfoContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;

  &.horse {
    padding: 0 20px;
  }
 

  .left-info {
    margin-right: 10px;
    width: 50%;

    .row-info {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      &.total {
        span {
          &:first-child {
            font-size: 16px;
          }
        }
      }

      &:nth-child(1) {
        span {
          color: #fff;
          font-size: 14px;
          &:first-child {
            /* text-align: right; */
            color: #8d8d8d;
          }
          &:last-child {
            text-align: left;
            font-size: 16px;
          }
        }
      }

      &:nth-child(2) {
        span {
          &:first-child {
            background: linear-gradient(90deg, #ffd901 0%, #fd6401 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
          }
        }
      }
      &:nth-child(3) {
        span {
          &:first-child {
            background: linear-gradient(90deg, #f2f2f2 0%, #4c4c4c 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
          }
        }
      }
      &:nth-child(4) {
        span {
          &:first-child {
            background: linear-gradient(90deg, #e69d68 0%, #7e3f1b 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
          }
        }
      }

      span {
        color: #fff;
        font-weight: 400;
        font-family: 'Abel';
        &:first-child {
          font-size: 20px;
          width: 50px;
        }
        &:last-child {
          font-size: 14px;
          /* text-align: left; */
        }
      }
    }

    .you-horse {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        color: #fff;
        font-weight: 400;
        font-family: 'Abel';

        &:first-child {
          font-size: 16px;
          /* text-align: right; */
          color: #8d8d8d;
        }
        &:last-child {
          font-size: 14px;
          text-align: left;
        }
      }
    }
  }
`

export default GuildInfoContainer
