/* eslint-disable @typescript-eslint/no-explicit-any */
import specialRaceApi from 'apis/specialRaceApi'
import { links } from 'apps'
import { RaceTable, RequestLoginModal } from 'features/Race/components'
import { useToggle } from 'hooks'
import { GetCurrentRaceListParams, RecordRace } from 'models'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { OneLineTitle } from 'shared'
import { eventHistoryColumns } from 'utils/columns'
import { handleAsyncRequest } from 'utils/helper'

import EventHistoryStyled from './styled'

export type IMyLendingProps = {
  isActive: boolean
}

const defaultParams: GetCurrentRaceListParams = {
  limit: 20,
  page: 1,
}

export default function EventHistory() {
  const [params, setParams] = useState<GetCurrentRaceListParams>(defaultParams)
  const [isLoading, setIsLoading] = useState(false)
  const [isRequestLoginModalOpen, toggleIsRequestLoginModalOpen] = useToggle(false)
  const memoizedResultListColumns = useMemo(() => eventHistoryColumns, [])
  const [currentRaces, setCurrentRaces] = useState<RecordRace[]>([])
  const [totalEvent, setTotalEvent] = useState(0)
  const navigate = useNavigate()

  const fetchListRaces = async () => {
    setIsLoading(true)
    const [error, result] = await handleAsyncRequest(
      specialRaceApi.getEventHistory({
        ...params
      })
    )
    if (error) {
      setIsLoading(false)
      return
    }
    if (result) {
      const records = result?.data?.records
      setCurrentRaces(params.page > 1 ? [...currentRaces, ...records] : records)
      setTotalEvent(result?.data.total)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchListRaces()
  }, [params])

  const handleRowItemClick = (id: number) => {
    navigate(links.specialRace.eventHistoryDetail(id))
  }
  return (
    <EventHistoryStyled>
      <div className='current-event-container'>
        <div>
          <OneLineTitle text='Event list' isLending />
          <div className='current-event'>
            <RaceTable
              columns={memoizedResultListColumns}
              data={currentRaces ?? []}
              raisePage={setParams}
              loader={!isLoading}
              params={params}
              isRowClickable
              checkLoadingPage={false}
              totalRace={totalEvent}
              onRowClick={handleRowItemClick}
            />
          </div>
        </div>
      </div>
      {isRequestLoginModalOpen && <RequestLoginModal toggleIsModalOpen={toggleIsRequestLoginModalOpen} />}
    </EventHistoryStyled>
  )
}
