import { useLocation, useOutlet } from 'react-router-dom'

import { links } from 'apps'
import { TwoLineTitle } from 'shared'
import SpecialRaceStyle from './styled'


function SpecialRaceMain() {
  const { pathname: currentPathname } = useLocation()
  const outlet = useOutlet()

  const titlePage = (): string => {
    let title = ''
    const slicePathName = currentPathname?.substring(currentPathname.lastIndexOf('/') + 1)
    switch (currentPathname) {
      case links.specialRace.currentRace():
      case links.specialRace.currentFarm():
        title = 'Current event'
        break
      case links.specialRace.raceHistory():
      case links.specialRace.eventHistoryDetail(slicePathName):
        title = 'Event History'
        break
      case links.specialRace.oldFarm():
        title = 'old farm'
        break
      default: ''
    }
    return title
  }

  return (
    <SpecialRaceStyle>
      <div className='container'>
        <div className='title-tabs-container'>
          <div className='title-tabs color-primary'>
            <TwoLineTitle text={titlePage()} />
          </div>
        </div>
        <div className='content'>{outlet}</div>
      </div>
    </SpecialRaceStyle>
  )
}

export default SpecialRaceMain
