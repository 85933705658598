import styled from 'styled-components'

const IncomeBoxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  .title {
    font-size: 16px;
    color: #4ef076;
    margin-bottom: 0;
  }

  .end {
    color: #eea414;
    font-size: 16px;
    margin-bottom: 0;
  }

  span {
    color: #fff;
    font-size: 20px;
  }

  .content-left {
    width: 175px;
  }
  .content-right {
    flex: 1;
  }
  .revenue-box {
    width: 240px;

    .row-revenue-total {
      display: flex;
      width: 100%;
    }

    .row-revenue {
      width: 50%;
      display: flex;
      justify-content: end;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
      }

      span {
        font-size: 16px;
      }
    }

    p {
      font-size: 16px;
      margin-bottom: 0;
      margin-top: 10px;
      color: #4ef076;
    }
  }
`

export default IncomeBoxContainer
