/* eslint-disable @typescript-eslint/no-explicit-any */
import { ItemNFT } from 'features/ShopAP/components'
import BuyItemsStyled from './styled'
import { RequestLoginModal, SwitchBtn } from 'features/Race/components'
import SelectCustom from 'shared/SelectCustom'
import { SearchInput } from 'shared'
import { ItemShop, ItemShopsParams, ShopInventorySort, ShopTypeItemsSort, ShopTypeRariTy, TypeSorts } from 'models'
import { useDebounce, useToggle, useAppSelector } from 'hooks'
import { useRef, useState, useEffect, useMemo } from 'react'
import { convertPluralText, handleAsyncRequest } from 'utils/helper'
import itemShopsApi from 'apis/itemShopsApi'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { constants } from 'apps'
import SkeletonList from 'features/components/SkeletonList'
import { notification } from 'antd'

const defaultTypeBuy: TypeSorts = [
  {
    name: ShopTypeRariTy.ALL,
    isActive: false
  },
  {
    name: ShopTypeRariTy.COMMON,
    isActive: false
  },
  {
    name: ShopTypeRariTy.RARE,
    isActive: false
  },
  {
    name: ShopTypeRariTy.EPIC,
    isActive: false
  },
  {
    name: ShopTypeRariTy.LEGENDARY,
    isActive: false
  },
  {
    name: ShopTypeRariTy.MYTHIC,
    isActive: false
  },
]

const defaultTypeFilters: TypeSorts = [
  {
    name: ShopInventorySort.DEFAULT,
    isActive: false
  },
  {
    name: ShopInventorySort.LOWEST,
    isActive: false
  },
  {
    name: ShopInventorySort.HIGHEST,
    isActive: false
  },
  {
    name: ShopInventorySort.ASC,
    isActive: false
  },
  {
    name: ShopInventorySort.DESC,
    isActive: false
  }
]

const defaultTypeItemsFilters: TypeSorts = [
  {
    name: ShopTypeItemsSort.ALL,
    isActive: false
  },
  {
    name: ShopTypeItemsSort.SYSTEMS,
    isActive: false
  },
  {
    name: ShopTypeItemsSort.USERS,
    isActive: false
  }
]

export default function BuyItems() {

  const { auth } = useAppSelector(state => state)
  const [listItems, setListItems] = useState<ItemShop[]>([])
  const [isMyHorseOn, toggleIsMyHorseOn] = useToggle(false)
  const useRefSwitch = useRef<HTMLButtonElement>(null)
  const [searchParams] = useSearchParams()
  const [searchValue, setSearchValue] = useState<string>('')
  const paramsSearch: any= Object.fromEntries(searchParams.entries())
  const [params, setParams] = useState<ItemShopsParams>(paramsSearch)

  const [isLoading, setIsLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [totalViewMore, setTotalViewMore] = useState(0)

  const debounceSearchValue = useDebounce<string>(searchValue, constants.DEBOUNCE_TIME)
  const [isRequestLoginModalOpen, toggleIsRequestLoginModalOpen] = useToggle(false)
  const [api] = notification.useNotification()
  const [isRefresh, toggleIsRefresh] = useToggle(false)
  const handleKeySortFullName = (item: string) => {
    switch (item) {
      case '' :
        return ShopInventorySort.DEFAULT
      case paramsSearch.type === 'ENERGY' || paramsSearch.type === 'DOPING' ? 'boost_name' : 'item_name':
        return ShopInventorySort.ASC
      case paramsSearch.type === 'ENERGY' || paramsSearch.type === 'DOPING' ? 'boost_name-desc' : 'item_name-desc':
        return ShopInventorySort.DESC
      case 'price' :
        return ShopInventorySort.LOWEST
      case 'price-desc':
        return ShopInventorySort.HIGHEST
      default:
        return 'Default'
    }
  }
  const handleKeySortDistributor = (item: string) => {
    switch (item) {
      case 'SYSTEM' :
        return ShopTypeItemsSort.SYSTEMS
      case 'USER' :
        return ShopTypeItemsSort.USERS
      default:
        return 'All'
    }
  }
  const handleKeySortRariTy = (item: string) => {
    switch (item) {
      case ShopTypeRariTy.ALL:
        return 'All'
      case 'COMMON':
        return ShopTypeRariTy.COMMON
      case 'EPIC' :
        return ShopTypeRariTy.EPIC
      case 'LEGENDARY' :
        return ShopTypeRariTy.LEGENDARY
      case 'MYTHIC' :
        return ShopTypeRariTy.MYTHIC
      case 'RARE' :
        return ShopTypeRariTy.RARE
      default:
        return 'All'
    }
  }
  const [classSelect, setClassSelect] = useState(handleKeySortDistributor(params?.distributor || '') )
  const [classSelectAll, setClassSelectAll] = useState(handleKeySortRariTy(params?.rarity || 'All'))
  const [sortSelect, setSortSelect] = useState(handleKeySortFullName(params?.sort || 'All'))
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const onItemClick = (id: string) => {
    if (!auth.isLogged) {
      localStorage.clear()
      location.reload()
      return
    } else {
      navigate(`${pathname}/${id}`)
    }
  }

  const isPageNFT = useMemo(() => (paramsSearch.type === 'ENERGY' || paramsSearch.type === 'DOPING') ? false : true, [paramsSearch.type])

  const openNotification = (message: string, description: string) => {
    notification.config({
      duration: 4
    })
    api.info({
      message,
      description,
      placement: 'top',
      className: message === 'ERROR' ? 'ant-custom-error' : 'ant-custom-success'
    })
  }

  const fetchListItems = async () => {
    setIsLoading(true)
    const [error, result]: any = await handleAsyncRequest(itemShopsApi.getItemShops({ ...params, yourItems: (isMyHorseOn && isPageNFT) ? isMyHorseOn : '' }))
    const records = result?.data.records
    setListItems(() => params.page > 1 ? [...listItems, ...records] : records)
    setTotal(result?.data.total)
    if (error) {
      setListItems([])
      if (error?.code === 403) {
        toggleIsRequestLoginModalOpen()
        setIsLoading(false)
        return
      }
      openNotification('ERROR', error.message || error.errors[0]?.message)
    }
    setIsLoading(false)
  }

  const renderQueries = (query : any) => {
    let index = 0;
    let queryText = `?type=${paramsSearch.type}`;
    for (const property in query) {
      if (query[property]) {
        queryText += `${index ? "&" : "&"}${property}=${query[property]}`;
        index++;
      }
    }
    return queryText;
  }
  useEffect(() => {
    if (params.bodyPart || params.boostType) {
      if (params?.limit) {
        fetchListItems()
      }
      const replaceNavigate = renderQueries(params as ItemShopsParams)
      navigate(
        `/shop/buy_items${replaceNavigate}`
      )
    }
  }, [params, isMyHorseOn])
  useEffect(() => {
    if (isRefresh) {
      setParams({ ...params, search: debounceSearchValue })
    }
  }, [debounceSearchValue])
  
  useEffect(() => {
    if (paramsSearch.type === 'ENERGY' || paramsSearch.type === 'DOPING') {
      setParams(
        { 
          limit: 20, 
          page: params?.page,
          boostType: paramsSearch.type,
          distributor: params.distributor || '',
          rarity: params.rarity || '',
          sort: params.sort || '',
          search: params.search || ''
        }
      )
    } else {
      setParams({ 
        limit: 20, 
        page: params?.page, 
        bodyPart: paramsSearch.type,
        distributor: params.distributor || '',
        rarity: params.rarity || '',
        sort: params.sort || '',
        search: params.search || '',
        yourItems: isMyHorseOn
      })
    }
    toggleIsMyHorseOn(isMyHorseOn)
    setSearchValue(params?.search || '')
  }, [
    searchParams,
    isMyHorseOn
  ])

  useEffect(() => {
    const currentType = searchParams.get('type')
    const storeType = localStorage.getItem('itemsType')
    if (currentType === storeType) {
      setParams({ ...params, page: 1, bodyPart: paramsSearch.type })
    } else {
      if (paramsSearch.type === 'ENERGY' || paramsSearch.type === 'DOPING') {
        setParams({ limit: 20, page: 1, boostType: paramsSearch.type })
      } else {
        setParams({ limit: 20, page: 1, bodyPart: paramsSearch.type })
      }
      setClassSelectAll(ShopTypeItemsSort.ALL)
      setClassSelect(ShopTypeItemsSort.ALL)
      setSortSelect('Sorted by...')
      toggleIsMyHorseOn(false)
      toggleIsRefresh(false)
      setSearchValue('')
      localStorage.setItem('itemsType', paramsSearch.type);
    }
  }, [searchParams.get('type')])
      
  const handleSortClicked = (item: string) => {
    const handleKeySort = () => {
      switch (item) {
        case ShopInventorySort.DEFAULT:
          return ''
        case ShopInventorySort.ASC:
          return paramsSearch.type === 'ENERGY' || paramsSearch.type === 'DOPING' ? 'boost_name' : 'item_name'
        case ShopInventorySort.DESC:
          return paramsSearch.type === 'ENERGY' || paramsSearch.type === 'DOPING' ? 'boost_name-desc' : 'item_name-desc'
        case ShopInventorySort.LOWEST:
          return 'price'
        case ShopInventorySort.HIGHEST:
          return 'price-desc'
        default:
          return ''
      }
    }
    setParams({
      ...params,
      page: 1,
      sort: handleKeySort()
    })
    setSortSelect(item)
  }

  const handleClickedTypeRarity = (item: string) => {
    const handleKeySort = () => {
      switch (item) {
        case ShopTypeRariTy.ALL:
          return ''
        case ShopTypeRariTy.COMMON:
          return 'COMMON'
        case ShopTypeRariTy.EPIC:
          return 'EPIC'
        case ShopTypeRariTy.LEGENDARY:
          return 'LEGENDARY'
        case ShopTypeRariTy.MYTHIC:
          return 'MYTHIC'
        case ShopTypeRariTy.RARE:
          return 'RARE'
        default:
          return 'ALL'
      }
    }
    setParams({
      ...params,
      page: 1,
      rarity: handleKeySort()
    })
    setClassSelectAll(item)
  }

  const handleSearchValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    toggleIsRefresh(true)
    setSearchValue(e.target.value)
  }

  const hanldeViewMore = () => {
    setParams({ ...params, page: params.page + 1 })
  }

  useEffect(() => {
    if (total > listItems?.length) {
      setTotalViewMore(() => total - (listItems?.length || 0))
    } else {
      setTotalViewMore(() => 0)
    }
  }, [listItems, total])

  const handleItemsFilterClicked = (item: string) => {
    const handleKeySort = () => {
      switch (item) {
        case ShopTypeItemsSort.SYSTEMS:
          return 'SYSTEM'
        case ShopTypeItemsSort.USERS:
          return 'USER'
        default:
          return null
      }
    }
    setParams({
      ...params,
      page: 1,
      distributor: handleKeySort()
    })
    setClassSelect(item)
  }

  return (
    <BuyItemsStyled>
      <div className='container'>
        <div className='buy-top'>
          <div className='total-items'>
            <span>{convertPluralText(total, 'item')}</span>
          </div>
          <div className='filter-items'>
            {isPageNFT &&
              <>
                <SwitchBtn
                  title={'Your items'}
                  isOn={isMyHorseOn}
                  handleSwitchBtnClicked={toggleIsMyHorseOn}
                  refSwitch={useRefSwitch}
                />
                <SelectCustom
                  isFiltered
                  dataSelect={defaultTypeItemsFilters}
                  nameSelect={classSelect}
                  onSelected={handleItemsFilterClicked}
                />
              </>
            }

            <SelectCustom
              isFiltered
              dataSelect={defaultTypeBuy}
              nameSelect={classSelectAll}
              onSelected={handleClickedTypeRarity}
            />

            <SelectCustom 
              dataSelect={defaultTypeFilters} 
              nameSelect={sortSelect} 
              onSelected={handleSortClicked} 
            />
            <SearchInput
              searchValue={searchValue}
              handleSearchValueChanged={handleSearchValueChanged}
              customClass='search-horse-input'
              placeholder={'Search...'}
              width={174}
            />
          </div>
        </div>
        {isLoading ? <SkeletonList rows={4} /> :
          total === 0 ?
            <div className='no-data'> NO ITEMS AVAILABLE </div>
            :
            <>
              <div className='items'>
                {listItems?.map((item: ItemShop) => (
                  <ItemNFT
                    key={item?.id}
                    itemName={item?.item?.name || item?.boost?.name}
                    price={item?.price}
                    onClick={onItemClick}
                    id={item?.id}
                    avt={item?.item?.img || item?.boost?.img}
                    type={item?.item?.body_part || item?.boost?.boost_type}
                    currency={item?.currency}
                    level={item?.boost?.level}
                  />
                ))}
              </div>
              {totalViewMore && listItems?.length > 0 ? (
                <div className='color-white view-more-container mt-5 mb-5' onClick={hanldeViewMore}>
                  <button className='btn-view-more'>
                    <span className='btn-view-more-text'>View More {totalViewMore} Items </span>
                  </button>
                </div>
              ) : ('')
              }
            </>
          } 
      </div>
      {isRequestLoginModalOpen && <RequestLoginModal toggleIsModalOpen={toggleIsRequestLoginModalOpen} />}
    </BuyItemsStyled>
  )
}
