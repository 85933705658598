/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import infoLicense from 'json/infoLicense.json'
import { getTooltipInfo } from "./action";

interface initialStateType {
  loadingTooltipInfo: boolean
  tooltipInfo: any
  errorTooltipInfo: string
}

const initialState: initialStateType = {
  loadingTooltipInfo: false,
  tooltipInfo: infoLicense,
  errorTooltipInfo: ""
}

export const tooltipInfoSlice = createSlice({
  name: 'tooltip',
  initialState,
  reducers: {},
  extraReducers: {
    [getTooltipInfo.pending.type]: (state) => {
      state.tooltipInfo = infoLicense;
      state.loadingTooltipInfo = true
    },

    [getTooltipInfo.fulfilled.type]: (state, action: PayloadAction<any>) => {
      state.loadingTooltipInfo = false;
      state.errorTooltipInfo = "";
      state.tooltipInfo = action.payload;
    },
    
    [getTooltipInfo.rejected.type]: (state, action: PayloadAction<string>) => {
      state.loadingTooltipInfo = false;
      state.errorTooltipInfo = action.payload;
    }
  },
})
export default tooltipInfoSlice.reducer
