import styled from 'styled-components'

const ProgressCircleContainer = styled.div`
  position: relative;
  .progress-box {
  }

  .text-info {
    position: absolute;
    top: 46px;
    left: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-width: 45px;

    .col {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      span {
        font-size: 16px;
        color: #fff;
        display: inline-block;
        line-height: 15px;
        &:last-child {
          font-size: 16px;
        }

        &.place {
          color: #F64040;
        }
      }
    }
  }

  .ant-progress-circle {
    .ant-progress-circle-path {
      &:last-child {
        stroke: #05CFFF !important;
      }
    }
  }
`

export default ProgressCircleContainer
