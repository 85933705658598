/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GUILD_MESSAGE } from 'i18n/constants'
import { Spin, notification } from 'antd'

import { setCoinUser } from 'features/Balance/coinUser.slice'
import ModalManagerWithdraw from 'features/Guild/components/ModalManagerWithdraw'
import { RequestLoginModal } from 'features/Race/components'
import { useAppDispatch, useAppSelector, useToggle } from 'hooks'
import { CURRENCY_TYPE, STRING, NUMBER, STRING_ROLE } from 'apps/constants'
import { getOnlyDecimal, handleAsyncRequest } from 'utils/helper'
import ManagementBalanceStyled from './styled'
import { CIRCEL_ARROW_DOWN, GAME_TOKEN_KUDA, GAME_TOKEN_MERAH, LINE_YELLOW, UP } from 'assets/images'
import SelectCustomImg from 'shared/SelectCustomImg'
import TwoLineTitle24 from 'shared/TwoLineTitle24'
import Button from 'shared/Button'
import guildApi from 'apis/guildApi'
import userApi from 'apis/userApi'

const defaultSortType: any = [
  {
    name: CURRENCY_TYPE.MERAH,
    img: GAME_TOKEN_MERAH
  },
  {
    name: CURRENCY_TYPE.KUDA,
    img: GAME_TOKEN_KUDA
  },
]

export default function ManagementBalance() {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [api, contextHolder] = notification.useNotification()
  const { profile } = useAppSelector(state => state)
  const [isRequestLoginModalOpen, toggleIsRequestLoginModalOpen] = useToggle(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [showModalWithdraw, setShowModalWithdraw] = useState<boolean>(false)
  const [valueBalance, setvalueBalance] = useState(NUMBER.ZERO)
  const [nameSelect, setNameSelect] = useState(CURRENCY_TYPE.MERAH)
  const [imgSelect, setImgSelect] = useState(GAME_TOKEN_MERAH)
  const [coinKuda, setCoinKuda] = useState<number>(NUMBER.ZERO)
  const [coinMerah, setCoinMerah] = useState<number>(NUMBER.ZERO)
  const [loadingModal, setLoadingModal] = useState<boolean>(false)

  useEffect(() => {
    fetchCoinUser()
  }, [])

  useEffect(() => {
    if (profile?.id) {
      setLoading(() => false)
    }
  }, [profile])

  const fetchCoinUser = async () => {
    const [error, resultCoinGuild]: any = await handleAsyncRequest(guildApi.getGuildInformation())

    if (error) {
      openNotification('ERROR', error.message || error.errors[0]?.message)
    }

    if (resultCoinGuild?.code === 200 && resultCoinGuild?.data) {
      setCoinKuda(() => resultCoinGuild?.data?.prize_kuda || 0)
      setCoinMerah(() => resultCoinGuild?.data?.prize_merah || 0)
    }
  }

  const openNotification = (message: string, description: string) => {
    notification.config({
      duration: 4
    })

    api.info({
      message,
      description,
      placement: 'bottomRight',
      className: message === STRING.ERROR ? 'ant-custom-error' : 'ant-custom-success'
    })
  }

  const layoutCoin = (logo: string, name: string, price: string | number) => {
    return (
      <div className='wallet-item'>
        <img src={UP} alt='' className='img__border_balance'/>
        <div className='icon-coin'>
          <img src={logo} alt='' />
        </div>
        <div className='name-coin'>
          {name}
        </div>
        <div className='value-coin'>
          {price}
        </div>
      </div>
    )
  }

  const handleSearchValueChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const inputCoin: any = getOnlyDecimal(e.target.value, nameSelect === CURRENCY_TYPE.KUDA ? coinKuda : coinMerah)
    setvalueBalance(() => inputCoin)
  }

  const handleSelected = (data: any) => {
    setNameSelect(() => data.name)
    setImgSelect(() => data.img)
    setvalueBalance(() => NUMBER.ZERO)
  }

  const _handleOpenModalWithdraw = () => {
    setShowModalWithdraw(() => true)
  }

  const _handleToggleModal = () => {
    setShowModalWithdraw(() => false)
  }

  const _getCoinUser = async () => {
    const [, resultCoinUser] = await handleAsyncRequest(userApi.getUserItems())
    if (!resultCoinUser) return
    dispatch(setCoinUser(resultCoinUser?.data))
  }

  const _handleWithdraw = async() => {
    setLoadingModal(() => true)
    const params = {
      amount: +valueBalance,
      item_type: nameSelect === CURRENCY_TYPE.KUDA ? CURRENCY_TYPE.KUDA_NEW : CURRENCY_TYPE.MERAH_NEW
    }
    
    const [error, result]: any = await handleAsyncRequest(guildApi.putGuildMasterWithdraw(params))
    
    if (error) {
      openNotification(STRING.ERROR, error.message || error.errors[NUMBER.ZERO]?.message)
    }

    if (result) {
      openNotification(STRING.SUCCESS, t(`${GUILD_MESSAGE}.message.withdraw_success`))
      setvalueBalance(() => NUMBER.ZERO)
      // useReloadCurrentPage()
      _getCoinUser()
      fetchCoinUser()
    }

    setLoadingModal(() => false)
    setShowModalWithdraw(() => false)
  }

  const renderNotRoleMaster = () => {
    return (
      <div className='box__no_master'>
        <div className='content__no_master'>
          {t(`${GUILD_MESSAGE}.content_no_master`)}
        </div>
      </div>
    )
  }

  return (
    <ManagementBalanceStyled>
      {contextHolder}

      {loading ? (
        <div className='loading'>
          <Spin className='mt-2 mr-3' />
        </div>
      ) : (
        profile?.role_in_guild === STRING_ROLE.MASTER ? (
          <>
            <div className='container__balance'>
              <div className='title-content'>{t(`${GUILD_MESSAGE}.title_balance`)}</div>
              <div className='title__img'>
                <img src={LINE_YELLOW} alt='' />
              </div>
    
              {/* Guild Balance */}
              <div className='title-tabs-container'>
                <div className='title-tabs color-primary'>
                  <TwoLineTitle24 text={t(`${GUILD_MESSAGE}.guild_balance`)} classGuild={"line__create"} fontSize={24} />
                </div>
              </div>
    
              <div className='wallet'>
                <div>
                  {layoutCoin(GAME_TOKEN_KUDA, CURRENCY_TYPE.KUDA, coinKuda)}
                </div>
                <div>
                  {layoutCoin(GAME_TOKEN_MERAH, CURRENCY_TYPE.MERAH, coinMerah)}
                </div>
              </div>
    
              {/* Withdraw */}
              <div className='title-tabs-container'>
                <div className='title-tabs color-primary'>
                  <TwoLineTitle24 text={t(`${GUILD_MESSAGE}.withdraw_balance`)} classGuild={"line__create"} />
                </div>
              </div>
    
              <div className='input_balance'>
                <div className='title_input'>{t(`${GUILD_MESSAGE}.guild_balance2`)}</div>
                <div className='input'>
                  <div className='input_input'>
                    {/* <div className='cover__rigth'></div>
                    <img src={ONE_LINE} alt='' className='orange-line position-absolute' /> */}
                    <input
                      className='value-merah search-input flex-grow-1'
                      value={valueBalance}
                      placeholder={STRING.STR_0}
                      onChange={handleSearchValueChanged}
                      maxLength={NUMBER.FIFTEEN}
                    />
                    <div className='input_select'>
                      <SelectCustomImg
                        dataSelect={defaultSortType}
                        nameSelect={nameSelect}
                        imgSelect={imgSelect}
                        onSelected={handleSelected}
                      />
                    </div>
                  </div>
                </div>
              </div>
    
              <div className='text-center mt-2 mb-4'>
                <img src={CIRCEL_ARROW_DOWN} alt='' />
              </div>
    
              <div className='input_balance'>
                <div className='title_input'>{t(`${GUILD_MESSAGE}.guild_master`)}</div>
                <div className='input'>
                  <div className='input_input'>
                    {/* <div className='cover__rigth'></div>
                    <img src={ONE_LINE} alt='' className='orange-line position-absolute' /> */}
                    <input
                      className='value-merah search-input flex-grow-1'
                      value={valueBalance}
                      placeholder={STRING.STR_0}
                      maxLength={NUMBER.FIFTEEN}
                      disabled
                    />

                    <div className='replay_select'>
                      <span className='select-name'>
                        <span className='img_select'>
                          <img src={imgSelect} alt='' />
                        </span>
                        {nameSelect}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <div className='input_balance contain__btn_withdraw'>
              <Button
                buttonName={t(`${GUILD_MESSAGE}.title_modal_withdraw`)}
                onClickButton={_handleOpenModalWithdraw}
                disabled={valueBalance <= NUMBER.ZERO}
              />
            </div>
          </>
        ) : renderNotRoleMaster()
      )}

      {/* PopUp withdraw */}
      {showModalWithdraw && valueBalance > NUMBER.ZERO && (
        <ModalManagerWithdraw
          title={t(`${GUILD_MESSAGE}.title_modal_withdraw`)}
          message={
            <p>
              {t(`${GUILD_MESSAGE}.content_withdraw_1`)} {valueBalance || NUMBER.ZERO} {' '}
              <span className='img_select'>
                <img src={imgSelect} alt='' />
              </span>
              {t(`${GUILD_MESSAGE}.content_withdraw_2`)}<br />
              {t(`${GUILD_MESSAGE}.content_withdraw_3`)}
            </p>}
          onClose={_handleToggleModal}
          onConfirm={_handleWithdraw}
          loading={loadingModal}
          titleColor="rgb(78, 240, 118)"
          width={560}
          heigth={240}
        />
      )}

      {/* Popup login */}
      {isRequestLoginModalOpen && <RequestLoginModal toggleIsModalOpen={toggleIsRequestLoginModalOpen} />}
    </ManagementBalanceStyled>
  )
}
