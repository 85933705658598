/* eslint-disable @typescript-eslint/no-explicit-any */
import { memo } from 'react'
import { useAppSelector } from 'hooks'
import UserInfoContainer from './styled'
import { AVATAR_DEFAULT } from 'assets/images'
import { getWinRate, shortenRaceNameV2 } from 'utils/helper'
import RankCount from '../RankCount'

const UserInfo = ({ data }: any) => {
  const profile: any = useAppSelector(state => state.profile)

  if (!profile?.public_address) {
    return null
  }

  return (
    <UserInfoContainer>
      <div className='d-flex text-uppercase'>
        <div className='width-25 center'>
          <RankCount rank={data?.user_rank} />
        </div>
        <div className='width-30'>
          <div className='name'>
            <div className='img'>
              <img src={data?.owner_avatar || AVATAR_DEFAULT} alt='avatar' />
            </div>
            <span>{shortenRaceNameV2(data?.owner_name, 20)}</span>
          </div>
        </div>
        <div className='width-20 left'>
          <span>
            {data?.total_number_of_races} {data?.first_count}/{data?.second_count}/{data?.third_count}
          </span>
        </div>
        <div className='width-25 left'>
          <span>{getWinRate(data)}</span>
        </div>
      </div>
    </UserInfoContainer>
  )
}

export default memo(UserInfo)
