import styled from 'styled-components'

interface InputStyledProps {
  width: number | undefined
}

const InputStyled = styled.div<InputStyledProps>`
  width: ${({ width }) => (width ? width + 'px' : '100%')};
  padding: 8px 12px;
  column-gap: 8px;

  background-color: ${({ theme }) => theme.color.neutralBackground};
  clip-path: polygon(97% 0%, 100% 15px, 100% 100%, 0 100%, 0 0);

  ${({ theme }) => theme.media.lessThan(theme.size.sm)} {
    width: 100%;
    clip-path: none;
  }

  .orange-line {
    left: 0;
    bottom: -1px;
    width: 70px;
    height: 3px;
  }

  .search-icon {
    width: 20px;
  }

  .search-input {
    background-color: ${({ theme }) => theme.color.transparent} !important;
    border: none;
    resize: none;
    padding: 4px 11px;

    font-size: 16px;
    line-height: 20px;
    color: ${({ theme }) => theme.color.white};

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &::placeholder {
      color: ${({ theme }) => theme.color.grey};
    }
  }
`

export default InputStyled
