import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import { useEffect, useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { formatTime } from 'utils/helper'

interface CountDownTimeProps {
  time: number
  toggleSortRaces?: () => void
}

function CountDownTime({ time }: CountDownTimeProps) {
  const [counter, setCounter] = useState(time);
  const intervalIdRef = useRef<number | null>(null);
  const { t } = useTranslation()
  const incrementCounter = () => {
    setCounter((c) => c - 1000);
  };

  useEffect(() => {
    if (intervalIdRef.current !== null) return;
    intervalIdRef.current = window.setInterval(() => {
      incrementCounter();
    }, 1000);

    return () => {
      if (intervalIdRef.current) {
        window.clearInterval(intervalIdRef.current);
        intervalIdRef.current = null;
      }
    };
  }, []);

  if (counter <= 0) {
    return (
      <div className='starts-in color-red font-bold d-flex align-items-center'>
        <div className='dot' /> <span className='live-text'>{t(`${NOTIFICATION_MESSAGE}.live`)}</span>
      </div>
    )
  }
  return <div>{formatTime(counter)}</div>
}

export default CountDownTime
