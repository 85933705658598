/* eslint-disable @typescript-eslint/no-explicit-any */
import { Menu, MenuProps } from 'antd'
import SubMenuStyled from './styled'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { links } from 'apps';
import { ARMOR_ICON, SKIN_ICON, DOPING_ICON } from 'assets/images'


type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuItem[] | any = [
  getItem(<div className='title-main'>BUY</div>, 'buy', null, [], 'group'),
  getItem('Armor', 'buy_armor', <img src={ARMOR_ICON} alt="" />, [
    getItem('Head', 'HEAD'),
    getItem('Body', 'BODY'),
    getItem('Leg', 'LEG'),
  ]),
  getItem('Skin', 'SKIN', <img src={SKIN_ICON} alt="" />),
  getItem('Booster', 'buy_booster', <img src={DOPING_ICON} alt="" />, [
    getItem('Energy', 'ENERGY'),
    getItem('Doping', 'DOPING'),
  ]),
  getItem(<div className='title-main'>INVENTORY</div>, 'inventory', null, [], 'group'),
  getItem('Armor', 'inventory_armor', <img src={ARMOR_ICON} alt="" />, [
    getItem('Head', 'inventory_head'),
    getItem('Body', 'inventory_body'),
    getItem('Leg', 'inventory_leg'),
  ]),
  getItem('Skin', 'inventory_skin', <img src={SKIN_ICON} alt="" />),
  getItem('Booster', 'inventory_booster', <img src={DOPING_ICON} alt="" />, [
    getItem('Energy', 'inventory_energy'),
    getItem('Doping', 'inventory_doping'),
  ]),
  getItem(<div className='title-main'>SELLING</div>, 'selling', null, [], 'group'),
  getItem('Armor', 'selling_armor', <img src={ARMOR_ICON} alt="" />, [
    getItem('Head', 'selling_head'),
    getItem('Body', 'selling_body'),
    getItem('Leg', 'selling_leg'),
  ]),
  getItem('Skin', 'selling_skin', <img src={SKIN_ICON} alt="" />),
];


export default function SubMenu() {
  const navigate = useNavigate()
  const handleClicked = (e: any) => {
    const { key } = e
    const searchParams = new URLSearchParams(window.location.search);
    if (key?.includes('inventory')) {
      searchParams.set('type', key);
      const newURL = `${links.shop.inventoryItems()}?${searchParams.toString()}`;
      navigate(newURL);
    } else if (key?.includes('selling')) {
      searchParams.set('type', key);
      const newURL = `${links.shop.sellItems()}?${searchParams.toString()}`;
      navigate(newURL);
    } else {
      searchParams.set('type', key);
      const newURL = `${links.shop.buyItems()}?${searchParams.toString()}`;
      navigate(newURL);
    }
  }
  const [searchParams] = useSearchParams();
  const typePart = searchParams.get("type") || '';

  return (
    <SubMenuStyled >
      <div style={{ width: 174 }}>
        <Menu
          defaultSelectedKeys={[typePart]}
          mode="inline"
          theme="dark"
          items={items}
          onClick={handleClicked}
          defaultOpenKeys={['buy_armor']}
        />
      </div>
    </SubMenuStyled>
  )
}