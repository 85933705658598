import { ALL_HEAD, BODER_CIRCLE, SUB_TRACT } from 'assets/images'
import ItemNFTStyled from './styled'
import { RateStar } from 'features/Horse/components'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import { useTranslation } from 'react-i18next'

interface ItemNFTProps {
  type?: string
  avt?: string
  level?: string
  itemName?: string
  amount?: number
  onClick?: () => void
}

export default function ItemNFT({ onClick, avt, itemName, amount, level, type, ...props }: ItemNFTProps) {
  const { t } = useTranslation()
  const generateRateLevel = (maxLevel: number, currentLevel: number): JSX.Element[] => {
    const rateStars: JSX.Element[] = []
    for (let i = 0; i < maxLevel; i++) {
      rateStars.push(<RateStar key={i} isActive={i < currentLevel} />)
    }
    return rateStars
  }
  return (
    <ItemNFTStyled {...props}>
      <div className={`item ${type?.toLowerCase()}`}>
        <img className='sub-tract' src={SUB_TRACT} alt="" />
        <img className='avt-item' src={avt ?? ALL_HEAD} alt="" />
        <div className='description'>
          <div className="star-amount">
            {(level && type === 'DOPING') ? (
              <div className='level d-flex ps-2'>{generateRateLevel(3, Number(level))}</div>
            ) : ''}
          </div>
          <h4>{itemName}</h4>
        </div>
        {amount ? (
          <div className="numb">
            <img src={BODER_CIRCLE} alt="" />
            <span>{amount}</span>
          </div>
        ) : ''}
        <div className='btn-detail-container'>
          <button className='font-bold btn-detail' onClick={onClick} >
            <span className='color-white'>{t(`${NOTIFICATION_MESSAGE}.details`)}</span>
          </button>
        </div>
      </div>
    </ItemNFTStyled>
  )
}
