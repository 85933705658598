import { Modal } from 'shared'
import ConfirmModalStyled from './styled'
import { APP_BUTTON, ONE_LINE_ORANGE } from 'assets/images'
import { ReactNode } from 'react'
import classNames from 'classnames'
import { MODAL_TYPE } from 'models'

interface ConfirmModalProps {
  onConfirm?: () => void
  message: ReactNode
  title?: string
  isLoading?: boolean
  btnCancel?: string
  isDisabled?: boolean
}

function ConfirmModal({ onConfirm, message, title }: ConfirmModalProps) {

  const classTitle = classNames([
    'race-name color-orange text-uppercase d-flex align-items-center flex-column',
    (title === 'completed' || title === 'success!') && 'color-primary'
  ])

  const classTitleFailed = classNames([
    'race-name color-orange text-uppercase d-flex align-items-center flex-column',
    title === MODAL_TYPE.failed && 'color-red'
  ])


  const handleClassTitle = () => {
    switch (title) {
      case MODAL_TYPE.completed:
        return classTitle
      case MODAL_TYPE.success:
        return classTitle
      case MODAL_TYPE.failed:
        return classTitleFailed
      default:
        return classTitle
    }
  }

  return (
    <Modal>
      <ConfirmModalStyled>
        <div className='choose-horse-modal'>
          {title &&
            <div className='race-name-container'>
              <p className={handleClassTitle()}>
                <span className='title'>{title}
                  <img className='line-bottom' src={ONE_LINE_ORANGE} alt='' />
                </span>
              </p>
            </div>
          }
          <div className='confirm-horse'>
            <div className='text'>
              {message}
            </div>
            <div className='confirm-horse-btns'>
              <div className='btn-custom' onClick={onConfirm}>
                <img src={APP_BUTTON} width={140} alt='app-button' />
                <span className='btn-text'> OK</span>
              </div>
            </div>
          </div>
        </div>
      </ConfirmModalStyled>
    </Modal>
  )
}

export default ConfirmModal
