/* eslint-disable @typescript-eslint/no-explicit-any */
import InventoryItemsStyled from './styled'
import { useState, useEffect } from 'react'
import { SearchInput } from 'shared'
import SelectCustom from 'shared/SelectCustom'
import { ShopTypeBodyPart, TypeSorts, ShopInventoryParams, ItemShop, ShopTypeRariTy, ShopInventorySort } from 'models'
import { ItemInventory } from 'features/ShopAP/components'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { convertPluralText, handleAsyncRequest } from 'utils/helper'
import itemShopsApi from 'apis/itemShopsApi'
import openNotification from 'utils/toast'
import itemApi from 'apis/itemApi'
import { RequestLoginModal } from 'features/Race/components'
import SkeletonList from 'features/components/SkeletonList'
import { constants } from 'apps'
import { useDebounce, useToggle, useAppSelector } from 'hooks'

export default function InventoryItems() {
  const { auth } = useAppSelector(state => state)
  const [searchParams] = useSearchParams()

  const paramsSearch: any= Object.fromEntries(searchParams.entries())

  const [params, setParams] = useState<ShopInventoryParams>(paramsSearch)
  const [isLoading, setIsLoading] = useState(false)
  const [listItems, setListItems] = useState<ItemShop[]>([])
  const [total, setTotal] = useState(0)
  const [totalViewMore, setTotalViewMore] = useState(0)
  const [isComingSoon] = useState(true)
  const [currentType, setCurrentType] = useState<boolean>(false)
  const [typeItems, setType] = useState<string>('')
  const [isRequestLoginModalOpen, toggleIsRequestLoginModalOpen] = useToggle(false)
  const [searchValue, setSearchValue] = useState<string>(params?.search || '')
  const debounceSearchValue = useDebounce<string>(searchValue, constants.DEBOUNCE_TIME)
  const handleKeySortFullName = (item: string) => {
    switch (item) {
      case '' :
        return ShopInventorySort.DEFAULT
      case 'name-asc' :
        return ShopInventorySort.ASC
      case 'name-desc' :
        return ShopInventorySort.DESC
      default:
        return ''
    }
  }
  const handleKeySortRariTy = (item: string) => {
    switch (item) {
      case ShopTypeRariTy.ALL:
        return 'All'
      case 'COMMON':
        return ShopTypeRariTy.COMMON
      case 'EPIC' :
        return ShopTypeRariTy.EPIC
      case 'LEGENDARY' :
        return ShopTypeRariTy.LEGENDARY
      case 'MYTHIC' :
        return ShopTypeRariTy.MYTHIC
      case 'RARE' :
        return ShopTypeRariTy.RARE
      default:
        return 'All'
    }
  }
  const [classSelectAll, setClassSelectAll] = useState(handleKeySortRariTy(params?.rarity || 'All'))
  const [classSelect, setClassSelect] = useState(handleKeySortFullName(params?.sort || ''))
  // const [classSelect, setClassSelect] = useState(params?.sort || 'Sort')

  const navigate = useNavigate()
  const { pathname } = useLocation()

  const typePart = searchParams.get("type");

  const converBodyPart = (typePart: any) => {
    const handleKey = () => {
      switch (typePart) {
        case ShopTypeBodyPart.HEAD:
          return 'HEAD'
        case ShopTypeBodyPart.BODY:
          return 'BODY'
        case ShopTypeBodyPart.LEG:
          return 'LEG'
        case ShopTypeBodyPart.SKIN:
          return 'SKIN'
        case ShopTypeBodyPart.DOPING:
          return 'DOPING'
        case ShopTypeBodyPart.ENERGY:
          return 'ENERGY'
        case ShopTypeBodyPart.NFT:
          return 'NFT'
        case ShopTypeBodyPart.SELLING:
          return 'SELLING'
        default:
          return ''
      }
    }
    setCurrentType(handleKey() === 'DOPING' || handleKey() === 'ENERGY')
    if (handleKey() === 'DOPING' || handleKey() === 'ENERGY') {
      setParams({
        ...params, 
        limit: 20,
        page: 1, 
        boostType: handleKey(), 
        type: handleKey()
      })
    } else {
      setParams({
        ...params, 
        limit: 20,
        page: 1, 
        type: handleKey(), 
        boostType: handleKey()
      })
    }
    setType(handleKey())
    return handleKey()
  }

  const onItemClick = (id: string) => {
    if (!auth.isLogged) {
      localStorage.clear()
      location.reload()
      return
    } else {
      setListItems([])
      currentType ? navigate(`${pathname}/${id}`) : navigate(`${pathname}/${id}?type=ARMOR`)
    }
  }

  const defaultTypeRarity: TypeSorts = [
    {
      name: ShopTypeRariTy.ALL,
      isActive: false
    },
    {
      name: ShopTypeRariTy.COMMON,
      isActive: false
    },
    {
      name: ShopTypeRariTy.RARE,
      isActive: false
    },
    {
      name: ShopTypeRariTy.EPIC,
      isActive: false
    },
    {
      name: ShopTypeRariTy.LEGENDARY,
      isActive: false
    },
    {
      name: ShopTypeRariTy.MYTHIC,
      isActive: false
    }
  ]

  const defaultTypeFilters: TypeSorts = [
    {
      name: ShopInventorySort.DEFAULT,
      isActive: false
    },
    {
      name: ShopInventorySort.ASC,
      isActive: false
    },
    {
      name: ShopInventorySort.DESC,
      isActive: false
    }
  ]
  const handleSearchValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }
  useEffect(() => {
    setParams({ ...params, search: debounceSearchValue })
  }, [debounceSearchValue])

  const handleClassFilterClicked = (item: string) => {
    const handleKeySort = () => {
      switch (item) {
        case ShopInventorySort.DEFAULT:
          return ''
        case ShopInventorySort.ASC:
          return 'name-asc'
        case ShopInventorySort.DESC:
          return 'name-desc'
        default:
          return ''
      }
    }
    setParams({
      ...params, page: 1, sort: handleKeySort()
    })
    setClassSelect(item)
  }
  const handleClickedTypeRarity = (item: string) => {
    const handleKeySort = () => {
      switch (item) {
        case ShopTypeRariTy.ALL:
          return ''
        case ShopTypeRariTy.COMMON:
          return 'COMMON'
        case ShopTypeRariTy.EPIC:
          return 'EPIC'
        case ShopTypeRariTy.LEGENDARY:
          return 'LEGENDARY'
        case ShopTypeRariTy.MYTHIC:
          return 'MYTHIC'
        case ShopTypeRariTy.RARE:
          return 'RARE'
        default:
          return 'ALL'
      }
    }
    setParams({
      ...params, page: 1, rarity: handleKeySort()
    })
    setClassSelectAll(item)
  }

  const fetchListItems = async () => {
    setIsLoading(true)
    const [error, result]: any = await handleAsyncRequest(itemShopsApi.getItemInventory({ ...params }))
    const records = result?.data.records
    if (records) {
      setListItems(params.page > 1 ? [...listItems, ...records] : records)
      setTotal(result?.data.total)
    }
    if (error) {
      setListItems([])
      if (error?.code === 403) {
        toggleIsRequestLoginModalOpen()
        setIsLoading(false)
        return
      }
      openNotification({
        message: 'ERROR',
        description: error.message || error.errors[0]?.message,
        className: 'ant-custom-error'
      })
    }

    if (records && records.length === 0) {
      // setLoader(true)
    }
    setIsLoading(false)
  }

  const fetchWeaponsItems = async () => {
    setIsLoading(true)
    const [error, result]: any = await handleAsyncRequest(itemApi.getItemsWeapons({ ...params }))
    if (result) {
      const records = result?.data.records
      setListItems(params.page > 1 ? [...listItems, ...records] : records)
      setTotal(result?.data?.total)
    }
    if (error) {
      setListItems([])
      if (error?.code === 403) {
        toggleIsRequestLoginModalOpen()
        setIsLoading(false)
        return
      }
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (total > listItems?.length) {
      setTotalViewMore(() => total - (listItems?.length || 0))
    } else {
      setTotalViewMore(() => 0)
    }
  }, [listItems, total])

  const hanldeViewMore = () => {
    setParams({ ...params, page: params.page + 1 })
  }
  
  useEffect(() => {
    converBodyPart(typePart)
  }, [typePart])
  
  useEffect(() => {
    const currentType = searchParams.get('type')
    const storeType = localStorage.getItem('typeInventory')
    if (storeType && (storeType === currentType)) {
      setSearchValue(params?.search || '')
    } else {
      setParams({...params, limit: 20, rarity: '', search: '', sort: '', type: converBodyPart(currentType), boostType: converBodyPart(currentType)},)
      setClassSelect('Default')
      setClassSelectAll('All')
      setSearchValue('')
      localStorage.setItem('typeInventory', currentType || '');
    }
  }, [searchParams.get('type')])
  
  const renderQueries = (query : any) => {
    let index = 0;
    let queryText = `?type=${typePart}`;
    for (const property in query) {
      if (query[property]) {
        queryText += `${index ? "&" : "&"}${property}=${query[property]}`;
        index++;
      }
    }
    return queryText;
  }

  useEffect(() => {
    if (params?.boostType && (typePart === 'inventory_doping' || typePart === 'inventory_energy')) {
      if (params?.limit) {
        fetchListItems()
      }
      const replaceNavigate = renderQueries(params as ShopInventoryParams)
      navigate(
        `/shop/inventory_items${replaceNavigate}`
      )
    } else if (params.type) {
      if (params?.limit) {
        fetchWeaponsItems()
      }
      const replaceNavigate = renderQueries(params as ShopInventoryParams)
      navigate(
        `/shop/inventory_items${replaceNavigate}`
      )
    }
  }, [params])

  return (
    <InventoryItemsStyled>
      <div className='inventory-body'>
        <div className='inventory-top  d-flex justify-content-between align-items-center'>
          <div className='total-items'>
            <span>{convertPluralText(total, 'item')}</span>
          </div>
          <div className='filter-items d-flex justify-content-right'>
            <SelectCustom
              dataSelect={defaultTypeRarity}
              nameSelect={classSelectAll}
              onSelected={handleClickedTypeRarity}
            />
            <SelectCustom
              dataSelect={defaultTypeFilters}
              nameSelect={classSelect}
              onSelected={handleClassFilterClicked}
            />
            <SearchInput
              searchValue={searchValue}
              handleSearchValueChanged={handleSearchValueChanged}
              customClass='search-horse-input'
              placeholder={'Search ...'}
              width={174}
            />
          </div>
        </div>
        {isComingSoon ? (
          <div className='inventory-list'>
            {isLoading ? (
              <SkeletonList rows={4} />
            ) : (
              <div className={`${(typePart === 'inventory_doping' || typePart === 'inventory_energy') ? 'list-doping' : 'list-armor'}`}>
                {listItems.length === 0 ? (
                  <p className='color-white text-uppercase d-flex justify-content-center align-items-center'>No items Available</p>
                ) : (
                  <div className='items'>
                    {
                      listItems && listItems.map((item, index) => (
                        <ItemInventory
                          avt={currentType ? item?.boost?.img : item?.img}
                          key={index}
                          itemName={currentType ? item?.boost?.name : item?.name}
                          amount={currentType ? Number(item?.amount) : 0}
                          onClick={() => onItemClick(currentType ? item?.boost?.id : item?.item_token)}
                          level={currentType ? item?.boost?.level : ''}
                          type={currentType ? typeItems : item?.body_part}
                        />
                      ))
                    }
                  </div>
                )}
                {totalViewMore && listItems?.length > 0 ? (
                  <div className='color-white view-more-container mt-5 mb-5'>
                    <button className='btn-view-more' onClick={hanldeViewMore}>
                      <span className='btn-view-more-text'>View More {totalViewMore} Item{totalViewMore === 1 ? '' : 's'} </span>
                    </button>
                  </div>
                ) : ('')
                }
              </div>
            )}
          </div>
        ) : (
          <h1 className='color-white text-uppercase d-flex justify-content-center align-items-center'>Coming Soon</h1>
        )}
      </div>
      {isRequestLoginModalOpen && <RequestLoginModal toggleIsModalOpen={toggleIsRequestLoginModalOpen} />}
    </InventoryItemsStyled>
  )
}
