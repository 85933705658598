/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-useless-escape */
import { useEffect } from 'react'
import { Form, Input } from 'antd'

export interface dataProps {
  profit_guild: string | number
  profit_owner: string | number
  profit_renter: string | number
}

interface formProps {
  data: dataProps
  isEdit: boolean
  form: any
  onSubmitForm: (value: any) => void
  isMaster: boolean
}

import { FormContainer } from './styled'

const FormIncome = ({ data, isEdit, form, onSubmitForm, isMaster }: formProps) => {
  useEffect(() => {
    form.setFieldsValue({
      profitGuild: data?.profit_guild,
      profitOwner: data?.profit_owner,
      profitRenter: data?.profit_renter
    })
  }, [form])

  const _handleKeyDown = (e: any) => {
    if (['e', 'E', '+', '-', '.', ','].includes(e.key) || [38, 40].includes(e.keyCode)) {
      return e.preventDefault()
    }
    if (e.key === 'Unidentified') {
      return
    }
  }

  return (
    <FormContainer>
      <Form form={form} name='basic' onFinish={onSubmitForm} autoComplete='off'>
        <div className='income-row'>
          <span>Guild</span>
          {!isMaster ? (
            <span className='input-box text'>{data?.profit_guild}%</span>
          ) : (
            <div className='input-box'>
              <Form.Item
                name='profitGuild'
                className='input-box'
                rules={[
                  { required: true, message: 'Please input your guild!' },
                  {
                    validator: (_, value) => {
                      if (+value > 100) {
                        return Promise.reject(new Error('Percent max is 100% !'))
                      }
                      return Promise.resolve()
                    }
                  }
                ]}
              >
                <Input type='number' disabled={!isEdit} onKeyDown={_handleKeyDown} />
              </Form.Item>
            </div>
          )}
        </div>
        <div className='income-row'>
          <span>Owner</span>
          {!isMaster ? (
            <span className='input-box text'>{data?.profit_owner}%</span>
          ) : (
            <div className='input-box'>
              <Form.Item
                name='profitOwner'
                className='input-box'
                rules={[
                  { required: true, message: 'Please input your guild owner!' },
                  {
                    validator: (_, value) => {
                      if (+value > 100) {
                        return Promise.reject(new Error('Percent max is 100% !'))
                      }

                      return Promise.resolve()
                    }
                  }
                ]}
              >
                <Input type='number' disabled={!isEdit} onKeyDown={_handleKeyDown} />
              </Form.Item>
            </div>
          )}
        </div>
        <div className='income-row'>
          <span>Renter</span>
          {!isMaster ? (
            <span className='input-box text'>{data?.profit_renter}%</span>
          ) : (
            <div className='input-box'>
              <Form.Item
                name='profitRenter'
                className='input-box'
                rules={[
                  { required: true, message: 'Please input your guild renter!' },
                  {
                    validator: (_, value) => {
                      if (+value > 100) {
                        return Promise.reject(new Error('Percent max is 100% !'))
                      }

                      return Promise.resolve()
                    }
                  }
                ]}
              >
                <Input type='number' disabled={!isEdit} onKeyDown={_handleKeyDown} />
              </Form.Item>
            </div>
          )}
        </div>
      </Form>
    </FormContainer>
  )
}

export default FormIncome
