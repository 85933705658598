/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import moment from 'moment'
import { useSearchParams } from 'react-router-dom'

import Tabs from 'features/Ranking/components/Tabs'
import { SearchInput } from 'shared'
import TopUserList from 'features/Ranking/components/TopUserList'
import { TopRankingContainer } from '../styled'
import { Horse, MyHorseListParams } from 'models'
import rankingApi from 'apis/rankingApi'
import { useFetch, useIsMounted, useDebounce } from 'hooks'
import { constants } from 'apps'
import Head from 'features/Ranking/components/Head'
import Loading from 'features/Ranking/components/Loading'

const currentYearMonth = moment().format('YYYY-MM')

const myUserListParams: MyHorseListParams = {
  limit: 10,
  page: 1,
  search: null,
  period: 'YEAR_MONTH',
  top: 50,
  yearMonth: currentYearMonth
}

const TopUser = () => {
  const isMounted = useIsMounted()
  const [searchValue, setSearchValue] = useState<string>('')
  const [searchParams, setSearchParams] = useSearchParams() as any
  const [userListParams, setUserListParams] = useState<MyHorseListParams>(myUserListParams)
  const [pageCount, setPageCount] = useState<number>(0)
  const [listUser, setListUser] = useState<Horse[]>([])
  const [numberUser, setNumberUser] = useState<string>('')
  const [period, setPeriod] = useState<string>('YEAR_MONTH')

  const paramsSearch = Object.fromEntries(searchParams.entries())
  const debounceSearchValue = useDebounce<string>(searchValue, constants.DEBOUNCE_TIME)

  const { data: userListResponse, loading } = useFetch(
    {
      fetcher: rankingApi.getTopUsers,
      params: {
        ...userListParams,
        page: paramsSearch.page ? +paramsSearch.page : userListParams.page
      }
    },
    [userListParams]
  )

  const { data: myRanking }: any = useFetch(
    {
      fetcher: rankingApi.getUserRanking,
      params: {
        period
      }
    },
    [period]
  )

  const _onTabClick = (v: string) => {
    if (v === 'ALL_TIME') {
      delete userListParams.yearMonth
    }
    setPeriod(v)
    setSearchParams({ page: 1 })
    setUserListParams({
      ...userListParams,
      page: 1,
      search: searchValue,
      period: v,
      ...(v === 'YEAR_MONTH' && {
        yearMonth: currentYearMonth
      })
    })
  }

  const handleSearchValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  useEffect(() => {
    setSearchParams({ page: 1 })
    setUserListParams({ ...userListParams, page: 1, search: searchValue })
  }, [debounceSearchValue])

  useEffect(() => {
    if (!userListResponse || !isMounted()) {
      return
    }
    setListUser(userListResponse.records)
    setPageCount(userListResponse.total_page)
    setNumberUser(userListResponse.total.toString())
  }, [userListResponse])

  const handlePageClick = (event: any) => {
    setSearchParams({ page: event.selected + 1 })
    setUserListParams({ ...userListParams, page: event.selected })
  }

  return (
    <TopRankingContainer>
      <Head name='Top Users' />
      <div className='head-box'>
        <SearchInput
          searchValue={searchValue}
          handleSearchValueChanged={handleSearchValueChanged}
          customClass='search-horse-input'
          placeholder={'Search'}
        />
      </div>
      <Tabs onTabClick={_onTabClick} />
      {loading ? (
       <Loading />
      ) : (
        <TopUserList
          data={listUser}
          handlePageClick={handlePageClick}
          pageCount={pageCount}
          paramsSearch={paramsSearch}
          numberUser={numberUser}
          debounceSearchValue={debounceSearchValue}
          myRankingData={myRanking}
        />
      )}
    </TopRankingContainer>
  )
}

export default TopUser
