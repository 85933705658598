/* eslint-disable @typescript-eslint/no-explicit-any */
import { HORSE_ARMOR_FRAME, HORSE_POPUP_ARMOR } from 'assets/images'
import styled from 'styled-components'

interface HorseItemStyleProps {
  width?: any
  height?: any
}

const HorseItemStyle = styled.div<HorseItemStyleProps>`
  position: relative;
  width: ${({ width }) => width + 'px'};
  min-height: ${({ height }) => height + 'px'};
  background: url(${HORSE_POPUP_ARMOR});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  z-index: 99;
  padding: 25px 32px 30px;

  .close-btn {
    top: 29px;
    right: 29px;
    background-color: ${({ theme }) => theme.color.transparent};
    border: none;
    z-index: 100;
  }

  .content-box {
    position: relative;
    display: flex;
    flex-direction: column;
    color: #fff;
    font-size: 18px;
    line-height: 38px;

    .box__info {
      display: flex;

      .box__img {
        width: 150px;
        height: 150px;
        background: url(${HORSE_ARMOR_FRAME});
        background-repeat: no-repeat;
        background-size: 100% 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .box__info__content {
        flex: 1;
        padding-top: 20px;
        padding-left: 10px;
        font-weight: 600;

        .info__title {
          font-size: 22px;
        }

        .info__type__left {
          color: #ccbb66;
          display: inline-block;
          width: 70px;
        }

        .info__type__icon {
          display: inline-block;
          width: 35px;
        }
      }
    }

    .information {
      margin-top: 6px;
      padding-left: 6px;

      .content{
        white-space-collapse: preserve-breaks;

        p{
          margin-bottom: 0px;
        }
      }

      .information-box {
        display: flex;
        gap: 20px;
        font-size: 18px;

        .active {
          position: relative;
          color: #4ef076;
          font-weight: 600;
          line-height: 24px;

          .line-text {
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translate(-50%);
          }
        }
      }
    }
  }

  .loading__item {
    position: absolute;
    top: 50%;
    left: 50%;

    .ant-spin {
      .ant-spin-dot-item {
        background-color: #4bf296;
      }
    }
  }
`

export { HorseItemStyle }
