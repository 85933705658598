import styled from 'styled-components'

const ItemInformationModalStyled = styled.div`
  min-height: 274px;
  width: 650px;
  position: relative;
  background-color: #191d2c;
  -webkit-clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);
  clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);
  padding: 30px 40px 20px;
  position: relative;
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: ${({ theme }) => theme.color.transparent};
    border: none;
    z-index: 1;
  }

  .btn-use {
    cursor: pointer;
  }
  &:before {
    content: '';
    position: absolute;
    min-height: 272px;
    top: 1px;
    left: 1px;
    background-color: #191d2c;
    display: block;
    -webkit-clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);
    clip-path: polygon(0% 0%, 0 90%, 100% 300%, 100% 100%, 100% 0%);
  }

  &.HEAD {
    .attribute-content {
      .avatar-box {
        display: flex;
        justify-content: flex-start;
        .avatar {
          width: 142px;
          height: 142px;

          img {
            transform: translateX(125px) translateY(80px) scale(3);
          }
        }
      }
    }
  }

  .title {
    width: 200px;
    background-color: ${({ theme }) => theme.color.transparent};
    z-index: 1;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 0%);
    .text {
      font-size: 24px;
    }
  }

  .attribute-content {
    padding-top: 50px;
    .avatar-box {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 20px 100%, 0 calc(100% - 20px));
      display: flex;
      justify-content: flex-start;
      .avatar {
        width: 142px;
        height: 142px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .info {
        flex: 1;
        padding: 20px;
        p {
          color: #fff;
        }
        .info-title {
          font-size: 24px;
          font-weight: 400;
          line-height: 20px;
          text-transform: uppercase;
        }
        .attribute-type {
          color: rgba(255, 255, 255, 0.48);
          font-size: 24px;
          font-weight: 400;
          text-align: left;
          margin-bottom: 0;
        }
        .sub-infor{
          color: #FFF;
          .icon-rarity{
            width: 42px;
          }
          span{
            color: #FFF566;
            font-size: 20px;
            text-align: left;
            margin-bottom: 0;
            min-width: 70px;
          }
        }
      }
    }

    .content-box {
      margin: 16px 0;

      .attribute-head {
        position: relative;
        background-color: #0f121c;
        height: 48px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 10px;
        clip-path: polygon(
          100% 0%,
          70% 0%,
          30% 0%,
          0% 0%,
          0% 25%,
          0% 65%,
          2% 100%,
          6% 100%,
          39% 100%,
          64% 100%,
          100% 100%,
          100% 73%,
          100% 47%,
          100% 25%
        );
        -webkit-clip-path: polygon(
          100% 0%,
          70% 0%,
          30% 0%,
          0% 0%,
          0% 25%,
          0% 65%,
          2% 100%,
          6% 100%,
          39% 100%,
          64% 100%,
          100% 100%,
          100% 73%,
          100% 47%,
          100% 25%
        );

        &:before {
          content: '';
          position: absolute;
          top: 1px;
          left: 1px;
          background-color: #0f121c;
          display: block;
          clip-path: polygon(
            100% 0%,
            70% 0%,
            30% 0%,
            0% 0%,
            0% 25%,
            0% 65%,
            2% 100%,
            6% 100%,
            39% 100%,
            64% 100%,
            100% 100%,
            100% 73%,
            100% 47%,
            100% 25%
          );
          -webkit-clip-path: polygon(
            100% 0%,
            70% 0%,
            30% 0%,
            0% 0%,
            0% 25%,
            0% 65%,
            2% 100%,
            6% 100%,
            39% 100%,
            64% 100%,
            100% 100%,
            100% 73%,
            100% 47%,
            100% 25%
          );
        }

        p {
          position: relative;
          color: #fff;
          margin-bottom: 0;
          text-transform: uppercase;
          font-size: 24px;
          font-weight: 400;
          line-height: 20px;
        }
      }

      .attribute-info {
        padding: 10px;
        color: rgba(255,255,255,0.48);
        font-size: 24px;
        font-weight: 400;
        line-height: 28px;
        white-space-collapse: preserve-breaks;

        textarea {
          resize: none;
          width: 100%;
          background: transparent;
          border: none;
          color: rgba(255, 255, 255, 0.48);
          font-size: 24px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          margin-bottom: 0;
          &.infinite-scroll-component{
            height: 100px;
            overflow: auto;
            display: block;
          }
          &.infinite-scroll-component::-webkit-scrollbar-track {
            border: 1px solid #000;
            padding: 2px 0;
            background-color: #000;
          }
        
          &.infinite-scroll-component::-webkit-scrollbar {
              width: 5px;
          }
        
          &.infinite-scroll-component::-webkit-scrollbar-thumb {
              border-radius: 10px;
              box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              background-color: #2f323e;
              border: 1px solid #000;
          }
        }
      }
    }
  }

  .button-row {
    display: flex;
    justify-content: space-evenly;
    .text {
      font-size: 24px;
    }
  }
`

export default ItemInformationModalStyled
