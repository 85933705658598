/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import guildApi from 'apis/guildApi'
import { handleAsyncRequest } from 'utils/helper'
import Loading from '../Loading'

const GuildInfo = () => {
  const [informationData, setInformationData] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const { guild_id } = useParams()

  useEffect(() => {
    getInformationData()
  }, [guild_id])

  const getInformationData = async () => {
    setLoading(true)
    const [error, result]: any = await handleAsyncRequest(
      guild_id ? guildApi.getGuildInfomationById(guild_id) : guildApi.getGuildInfomation()
    )
    setLoading(false)

    if (result) {
      setInformationData(result.data)
    }
  }

  return (
    <div className='guild-info'>
      {loading ? (
        <Loading />
      ) : (
        <div className='head-guild'>
          <div className='contain_img'>
            <img src={informationData?.avatar} alt='avatar' />
          </div>
          <span className='color-yellow'>[{informationData?.guild_tag}]</span>
          <span>{informationData?.name}</span>
        </div>
      )}
    </div>
  )
}

export default GuildInfo
