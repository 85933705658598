import styled from 'styled-components'

const SubMenuStyled = styled.div`
  .sub-menu {
    top: -5px;
    display: none;
    position: relative;
    ul.select-content-dropdown {
      z-index: 1;
      position: absolute;
      left: 0;
      right: 0;
      width: 144px;
      background-color: transparent;
      margin: 0;
      margin-top: 3px;
      padding: 0;
      list-style: none;
      /* border-top: 1px solid #8d8d8d; */
      background: linear-gradient(180deg, rgba(25, 29, 43, 0.8) 0%, #090911 100%);
      li {
        width: 100%;
      }
      a {
        color: #ffffff;
        padding: 6px 12px;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        /* background-color: #10102080; */
        border-left: 1px solid #1b5414;
        border-right: 1px solid #1b5414;
        cursor: pointer;
      }
      a {
        display: block;
        color: #ffffff;
        text-decoration: none;
        font-size: 14px;
        width: 100%;
      }
      a.active {
        background: linear-gradient(
          90deg,
          rgba(227, 109, 2, 0) 6.92%,
          rgb(76 233 82 / 45%) 47.38%,
          rgba(227, 109, 2, 0) 92.56%
        );
      }
      a:hover {
        background: linear-gradient(
          90deg,
          rgba(227, 109, 2, 0) -2.08%,
          rgb(76 233 82 / 45%) 47.38%,
          rgba(227, 109, 2, 0) 101.56%
        );
        filter: grayscale(0.3);
      }

      .polygon {
        display: flex;
        border-right: 1px solid #1b5414;
        .rect {
          width: 100%;
          /* background-color: #10102080; */
          border-left: 1px solid #1b5414;
          border-bottom: 1px solid #1b5414;
        }
      }
    }
  }
`

export default SubMenuStyled
