import { SUB_TRACT } from 'assets/images'
import ItemNFTStyled from './styled'
import IconMerahOrKuda from '../IconMerahOrKuda'
import { RateStar } from 'features/Horse/components'
import { useSearchParams } from 'react-router-dom'
import { NOTIFICATION_MESSAGE } from 'i18n/constants'
import { useTranslation } from 'react-i18next'
interface ItemNFTProps {
  avt?: string
  itemName?: string
  price?: number
  id?: string
  onClick?: (id: string) => void
  type?: string
  isDetail?: boolean
  currency?: string
  level?: string
}

export default function ItemNFT({ level, currency, isDetail, type, onClick, avt, itemName = '', price, id = '', ...props }: ItemNFTProps) {
  const [searchParams] = useSearchParams()
  const paramsSearch = Object.fromEntries(searchParams.entries())
  const { t } = useTranslation()

  const generateRateLevel = (maxLevel: number, currentLevel: number): JSX.Element[] => {
    const rateStars: JSX.Element[] = []
    for (let i = 0; i < maxLevel; i++) {
      rateStars.push(<RateStar key={i} isActive={i < currentLevel} />)
    }
    return rateStars
  }

  return (
    <ItemNFTStyled {...props} >
      <div className={`item ${isDetail && 'detail'} ${type?.toLowerCase()}`}>
        <img className='sub-tract' src={SUB_TRACT} alt="" />
        <img className='avt-item' src={avt} alt="" />
        {!isDetail &&
          <div className='description'>
            {(level && paramsSearch.type === 'DOPING') ? (
              <div className='level d-flex align-items-center justify-content-center'>{generateRateLevel(Number(level), Number(level))}</div>
            ) : ''}
            <h4 className='item-name'>{itemName}</h4>
            <div className='color-white'>
              <IconMerahOrKuda type={currency} price={price || 0} />
            </div>
          </div>
        }
        <div className='btn-detail-container'>
          <button className='font-bold btn-detail' onClick={() => onClick?.(id)} >
            <span className='color-white'>{t(`${NOTIFICATION_MESSAGE}.details`)}</span>
          </button>
        </div>
      </div>
    </ItemNFTStyled>
  )
}
